import { makeFuture } from '../../../utils/future';
import { AdsManager, GImaSdk } from './types';

const loadSdk = () => new Promise<GImaSdk>(
  (res, rej) => {
    const isLoaded = 'google' in window && 'ima' in window.google;

    if (isLoaded) {
      return res(window.google.ima);
    }

    const script = document.createElement('script');

    script.onload = () => {
      res(window.google.ima);
    };

    script.onerror = (ev) => {
      rej(ev);
    };

    script.src = '//imasdk.googleapis.com/js/sdkloader/ima3.js';

    document.body.append(script);
  },
);

export const playAd = async (
  videoElement: HTMLVideoElement,
  wrapperElement: HTMLElement,
  vastUrl: string,
  onAdFinished?: (error?: any) => void,
) => {
  const adsSdk = await loadSdk();

  const adDisplayContainer = new adsSdk.AdDisplayContainer(
    wrapperElement,
    videoElement,
  );

  const adsManagerFuture = makeFuture<AdsManager>();

  const adsLoader = new adsSdk.AdsLoader(adDisplayContainer);
  adsLoader.addEventListener(
    adsSdk.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
    (adsManagerLoadedEvent) => {
      const adsRenderingSettings = new adsSdk.AdsRenderingSettings();
      adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;

      const adsManager = adsManagerLoadedEvent.getAdsManager(videoElement, adsRenderingSettings);
      adsManager.addEventListener(
        adsSdk.AdErrorEvent.Type.AD_ERROR,
        (ev) => {
          adsManager.destroy();
          adDisplayContainer.destroy();
          onAdFinished?.(ev.getError());
        },
      );
      adsManager.addEventListener(
        adsSdk.AdEvent.Type.CONTENT_RESUME_REQUESTED,
        () => {
          adsManager.destroy();
          adDisplayContainer.destroy();
          onAdFinished?.();
        },
      );
      adsManagerFuture.resolve(adsManager);
    },
    false,
  );

  adsLoader.addEventListener(
    adsSdk.AdErrorEvent.Type.AD_ERROR,
    async (ev) => {
      adDisplayContainer.destroy();
      onAdFinished?.(ev.getError());
      (await adsManagerFuture.promise)?.destroy();
    },
    false,
  );

  const adsRequest = new adsSdk.AdsRequest();
  adsRequest.adTagUrl = vastUrl;
  adsRequest.linearAdSlotWidth = videoElement.clientWidth;
  adsRequest.linearAdSlotHeight = videoElement.clientHeight;
  adsRequest.nonLinearAdSlotWidth = videoElement.clientWidth;
  adsRequest.nonLinearAdSlotHeight = videoElement.clientHeight / 3;
  adsLoader.requestAds(adsRequest);

  adDisplayContainer.initialize();

  const manager = await adsManagerFuture.promise;
  manager.init(videoElement.clientWidth, videoElement.clientHeight, adsSdk.ViewMode.NORMAL);
  manager.start();
};
