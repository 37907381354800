// tslint:disable:jsx-boolean-value
import React from 'react';
import qs from 'qs';
import withErrorGuard from 'components/core/withErrorGuard';
import useFooterState from 'hooks/use-footer-state';
import { FooterLink, Sponsor, Social } from 'models/IFooter';
// @ts-ignore
import FeatureGate, { Feature } from 'components/core/FeatureGate';
import { openPopup } from 'utils';
import { getTopLocationUrl } from 'url-utils';
import {
  Container,
  FooterSocialSponsorWrapper,
  StyledTermsOfUse,
  StyledLocaleSelect,
  LocaleWrapper,
  RowContainer,
  FooterSiteInfo,
} from './styles';

import SocialLinks from '../SocialLinks';
// @ts-ignore
import SponsorLinks from '../SponsorLinks';
import FooterLinks from '../FooterLinks';

const BASE_TWEET_URL = 'https://twitter.com/intent/tweet';

export interface FooterProps {
  className?: string;
}

function Footer({
  className = '',
}: FooterProps) {
  const {
    enabled,
    footerLinks,
    fullscreenActive,
    hashtag = '',
    isAdmin,
    onEngage,
    socials,
    sponsors,
    tweetMessage = '',
  } = useFooterState();

  const onFooterEngage = ({ name, image, url }: FooterLink) => {
    onEngage({ data: { kind: 'footer_link', name } }, { kind: image ? 'image' : 'text', url });
  };

  const onSponsorEngage = ({ image, link: url }: Sponsor) => {
    onEngage({ data: { kind: 'sponsor', name: image } }, { url });
  };

  const onHashtagEngage = () => {
    onEngage({ data: { kind: 'hashtag', name: hashtag } });

    const query = qs.stringify({
      hashtags: hashtag,
      text: tweetMessage.trim(),
      url: getTopLocationUrl(),
    });

    openPopup(650, 400, `${BASE_TWEET_URL}?${query}`, 'Twitter');
  };

  const onSocialEngage = ({ name, url }: Social) => {
    onEngage({ data: { kind: 'social', name } }, { url });
  };

  return enabled ? (
    <Container className={className} id="FOOTER_CONTENT_ROOT_NODE">
      {!fullscreenActive && (
      <>
        <FooterSocialSponsorWrapper hideLine={!hashtag && !socials?.length && !sponsors?.length}>
          <SocialLinks
            hashtag={hashtag}
            isAdmin={isAdmin}
            onHashtagEngage={onHashtagEngage}
            onSocialEngage={onSocialEngage}
            socials={socials || []}
            tweetMessage={tweetMessage}
          />
          <SponsorLinks
            isAdmin={isAdmin}
            onSponsorEngage={onSponsorEngage}
            sponsors={sponsors || []}
          />
        </FooterSocialSponsorWrapper>
        <FooterSiteInfo>
          <RowContainer>
            <FooterLinks
              footerLinks={footerLinks || []}
              onFooterEngage={onFooterEngage}
            />
            <FeatureGate feature={Feature.HIDE_COPYRIGHT}>
              {(hideMaestroCopyright: boolean) => hideMaestroCopyright ? null : <StyledTermsOfUse />}
            </FeatureGate>
            <LocaleWrapper>
              <StyledLocaleSelect />
            </LocaleWrapper>
          </RowContainer>
        </FooterSiteInfo>
      </>
      )}
    </Container>
  ) : null;
}

export default withErrorGuard()(Footer);
