import React from 'react';
import { PaymentMethod } from '@stripe/stripe-js';
import AddNewPaymentMethodButton from 'components/payment/PaymentMethodsV3/AddNewPaymentMethodButton';
import SinglePaymentMethodCard from 'components/payment/PaymentMethodsV3/SinglePaymentMethodCard';
import PurchaseSummary from 'components/payment/PurchaseSummary';
import Checkbox from 'components/ui/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { EcommerceView, pushEcommerceView, setLoggedOutUserEmail, submitShopifyCheckout, triggerShopifyCheckout } from 'services/ecommerce';
import GoBack from '../GoBack';
import useEcommerceCart from 'hooks/ecommerce/useEcommerceCart';
import CartProduct from '../Cart/CartProduct';
import { useAdminTranslation } from 'hooks/use-translation';
import { BodySection, BodySectionTitle, StyledButton } from '../sharedStyles';
import { Body, CartInfo, CartQuantity, CartQuantityText, CheckboxContainer, ErrorMessage, Footer, MyCartText, ReturnPolicyAndTerms } from './styles';
import Spinner from 'components/ui/Spinner';
import ShopifyShippingMethods from './ShippingMethods';
import useCheckout from './use-checkout';
import { getEcommerceView, getLoggedOutUserEmail, getShippingMethod, getShopifyBlockData, getShopifyTransaction } from 'services/ecommerce/selectors';
import { getUserEmail } from 'services/auth';
import { getIsShippingAddressComplete, getShippingAddress } from 'services/shipping-address';
import FormInput from 'components/ui/v2/Inputs';
import CheckoutShippingAddress from './CheckoutShippingAddress';
import CheckoutBillingAddress from './CheckoutBillingAddress';
import Link from 'components/ui/Link';

interface ICheckoutProps {
  paymentMethod: PaymentMethod;
  stripeTokenId: string | null;
}

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const Checkout: React.FC<ICheckoutProps> = ({ paymentMethod, stripeTokenId }) => {
  const ecommerceView = useSelector(getEcommerceView);
  const loggedEmail = useSelector(getUserEmail);
  const shopifyTransaction = useSelector(getShopifyTransaction);
  const shippingAddress = useSelector(getShippingAddress);
  const isShippingAddressComplete = useSelector(getIsShippingAddressComplete);
  const { returnPolicy, termsOfUse } = useSelector(getShopifyBlockData) || {};
  const dispatch = useDispatch();
  const { t } = useAdminTranslation();
  const { productsData, shouldPreventCheckout, preventShopifyCheckoutError } = useEcommerceCart();
  const {
    cartItems,
    cartItemsQuantity,
    shopifyCheckoutAvailableShippingLines,
    shopifyCheckoutError,
    shopifyCurrencyCode,
    shopifyTotalTax,
    total,
    shopifyCheckoutLoading,
    shopifyPaymentProcessing,
    billingSameAsShipping,
    setBillingSameAsShipping,
  } = useCheckout();
  const shippingHandle = useSelector(getShippingMethod);
  const loggedOutEmail = useSelector(getLoggedOutUserEmail);

  const selectedShippingRate = shopifyCheckoutAvailableShippingLines?.shippingRates.find(shippingRate => shippingRate.handle === shippingHandle);

  const renderPaymentMethodCard = React.useCallback(() => {
    const navigate = (view: EcommerceView) => () => {
      dispatch(pushEcommerceView(view));
    };

    if (!paymentMethod) {
      return <AddNewPaymentMethodButton onClick={navigate('addPaymentMethod')}>+ {t('ADD_NEW_PAYMENT_METHOD')}</AddNewPaymentMethodButton>;
    }

    return <SinglePaymentMethodCard paymentMethod={paymentMethod} onChange={navigate('choosePaymentMethod')}/>;
  }, [paymentMethod, dispatch]);


  const purchaseSummaryItems = React.useMemo(() => {
    const items = [
      {
        name: `${t('SUBTOTAL')} (${cartItems.length})`,
        price: total,
      },
    ];

    if (selectedShippingRate) {
      items.push({
        name: `${t('LABEL_SHIPPING_COST')}: ${selectedShippingRate.title}`,
        price: Number(selectedShippingRate.priceV2.amount),
      });
    }

    if (shopifyTotalTax && Number(shopifyTotalTax.amount) > 0) {
      items.push({
        name: t('SALES_TAX'),
        price: Number(shopifyTotalTax.amount),
      });
    }

    return items;
  }, [cartItems, selectedShippingRate, shopifyTotalTax, total]);

  const email = !loggedEmail ? loggedOutEmail : (loggedEmail || '');

  React.useEffect(() => {
    const shouldBlockTrigger = !isShippingAddressComplete || cartItems.length === 0 || shopifyTransaction || !email || !EMAIL_REGEX.test(email);
    if (shouldBlockTrigger) {
      return;
    }

    dispatch(triggerShopifyCheckout({ email }));
  }, [shippingAddress, isShippingAddressComplete, cartItems, dispatch, shopifyTransaction, email]);

  const handleSubmit = React.useCallback(() => {
    if (shopifyCheckoutError) {
      return;
    }

    dispatch(submitShopifyCheckout({
      paymentMethod,
      billingAddressSameAsShipping: billingSameAsShipping,
      stripeTokenId,
      email,
    }));
  }, [dispatch, paymentMethod, billingSameAsShipping, stripeTokenId, email, shopifyCheckoutError]);

  const renderCtaContent = React.useCallback(() => {
    if (shopifyPaymentProcessing) {
      return <Spinner style={{ width: '16px' }} />;
    }

    if (shopifyCheckoutLoading) {
      return t('LOADING_SHOPIFY_CHECKOUT');
    }

    return t('PAY_NOW');
  }, [shopifyPaymentProcessing, shopifyCheckoutLoading, t]);

  const handleLoggedOutUserEmail = React.useCallback((value: string) => {
    dispatch(setLoggedOutUserEmail(value));
  }, [dispatch]);

  return (
    <>
      {ecommerceView !== 'checkoutOverlay' && <GoBack>{t('GO_BACK_TO_CART')}</GoBack>}
      <CartInfo shopifyPaymentProcessing={shopifyPaymentProcessing}>
        <CartQuantity>
          <MyCartText>{t('MY_CART')}</MyCartText>
          <CartQuantityText>{cartItemsQuantity} {cartItemsQuantity > 1 ? t('ITEMS') : t('ITEM')}</CartQuantityText>
        </CartQuantity>
        {
          productsData.map(({ product, preSelectedOptions }) => {
          return (
            <CartProduct
              key={product.id}
              cartCurrency={shopifyCurrencyCode}
              preSelectedOptions={preSelectedOptions}
              product={product}
            />
          );
        })}
      </CartInfo>
      <Body>
        {billingSameAsShipping && <CheckoutShippingAddress />}
        {!loggedEmail && (
          <FormInput.Root error={loggedOutEmail.length > 0 && !EMAIL_REGEX.test(loggedOutEmail)}>
            <FormInput.FieldSet>
              <FormInput.Legend>{t('EMAIL')}</FormInput.Legend>
              <FormInput.TextInput
                type="email"
                value={loggedOutEmail}
                placeholder={t('ADMIN_EMAIL_ADRESS')}
                onChange={handleLoggedOutUserEmail}
                disabled={shopifyPaymentProcessing}
              />
            </FormInput.FieldSet>
            {loggedOutEmail.length > 0 && !EMAIL_REGEX.test(loggedOutEmail) && <FormInput.SupportingText>{t('REQUIRED_FIELD')}</FormInput.SupportingText>}
          </FormInput.Root>
        )}
        <ShopifyShippingMethods shippingHandle={shippingHandle} />
        <BodySection shopifyPaymentProcessing={shopifyPaymentProcessing}>
          <BodySectionTitle>{t('PAYMENT_METHOD')}</BodySectionTitle>
          {renderPaymentMethodCard()}
        </BodySection>
        <CheckboxContainer htmlFor="billing-address-same-as-shipping" shopifyPaymentProcessing={shopifyPaymentProcessing}>
          <Checkbox
            checked={billingSameAsShipping}
            data-testid="billing-address-same-as-shipping"
            onChangeChecked={setBillingSameAsShipping}
            id="billing-address-same-as-shipping"
            size={18}
          />
          {t('BILLING_ADDRESS_IS_SAME_AS_SHIPPING')}
        </CheckboxContainer>
        {!billingSameAsShipping && <CheckoutBillingAddress />}
      </Body>
      <PurchaseSummary items={purchaseSummaryItems} currency={shopifyCurrencyCode} />
      <Footer>
        <StyledButton disabled={shopifyPaymentProcessing || shopifyCheckoutLoading || shouldPreventCheckout} onClick={handleSubmit}>
          {renderCtaContent()}
        </StyledButton>
        {(shouldPreventCheckout || shopifyCheckoutError) && <ErrorMessage>{(preventShopifyCheckoutError || shopifyCheckoutError)}</ErrorMessage>}

        {(returnPolicy || termsOfUse) && (
          <ReturnPolicyAndTerms>
            {returnPolicy && <Link target="_blank" href={returnPolicy}>{t('ADMIN_LABEL_RETURN_POLICY')}</Link>}
            {returnPolicy && termsOfUse && (' ' + '&' + ' ')}
            {termsOfUse && <Link target="_blank" href={termsOfUse}>{t('TERMS_OF_SERVICE')}</Link>}
          </ReturnPolicyAndTerms>
        )}
      </Footer>
    </>
  );
};

export default Checkout;
