// tslint:disable:no-console
import React, { memo, useCallback, useRef, useState } from 'react';
import { waitForUserInteraction } from 'services/interaction-observer';
import { makeFuture } from 'utils/future';
import { playAd } from './ImaSdk';
import { AdVideo, Wrapper } from './styles';

export type VastAdPlayerProps = {
  onBeforePlay?: () => Promise<unknown>;
  onFinished?: (error?: any) => unknown;
  vastUrl: string;
};

export const VastAdPlayer = memo(
  (
    {
      vastUrl,
      onFinished,
      onBeforePlay,
    }: VastAdPlayerProps,
  ) => {
    const cleanupCallback = useRef<() => void>();
    const [ready, setReady] = useState(false);

    const wireUpAds = useCallback(
      (videoEl: HTMLVideoElement | null) => {
        cleanupCallback.current?.();

        if (!videoEl) {
          return;
        }

        const abortSignal = {};
        const abortFuture = makeFuture<{}>();

        Promise.race([
          waitForUserInteraction,
          abortFuture.promise,
        ])
          .then((value) => {
            if (value === abortSignal) {
              return;
            }

            return Promise.race([
              (onBeforePlay?.() ?? Promise.resolve()),
              abortFuture.promise,
            ]);
          })
          .then((value) => {
            if (value === abortSignal) {
              return;
            }

            setReady(true);

            playAd(
              videoEl,
              videoEl.parentElement!,
              vastUrl,
              onFinished,
            )
              .catch((playAdError) => {
                console.error('Error in VastAdPlayer:', playAdError);
              });
          })
          .catch((onBeforePlayError) => {
            console.error('VastAdPlayer: onBeforePlay() rejected', onBeforePlayError);
            onFinished?.(onBeforePlayError);
          });

        cleanupCallback.current = () => {
          abortFuture.resolve(abortSignal);
        };
      },
      [vastUrl, onFinished, onBeforePlay],
    );

    return (
      <Wrapper $ready={ready}>
        <AdVideo ref={wireUpAds} />
      </Wrapper>
    );
  },
);
