import React from 'react';
import { useSelector } from 'react-redux';
import { NAVIGATION_BLOCK_SITE_NAVIGATION_EXTERNAL_LINKS_ID } from 'global-ids';
import { getDefaultNavigation } from 'services/navigationv2';
import ExternalLink from './ExternalLink';
import { Container, ExternalLinkList, Title } from './styles';
import { useAdminTranslation } from 'hooks/use-translation';

const SiteNavigationExternalLinks = () => {
  const { t } = useAdminTranslation();
  const defaultNavigation = useSelector(getDefaultNavigation);

  return (
    <Container
      id={NAVIGATION_BLOCK_SITE_NAVIGATION_EXTERNAL_LINKS_ID}
      data-testid={NAVIGATION_BLOCK_SITE_NAVIGATION_EXTERNAL_LINKS_ID}
    >
      <Title>{t('EXTERNAL_LINKS')}</Title>
      <ExternalLinkList>
        {defaultNavigation.externalLinks.map(link => (
          <ExternalLink
            key={link.url}
            link={link}
            navigationId={defaultNavigation._id!}
          />
        ))}
      </ExternalLinkList>
    </Container>
  );
};

export default SiteNavigationExternalLinks;
