import Icon, { IProps as IIconProps } from 'components/ui/Icon';
import Link from 'components/ui/Link';
import { Line } from 'components/ui/Skeleton/styles';
import {
  SURFACE_2,
  SURFACE_3,
  SURFACE_4,
  SURFACE_5,
  TEXT_100,
  TEXT_200,
} from 'style/constants';
import { TEXT_LABEL_M_MEDIUM } from 'style/design-system/textStyles';
import styled, { css } from 'styled-components';

const MENU_APPEARING_ANIMATION = css`
  @keyframes fadeIn {
    from {
      transform: translateY(-30px);
    }
    to {
      transform: translateY(0);
    }
  }
  animation: fadeIn 0.1s;
`;

const SUB_MENU_APPEARING_ANIMATION = css`
  @keyframes fadeIn {
    from {
      transform: translateX(-25px);
    }
    to {
      transform: translateX(0);
    }
  }
  animation: fadeIn 0.2s;
`;

export const FloatingMenuContainer = styled.div<{ hasChildren: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${SURFACE_2};
  border-radius: 10px;
  border: 1px solid ${SURFACE_3};
  max-height: 50vh;
  overflow-y: auto;
  ${({ hasChildren }) =>
    hasChildren &&
    css`
      flex-direction: row;
    `}
  ${MENU_APPEARING_ANIMATION}
`;

export const HoverContainer = styled.div`
  padding-top: 16px;
  position: fixed;
  z-index: 1;
  transform: translate(0);
`;

export const FloatingColumnContianer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FloatingParentContainer = styled.div<{
  forLinks?: boolean;
  hasChildren?: boolean;
  needBottom?: boolean;
}>`
  ${({ needBottom }) =>
    needBottom &&
    css`
      border-bottom: 1px solid ${SURFACE_4};
    `}
  width: 100%;
`;

export const FloatingParentColumn = styled(FloatingColumnContianer)<{
  applyBorder?: boolean;
  forLinks?: boolean;
  needBasePadding?: boolean;
}>`
  height: 100%;
  width: 100%;
  align-items: flex-start;
  align-self: stretch;

  ${({ applyBorder }) =>
    applyBorder &&
    css`
      border-right: 1px solid ${SURFACE_4};
    `}
`;

export const StyledDivider = styled(Line).attrs((props) => ({
  color: SURFACE_5(props),
}))`
  height: 1px;
  width: 100%;
  margin-bottom: 0px;
`;

export const FloatingColumnContianerOverflow = styled(FloatingColumnContianer)<{
  applyBorder?: boolean;
}>`
  align-items: flex-start;
  align-self: stretch;
  ${({ applyBorder }) =>
    applyBorder &&
    css`
      padding-left: 15px;
      border-left: 1px solid ${SURFACE_4};
    `}
  ${SUB_MENU_APPEARING_ANIMATION}
`;

export const SubMenuItem = styled(Link)<{
  hasChildren?: boolean;
  isCurrentPage?: boolean;
  isFolder?: boolean;
}>`
  color: ${({ isCurrentPage }) => (isCurrentPage ? TEXT_100 : TEXT_200)};
  ${TEXT_LABEL_M_MEDIUM}
  text-decoration: none;
  cursor: default;
  display: flex;
  flex-direction: row;
  padding: 16px 28px;
  border-radius: 10px;
  width: 100%;
  overflow: hidden;

  &:hover {
    ${({ isFolder }) =>
      !isFolder &&
      css`
        cursor: pointer;
      `}
    color: ${TEXT_100};
    transition: color 0.3s;
    background-color: ${SURFACE_4};
  }
`;

export const RightArrow = styled(Icon).attrs({
  name: 'arrowRight',
})`
  margin-left: 7px;
  display: flex;
  align-items: center;
  svg {
    height: 10px;
    path {
      fill: ${TEXT_200};
    }
  }
`;

interface IDownArrowProps extends IIconProps {
  flipped?: boolean;
}

export const DownArrow = styled(Icon).attrs((props: IDownArrowProps) => ({
  name: 'downArrow',
}))`
  margin-left: 7px;
  svg {
    width: 18px;
    path {
      fill: ${TEXT_200};
    }
  }
  ${({ flipped }: IDownArrowProps) =>
    flipped
      ? css`
          transform: scaleY(-1);
          transition: transform 0.4s;
        `
      : css`
          transform: scaleY(1);
          transition: transform 0.4s;
        `}
`;
