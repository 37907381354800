import React, { MouseEvent } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device';
import { DARK_THEME } from 'services/themes/constants';
import { desktopOnly, mobileOnly, portraitOnly, SCROLL_BAR } from 'style/mixins';
import { MAESTRO_WHITE } from 'style/constants';
import { useVideoPlayerContext } from 'components/Video/video-player/Player/Context';
import KeyedListMapper from 'components/core/KeyedListMapper';
import ConditionalWrapper from 'components/ui/ConditionalWrapper';
import Icon from 'components/ui/Icon';
import TranslatedText from 'components/i18n/TranslatedText';
import ModalPortal from 'components/modals/ModalPortal';
import { useFullScreenDetector } from 'contexts/FullScreenDetectorContext';
import { ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';

const BACKGROUND_DESKTOP = DARK_THEME.colors.surface1;
const BACKGROUND_BACKDROP = DARK_THEME.colors.surface1;
const BACKGROUND_MOBILE = DARK_THEME.colors.surface2;
const BACKGROUND_SELECTED = DARK_THEME.colors.surface4;
const COLOR_NORMAL = DARK_THEME.colors.text200;
const COLOR_ACTIVE = DARK_THEME.colors.text100;
const DIVIDER_COLOR_DESKTOP = DARK_THEME.colors.surface5;
const DIVIDER_COLOR_MOBILE = DARK_THEME.colors.surface4;

interface LanguageSelectorProps {
  onClose: () => void;
  open: boolean;
}

export default function({ onClose, open }: LanguageSelectorProps) {
  const { subtitleOptions, selectedSubtitle, setSelectedSubtitle } = useVideoPlayerContext();
  const isMobile = useSelector(isMobileLayout);
  const { isDocumentInFullScreen } = useFullScreenDetector();
  const fixedPosition = isMobile && !isDocumentInFullScreen;

  return open ? (
    <ConditionalWrapper wrap={fixedPosition} wrapper={ModalPortal}>
      {fixedPosition && <Backdrop onClick={onClose} />}
      <LanguageSelector>
        <TranslatedText
          stringKey="ADMIN_LABEL_SUBTITLES"
          component={Title}
        />
        <Languages>
          <KeyedListMapper list={subtitleOptions} keyField="label">
            {(key, option) => {
              const isSelected = selectedSubtitle.value === option.value;
              const selectSubtitle = (e: MouseEvent) => {
                if (e.button === 0) {
                  setSelectedSubtitle(option);
                  onClose();
                }
              };
              return (
                <LanguageOption key={key} isSelected={isSelected} onClick={selectSubtitle}>
                  {isSelected && <OptionIcon name="checked" />}
                  <OptionName>{option.label}</OptionName>
                </LanguageOption>
              );
            }}
          </KeyedListMapper>
        </Languages>
        {isMobile && (
          <Cancel onClick={onClose}>
            <OptionIcon name="closeThin" />
            <TranslatedText stringKey="ADMIN_ACTION_CANCEL" component={OptionName} />
          </Cancel>
        )}
      </LanguageSelector>
    </ConditionalWrapper>
  ) : null;
}

const LanguageSelector = styled.div`
  display: flex;
  flex-direction: column;
  cursor: default;
  ${desktopOnly`
    border-radius: 4px;
    max-height: 260px;
    min-width: 200px;
    background: ${BACKGROUND_DESKTOP};
    position: absolute;
    bottom: calc(100% + 13px);
    right: 0;
  `}
  ${mobileOnly`
    width: 100%;
    max-height: 390px;
    background: ${BACKGROUND_MOBILE};
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
  `}
  ${portraitOnly`
    padding-bottom: 30px;
  `}
`;

const Backdrop = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background: ${BACKGROUND_BACKDROP};
  opacity: 0.77;
`;

const Title = styled.span`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  weight: 700;
  padding-inline: 16px;
  border-bottom: 1px solid;
  color: ${COLOR_ACTIVE};
  text-align: left;
  ${desktopOnly`
    padding-block: 14px;
    line-height: 16px;
    border-color:  ${DIVIDER_COLOR_DESKTOP};
  `}
  ${mobileOnly`
    padding-block: 16px;
    font-size: 16px;
    line-height: 18px;
    border-color:  ${DIVIDER_COLOR_MOBILE};
  `}
`;

const Languages = styled.section`
  ${SCROLL_BAR}
  scrollbar-color: ${MAESTRO_WHITE} transparent;
  & ::-webkit-scrollbar-thumb {
    background: ${MAESTRO_WHITE};
  }
  & ::-webkit-scrollbar {
    width: 4px;
  }
  flex: 1;
`;

const OPTION_STYLE = css`
  display: flex;
  cursor: pointer;
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${COLOR_NORMAL};
  &:hover {
    background: ${BACKGROUND_SELECTED};
    color: ${COLOR_ACTIVE};
  }
`;

const Cancel = styled.div`
  ${OPTION_STYLE}
  border-top: 1px solid ${DIVIDER_COLOR_MOBILE};
`;

const LanguageOption = styled.div<{ isSelected?: boolean }>`
  ${OPTION_STYLE}
  ${({ isSelected }) => isSelected ? `
    background: ${BACKGROUND_SELECTED};
    color: ${COLOR_ACTIVE};
  ` : '--language-name-margin-left: 40px;'}
`;

const OptionIcon = styled(Icon)`
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${MAESTRO_WHITE};
  & svg {
    width: 12px;
    height: 9.5px;
  }
`;

const OptionName = styled.span`
  display: flex;
  padding: 12px 8px;
  margin-left: var(--language-name-margin-left, 0);
`;
