import { INavigationParent } from 'models/INavigation';
import React from 'react';
import { NAVIGATION_BAR_ITEM_ID } from 'global-ids';
import { FloatingMenuContainer, HoverContainer, SubMenuItem } from './styles';
import { OTHERS_ID } from '../utils';
import { useNavigationBarContext } from '../NavigationBarContext';
import { useSelector } from 'react-redux';
import { getPageId } from 'services/app';

interface IFloatingNavigationMenuProps {
  navParent?: INavigationParent;
}

const FloatingNavigationMenu: React.FC<IFloatingNavigationMenuProps> = ({
  navParent,
}) => {
  const currentPageId = useSelector(getPageId);
  const isCurrentPage = (id: string) => currentPageId === id;
  const { setFloatMenuId, getPathBySlug } = useNavigationBarContext();
  const handleHoverEnter = () => {
    const id = navParent?.id ?? OTHERS_ID;
    setFloatMenuId(id);
  };

  const handleHoverLeave = () => {
    setFloatMenuId(null);
  };

  if (!navParent) return null;

  const { children } = navParent;

  if (!children.length) return null;

  return (
    <HoverContainer
      onMouseEnter={handleHoverEnter}
      onMouseLeave={handleHoverLeave}
    >
      <FloatingMenuContainer hasChildren={false}>
        {children.map((child, index) => (
          <SubMenuItem
            href={getPathBySlug(child.slug)}
            key={`floating-nav-submenu-item-${index}`}
            data-testid={NAVIGATION_BAR_ITEM_ID}
            isCurrentPage={isCurrentPage(child.id)}
          >
            {child.name}
          </SubMenuItem>
        ))}
      </FloatingMenuContainer>
    </HoverContainer>
  );
};

export default FloatingNavigationMenu;
