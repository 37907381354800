import { ALERT_ERROR, SURFACE_4, TEXT_100, TEXT_200, TEXT_300 } from 'style/constants';
import { TEXT_LABEL_L_MEDIUM, TEXT_LABEL_M_BOLD, TEXT_LABEL_M_MEDIUM, TEXT_LABEL_S_BOLD, TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';
import { SINGLE_LINE_ELLIPSIS } from 'style/mixins';
import styled, { css } from 'styled-components';
import Spinner from 'components/ui/Spinner';

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  text-align: center;
`;

export const ErrorMessage = styled.span`
  ${TEXT_LABEL_S_BOLD}
  color: ${ALERT_ERROR};
  align-self: stretch;
  margin-top: 10px;
`;

export const ShippingRateInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;

  & span {
    ${TEXT_LABEL_M_MEDIUM}
  }
`;

export const ShippingRateName = styled.span`
  ${TEXT_LABEL_M_BOLD}
  color: ${TEXT_100};
  ${SINGLE_LINE_ELLIPSIS}
  max-width: 125px;
`;

export const ReturnPolicyAndTerms = styled.label`
  ${TEXT_LABEL_S_MEDIUM}
  color: ${TEXT_300};
  margin-top: 20px;
`;

export const CheckboxContainer = styled.label<{ shopifyPaymentProcessing: boolean; }>`
  ${TEXT_LABEL_M_MEDIUM}
  color: ${TEXT_200};
  display: flex;
  align-items: center;
  gap: 12px;
  width: fit-content;
  align-self: baseline;

  ${({ shopifyPaymentProcessing }) => shopifyPaymentProcessing && css`
    opacity: 0.5;
    pointer-events: none;
  `}
`;

export const CartInfo = styled.div<{ shopifyPaymentProcessing: boolean; }>`
  align-self: stretch;
  ${({ shopifyPaymentProcessing }) => shopifyPaymentProcessing && css`
    opacity: 0.5;
    pointer-events: none;
  `}
`;

export const CartQuantity = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-top: 1px solid ${SURFACE_4};
  padding: 13px 0;
`;

export const MyCartText = styled.span`
  ${TEXT_LABEL_L_MEDIUM}
  color: ${TEXT_100};
  flex: 1;
`;

export const CartQuantityText = styled.span`
  ${TEXT_LABEL_M_MEDIUM}
  color: ${TEXT_300};
`;

export const Body = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  align-self: stretch;
`;

export const StyledSpinner = styled(Spinner).attrs(({
 style: { width: '50px' },
}))``;
