import React from 'react';
import OpenLinkInputs from './OpenLinkInputs';
import GoToPageInputs from './GoToPageInputs';
import { CtaBehaviorData, isGoToPageBehavior, isOpenLinkBehavior } from '../utils';

export type BehaviorsProps<T extends CtaBehaviorData = CtaBehaviorData> = {
  behavior: T;
  onChange: (behavior: T) => void;
};

const Behaviors = ({ behavior, onChange }: BehaviorsProps) => {
  if (isOpenLinkBehavior(behavior)) {
    return <OpenLinkInputs behavior={behavior} onChange={onChange} />;
  }

  if (isGoToPageBehavior(behavior)) {
    return <GoToPageInputs behavior={behavior} onChange={onChange} />;
  }

  return null;
};

export default Behaviors;
