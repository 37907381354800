import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { PlayerState } from './use-player-state';

export type SubtitleOption = { label: string; value: number };

export type VideoPlayerContextState = {
  concurrents: number;
  playerState: PlayerState;
  selectedSubtitle: SubtitleOption;
  setSelectedSubtitle: Dispatch<SetStateAction<SubtitleOption>>;
  setSubtitleOptions: Dispatch<SetStateAction<SubtitleOption[]>>;
  subtitleOptions: SubtitleOption[];
};

export const SUBTITLES_OFF: SubtitleOption = { label: 'off', value: -1 };

export const VideoPlayerContext = createContext(
  null as unknown as VideoPlayerContextState,
);

export default VideoPlayerContext.Provider;

export const useVideoPlayerContext = () => useContext(VideoPlayerContext);

export const useHighFrequencyPlaybackStateUpdates = (updatePeriodMs = 300) => {
  const {
    playerState: { highFrequencyPlaybackState },
  } = useVideoPlayerContext();
  const [values, setValues] = useState(highFrequencyPlaybackState.current);

  useEffect(() => {
    const updateFn = () => {
      const newState = highFrequencyPlaybackState.current;
      setValues({ ...newState });
    };

    updateFn();
    const interval = setInterval(updateFn, updatePeriodMs);

    return () => {
      clearInterval(interval);
    };
  }, [updatePeriodMs]);

  return values;
};
