import React from 'react';
import { GRID_TEXT_ALIGNMENT, GRID_TEXT_ALIGNMENT_WORD_MAP, GridTextAlignmentOptions } from './types';
import { Example, Grid, Button, Dot, First, Second, SelectedWrapper, Third, Wrapper } from './styles';

interface IGridTextAlignmentProps {
  alignment: GridTextAlignmentOptions;
  setAlignment: (alignment: GridTextAlignmentOptions) => void;
}

const GridTextAlignment: React.FC<IGridTextAlignmentProps> = ({ setAlignment, alignment }) => {
  const renderContent = React.useCallback((alignmentOption: GridTextAlignmentOptions) => {
    if (alignmentOption !== alignment) {
      return <Dot />;
    }

    return (
      <SelectedWrapper alignment={alignment}>
        <First />
        <Second />
        <Third />
      </SelectedWrapper>
    );
  }, [alignment]);

  const handleSelectAlignmentOption = (alignmentOption: GridTextAlignmentOptions) => () => {
    setAlignment(alignmentOption);
  };

  return (
    <Wrapper>
      <Grid>
        {
          Object.values(GRID_TEXT_ALIGNMENT).map((alignmentOption) => (
            <Button key={alignmentOption} alignment={alignmentOption} onClick={handleSelectAlignmentOption(alignmentOption)}>
              {renderContent(alignmentOption)}
            </Button>
          ))
        }
      </Grid>
      <Example alignment={alignment}>
        {GRID_TEXT_ALIGNMENT_WORD_MAP[alignment]}
      </Example>
    </Wrapper>
  );
};

export default GridTextAlignment;
