import styled from 'styled-components';
import { CloseButton as AdminCloseButton } from 'components/admin2/ui/Button';

export const VideoModalContainer = styled.div`
  position: absolute;
  background: rgba(0,0,0,0.8);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
`;

export const CloseButton = styled(AdminCloseButton).attrs({
  containerCss: `
    position: absolute;
    top: 13px;
    right: 16px;
    z-index: 1;
  `,
})``;
