import React, { FC } from 'react';
import { secondsToFormattedTime } from 'utils';
import { pickFromVideoOrFile } from 'utils/video';
import UploaderProgressBar from 'components/admin2/UploaderProgressBar';
import {
  NameCellWrapper,
  NameCellLabel,
  NameCellDetails,
} from './styles';
import type { IVideoItem } from './VideoLibraryList';
import { IVideoUploadJobStatus } from 'services/video-upload/state';
import { TranscodingStatuses } from 'models';
import { useVideoUploadProgress } from 'services/video-upload/hooks/useVideoUploadProgress';
import { injectT, useAdminTranslation } from 'hooks/use-translation';

export const NameCell: FC<{
  id: number,
  onRetryUpload: (id: string) => void;
  video: IVideoItem;
}> = ({ id, video, onRetryUpload }) => {
  const { t } = useAdminTranslation();
  const handleOnRefresh = () => onRetryUpload(video.id);
  const duration = pickFromVideoOrFile(video, 'durationSeconds');
  const transcodingStatus = pickFromVideoOrFile(video, 'uploadStatus');
  const uploadStatus = pickFromVideoOrFile(video, undefined, 'uploadingStatus');
  const modifiedDate = pickFromVideoOrFile(video, 'modified');
  const videoUploadProgressId = pickFromVideoOrFile(video, undefined, 'id');

  const transcodingText = transcodingStatus === TranscodingStatuses.pending
    ? 'Transcoding'
    : undefined;

  const progress = useVideoUploadProgress(
    videoUploadProgressId,
    !!uploadStatus,
  );

  const progressFallbackValue = uploadStatus === IVideoUploadJobStatus.COMPLETE
    ? 100
    : 0;

  return (
    <NameCellWrapper>
      <NameCellLabel data-testid={`videoTitle-${id}`}>{video.title}</NameCellLabel>
      <NameCellDetails data-testid={`videoDetails-${id}`}>
        <p>
          {!!duration && (
            <>
              {secondsToFormattedTime(duration)} &bull;{' '}
            </>
          )}
          {getModifiedText(t, modifiedDate)}
        </p>
        <div className="progressbar-wrapper">
          <UploaderProgressBar
            data-testid={`upload-progress-${id}`}
            hasFailed={IVideoUploadJobStatus.ERROR === uploadStatus}
            onRetryUpload={handleOnRefresh}
            progress={progress ?? progressFallbackValue}
            showLoader={transcodingStatus === TranscodingStatuses.pending}
            status={(uploadStatus ?? transcodingText)!}
          />
        </div>
      </NameCellDetails>
    </NameCellWrapper>
  );
};

const getModifiedText = injectT(
  (t) => (modified?: number) => {
    if (!modified)
      return t('ADMIN_LABEL_JUST_NOW');

    const diffInMinutes = Math.round((Date.now() - modified) / 60000);

    if (diffInMinutes === 0)
      return t('ADMIN_LABEL_JUST_NOW');

    if (diffInMinutes < 60)
      return t('ADMIN_RELATIVE_TIME_X_MINUTES_AGO', { minutes: diffInMinutes });

    if (diffInMinutes < 24 * 60)
      return t('ADMIN_RELATIVE_TIME_X_HOURS_AGO', { hours: Math.round(diffInMinutes / 60) });

    return new Date(modified).toLocaleString();
  },
);
