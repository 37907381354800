import { connect } from 'react-redux';
import { isLandscape, isMobileLayout } from 'services/device';
import withErrorGuard from 'components/core/withErrorGuard';
import {
  getCountdownTitle,
  getCountdownStartTime,
  getGateLogo,
  getGateLogoLink,
  getGateLogoTitle,
  isLandingPageType,
  hasHeader,
} from 'services/app/selectors';
import { isFullscreenActive, isSidebarVisible } from 'services/user-layout';
import {
  isEditMode,
  inAccessMenu,
  getPendingPageCountdown,
  getPendingOrActualLogoLink,
  getPendingOrActualLogoTitle,
  getPendingOrActualLogoUrl,
} from 'services/admin/selectors';
import { isUserAdmin } from 'services/auth';
import { trackHeader } from 'services/insights';
import { onNavClick } from 'services/third-party-analytics';
import {
  isAdminViewingAccessTab,
  isGateActive,
  isChannelGateEnabled,
} from 'services/gate/selectors';
import Header from './view';

const mapStateToProps = state => ({
  countdownTitle: getPendingPageCountdown(state)?.title || getCountdownTitle(state),
  fullscreenActive: isFullscreenActive(state),
  gateLogo: getGateLogo(state),
  gateLogoLink: getGateLogoLink(state),
  gateLogoTitle: getGateLogoTitle(state),
  hasHeader: hasHeader(state),
  isAdmin: isUserAdmin(state),
  isAdminViewingAccessTab: isAdminViewingAccessTab(state),
  isChannelGateEnabled: isChannelGateEnabled(state),
  isEditMode: isEditMode(state),
  isGateActive: isGateActive(state),
  isInAccessMenu: inAccessMenu(state),
  isLanding: isLandingPageType(state),
  isLandscape: isLandscape(state),
  isMobileLayout: isMobileLayout(state),
  logoLink: getPendingOrActualLogoLink(state),
  logoTitle: getPendingOrActualLogoTitle(state),
  logoUrl: getPendingOrActualLogoUrl(state),
  shouldShowLogin: !isSidebarVisible(state),
  startTime: getPendingPageCountdown(state)?.startTime || getCountdownStartTime(state),
});

const mapDispatchToProps = dispatch => ({
  trackHeader: (doc, extra) => {
    dispatch(trackHeader('engage', doc, extra));
    dispatch(onNavClick(doc.title));
  },
});

export default withErrorGuard()(connect(mapStateToProps, mapDispatchToProps)(Header));
