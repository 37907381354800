/* tslint:disable: jsx-boolean-value */
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { PlayerTypes } from 'models';
import isUndefined from 'lodash/isUndefined';
import withErrorGuard from 'components/core/withErrorGuard';
import {
  ReactPlayerWrapper,
  IReactPlayerWrapperProps,
} from 'components/Video/video-player/ReactPlayer';
import TranslatedText from 'components/i18n/TranslatedText';
import VideoOverlay from 'components/Video/video-controls/VideoOverlay';
import hash from 'json-stable-stringify';
import usePlayerState from 'components/Video/video-player/Player/use-player-state';
import useChromecast from 'hooks/use-chromecast';
import { ReactPlayerProps } from 'react-player';
import { PlayerProps } from './interfaces';
import { ErrorMessage, Container, Wrapper } from './styles';
import VideoPlayerContext, { SUBTITLES_OFF } from './Context';
import ConcurrentsLoader from 'components/Video/video-controls/ConcurrentsLoader';
import { useSelector } from 'react-redux';
import { getShowYoutubeNoCookieFlag } from 'services/app/selectors';
import { useVideoBeacon } from 'services/insights/useVideoBeacon';
import {
  MaestroIFrameEvents,
  getMaestroReadyBasePayload,
  postMessageToIframeSDK,
} from 'services/iframe';

const PLAYERS_WITHOUT_NATIVE_VOLUME_LISTENER = [
  PlayerTypes.youtube,
  PlayerTypes.twitch,
];
const YOUTUBE_NO_COOKIE_URL = 'https://www.youtube-nocookie.com';

function Player({
  activeVideoElementId,
  width = '100%',
  video,
  scheduledVideoStartTime,
  spot,
  onVideoComplete,
  loop,
  autoPlay = true,
}: PlayerProps) {
  const { connected: isChromecastCasting } = useChromecast();
  const [firstChange, setFirstChange] = useState(true);
  const playerState = usePlayerState({
    video,
    scheduledVideoStartTime,
    spot,
    onVideoComplete,
  });
  const showYoutubeNoCookie = useSelector(getShowYoutubeNoCookieFlag);

  /** Track playback for billing */
  useVideoBeacon({
    video,
    isVideoPlaying: playerState.playing,
    scheduledVideoStartTime,
    spot,
  });

  const pageInfo = useSelector(getMaestroReadyBasePayload);
  const iframeMsgPosted = useRef<string>();

  useEffect(() => {
    // don't fire event twice for the same video
    if (iframeMsgPosted.current === video._id) {
      return;
    }

    postMessageToIframeSDK({
      action: MaestroIFrameEvents.VIDEO_CONTENT_READY,
      vod: video,
      pageInfo,
    });

    iframeMsgPosted.current = video._id;
  }, [pageInfo, video]);

  const [playerRef, setPlayerRef] =
    useState<
      Parameters<NonNullable<IReactPlayerWrapperProps['forwardRef']>>[0]
    >(null);

  const [ready, setReady] = useState(false);

  const [lastVolume, setLastVolume] = useState(playerState.volume);

  const [subtitleOptions, setSubtitleOptions] = useState([SUBTITLES_OFF]);
  const [selectedSubtitle, setSelectedSubtitle] = useState(subtitleOptions[0]);
  const [concurrents, setConcurrents] = useState(1);

  const firstPlayFired = useRef<string>();

  useEffect(() => {
    if (firstPlayFired.current === video._id) {
      return;
    }

    if (!playerState.playing) {
      firstPlayFired.current = video._id;

      playerState.onPlaybackUpdate({
        playing: autoPlay,
      });
    }
  }, [playerState, video._id, autoPlay]);

  useEffect(() => {
    setReady(false);
  }, [hash(playerState.video)]);

  useEffect(() => {
    if (!playerRef || !ready) {
      return;
    }

    playerRef.seekTo(playerState.offset || 1e-99, 'seconds');
  }, [ready, playerState.offset, playerRef]);

  const { playerType } = playerState;

  // player methods
  const ref = useCallback<NonNullable<IReactPlayerWrapperProps['forwardRef']>>(
    (player) => {
      setPlayerRef((prevRef) => {
        if (player === prevRef) {
          return prevRef;
        }

        const videoElement = player?.getInternalPlayer();

        // handle native controls unmute event
        if (videoElement instanceof HTMLVideoElement) {
          videoElement.oncontextmenu = (event) => event.preventDefault();

          // if player has native volume listener and is not maestro player, append custom function to listener
          // since maestro player's volume is handled by custom controls, below would be redundant
          if (
            playerType !== PlayerTypes.file &&
            !PLAYERS_WITHOUT_NATIVE_VOLUME_LISTENER.includes(playerType)
          ) {
            if (!videoElement.onvolumechange) {
              videoElement.onvolumechange =
                playerState.onVolumeChange(videoElement);
            }
          }
        }

        return player;
      });
    },
    [playerState.onVolumeChange, playerType],
  );

  // adds listener for players that don't have native volume listener in their api
  useEffect(() => {
    if (
      !PLAYERS_WITHOUT_NATIVE_VOLUME_LISTENER.includes(playerType)
    ) {
      return;
    }

    const interval = setInterval(() => {

      const nativeVideoPlayer = playerRef?.getInternalPlayer() as {
        getMuted?: () => boolean;
        getVolume?: () => number;
        isMuted?: () => boolean;
        isPaused?: () => boolean;
      };

      if (
        !nativeVideoPlayer
      ) {
        return;
      }

      let isNativeVideoMuted;
      let nativeVideoVolume;

      switch (playerType) {
        case PlayerTypes.youtube:
          isNativeVideoMuted = nativeVideoPlayer?.isMuted?.();
          // returns float between 0-100
          const youtubeVolume = nativeVideoPlayer?.getVolume?.();
          nativeVideoVolume = youtubeVolume ? youtubeVolume / 100 : 0;
          break;
        case PlayerTypes.twitch:
          isNativeVideoMuted = nativeVideoPlayer?.getMuted?.();
          // returns float between 0-1
          nativeVideoVolume = nativeVideoPlayer?.getVolume?.();
          break;
        default:
          break;
      }

      // sync player state to youtube/twitch volume and mute state (mute can change without volume changing)
      if (
        playerState.muted !== isNativeVideoMuted &&
        !isUndefined(isNativeVideoMuted)
      ) {
        if (
          playerState.volume !== nativeVideoVolume &&
          !isUndefined(nativeVideoVolume)
        ) {
          playerState.onPlaybackUpdate({
            muted: isNativeVideoMuted,
            volume: nativeVideoVolume,
          });
        } else {
          playerState.onPlaybackUpdate({ muted: isNativeVideoMuted });
        }
      } else {
        if (
          playerState.volume !== nativeVideoVolume &&
          !isUndefined(nativeVideoVolume)
        ) {
          playerState.onPlaybackUpdate({ volume: nativeVideoVolume });
        }
      }
    }, 500);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [
    playerRef,
    playerType,
    playerState.muted,
    playerState.volume,
    playerState.onPlaybackUpdate,
  ]);

  // initial sync on Ready
  const onReady = useCallback(() => {
    if (!ready) {
      setReady(true);
    }
  }, [ready]);

  useEffect(() => {
    if (isChromecastCasting) {
      return setLastVolume(playerState.volume);
    }

    if (!firstChange) {
      setTimeout(() => {
        playerState.onPlaybackUpdate({ volume: lastVolume });
      }, 3e3);
    }

    setFirstChange(false);
  }, [isChromecastCasting, firstChange]);

  const controls = Number(playerState.isShowingNativeControls);

  const playerConfig = useMemo<ReactPlayerProps['Config']>(() => {
    const commonConfigs = {
      facebook: {
        appId: playerState.facebookId,
      },
      file: {
        attributes: {
          controls,
          controlsList: 'nodownload',
          disablePictureInPicture: true,
        },
        forceVideo: false,
        tracks: [],
      },
      options: playerState.twitchOptions,
      twitch: {
        options: {
          controls: playerState.isShowingNativeControls,
        },
      },
      youtube: {
        playerVars: {
          cc_load_policy: Number(playerState.cc),
          controls,
          origin: playerState.parentOrigin,
        },
      },
    };

    if (showYoutubeNoCookie) {
      return {
        ...commonConfigs,
        youtube: {
          ...commonConfigs.youtube,
          embedOptions: {
            host: YOUTUBE_NO_COOKIE_URL,
          },
        },
      };
    }

    return commonConfigs;
  }, [
    playerState.facebookId,
    controls,
    playerState.playerType,
    playerState.twitchOptions,
    playerState.isShowingNativeControls,
    playerState.cc,
    playerState.parentOrigin,
    showYoutubeNoCookie,
  ]);

  const playerStyle = useMemo(() => {
    const style: CSSProperties = playerState.isFacebook
      ? {}
      : {
          left: 0,
          position: 'absolute',
          top: 0,
        };

    if (!playerState.isShowingNativeControls) {
      style.zIndex = -1;
    }

    return style;
  }, [playerState.isFacebook, playerState.isShowingNativeControls]);

  return (
    <VideoPlayerContext
      value={{
        concurrents,
        subtitleOptions,
        selectedSubtitle,
        setSelectedSubtitle,
        setSubtitleOptions,
        playerState,
      }}
    >
      <ConcurrentsLoader onLoad={setConcurrents} />
      <VideoOverlay
        isShowingNativeControls={playerState.isShowingNativeControls}
        hideMuteWarning={playerState.hideMuteWarning || (!autoPlay && !playerState.playing)}
      >
        {(() => {
          if (playerState.hasError) {
            return (
              <ErrorMessage>
                <TranslatedText stringKey="ERROR_VIDEO_PLAYBACK" />
              </ErrorMessage>
            );
          }

          const PlayerContainer =
            playerState.isFacebook
              ? Container
              : React.Fragment;

          const playerHeight = playerState.isFacebook ? 'auto' : '100%';
          const playerWidth = playerState.isFacebook ? width : '100%';

          return (
            <PlayerContainer>
              {!playerState.isChromaKeyMode && playerState.video && (
                <ReactPlayerWrapper
                  controls={playerState.isShowingNativeControls}
                  playerType={playerState.playerType}
                  playing={playerState.playing}
                  selectedSubtitle={selectedSubtitle}
                  key={playerState.video?._id}
                  className={playerState.playerClassName}
                  config={playerConfig}
                  forwardRef={ref}
                  height={playerHeight}
                  id={activeVideoElementId}
                  muted={
                    isChromecastCasting ||
                    playerState.muted ||
                    playerState.forceMute
                  }
                  onReady={onReady}
                  onDuration={playerState.onDuration}
                  onEnded={playerState.onVideoComplete!}
                  onError={playerState.onError}
                  onPause={playerState.onPause}
                  onPlay={playerState.onPlay}
                  onProgress={playerState.updateHighFrequencyState}
                  playsinline
                  light={false}
                  loop={Boolean(loop)}
                  style={playerStyle}
                  url={video.url}
                  volume={
                    playerState.forceMute || isChromecastCasting
                      ? 0
                      : playerState.volume
                  }
                  width={playerWidth}
                  wrapper={Wrapper}
                />
              )}
            </PlayerContainer>
          );
        })()}
      </VideoOverlay>
    </VideoPlayerContext>
  );
}

export default withErrorGuard()(Player);
