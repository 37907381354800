import React from 'react';
import { StyledFontSizeDropdown } from '../styles';
import { useCTAEditorModalContext } from '../Context';
import { FONT_SIZES } from '../utils';

const FontSizeDropdown = () => {
  const { cta, onChangeFontSize  } = useCTAEditorModalContext();
  const handleChangeFontSize = React.useCallback((option: { label: string, value: number; }) => {
    if (cta.fontSize === option.value) {
      return;
    }

    onChangeFontSize?.(option.value);
  }, [onChangeFontSize, cta.fontSize]);

  const getValue = React.useCallback((value: number) => FONT_SIZES.find((option) => option.value === value), []);

  const fontSize = cta.fontSize;
  if (typeof fontSize === 'undefined') {
    return null;
  }

  return (
    <StyledFontSizeDropdown
      labelKey="LABEL_FONT_SIZE"
      onChange={handleChangeFontSize}
      options={FONT_SIZES}
      value={getValue(fontSize)}
    />
  );
};

export default FontSizeDropdown;
