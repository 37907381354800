// tslint:disable:jsx-boolean-value
import React from 'react';
import isArray from 'lodash/isArray';
import KeyedListMapper from 'components/core/KeyedListMapper';
import AdminSocialLinks from 'components/admin-bridge/AdminSocialLinks';
import { SOCIALS_ID } from 'global-ids';
import { EMPTY, EmptyType, Social } from 'models/IFooter';
import {
  SocialLink,
  SocialLinksContainer,
  LinkIcon,
  ActionsText,
  Hashtag,
  SocialLinksWrapper,
  StyledQuickEditOverlay,
} from './styles';
import { EMPTY_ARRAY } from 'services/utils';
import { useSelector } from 'react-redux';
import { useFeatureGateEnabled } from 'hooks';
import { Feature } from 'hooks/use-feature-gate-enabled';
import { isEditMode } from 'services/admin';

export interface SocialLinksProps extends SocialData {
  isAdmin: boolean;
  onHashtagEngage(): void;
  onSocialEngage(data: Partial<Social>): void;
}

export interface SocialData {
  hashtag: string;
  socials: Social[] | EmptyType;
  tweetMessage: string;
}

const parseSocialLinks = (socials: any) => {
  return socials === EMPTY || !isArray(socials)
  ? EMPTY_ARRAY
  : socials;
};

export default function SocialLinks({
  onHashtagEngage,
  onSocialEngage,
  hashtag,
  isAdmin,
  socials,
  tweetMessage,
}: SocialLinksProps) {
  const [isActionModalOpen, setIsActionModalOpen] = React.useState(false);
  const isSimpleModeEnabled = useFeatureGateEnabled({
    feature: Feature.SIMPLE_MODE,
    type: 'feature',
  });
  const isEditing = useSelector(isEditMode);
  const parsedSocials = parseSocialLinks(socials);

  const handleCloseActionModal = () => setIsActionModalOpen(false);
  const handleOpenActionModal = () => setIsActionModalOpen(true);

  const renderEditable = () => {
    return (
      <AdminSocialLinks
        hashtag={hashtag}
        socials={parsedSocials}
        tweetMessage={tweetMessage}
        onCloseActionModal={handleCloseActionModal}
        isActionModalOpen={isActionModalOpen}
      />
    );
  };

  const renderDefault = () => {
    return (
      <SocialLinksWrapper disabled={!(Boolean(parsedSocials?.length) || hashtag)}>
        {hashtag && (
          <Hashtag onClick={onHashtagEngage}>
            {`#${hashtag}`}
          </Hashtag>
        )}
        <SocialLinksContainer id={SOCIALS_ID}>
          {Boolean(socials?.length) && (
            <KeyedListMapper
              keyField="arrayId"
              list={parsedSocials}
            >
              {
                (key: string, { icon, name, title, url }: Social) => (
                  <SocialLink
                    key={key}
                    href={url}
                    // tslint:disable-next-line:jsx-no-lambda
                    onClick={() => onSocialEngage({ name: icon, url })}
                  >
                    {
                      icon ? (
                        <LinkIcon name={icon} title={name || title || ''} />
                      ) : (
                        <ActionsText>{name || title}</ActionsText>
                      )
                    }
                  </SocialLink>
                )
              }
            </KeyedListMapper>
          )}
        </SocialLinksContainer>
      </SocialLinksWrapper>
    );
  };

  const renderSocialLinks = () => {
    const shouldRenderEditable = isSimpleModeEnabled ? isAdmin : isEditing;
    if (shouldRenderEditable) {
      return renderEditable();
    }

    return renderDefault();
  };

  return (
    <StyledQuickEditOverlay
      testId="editSocialLanding"
      onEdit={handleOpenActionModal}
      titleKey="SOCIAL_LABEL"
    >
      {renderSocialLinks()}
    </StyledQuickEditOverlay>
  );
}
