import { ADMIN_ACCENT_PRIMARY } from 'style/constants';
import { desktopHover, mobileOnly } from 'style/mixins';
import styled from 'styled-components';
import { css } from 'styled-components';

export const EditorItemActionsWrapper = styled.div`
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  ${mobileOnly`display: none;`};
`;

const ACTIVE = css`
  border-width: 1px;
  border-color: ${ADMIN_ACCENT_PRIMARY};

  & ${EditorItemActionsWrapper} {
    opacity: 1;
  }
`;

export const Wrapper = styled.div<{ isAdmin: boolean; isEditing: boolean; isSimpleModeEnabled: boolean; }>`
  position: relative;

  ${({ isAdmin, isSimpleModeEnabled }) => isAdmin && css`
    border: 0 solid transparent;
    transition: border 0.2s ease-in-out;

    ${isSimpleModeEnabled && css`
      ${desktopHover`${ACTIVE}`};
    `}
  `};
  ${({ isEditing }) => isEditing && ACTIVE};
`;
