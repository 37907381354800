import styled, { css } from 'styled-components';
import {
  ACCENT_PRIMARY,
  SPACING_SMALL,
  ACCENT_SECONDARY,
  TEXT_CONTRAST_ON_ACCENT_PRIMARY,
} from 'style/constants';
import {
  TEXT_H5,
  CIRCLE,
  SINGLE_LINE_ELLIPSIS,
  TEXT_H6,
  FLEX_CENTER,
} from 'style/mixins';
import Icon from 'components/ui/Icon';
import Link from 'components/ui/Link';
import { bw } from 'colors';
import QuickEditOverlay from 'components/admin-bridge/QuickEditOverlay';

const LINK_STYLE = css`
  display: block;
  line-height: normal;
`;

export const StyledQuickEditOverlay = styled(QuickEditOverlay)`
  padding: 0 20px;
  padding-top: 17px;
  min-width: 120px;
  height: 100%;
  display: flex;
`;

export const SocialLink = styled(Link).attrs({
  plain: true,
})`${LINK_STYLE}`;

export const SocialLinksWrapper = styled.div<{ disabled?: boolean }>`
  display: ${props => props.disabled ? 'none' : 'flex'};
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 16px;
  min-height: 53px;
`;

export const SocialLinksContainer = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  font-size: 24px;
  position: relative;
  & > ${SocialLink} {
    margin-bottom: 6px;
    margin-top: 6px;
  }
  & > ${SocialLink}:not(:last-child) {
    margin-right: ${SPACING_SMALL};
  }
`;

export const LinkIcon = styled(Icon).attrs((props) => ({
  color: TEXT_CONTRAST_ON_ACCENT_PRIMARY(props),
  radius: '25px',
}))`
  ${CIRCLE}
  ${FLEX_CENTER}
  background: ${ACCENT_PRIMARY};
  font-size: 15px;
  color: ${TEXT_CONTRAST_ON_ACCENT_PRIMARY};
  & svg {
    width: 15px;
    height: 15px;
    & path {
      fill: ${TEXT_CONTRAST_ON_ACCENT_PRIMARY};
    }
  }
  &:hover {
    background: ${ACCENT_SECONDARY};
    color:${props => bw(ACCENT_SECONDARY(props))};
    & path {
      fill: ${props => bw(ACCENT_SECONDARY(props))};
    }
  }
`;

export const ActionsText = styled.div`
  ${TEXT_H5}
  color: ${ACCENT_PRIMARY};
`;

export const Hashtag = styled(Link).attrs({
  plain: true,
})`
  &&, &.plain {
    ${SINGLE_LINE_ELLIPSIS}
    ${TEXT_H6}
    max-width: 95%;
    margin-right: 10px;
    ${FLEX_CENTER}
    border-radius: 20px;
    min-height: 25px;
    max-height: 25px;
    padding: 10px 13px;
    background: ${ACCENT_PRIMARY};
    color: ${TEXT_CONTRAST_ON_ACCENT_PRIMARY};
    min-width: max-content;
    &:hover {
      background: ${ACCENT_SECONDARY};
      color:${props => bw(ACCENT_SECONDARY(props))};
    }
  }
` as typeof SocialLink;
