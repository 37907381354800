import PropTypes from 'prop-types';
import React from 'react';
import RendererEditor from 'components/admin2/RendererEditor';
import { RendererWrapper } from './styles';

const descriptionField = (descriptionKey) => ({
  type: 'description',
  typeOptions: {
    descriptionKey,
    padding: '15px 0 0 0',
  },
});

const ctaField = {
  fieldName: 'ctaRenderer',
  labelKey: 'ADMIN_LABEL_CTA',
  type: 'switch',
  typeOptions: {
    optional: true,
    testId: 'ctaDropdown',
    variantField: 'cardCtaType',
    variants: [
      {
        id: 'facebook_share',
        labelKey: 'ADMIN_LABEL_CTA_FACEBOOK_SHARE',
        properties: [],
      },
      {
        id: 'open_link',
        labelKey: 'ADMIN_LABEL_CTA_OPEN_LINK',
        properties: [
          {
            fieldName: 'linkText',
            labelKey: 'ADMIN_LABEL_TEXT',
            type: 'string',
            typeOptions: {
              testId: 'CtaOpenLinkTextInput',
            },
          },
          {
            fieldName: 'linkUrl',
            labelKey: 'ADMIN_LABEL_URL',
            type: 'string',
            typeOptions: {
              testId: 'CtaOpenLinkUrlInput',
            },
          },
        ],
      },
      {
        id: 'play_video',
        labelKey: 'ADMIN_LABEL_CTA_PLAY_VIDEO',
        properties: [
          {
            fieldName: 'text',
            labelKey: 'ADMIN_LABEL_TEXT',
            type: 'string',
            typeOptions: {
              testId: 'CtaPlayVideoTextInput',
            },
          },
          {
            fieldName: 'videoId',
            labelKey: 'ADMIN_LABEL_VIDEO',
            type: 'videoId', // TODO: IMPLEMENT
            typeOptions: {
              testId: 'CtaPlayVideoDropdown',
            },
          },
        ],
      },
      {
        id: 'show_person',
        labelKey: 'ADMIN_LABEL_CTA_SHOW_PERSON',
        properties: [
          {
            fieldName: 'text',
            labelKey: 'ADMIN_LABEL_TEXT',
            type: 'string',
          },
          {
            fieldName: 'personId',
            labelKey: 'ADMIN_LABEL_PERSON',
            type: 'personId', // TODO: IMPLEMENT
          },
        ],
      },
      {
        id: 'show_panel',
        labelKey: 'ADMIN_LABEL_CTA_SHOW_PANEL',
        properties: [
          {
            fieldName: 'text',
            labelKey: 'ADMIN_LABEL_TEXT',
            type: 'string',
          },
          {
            fieldName: 'panelId',
            labelKey: 'ADMIN_LABEL_PANEL',
            type: 'panelId', // TODO: IMPLEMENT
          },
        ],
      },
      {
        id: 'html',
        labelKey: 'ADMIN_LABEL_CTA_HTML',
        properties: [
          {
            fieldName: 'html',
            labelKey: 'ADMIN_LABEL_HTML',
            type: 'html', // TODO: IMPLEMENT
          },
        ],
      },
    ],
  },
};

const titleField = {
  fieldName: 'title',
  labelKey: 'ADMIN_LABEL_TITLE',
  type: 'string',
};

const idToFields = {
  image: [
    {
      ...titleField,
      typeOptions: {
        testId: 'imageTitleInput',
      },
    },
    {
      fieldName: 'imageUrl',
      labelKey: 'ADMIN_LABEL_IMAGE',
      type: 'image',
      typeOptions: {
        canDrop: true,
      },
    },
    ctaField,
  ],
  message: [
    {
      ...titleField,
      typeOptions: {
        testId: 'messageCardTitleInput',
      },
    },
    {
      fieldName: 'message',
      labelKey: 'ADMIN_LABEL_MESSAGE',
      type: 'text',
      typeOptions: {
        testId: 'messageCardMsgInput',
      },
    },
    ctaField,
  ],
  person: [
    descriptionField('ADMIN_CARD_PERSON_INFO'),
    { ...titleField, placeholderLabelKey: 'LABEL_PERSON_TITLE_PLACEHOLDER' },
    {
      fieldName: 'personId',
      labelKey: 'ADMIN_LABEL_PERSON',
      type: 'libraryButton',
      typeOptions: {
        fancyButtonProps: {
          buttonPlaceholderKey: 'ADMIN_PERSON_BUTTON_PLACEHOLDER',
          docToButtonProps: doc => ({
            imageSrc: doc?.renderer?.personImageUrl,
            showImage: true,
            text: doc?.renderer?.personName,
          }),
        },
        icon: 'adminbarUserProfiles',
        type: 'person',
        valueField: 'id',
      },
    },
  ],
  poll: [
    descriptionField('ADMIN_LABEL_MULTIPLE_CHOICE_DESCRIPTION'),
    { ...titleField, placeholderLabelKey: 'LABEL_MULTIPLE_CHOICE_TITLE_PLACEHOLDER' },
    {
      fieldName: 'pollId',
      labelKey: 'ADMIN_LABEL_POLL',
      type: 'libraryButton',
      typeOptions: {
        fancyButtonProps: {
          buttonPlaceholderKey: 'ADMIN_POLL_BUTTON_PLACEHOLDER',
          docToButtonProps: doc => ({
            showImage: false,
            text: doc?.data?.name,
          }),
        },
        icon: 'check',
        type: 'poll',
        valueField: '_id',
      },
    },
  ],
  tweet: [
    {
      ...titleField,
      typeOptions: {
        testId: 'twitterCardTitleInput',
      },
    },
    {
      fieldName: 'tweetUrl',
      labelKey: 'ADMIN_LABEL_TWEET_URL',
      type: 'tweetUrl',
      typeOptions: {
        testId: 'twitterCardUrlInput',
      },
    },
  ],
};

export default class CardEditor extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    renderer: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  render() {
    const {
      onChange,
      renderer,
    } = this.props;

    const { cardType } = renderer || {};
    const properties = idToFields?.[cardType];

    // TODO: Debug only code; should crash or error
    if (!properties) {
      return cardType;
    }

    return (
      <RendererWrapper>
        <RendererEditor
          key={renderer.cardType}
          onChange={onChange}
          properties={properties}
          renderer={renderer}
        />
      </RendererWrapper>
    );
  }
}
