import React from 'react';
import { CtaBehaviorData, CTAButtonProps, CTASize } from './utils';
import { CTAVariant } from '../../ui/CTAButton';

interface CTAEditorModalContextProviderProps {
  cta: CTAButtonProps;
  onChangeBehavior: <T extends CtaBehaviorData>(behavior: T) => void;
  onChangeDesktopHeight: (value: CTASize) => void;
  onChangeFontSize?: (value: number) => void;
  onChangeMobileHeight: (value: CTASize) => void;
  onChangeText: (text: string) => void;
  onChangeVariant: (value: CTAVariant) => void;
}

export const CTAEditorModalContext = React.createContext({} as CTAEditorModalContextProviderProps);

export const useCTAEditorModalContext = () => {
  const context = React.useContext(CTAEditorModalContext);

  if (!context) {
    throw new Error('useCTAEditorModalContext must be used within a CTAEditorModalContextProvider');
  }

  return context;
};
