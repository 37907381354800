import { ACCENT_PRIMARY, SURFACE_5, TEXT_100, TEXT_300 } from 'style/constants';
import { TEXT_BODY_XS_MEDIUM, TEXT_LABEL_M_MEDIUM, TEXT_LABEL_S_MEDIUM, TEXT_LABEL_L_MEDIUM } from 'style/design-system/textStyles';
import { SINGLE_LINE_ELLIPSIS, mobileOnly } from 'style/mixins';
import styled, { css } from 'styled-components';

export const GenericSelectorContainer = styled.div`
  width: fit-content;
  ${mobileOnly`width: 100%;`}
`;

export const GenericSelectorHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 10px;
`;

export const Title = styled.span`
  color: ${TEXT_100};

  ${TEXT_LABEL_S_MEDIUM};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;

  margin-right: 3px;
`;

export const Subtitle = styled.span`
  color: ${TEXT_300};

  ${TEXT_BODY_XS_MEDIUM};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  text-transform: capitalize;
`;

export const GenericOptionValues = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
`;

export const GenericOptionCircle = styled.div<{
  isDisabled?: boolean;
  isSelected?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 38px;
  height: 38px;
  padding: 10px 14px;
  gap: 10px;
  border-radius: 50px;
  background: ${SURFACE_5};
  cursor: pointer;

  ${({ isSelected }) => isSelected && css`
    border: 1px solid ${ACCENT_PRIMARY};
  `};

  ${({ isDisabled }) => isDisabled && css`
    opacity: 0.2;
    cursor: default;
  `};
`;

export const GenericOptionCircleText = styled.span<{isSelected?: boolean }>`
  color: ${TEXT_300};

  ${TEXT_BODY_XS_MEDIUM};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  text-transform: capitalize;

  ${({ isSelected }) => isSelected && css`
    color: ${TEXT_100};
  `};
`;

export const GenericOptionContainer = styled.div<{ isRenderingOnPanel: boolean }>`
  color: ${TEXT_100};
  ${props => props.isRenderingOnPanel && css`
    ${TEXT_LABEL_M_MEDIUM};
  `}
  ${props => !props.isRenderingOnPanel && css`
    ${TEXT_LABEL_L_MEDIUM};
  `}

  padding-left: 5px;
  padding-top: 2px;
  white-space: nowrap;
  overflow: hidden;
  ${SINGLE_LINE_ELLIPSIS}
`;
