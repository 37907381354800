import React, { ComponentType, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isInPageSettings, isStreamMode } from 'services/admin/selectors';

import { ActionWrapper } from 'components/admin-bridge/Toolbar/styles';
import { asElementTypes } from 'shared/type-utils';
import {
  TOOLBAR_PORTAL_CENTER,
  TOOLBAR_PORTAL_LEFT,
  TOOLBAR_PORTAL_RIGHT,
} from 'global-ids';
import DefaultView from './Default';
import StreamModeView from './StreamMode';

type ToolbarSide = 'toolbarLeft' | 'toolbarCenter' | 'toolbarRight';

export interface SharedToolbarViewProps {
  children?: {
    [key in ToolbarSide]?: ComponentType<any>;
  };
  viewProps?: { [key in ToolbarSide]?: {} };
}

const VIEW_MAP = asElementTypes<Required<SharedToolbarViewProps>['children']>()({
  default: DefaultView,
  streamMode: StreamModeView,
});

type ToolbarViewKey = keyof typeof VIEW_MAP;

export default function({
  children,
  viewProps = {},
}: SharedToolbarViewProps) {
  const inPageSettings = useSelector(isInPageSettings);
  const streamMode = useSelector(isStreamMode);
  const leftRef = useRef<HTMLDivElement>(null);
  const centerRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLDivElement>(null);

  const view = useMemo<ToolbarViewKey | null>(() => {
    if (streamMode) {
      return 'streamMode';
    } else if (inPageSettings) {
      return null;
    }
    return 'default';
  }, [inPageSettings, streamMode]);

  const ToolbarView = children || (view && VIEW_MAP[view]);

  return (
    <>
      <ActionWrapper id={TOOLBAR_PORTAL_LEFT} ref={leftRef}>
        {ToolbarView?.toolbarLeft && (
          <ToolbarView.toolbarLeft {...viewProps.toolbarLeft} />
        )}
      </ActionWrapper>
      <ActionWrapper id={TOOLBAR_PORTAL_CENTER} ref={centerRef}>
        {ToolbarView?.toolbarCenter && (
          <ToolbarView.toolbarCenter {...viewProps.toolbarCenter} />
        )}
      </ActionWrapper>
      <ActionWrapper id={TOOLBAR_PORTAL_RIGHT} ref={rightRef}>
        {ToolbarView?.toolbarRight && (
          <ToolbarView.toolbarRight {...viewProps.toolbarRight} />
        )}
      </ActionWrapper>
    </>
  );
}
