import React from 'react';
import { Container, Quantity, QuantitySymbol } from './styles';
import { useDispatch } from 'react-redux';
import withConfirmation from 'components/core/withConfirmation';

const QUANTITY_VIEW_ID = 'ecommerce-quantity_view';

interface IQuantityView {
  onProductDelete: () => void;
  onQuantityDecrease: () => void;
  onQuantityIncrease: () => void;
  quantity: number;
  setQuantity: React.Dispatch<React.SetStateAction<number>>;
}

const QuantitySymbolButton = withConfirmation(QuantitySymbol);

const QuantityView: React.FC<IQuantityView> = ({
  onProductDelete,
  onQuantityDecrease,
  onQuantityIncrease,
  quantity,
  setQuantity,
}) => {
  const dispatch = useDispatch();
  const handleIncrease = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    onQuantityIncrease();
  };

  const handleDecrease = () => {
    const newQuantity = quantity - 1;
    if (newQuantity === 0) {
      onRemoveConfirm();
      return;
    }
    setQuantity(Math.max(newQuantity, 0));
    onQuantityDecrease();
  };

  const onRemoveConfirm = () => {
    setTimeout(() => {
      onProductDelete();
    }, 500);
  };

  return (
    <Container data-testid={QUANTITY_VIEW_ID} id={QUANTITY_VIEW_ID}>
      <QuantitySymbolButton action={handleDecrease} shouldAskConfirmation={quantity === 1} messageKey="REMOVE_CART_ITEM_CONFIRM">-</QuantitySymbolButton>
      <Quantity>{quantity}</Quantity>
      <QuantitySymbol onClick={handleIncrease}>+</QuantitySymbol>
    </Container>
  );
};

export default QuantityView;
