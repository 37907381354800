import styled, { css } from 'styled-components';
import Expandable from 'components/ui/Expandable';
import { ADMIN_SURFACE_4, ADMIN_SURFACE_6 } from 'style/constants';

export const PageNavigationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 100%;
  justify-content: center;
  position: relative;
  margin-left: -14px;
`;

export const SiteNavigationExpandableWrapper = styled(Expandable)`
  & > * {
    display: flex;
    flex-direction: column;
    transition: padding 0.5s;
    padding: 20px 0 0 20px;
    overflow: hidden;
    height: 100%;
    &:empty {
      padding: 0px;
    }
  }
  width: 30vw;
  max-width: 350px;
  position: absolute;
  top: 100%;
  max-height: calc(100vh - 51px);
  display: flex;
  background: ${ADMIN_SURFACE_4};
  ${({ isExpanded }) => isExpanded && css`border-top: 1px solid ${ADMIN_SURFACE_6};`}
`;

export const NavigationButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30vw;
  max-width: 350px;
  padding: 0 17.5px 0 15px;
  height: 100%;
  border-right: 1px solid ${ADMIN_SURFACE_6};
  && {
    cursor: pointer;
  }
`;
