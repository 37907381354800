import React from 'react';
import { useProductOptions } from 'hooks/ecommerce/useProductOptions';
import { useProductVariant } from 'hooks/ecommerce/useProductVariant';
import { formatPrice } from 'shared/string-utils';
import { IProduct, SelectedOption } from '../../models';
import { Column, Container, Content, Name, Row } from './styles';
import { Price } from 'components/Ecommerce/sharedStyles';
import OptionsView from '../../sharedComponents/OptionsView';
import QuantityView from '../../sharedComponents/QuantityView';
import ProductImage from 'components/Ecommerce/sharedComponents/ProductImage';
import useEcommerceCartProduct from 'hooks/ecommerce/useEcommerceCartProduct';
import { getRectPositionCheckingBounds } from 'dom-utils';
import { useSelector } from 'react-redux';
import { isLandingPageType } from 'services/app';
import { CART_PRODUCT_ID } from 'global-ids';
import { getShopifyBlockData } from 'services/ecommerce/selectors';
import useShopifyBlockAspectRatio from 'components/page-blocks/Shopify/use-shopify-block-aspect-ratio';
import { DEFAULT_SHOPIFY, IShopifyBlockData } from 'components/page-blocks/Shopify/utils';

export interface ICartProductComponentProps {
  cartCurrency: string;
  preSelectedOptions?: SelectedOption[];
  product: IProduct;
}

const CartProduct: React.FC<ICartProductComponentProps> = ({
  cartCurrency,
  preSelectedOptions,
  product,
}) => {
  const shopifyBlockData: IShopifyBlockData = useSelector(getShopifyBlockData) || DEFAULT_SHOPIFY;
  const aspectRatio = useShopifyBlockAspectRatio(shopifyBlockData);

  const isLandingPage = useSelector(isLandingPageType);
  const { options, selectedOptions, handleOptionChange, selectedVariant } = useProductOptions(product, preSelectedOptions);
  const { productVariant, setQuantity } = useProductVariant(product, selectedOptions, selectedVariant);
  const { handleQuantityDecrease, handleQuantityIncrease, handleProductDeletion, handleVariantUpdate } = useEcommerceCartProduct(product.id, productVariant?.variantId || '');

  const setVariantsOptionsMenuPosition = React.useCallback((menu: HTMLDivElement, menuContainer: HTMLDivElement) => {
    const menuContainerRect = menuContainer.getBoundingClientRect();

    const safePosition = getRectPositionCheckingBounds({
      elem: menu,
      position: {
        x: menuContainerRect.left,
        y: menuContainerRect.top,
      },
      margin: 20,
    });

    if (isLandingPage) {
      menu.style.position = 'fixed';
      menu.style.top = `${safePosition.y + 30}px`;
      menu.style.left = `${safePosition.x}px`;
    } else {
      const menuWidth = menu.getBoundingClientRect().width;
      const menuContainerCenter = menuContainerRect.left + (menuContainerRect.width / 2);
      const leftPosition = menuContainerCenter - (menuWidth / 2) - menuContainerRect.left;
      const maxWidth = window.innerWidth - menuContainerRect.left + Math.abs(leftPosition) - 20;

      menu.style.position = 'absolute';
      menu.style.top = `${menuContainerRect.height + 10}px`;
      menu.style.left = `${-leftPosition}px`;
      menu.style.maxWidth = `${maxWidth}px`;
    }
  }, [isLandingPage]);

  const prevSelectedVariantId = React.useRef<string | null>(selectedVariant?.id || null);

  React.useEffect(() => {
    if (!selectedVariant) return;

    if (
      prevSelectedVariantId.current && selectedVariant.id &&
      prevSelectedVariantId.current !== selectedVariant?.id
    ) {
      handleVariantUpdate(prevSelectedVariantId.current);
    }

    prevSelectedVariantId.current = selectedVariant.id;
  }, [selectedVariant?.id]);

  React.useEffect(() => {
    setQuantity(product.quantity);
  }, [product.quantity]);


  if (!productVariant || !selectedVariant) {
    return null;
  }

  return (
    <Container data-testid={CART_PRODUCT_ID} id={CART_PRODUCT_ID}>
      <Content>
        <ProductImage
          images={productVariant.images}
          source="CartProduct"
          isAvailableForSale={productVariant.availableForSale}
          name={productVariant.name}
          aspectRatio={aspectRatio}
        />
        <Column>
          <Name>{productVariant.name}</Name>
          <OptionsView
            handleOptionChange={handleOptionChange}
            options={options}
            selectedOptions={selectedOptions}
            setMenuPosition={setVariantsOptionsMenuPosition}
            isCartProduct={true}
            variantName={productVariant.name}
          />
          <Row>
            <Price>{formatPrice(cartCurrency, productVariant.price * productVariant.quantity)}</Price>
            <QuantityView
              onProductDelete={handleProductDeletion}
              onQuantityDecrease={handleQuantityDecrease}
              onQuantityIncrease={handleQuantityIncrease}
              quantity={productVariant.quantity}
              setQuantity={setQuantity}
            />
          </Row>
        </Column>
      </Content>
    </Container>
  );
};

export default CartProduct;
