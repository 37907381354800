import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FacebookShareCardCta from 'components/cards/FacebookShareCardCta';
import HtmlCardCta from 'components/cards/HtmlCardCta';
import OpenLinkCardCta from 'components/cards/OpenLinkCardCta';
import ShowPersonCardCta from 'components/cards/ShowPersonCardCta';
import ShowPanelCardCta from 'components/cards/ShowPanelCardCta';
import PlayVideoCta from 'components/cards/PlayVideoCta';

const CTA_RENDERER_MAP = {
  facebook_share: FacebookShareCardCta,
  html: HtmlCardCta,
  open_link: OpenLinkCardCta,
  play_video: PlayVideoCta,
  show_panel: ShowPanelCardCta,
  show_person: ShowPersonCardCta,
  show_stored_panel: ShowPanelCardCta,
};

const CtaWrapper = styled.div`
  margin-top: auto;
`;

export default class CardCta extends React.Component {
  static propTypes = {
    cardRenderer: PropTypes.object.isRequired,
    className: PropTypes.string,
    onEngage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: undefined,
  };

  render() {
    const { className, cardRenderer, onEngage } = this.props;
    const { ctaRenderer } = cardRenderer;
    const cardEngage = extra => onEngage(cardRenderer, extra);
    const Component = CTA_RENDERER_MAP[ctaRenderer.cardCtaType];
    const { id: cardId } = cardRenderer;
    const referralId = `social_c_${cardId}`;
    if (!Component) {
      return null; // TODO: Log error
    }
    return (
      <CtaWrapper className={className} data-testid="liveCardCtaButton">
        <Component
          cardRenderer={cardRenderer}
          ctaRenderer={ctaRenderer}
          onEngage={cardEngage}
          referralId={referralId}
          testIdText="liveCardCtaButtonTitle"
        />
      </CtaWrapper>
    );
  }
}
