import React from 'react';
import { useSelector } from 'react-redux';
import { getOrientationDegrees } from 'services/device/selectors';
import { GlobalStyle } from './styles';
import { getIsOrientationLocked } from 'services/user-layout/selectors/common';

const GlobalStyles = () => {
  const isOrientationLocked = useSelector(getIsOrientationLocked);
  const orientation = useSelector(getOrientationDegrees);
  return <GlobalStyle orientation={orientation} isOrientationLocked={isOrientationLocked} />;
};

export default GlobalStyles;
