import React, { useCallback } from 'react';
import { IColorOption, IGenericOption, IProductVariant, ISizeOption, OPTION_TYPE, Option, OptionData, OptionType } from 'components/Ecommerce/models';
import ColorSelector from './ColorSelector';
import SizeSelector from './SizeSelector';
import GenericSelector from './GenericSelector';

interface IOptionsSelector {
  handleOptionChange: (type: OptionType, optionData: OptionData) => void;
  options: Option[];
  selectedOptions: IProductVariant['selectedOptions'];
}

const OptionsSelector: React.FC<IOptionsSelector> = ({
  handleOptionChange,
  options,
  selectedOptions,
}) => {
  const render = useCallback(() => {
    return options.map((option: Option) => {
      const selectedOptionValue = selectedOptions.find(opt => opt.type === option.type);

      if (option.type === OPTION_TYPE.color) {
        return (
          <ColorSelector
            key={option.type}
            option={option as IColorOption}
            selectedOptionValue={selectedOptionValue!}
            handleOptionChange={handleOptionChange}
          />
        );
      }

      if (option.type === OPTION_TYPE.size) {
        return (
          <SizeSelector
            key={option.type}
            option={option as ISizeOption}
            selectedOptionValue={selectedOptionValue!}
            handleOptionChange={handleOptionChange}
          />
        );
      }

      return (
        <GenericSelector
          key={option.type}
          option={option as IGenericOption}
          selectedOptionValue={selectedOptionValue!}
          handleOptionChange={handleOptionChange}
        />
      );
    });
  }, [options, handleOptionChange]);

  return <>{render()}</>;
};

export default OptionsSelector;
