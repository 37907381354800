import styled from 'styled-components';
import { rgba } from 'colors';
import ActionButton from 'components/admin2/ActionButton';
import AdminIcon from 'components/admin2/AdminIcon';

import {
  ADMIN_SURFACE_1,
  ADMIN_ACTION_COLOR_HOVER,
  SPACING_MEDIUM,
  SPACING_SMALL,
  SPACING_X_SMALL,
  PASSIVE_OPACITY,
  DISABLED_OPACITY,
  ADMIN_TEXT_200,
} from 'style/constants';
import {
  ACTION_NORMAL,
  ADMIN_BACKGROUND_TITLE,
  ADMIN_SCROLL_BAR,
} from 'style/mixins';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_BODY_XS_BOLD, ADMIN_TEXT_BODY_XS_REGULAR, ADMIN_TEXT_TITLE_S } from 'style/design-system/textStyles';

export const WideButton = styled(ActionButton)`
justify-content: center;
padding: ${SPACING_X_SMALL};
background-color: ${props => rgba(ADMIN_SURFACE_1(props), 0.5)};
&:hover{
  ${ADMIN_BACKGROUND_TITLE};
}
`;

export const HalfButton = styled(ActionButton)`
  display: flex;
  align-content: stretch;
  justify-content: center;
  flex-grow: 1;
  padding: ${SPACING_X_SMALL};
  background-color: ${props => rgba(ADMIN_SURFACE_1(props), 0.5)};
  &:hover{
    ${ADMIN_BACKGROUND_TITLE};
  }
`;

export const SectionContents = styled.div`
display: flex;
flex-flow: column nowrap;
align-items: stretch;
`;

export const Weekday = styled.span`
margin-left: ${SPACING_SMALL};
`;

export const DateHeader = styled.div`
  text-transform: uppercase;
  ${ADMIN_TEXT_BODY_XS_BOLD}
  margin: 8px 0;
`;

export const Pagination = styled.div`
display: flex;
flex-flow: row nowrap;
align-items: space-between;
${props => props.disabled && `opacity: ${DISABLED_OPACITY};`}
`;

export const DateRange = styled.span`
${ADMIN_TEXT_BODY_S_MEDIUM}
text-transform: uppercase;
opacity: ${PASSIVE_OPACITY};
padding: 0 16px 0 16px;
`;

export const LeftIcon = styled(AdminIcon).attrs({
  name: 'left-open-big',
})`
${ACTION_NORMAL};
cursor: pointer;
`;

export const RightIcon = styled(AdminIcon).attrs({
  name: 'right-open-big',
})`
${ACTION_NORMAL};
cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const Spacer = styled.div`
  width: 2px;
`;

export const HeaderAction = styled.div.attrs({
  role: 'button',
})`
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
`;

export const Container = styled.div`
  width: 100%;
`;

export const InnerContainer = styled.div`
  ${ADMIN_SCROLL_BAR}
  padding: ${SPACING_MEDIUM};
`;

export const Toolbar = styled.div`
  display: flex;
  padding: 16px;
  flex-flow: row nowrap;
  align-items: center;
  ${ADMIN_BACKGROUND_TITLE};
  & > :not(:first-child) {
    margin-left: 16px;
  }
`;

export const FieldTitle = styled.div`
  text-transform: uppercase;
${ADMIN_TEXT_TITLE_S}
  margin-bottom: ${SPACING_X_SMALL};
  margin-top: ${SPACING_SMALL};
`;

export const VideoBox = styled.div`
  pointer-events: auto;
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_TEXT_200};
  border: 2px solid ${ADMIN_SURFACE_1};
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    cursor: pointer;
    color: ${ADMIN_ACTION_COLOR_HOVER};
  }
`;

export const ChooseVideoBox = styled.div`
  ${ACTION_NORMAL}
  background-color: ${ADMIN_SURFACE_1};
  padding: 8px;
  cursor: pointer;
`;
