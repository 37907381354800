import Icon from 'components/ui/Icon';
import { HIGHLIGHT_PRIMARY, TEXT_100 } from 'style/constants';
import { TEXT_LABEL_M_MEDIUM } from 'style/design-system/textStyles';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  align-self: stretch;
  ${TEXT_LABEL_M_MEDIUM}
  color: ${TEXT_100};
  width: fit-content;
  cursor: pointer;
  transform: translateX(-7.5px);
`;

export const BackIcon = styled(Icon).attrs(({
  name: 'arrowLeft',
}))`
  display: flex;
  align-items: center;
  padding-left: 7px;
  padding-right: 8px;

  & svg {
    width: 6px;
    height: 10px;

    & path {
      fill: ${HIGHLIGHT_PRIMARY};
    }
  }
`;
