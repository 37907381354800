import React from 'react';
import KeyedListMapper, {
  IProps as KeyedListMapperProps,
} from 'components/core/KeyedListMapper';
import hash from 'json-stable-stringify';

interface IProps<T> extends Pick<KeyedListMapperProps<T>, 'children' | 'keyField'> {
  list: T[];
}

function NonKeyedListMapper<T>({
  children,
  keyField = hash,
  list= [],
}: IProps<T>) {
  return (
    <KeyedListMapper dedupe={false} keyField={keyField} list={list} >
      {children}
    </KeyedListMapper>
  );
}

export default NonKeyedListMapper;
