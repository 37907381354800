import produce from 'immer';
import invariant from 'invariant';
import PropTypes from 'prop-types';
import React, { useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import shortid from 'shortid';
import hash from 'json-stable-stringify';
import capitalize from 'lodash/capitalize';

import AdminModal from 'components/admin2/ui/AdminModal';
import DatetimePicker from 'components/admin2/DatetimePicker';
import Dropdown from 'components/admin2/ui/Dropdown';
import VideoDataProvider from 'components/admin2/VideoDataProvider';
import PlaylistLibraryModal from 'components/admin2/videoPanels/PlaylistLibraryModal';
import VideoLibraryModal from 'components/admin2/videoPanels/VideoLibraryModal';
import videoLibraryModalTypes from 'components/modals/videoLibraryModalTypes';
import TranslatedText from 'components/i18n/TranslatedText';
import Icon from 'components/ui/Icon';
import BackButton from 'components/admin2/NavControls/BackButton';
import Button from 'components/admin2/ui/Button';

import { useAdminTranslation } from 'hooks/use-translation';
import {
  Container,
  Toolbar,
  InnerContainer,
  ChooseVideoBox,
  FieldTitle,
  VideoBox,
} from './styles';

const VIDEO = 'video';
const PLAYLIST = 'playlist';

const propTypes = {
  initialItem: PropTypes.shape({}),
  itemType: PropTypes.oneOf([VIDEO, PLAYLIST]),
  onAdd: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
};

const defaultProps = {
  initialItem: null,
  itemType: null,
  onAdd: null,
  onEdit: null,
};

const REPEAT_OPTIONS = [
  {
    label: 'ADMIN_LABEL_NONE',
    value: 'none',
  },
  {
    label: 'ADMIN_LABEL_WEEKLY',
    value: 'weekly',
  },
  {
    label: 'ADMIN_LABEL_DAILY',
    value: 'daily',
  },
];

const scheduleContentKeys = {
  playlist: 'ADMIN_PLACEHOLDER_SCHEDULE_PLAYLIST',
  video: 'ADMIN_PLACEHOLDER_SCHEDULE_VIDEO',
};

const getDefaultEndTime = ({ startTime, duration }) => {
  const time = new Date(startTime).getTime() + (duration * 1000);
  return typeof time === 'number' ? new Date(time).toString() : null;
};

const itemTypeToI18nKey = {
  playlist: 'ADMIN_LABEL_PLAYLIST',
  video: 'ADMIN_LABEL_VIDEO',
};

export default function PageEditorScheduleModal({
  itemType,
  initialItem,
  onAdd,
  onClose,
  onEdit,
}) {
  const [item, innerSetItem] = useState(initialItem);
  const id = useMemo(() => (
    item && itemType && item[itemType]?._id
  ), [hash(item), itemType]);
  const [picking, setPicking] = useState(!id);
  const [fallbackTimeString] = useState(() => new Date().toString());
  const { t } = useAdminTranslation();

  const updateItem = useCallback((producer) => {
    innerSetItem(produce(producer));
  }, [innerSetItem]);

  const selectItem = useCallback((doc, selectedType) => {
    const { durationSeconds, _id, title } = doc;
    invariant(_id, `invalid _id: ${_id}`);
    invariant(
      selectedType === VIDEO || selectedType === PLAYLIST,
      `invalid type: ${selectedType}`,
    );

    updateItem((draft) => {
      if (!draft) {
        draft = {}; // eslint-disable-line
      }
      draft.kind = selectedType;
      draft.duration = durationSeconds || draft.duration || 30 * 60;
      draft.repeat = draft.repeat || 'none';
      draft.arrayId = draft.arrayId || shortid.generate();
      draft.showCountdown = false;
      draft.startTime = draft.startTime || fallbackTimeString;
      draft[selectedType] = {
        _id,
        data: { title },
      };

      if (draft.startTime && draft.duration) {
        draft.endTime = draft.endTime || getDefaultEndTime(draft);
      }
      return draft;
    });
    setPicking(false);
  }, [setPicking, updateItem]);

  const onSelectItem = useCallback(
    (type) => (doc) => selectItem(doc, type),
    [selectItem],
  );

  const editingItemType = item?.kind || itemType;
  const isEditingVideo = picking && editingItemType === VIDEO;
  const isEditingPlaylist = picking && editingItemType === PLAYLIST;

  const disableAdd = useMemo(() => {
    if (!item) {
      return true;
    }
    return new Date(item.startTime).getTime() < Date.now() - (item.duration * 1000);
  }, [hash(item)]);

  const itemTypeLowerCase = itemType?.toLowerCase();

  return (
    <AdminModal allowOverflow fixedHeight maxWidth="600px" onClose={onClose} overlapAdminbar>
      <Container>
        <Toolbar>
          <BackButton onClick={onClose} />
          <Spacer />
          {id && onEdit && (
            <Button onClick={() => onEdit(item)}>{t('ADMIN_ACTION_SAVE')}</Button>
          )}
          {id && onAdd && (
            <Button disabled={disableAdd} onClick={() => onAdd(item)}>{t('ADMIN_ACTION_ADD')}</Button>
          )}
        </Toolbar>
        <InnerContainer>
          <FieldTitle>
            {
              itemTypeToI18nKey[itemTypeLowerCase] ?
                t(itemTypeToI18nKey[itemTypeLowerCase]) :
                capitalize(itemType)
            }
          </FieldTitle>
          {!id && (
            <ChooseVideoBox onClick={() => setPicking(true)}>
              <TranslatedText stringKey={scheduleContentKeys[itemType]} />
            </ChooseVideoBox>
          )}
          {id && (
            <VideoDataProvider id={id} type={itemType}>
              {(data, error, loaded) => {
                if (error) {
                  return error.message;
                }
                if (!loaded || !data) {
                  return 'Loading...';
                }

                const startTimeString = item.startTime;
                const startTimestamp = new Date(startTimeString).getTime();
                const endTimestamp = new Date(item.endTime).getTime();
                const showEndTime = !data.durationSeconds || data.durationSeconds === 0;

                return (
                  <>
                    <VideoBox onClick={() => setPicking(true)}>
                      {data.title}
                      <Icon admin name="pencil" />
                    </VideoBox>
                    <FieldTitle>{t('ADMIN_LABEL_START')}</FieldTitle>
                    <DatetimePicker
                      onTimeChange={(timestamp) => {
                        updateItem((draft) => {
                          draft.startTime = new Date(timestamp).toString();
                          if (!showEndTime) {
                            const endTime = timestamp + (data.durationSeconds * 1000);
                            draft.endTime = new Date(endTime).toString();
                          }
                        });
                      }}
                      timestamp={startTimestamp}
                    />
                    {showEndTime && (
                      <>
                        <FieldTitle>{t('ADMIN_LABEL_END')}</FieldTitle>
                        <DatetimePicker
                          onTimeChange={(timestamp) => {
                            updateItem((draft) => {
                              draft.duration = (timestamp - startTimestamp) / 1000;
                              draft.endTime = new Date(timestamp).toString();
                            });
                          }}
                          timestamp={endTimestamp}
                        />
                      </>
                    )}
                    <FieldTitle>{t('ADMIN_LABEL_REPEAT')}</FieldTitle>
                    <Dropdown
                      data-testid="repeatDropdown"
                      onChange={({ value }) => {
                        updateItem((draft) => {
                          draft.repeat = value;
                        });
                      }}
                      options={REPEAT_OPTIONS}
                      translated
                      value={REPEAT_OPTIONS.find(option => option.value === item.repeat) || null}
                    />
                  </>
                );
              }}
            </VideoDataProvider>
          )}
          {
            isEditingVideo && (
              <VideoLibraryModal
                onClose={() => setPicking(false)}
                onSelectItem={onSelectItem(VIDEO)}
                type={videoLibraryModalTypes.SCHEDULE_EDIT}
              />
            )
          }
          {
            isEditingPlaylist && (
              <PlaylistLibraryModal
                onClose={() => setPicking(false)}
                onSelectItem={onSelectItem(PLAYLIST)}
              />
            )
          }
        </InnerContainer>
      </Container>
    </AdminModal>
  );
}

const Spacer = styled.div`flex-grow: 1;`;

PageEditorScheduleModal.propTypes = propTypes;
PageEditorScheduleModal.defaultProps = defaultProps;
