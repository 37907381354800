import withRenderCondition from 'components/core/withRenderCondition';
import React, {
  ComponentProps,
  MouseEventHandler,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  publishPendingChanges,
  publishPendingGateChanges,
  publishPendingPanelChanges,
} from 'services/admin/actions';
import { isLandingPageType, hasBothYtOrTwitchAndUploadedVideosOnPageContent, getPageId, getPageName } from 'services/app/selectors';
import {
  hasUnpublishedAdminChanges,
  getLandingPageContent,
  getIsPublishingPendingChanges,
} from 'services/admin/selectors';
import Button from './Button';
import { getAdminAccentSecondary } from 'services/themes';
import { getIsEnterprisePlan, getIsLegacyPlan, getIsUnlimitedPlan } from 'services/billing';
import { showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';
import { COMPONENTS_WITH_VIDEO } from 'components/admin-bridge/AdminGridController/constants';
import { isChannelGateEnabled } from 'services/gate/selectors/common';
import { hasYoutubeOrTwitchVideoOnPageContent } from 'services/video';
import useHandlers from '../ToolbarView/Default/hooks/use-handlers';
import styled from 'styled-components';
import { useAdminTranslation } from 'hooks/use-translation';
import Icon from 'components/admin2/Icon';
import Spinner from 'components/ui/Spinner';
import { ADMIN_HIGHLIGHT_TERTIARY, HIGHLIGHT_TERTIARY } from 'style/constants';
import { trackEvent } from 'services/segment-analytics';
import { AdminActionEvents } from 'services/admin/models';

interface PublishButtonProps
  extends Omit<ComponentProps<typeof Button>, 'stringKey'> {
  hasChanges?: boolean;
  isDisabled?: boolean;
}

function PublishButton({ hasChanges, onClick, isDisabled, ...props }: PublishButtonProps) {
  const dispatch = useDispatch();
  const hasAdminChanges = useSelector(hasUnpublishedAdminChanges);
  const adminAccentSecondary = useSelector(getAdminAccentSecondary);
  const isEnterprisePlan = useSelector(getIsEnterprisePlan);
  const isUnlimitedPlan = useSelector(getIsUnlimitedPlan);
  const isLegacyPlan = useSelector(getIsLegacyPlan);
  const isLandingPage = useSelector(isLandingPageType);
  const landingContent = useSelector(getLandingPageContent);
  const isGateEnabled = useSelector(isChannelGateEnabled);
  const hasYoutubeOrTwitchContent = useSelector(hasYoutubeOrTwitchVideoOnPageContent);
  const hasBothYtOrTwitchAndUploadedContent = useSelector(hasBothYtOrTwitchAndUploadedVideosOnPageContent);
  const isPublishingPendingChanges = useSelector(getIsPublishingPendingChanges);
  const channelId = useSelector(getPageId);
  const channelName = useSelector(getPageName);
  const [isPublishing, setIsPublishing] = useState(false);
  const { exitEditor } = useHandlers();
  const { t } = useAdminTranslation();

  const pageHasVideo = useMemo(
    () =>
      landingContent.some((content) =>
      // @ts-ignore
        COMPONENTS_WITH_VIDEO.includes(content.kind),
      ),
    [landingContent],
  );

  const hasEditorChanges =
    hasChanges !== undefined
      ? hasChanges
      : hasAdminChanges;

  const publishEditorChanges = useCallback(() => {
    dispatch(publishPendingChanges());
    dispatch(publishPendingGateChanges());
    dispatch(publishPendingPanelChanges());
  }, []);

  const handlePublish: MouseEventHandler<HTMLButtonElement> = (e) => {
    /**
     * Pricing V2: If the landing page contains a video and the user is not on an enterprise, legacy or unlimited plan, halt publish.
     *             Admin is allowed to publish a video spotlight or playlist with Youtube or Twitch content if the gate is off
     */
    const planHasGateLimitations = !isEnterprisePlan && !isUnlimitedPlan && !isLegacyPlan;
    const landingPageHasVideo = isLandingPage && pageHasVideo && !hasYoutubeOrTwitchContent;
    const isGateOff = !isGateEnabled;

    if (
      planHasGateLimitations &&
      landingPageHasVideo &&
      isGateOff
    ) {
      dispatch(
        showModal({
          kind: ModalKinds.upgradePlan,
          data: {
            planWarningMessage: 'ADMIN_UPGRADE_PLAN_LANDING_PAGE_WITHOUT_GATE_ERROR',
            preSelectedPlan: 'custom',
          },
        }),
      );
      return;
    }
    // check if landing page has both uploaded and yt/twitch video content but plan doesn't support non-gated shows
    if (
      isGateOff &&
      planHasGateLimitations &&
      isLandingPage &&
      hasBothYtOrTwitchAndUploadedContent
    ) {
      dispatch(
        showModal({
          kind: ModalKinds.upgradePlan,
          data: {
            planWarningMessage: 'ADMIN_UPGRADE_PLAN_WHEN_PAGE_HAS_UPLOADED_AND_YT_OR_TWITCH_CONTENT',
            preSelectedPlan: 'custom',
          },
        }),
      );
      return;
    }

    // Display Halt Gate Modal if gate is activated and admin is trying to publish youtube or twitch content
    if (
      isGateEnabled &&
      isLandingPage &&
      hasYoutubeOrTwitchContent
    ) {
      dispatch(showModal({ kind: ModalKinds.haltGate }));
      return;
    }


    (onClick || publishEditorChanges)(e);
    setIsPublishing(true);
  };

  const trackPagePublish = () => {
    dispatch(
      trackEvent({
        event: isLandingPage ?
          AdminActionEvents.PAGE_PUBLISHED : AdminActionEvents.CHANNEL_PUBLISHED,
        properties: {
          channelId,
          channelName,
        },
      }),
    );
  };

  const showPublishedText = isPublishing && !isPublishingPendingChanges;
  if (showPublishedText) {
    trackPagePublish();
    setTimeout(() => {
      setIsPublishing(false);
      exitEditor();
    }, 2000);
  }

  const renderText = React.useCallback(() => {
    if (isPublishingPendingChanges) {
      return <LoadingSpinner />;
    }

    if (showPublishedText) {
      return <>{t('ADMIN_ACTION_PUBLISHED')} <CheckIcon /></>;
    }

    return t('ADMIN_ACTION_PUBLISH');
  }, [isPublishingPendingChanges, showPublishedText]);

  return (
    <StyledButton
      {...props}
      background={adminAccentSecondary}
      data-testid="publishButton"
      disabled={(!hasEditorChanges && !isPublishing) || isDisabled}
      onClick={handlePublish}
      isPublishing={isPublishingPendingChanges || isPublishing}
    >
      {renderText()}
    </StyledButton>
  );
}

const StyledButton = styled(Button)<{ isPublishing: boolean; }>`
  min-width: 92px;
  ${({ isPublishing }) => isPublishing && `pointer-events: none;`};
  &:disabled {
    background-color: ${props => props.background} !important;
    opacity: 0.4;
  }
`;

const LoadingSpinner = styled(Spinner).attrs(props => ({
  style: { width: '20px', height: '20px', transform: 'scale(1.5)', display: 'flex' },
  fillColor: ADMIN_HIGHLIGHT_TERTIARY(props),
}))``;

const CheckIcon = styled(Icon).attrs({
  name: 'checkmarkCircle',
})`
  display: flex;
  align-items: center;
  margin-left: 4px;
  & svg {
    width: 12px;
    height: 12px;
  }
`;


export { PublishButton };
export default withRenderCondition(PublishButton);
