import BillingInfoForm from 'components/payment/BillingInfoForm';
import CreditCardForm from 'components/payment/CreditCardForm';
import { RegionRenderingStripeCreditCardEnum, addRegionRenderingStripeCreditCard, removeRegionRenderingStripeCreditCard } from 'services/billing';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePaymentMethods, { IHandleSubmitCreditCardFormParams } from 'hooks/use-payment-methods';
import { showAdminErrorModal } from 'services/modals';
import { PaymentMethod } from '@stripe/stripe-js';
import GoBack from '../GoBack';
import { useAdminTranslation } from 'hooks/use-translation';
import { BodySection, BodySectionTitle } from '../sharedStyles';
import { isLoggedIn } from 'services/auth';

interface IAddNewPaymentMethodFormProps {
  onSubmit: (pm: PaymentMethod, stripeTokenId: string) => void;
}

const AddNewPaymentMethodForm: React.FC<IAddNewPaymentMethodFormProps> = ({ onSubmit }) => {
  const [loading, setLoading] = React.useState(false);
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();
  const { submitCreditCard } = usePaymentMethods();
  const userLoggedIn = useSelector(isLoggedIn);

  React.useEffect(() => {
    dispatch(addRegionRenderingStripeCreditCard(RegionRenderingStripeCreditCardEnum.ECOMMERCE_CHECKOUT));
    return () => {
      dispatch(removeRegionRenderingStripeCreditCard(RegionRenderingStripeCreditCardEnum.ECOMMERCE_CHECKOUT));
    };
  }, []);

  const handleSubmitCreditCard = async (params: IHandleSubmitCreditCardFormParams) => {
    setLoading(true);

    try {
      const result = await submitCreditCard(params);
      if (result.paymentMethod && result.stripeTokenId) {
        onSubmit(result.paymentMethod, result.stripeTokenId);
      }
    } catch(err) {
      if (err.response?.data?.message) {
        // currently there is no modal for regular users...
        dispatch(showAdminErrorModal(err.response.data.message));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <BodySection>
      <GoBack>{t('ADMIN_LABEL_GO_BACK')}</GoBack>
      <BodySectionTitle>{t('PAYMENT_METHOD')}</BodySectionTitle>
      <BillingInfoForm />
      <CreditCardForm
        loading={loading}
        onSubmit={handleSubmitCreditCard}
        regionRenderingStripeCreditCard={RegionRenderingStripeCreditCardEnum.ECOMMERCE_CHECKOUT}
        submitButtonStringKey="ADD_AND_CONTINUE"
        shouldRenderMakePrimaryPaymentMethodCheckbox={userLoggedIn}
      />
    </BodySection>
  );
};

export default AddNewPaymentMethodForm;
