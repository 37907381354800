import React, { memo } from 'react';
import { useAdminTranslation } from 'hooks/use-translation';
import { Container, Content, Title, Text, ShoppingCartIcon } from './styles';

const EmptyCart = () => {
  const { t } = useAdminTranslation();
  return (
    <Container>
      <Content>
        <ShoppingCartIcon />
        <Title>{t('EMPTY_CART_TITLE')}</Title>
        <Text>{t('EMPTY_CART_DESCRIPTION')}</Text>
      </Content>
    </Container>
  );
};

export default memo(EmptyCart);
