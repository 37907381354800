import React from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'services/admin';
import { TRANSPARENT_COLOR } from 'style/constants';

export type Keys = (
  'surface1' |
  'surface2' |
  'surface3' |
  'surface4' |
  'surface5' |
  'surface6' |
  'accentPrimary'
);

const usePageBlockBackgroundColorOptions = (color: string) => {
  const theme = useSelector(getTheme);

  const { colors, colorKeys, colorValues } = React.useMemo(() => {
    const themeColors: Record<Keys, string> = {
      surface1: theme.colors.surface1,
      surface2: theme.colors.surface2,
      surface3: theme.colors.surface3,
      surface4: theme.colors.surface4,
      surface5: theme.colors.surface5,
      surface6: theme.colors.surface6,
      accentPrimary: theme.colors.accentPrimary,
    };

    const themeColorKeys = Object.keys(themeColors);
    const themeColorValues = Object.values(themeColors);

    return {
      colors: themeColors,
      colorKeys: themeColorKeys,
      colorValues: themeColorValues,
    };
  }, [theme.colors]);

  const getColor = (targetColor: string) => {
    return colorKeys.find(key => colors[key] === targetColor) || targetColor;
  };

  const isCustomColor = (targetColor: string) => {
    return !colorKeys.includes(targetColor) && !colorValues.includes(targetColor) && targetColor !== TRANSPARENT_COLOR;
  };

  return {
    colors: colorValues,
    selectedColor: colorValues.find(value => value === colors[color]) || color,
    getColor,
    isCustomColor,
  };
};

export default usePageBlockBackgroundColorOptions;
