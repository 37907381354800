import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigateToAdminBarAction, pushAdminBarSubMenuKey } from 'services/admin/actions';
import { ActionKey } from 'services/admin/constants';
import { getStreamingProvider } from 'services/app/selectors';
import {
  ProviderDisplay,
  ProviderLabel,
  SettingsIcon,
} from './styles';

const DEFAULT_STREAM_PROVIDER = 'mux';

export default function StreamProviderDisplay() {
  const dispatch = useDispatch();
  const provider = useSelector(getStreamingProvider);

  const onOpen3rdPartyApps = () => {
    dispatch(pushAdminBarSubMenuKey('settings'));
    dispatch(navigateToAdminBarAction({
      actionKey: ActionKey.thirdParty,
    }));
  };

  return provider ? (
    <ProviderDisplay>
      <ProviderLabel labelKey="ADMIN_LABEL_PROVIDER">
        {provider}
      </ProviderLabel>
      {provider !== DEFAULT_STREAM_PROVIDER && <SettingsIcon onClick={onOpen3rdPartyApps}/>}
    </ProviderDisplay>
  ) : null;
}
