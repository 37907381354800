import { useFeatureGateEnabled } from 'hooks';
import { Feature } from 'hooks/use-feature-gate-enabled';
import useRenderConditions from 'hooks/use-render-conditions';
import { useSelector } from 'react-redux';
import {
  hasUnpublishedAdminChanges,
  inAccessMenu,
  inContentMenu,
  isBroadcastMode,
  isEditing as isEditingSelector,
} from 'services/admin/selectors';
import { isLandingPageType } from 'services/app/selectors';

export enum Action {
  gateToggle = 'gateToggle',
  autoSave = 'autoSave',
  stream = 'stream',
  editGate = 'editGate',
  editChannel = 'editChannel',
  exitEditor = 'exitEditor',
  publish = 'publish',
}

const ActionKeys = Object.keys(Action) as (keyof typeof Action)[];

export default function useDefaultConditions() {
  const isInAccessMenu = useSelector(inAccessMenu);
  const isInContentMenu = useSelector(inContentMenu);
  const isLanding = useSelector(isLandingPageType);
  const isEditing = useSelector(isEditingSelector);
  const isBroadcast = useSelector(isBroadcastMode);
  const hasAdminChanges = useSelector(hasUnpublishedAdminChanges);
  const isSimpleModeEnabled = useFeatureGateEnabled({
    feature: Feature.SIMPLE_MODE,
    type: 'feature',
  });

  return useRenderConditions({
    fieldMap: ActionKeys,
    renderConditions: [{
      condition: isEditing,
      fields: [
        Action.autoSave,
        Action.exitEditor,
        Action.publish,
      ],
    }, {
      condition: !isEditing && isInAccessMenu,
      fields: [
        Action.editGate,
      ],
    }, {
      condition: !isEditing && !isInAccessMenu,
      fields: [
        Action.editChannel,
      ],
    }, {
      condition: !isEditing && !isLanding,
      fields: [
        Action.stream,
      ],
    }, {
      condition: !isInContentMenu,
      fields: [
        Action.gateToggle,
      ],
    }, ...isSimpleModeEnabled ? [{
      condition: hasAdminChanges,
      fields: [
        Action.autoSave,
        Action.publish,
      ],
    }] : []],
  });
}
