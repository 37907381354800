import styled from 'styled-components';
import {
  ADMIN_SURFACE_1,
  ADMIN_TEXT_100,
  ADMIN_ACCENT_PRIMARY,
  LIVE_INDICATOR_RED,
  ADMIN_BUTTON_BORDER_RADIUS,
  SPACING_X_SMALL_PX,
} from 'style/constants';
import { CIRCLE } from 'style/mixins';
import { ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';

export const LiveIndicator = styled.div.attrs({
  radius: '8px',
})<{ defaultColor?: boolean, live?: boolean }>`
  padding: 0 ${SPACING_X_SMALL_PX}px;
  height: 25px;
  min-width: 87px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ live }) => live ? LIVE_INDICATOR_RED : ADMIN_SURFACE_1};
  color: ${ADMIN_TEXT_100};
  border-radius: ${ADMIN_BUTTON_BORDER_RADIUS};
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  text-transform: capitalize;
  user-select: none;
  &:before {
    content: "";
    ${CIRCLE}
    margin-right: 5px;
    background: ${({ defaultColor }) => defaultColor ? ADMIN_TEXT_100 : ADMIN_ACCENT_PRIMARY };
  }
`;
