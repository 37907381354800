import React from 'react';
import { VIDEO_ID } from 'global-ids';
import Player from 'components/Video/video-player/Player';
import { CastProvider } from 'react-cast-sender';
import { PlayerProps } from '../video-player/Player/interfaces';

const DEFAULT_APP_ID = 'CC1AD845';

export const VideoPlayback = (playerProps: PlayerProps) => {
  return (
    <CastProvider receiverApplicationId={DEFAULT_APP_ID}>
      <Player
        activeVideoElementId={VIDEO_ID}
        {...playerProps}
      />
    </CastProvider>
  );
};
