import { connect } from 'react-redux';

import withErrorGuard from 'components/core/withErrorGuard';
import FontLoader from 'components/ui/FontLoader';
import { getFonts } from 'services/app/selectors';

const mapStateToProps = state => ({
  fonts: getFonts(state),
});

export default withErrorGuard()(
  connect(mapStateToProps)(FontLoader),
);
