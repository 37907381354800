import styled from 'styled-components';
import Button from 'components/admin2/ui/Button';
import { ADMIN_TEXT_LABEL_M_BOLD } from 'style/design-system/textStyles';

export const Container = styled(Button)`
  margin-top: 25px;
  height: 40px;
  width: fit-content;
  margin-left: auto;
  ${ADMIN_TEXT_LABEL_M_BOLD};
`;
