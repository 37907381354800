import React, { ReactNode } from 'react';
import { BoundingBox, Container, StyledAspectRatio } from './styles';

export type AspectRatioVideoProps = {
  aspectRatio: number;
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  enabled?: boolean;
  isLandscape: boolean;
  offsetHeight: number;
  videoHeight: number;
};

export default class AspectRatioVideo extends React.Component<AspectRatioVideoProps> {
  static defaultProps = {
    aspectRatio: 16 / 9,
    className: undefined,
    disabled: false,
    enabled: false,
    offsetHeight: 0,
  };

  container: HTMLDivElement | null = null;
  intervalId: NodeJS.Timeout | null = null;

  state = {
    height: '0px',
    width: '0px',
  };

  componentDidMount() {
    this.intervalId = setInterval(this.updateSize, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId!);
  }

  // set dom container for maths
  ref = (container: HTMLDivElement | null) => {
    if (container === this.container) {
      return;
    }

    this.container = container;
    this.updateSize();
  }

  render() {
    const {
      children,
      className,
      isLandscape,
      videoHeight,
      enabled,
      disabled,
    } = this.props;

    const { height, width } = this.state;

    return (
      <StyledAspectRatio
        className={className}
        disabled={isLandscape || disabled}
        maxHeight={videoHeight}
      >
        <Container ref={this.ref}>
          <BoundingBox
            height={enabled ? height : '100%'}
            width={enabled ? width : '100%'}
          >
            {children}
          </BoundingBox>
        </Container>
      </StyledAspectRatio>
    );
  }

  // debounceThis a bit
  updateSize = () => {
    if (!this.container) {
      return;
    }

    const { aspectRatio, offsetHeight } = this.props;
    const containerWidth = this.container.offsetWidth;
    const containerHeight = this.container.offsetHeight + offsetHeight;

    // as big as it can be
    const height = Math.min(containerHeight, containerWidth / aspectRatio);
    const width = Math.min(containerWidth, containerHeight * aspectRatio);

    const {
      height: currentHeight,
      width: currentWidth,
    } = this.state;

    // snap to fit tech
    if (width === containerWidth) {
      const calculatedHeight = `${height - offsetHeight}px`;
      const calculatedWidth = '100%';

      if (currentHeight !== calculatedHeight || currentWidth !== calculatedWidth) {
        this.setState({
          height: calculatedHeight,
          width: calculatedWidth,
        });
      }
    }

    if (height === containerHeight) {
      const calculatedHeight = '100%';
      const calculatedWidth = `${width}px`;

      if (currentHeight !== calculatedHeight || currentWidth !== calculatedWidth) {
        this.setState({
          height: calculatedHeight,
          width: calculatedWidth,
        });
      }
    }
  }
}
