import styled from 'styled-components';
import { ADMIN_TEXT_200 } from 'style/constants';
import {
  FLEX_CENTER,
} from 'style/mixins';
import { ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';

export const ShareModalContainer = styled.div`
  ${FLEX_CENTER}
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  color: ${ADMIN_TEXT_200};
  width: 100%;
  height: 100%;
  position: relative;
  flex-direction: column;
  & > *:first-child {
    margin-bottom: 19px;
    margin-top: -19px;
    &:after {
      content: ":";
    }
  }
`;
