import React from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import { useSelector } from 'react-redux';
import { OverlayWrapper, BROADCAST_OVERLAY_TRANSITION_TIME_IN_MILLISECONDS } from './styles';
import { getOverlayBeingEdited } from 'services/overlays/selectors';
// @ts-ignore
import Overlay from 'components/objects/Overlay';
import userOverlay from 'hooks/use-overlays';
import { Overlay as TempOverlay } from 'components/Video/video-content/Overlays';

const displayControlsOverlays = [
  'poll',
  'poll_results',
  'trivia',
  'lower_third',
  'shopify',
];

const limitWidthOverlays = [
  'poll',
  'poll_results',
  'trivia',
  'shopify',
];

const showVideoControlOnMobile = [
  'shopify',
];

export interface IBroadcastOverlayProps {
  overlay: any;
}

export const BroadcastOverlay = ({ overlay }: IBroadcastOverlayProps) => {
  const editingOverlay = useSelector(getOverlayBeingEdited);

  const showVideoControls = (overlayType: string) => displayControlsOverlays.includes(overlayType);
  const limitOverlayWidth = (overlayType: string) => limitWidthOverlays.includes(overlayType);
  const showVideoControlsMobile = (overlayType: string) => showVideoControlOnMobile.includes(overlayType);

  const { activeOverlay, overlayStatusClass } = userOverlay();

  if (editingOverlay) {
    const overlayType = editingOverlay?.payload?.renderer?.overlayType || editingOverlay?.payload?.data?.kind;

    return (
      <OverlayWrapper
        data-testid="broadcast-overlay-wrapper"
        limitOverlayWidth={limitOverlayWidth(overlayType)}
        showVideoControls={showVideoControls(overlayType)}
        showVideoControlsMobile={showVideoControlsMobile(overlayType)}
      >
        <Overlay data={editingOverlay} editing={true} />
      </OverlayWrapper>
    );
  } else {
    const overlayType = overlay?.payload?.renderer?.overlayType || overlay?.payload?.data?.kind;

    return (
      <TransitionGroup component={null}>
        {
          (Boolean(overlay) || activeOverlay) && (
            <Transition
              key={overlay?.key || overlay?.broadcastId}
              timeout={BROADCAST_OVERLAY_TRANSITION_TIME_IN_MILLISECONDS}
            >
              {
                status => (
                  <OverlayWrapper
                    className={status}
                    limitOverlayWidth={limitOverlayWidth(overlayType)}
                    showVideoControls={showVideoControls(overlayType)}
                    showVideoControlsMobile={showVideoControlsMobile(overlayType)}
                  >
                    {activeOverlay && <TempOverlay overlay={activeOverlay} statusClass={overlayStatusClass} />}
                    <Overlay data={overlay} editing={false}/>
                  </OverlayWrapper>
                )
              }
            </Transition>
          )
        }
      </TransitionGroup>
    );
  }

};
