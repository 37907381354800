import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import { ADMIN_TEXT_100, ADMIN_SURFACE_1, ADMIN_ACCENT_PRIMARY, ADMIN_SURFACE_4, ADMIN_ALERT_ERROR, ADMIN_SURFACE_2, ADMIN_SURFACE_3 } from 'style/constants';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_BODY_XL_BOLD, ADMIN_TEXT_BODY_XS_BOLD, ADMIN_TEXT_HEADLINE_S } from 'style/design-system/textStyles';

export const Container = styled.div`
  background: ${ADMIN_SURFACE_1};
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  background: ${ADMIN_SURFACE_3};
  border-radius: 10px;
  height: auto;
  width: 813px;
  max-width: calc(100% - 40px);
  padding: 20px 35px 40px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  margin-bottom: 15px;
`;
export const HeaderText = styled.span`
  ${ADMIN_TEXT_BODY_XL_BOLD};
`;

export const StreamModes = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 380px;
`;

export const StreamMode = styled.button`
  background: ${ADMIN_SURFACE_2};
  border-radius: 20px;
  max-width: 371px;
  padding: 25px 25px 0px 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid transparent;
  cursor: pointer;
  &:first-child {
    margin-right: 15px;
  }
  &:hover {
    border: 1px solid ${ADMIN_ACCENT_PRIMARY};
  }
`;

export const Image = styled.img`
  border-radius: 10px;
  max-height: 181px;
  width: 100%;
  display: block;
  border: 1px solid ${ADMIN_SURFACE_4};
  background: ${ADMIN_SURFACE_1};
  padding: 40px 0;
`;

export const TitleAndDescription = styled.div`
  margin: auto;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.span`
  ${ADMIN_TEXT_HEADLINE_S};
  color: ${ADMIN_TEXT_100};
  text-align: center;
`;

export const BetaWrapper = styled.span`
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
`;

export const Highlight = styled.span`
  ${ADMIN_TEXT_BODY_XS_BOLD};
  background: ${ADMIN_ACCENT_PRIMARY};
  border-radius: 12px;
  text-transform: uppercase;
  color: ${ADMIN_TEXT_100};
  padding: 2px 10px;
  margin-left: 6px;
  line-height: 18px;
`;

export const Description = styled.p`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${ADMIN_TEXT_100};
  text-align: center;
  width: 90%;
`;

export const WarningIcon = styled(Icon).attrs(props => ({
  color: ADMIN_ALERT_ERROR(props),
  name: 'warning',
}))`
  padding-bottom: 12px;
  & svg {
    width: 27px;
    height: 22px;
  }
`;

export const TitleText = styled.div`
  font-weight: 700;
  font-size: 19px;
  line-height: 19px;
  color: ${props => ADMIN_ALERT_ERROR(props)};
  padding-top: 12px;
`;

export const WarningText = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM};
  color: ${ADMIN_TEXT_100};
  padding: 20px 0;
  text-align: center;
  max-width: 247px;
`;

export const BrokenCredentials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 20px;
`;
