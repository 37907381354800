import React, { useMemo } from 'react';
import invariant from 'invariant';
import hash from 'json-stable-stringify';
import { computeEntries } from 'array-utils';

export interface IProps<T> {
  children: (keyField: string, item: T, index: number) => React.ReactNode;
  dedupe?: boolean;
  keyField?: string | ((value: T) => string);
  list: T[];
}

function KeyedListMapper<T>({
  children,
  dedupe = false,
  keyField,
  list= [],
}: IProps<T>) {
  const entries = useMemo(() => (
    computeEntries(list, keyField, dedupe)
  ), [
    hash(list), keyField, dedupe,
  ]);

  if (typeof children !== 'function' || !(children.length === 2 || children.length === 3)) {
    invariant(
      false,
      'KeyedListMapper: child must be a function with arguments (key, value, [index])',
    );
  }

  return (
    <>
      {entries.map(({ key, value }, index) => children(key, value, index))}
    </>
  );
}

export default KeyedListMapper;
