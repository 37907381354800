import invariant from 'invariant';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import RealtimeDocument from 'components/core/RealtimeDocument';
import { getDocument, isDocumentLoaded } from 'services/realtime';

const applyMapper = (mapper, doc, docLoaded, id, collection) => (
  typeof mapper === 'function' ?
    mapper(doc, docLoaded, id, collection) :
    { [mapper]: doc, [`${mapper}Loaded`]: docLoaded }
);

const mapStateToProps = (state, { collection, id }) => ({
  doc: (collection && id) ? getDocument(state, collection, id) : null,
  docLoaded: (collection && id) ? isDocumentLoaded(state, collection, id) : false,
});

const autoConnect = connect(mapStateToProps);

/**
 * Please rewrite me using hooks + TS!!!
 * @param {any} props
 */
export default function withRealtimeDocument({
  collection: defaultCollection,
  component: Component,
  loading: defaultLoading = undefined,
  mapper: defaultMapper = 'doc',
}) {
  invariant(
    typeof Component === 'function' || Component.styledComponentId,
    `withRealtimeDocument expected component but received: ${JSON.stringify(Component, null, 2)}`,
  );

  class WithRealtimeDocument extends React.Component {
    static propTypes = {
      collection: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      loading: PropTypes.func,
      mapper: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
      ]),
    };

    static defaultProps = {
      loading: defaultLoading,
      mapper: defaultMapper,
    };

    static displayName = `WithRealtimeDocument(${
      Component.displayName || Component.name
    })`;

    state = {
      doc: null,
      docLoaded: false,
    };

    handleValue = (doc, docLoaded) => {
      this.setState({ doc, docLoaded });
    };

    render() {
      const {
        collection,
        id,
        loading: LoadingComponent,
        mapper,
        ...baseProps
      } = this.props;
      const {
        doc,
        docLoaded,
      } = this.state;

      const shouldRenderLoading = !docLoaded && LoadingComponent;

      const ChildComponent = shouldRenderLoading ? LoadingComponent : Component;
      const mappedProps = shouldRenderLoading ?
        {} :
        applyMapper(mapper, doc, docLoaded, id, collection);

      return (
        <RealtimeDocument
          collection={collection}
          id={id}
          onValue={this.handleValue}
        >
          <ChildComponent {...baseProps} {...mappedProps} />
        </RealtimeDocument>
      );
    }
  }

  const WithRealtimeDocumentConnected = autoConnect(WithRealtimeDocument);
  WithRealtimeDocumentConnected.defaultProps = {
    collection: defaultCollection,
  };
  return WithRealtimeDocumentConnected;
}
