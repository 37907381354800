import styled from 'styled-components';
import Icon from 'components/admin2/Icon';
import { ADMIN_HIGHLIGHT_PRIMARY } from 'style/constants';
import { NAME } from '../styles';
import { ELLIPSIS } from 'style/mixins';
import Link from 'components/ui/Link';

export const LinkIcon = styled(Icon).attrs(() => ({
  name: 'adminbarLink',
  color: ADMIN_HIGHLIGHT_PRIMARY,
}))`
  display: flex;
  width: 16px;
  height: 16px;

  & > svg {
    path {
      fill: ${ADMIN_HIGHLIGHT_PRIMARY};
    }
  }

  margin-left: 6px;
  margin-right: 11.5px;
  transform: translateY(-2px);
`;

export const LinkName = styled(Link)<{ maxLength: number }>`
  ${NAME};
  ${ELLIPSIS}
  display: inline-block;
  max-width: ${({ maxLength }) => maxLength}ch;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
