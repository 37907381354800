import styled from 'styled-components';
import QuickEditOverlay from 'components/admin-bridge/QuickEditOverlay';
import { HEADER_HEIGHT_WITH_UNITS } from 'style/constants';
import TextInput from 'components/admin2/TextInput';
import Countdown from 'components/ui/Countdown';

export const Placeholder = styled.div`
  min-width: 150px !important;
  height: ${HEADER_HEIGHT_WITH_UNITS};
`;

export const StyledQuickEditOverlay = styled(QuickEditOverlay)`
  height: 100%;
  min-width: 150px;
`;

export const TitleInput = styled(TextInput)`
  margin-bottom: 24px;
`;

export const StyledCountdown = styled(Countdown)<{ hidden?: boolean; }>`
  visibility: ${props => props.hidden ? 'hidden' : 'visible'};
`;
