import Button from 'components/admin2/ui/Button';
import withTooltip from 'components/core/withTooltip';
import Icon from 'components/ui/Icon';
import { ADMIN_ACCENT_PRIMARY, ADMIN_FONT_FAMILY, ADMIN_HIGHLIGHT_SECONDARY, ADMIN_TEXT_100, ADMIN_TEXT_200 } from 'style/constants';
import { ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 20px;
  height: 28px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  gap: 9px;
`;

export const Text = styled.span`
  color: ${ADMIN_TEXT_100};
  font-family: ${ADMIN_FONT_FAMILY};
  ${ADMIN_TEXT_BODY_S_BOLD}
`;

export const InfoTooltip = withTooltip(
  styled(Icon).attrs(() => ({
    name: 'analyticsInfo',
    color: ADMIN_HIGHLIGHT_SECONDARY,
  }))`
  `,
  { admin: true,
    tooltipArrowCss: 'left: 26.2%;',
    tooltipCss: css`
      max-width: 244px;
      white-space: normal;
      text-align: left;
      padding: 10px;
      color: ${ADMIN_TEXT_200};
      font-family: ${ADMIN_FONT_FAMILY};
      ${ADMIN_TEXT_BODY_XS_MEDIUM}
      margin-left: 117.5px;
    `,
  },
);

export const Options = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CreateButtonContainer = styled.div<{ isSelected: boolean }>`
  position: relative;
`;

export const CreateButton = styled(Button)`
  display: flex;
  align-items: center;
  height: 24px;
  gap: 5px;
  padding: 0 15px 0 10px;
`;

export const PlusIcon = styled(Icon).attrs(props => ({
  name: 'plus',
  color: ADMIN_ACCENT_PRIMARY(props),
}))`
  display: flex;
  transition: transform 0.5s;
  transform: scale(0.9);
  width: 16px;
  height: 16px;
`;
