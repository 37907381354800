import { isLandscape, isMobileLayout } from 'services/device/selectors';
import { getCalculatedVideoHeight } from 'services/device/selectors/common';
import { connect } from 'react-redux';
import AspectRatio from './view';
import type IState from 'services/state';

const mapStateToProps = (state: IState) => ({
  isLandscape: isMobileLayout(state) && isLandscape(state),
  videoHeight: getCalculatedVideoHeight(state),
});

export default connect(mapStateToProps)(AspectRatio);
