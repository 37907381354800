import React from 'react';
import PropTypes from 'prop-types';
import { FacebookShareButton } from 'react-share';
import { getReferralUrl } from 'services/insights';
import CardButton from 'components/cards/CardButton';
import TranslatedText from 'components/i18n/TranslatedText';

export default class FacebookShareCardCta extends React.Component {
  static propTypes = {
    onEngage: PropTypes.func.isRequired,
    referralId: PropTypes.string.isRequired,
    testIdText: PropTypes.string,
  };

  static defaultProps = {
    testIdText: undefined,
  };

  render() {
    const { onEngage, referralId, testIdText } = this.props;
    const referralUrl = getReferralUrl(referralId);

    return (
      <FacebookShareButton
        beforeOnClick={() => {
          onEngage({ cta: 'facebook_share' });
        }}
        url={referralUrl}
      >
        <CardButton iconName="facebook-squared">
          <TranslatedText componentProps={{ 'data-testid': testIdText }} stringKey="SHARE_TO_FACEBOOK" />
        </CardButton>
      </FacebookShareButton>
    );
  }
}
