import styled from 'styled-components';
import Link from 'components/ui/Link';
import Button from 'components/admin2/ui/Button';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import {
  ADMIN_ACCENT_PRIMARY,
  ADMIN_SURFACE_4,
  ADMIN_SURFACE_6,
  TOOLBAR_HEIGHT,
} from 'style/constants';
import { ADMIN_TEXT_LABEL_S_BOLD } from 'style/design-system/textStyles';

export const ToolbarWrapper = styled.div`
  background: ${ADMIN_SURFACE_4};
  height: ${TOOLBAR_HEIGHT};
  z-index: 5;
  border-bottom: 1px solid ${ADMIN_SURFACE_6};
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  justify-content: space-between;
  padding: 0 14px;
  align-items: center;
  position: relative;
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  min-width: 0;
  & > *:not(:first-child) {
    margin-left: 10px;
  }
`;

export const AutoSaveContainer = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 113px;
  &:hover {
    display: flex;
    justify-content: center;
  }
  &:hover > *:first-child {
    pointer-events: none;
    display: none;
  }
  &:not(:hover) > *:last-child {
    display: none;
  }
`;

export const StyledLink = styled(Link)<{ disabled?: boolean }>`
  &, &.plain, &:not(.plain) {
    display: flex;
    ${({ disabled }) => disabled && 'cursor: initial;'}
  }
`;

export const StyledSpinner = styled(LoadingSpinner)`
  width: 125px;
  & * {
    color: ${ADMIN_ACCENT_PRIMARY};
  }
`;
export const StyledButton = styled(Button)`
  ${ADMIN_TEXT_LABEL_S_BOLD}
  height: 24px;
`;

export const NotificationContainer = styled.div``;
