import React from 'react';
import WelcomeScreen from 'components/ui/WelcomeScreen';
import MobileBody from 'components/mobile/MobileBody';
import useSelector from 'hooks/use-selector';
import { Transition } from 'react-transition-group';
import {
  isSidebarVisible,
  isMobilePanelEmbed,
  isVideoEmbed,
} from 'services/user-layout/selectors';
import { getInitialSidebarWidth } from 'services/device/selectors/common';
import { isLandingPageType } from 'services/app/selectors';
import { shouldRenderChannelGate } from 'services/gate/selectors/common';
import { getCustomPanels } from 'services/custom-panels/selectors';
import useSiteLayout from 'hooks/use-site-layout';
import PageFetcher from 'components/admin-bridge/Toolbar/PageFetcher';
import { StyledContent, StyledSidebar, SidebarWrapper } from './styles';

const ContentWithSidebar = () => {
  const customPanels = useSelector(getCustomPanels);
  const isSidebarVisibleResult = useSelector(isSidebarVisible);
  const mobilePanelEmbedLayout = useSelector(isMobilePanelEmbed);
  const videoEmbeded = useSelector(isVideoEmbed);
  const isLanding = useSelector(isLandingPageType);
  const initialSidebarWidth = useSelector(getInitialSidebarWidth);
  const channelGateOn = useSelector(shouldRenderChannelGate);

  const { showMobile, showSidebar } = useSiteLayout();

  return (
    <React.Fragment>
      {!mobilePanelEmbedLayout && <WelcomeScreen />}
      {!mobilePanelEmbedLayout && <StyledContent /> }
      <Transition in={isSidebarVisibleResult || mobilePanelEmbedLayout} timeout={0}>
        {(state) => (isLanding && !customPanels.length) ? null : (
          <SidebarWrapper
            state={state}
            width={initialSidebarWidth}
          >
            {showSidebar || mobilePanelEmbedLayout ? (
              <>
                {mobilePanelEmbedLayout && <PageFetcher />}
                <StyledSidebar
                  channelGateOn={channelGateOn}
                  isMobilePanelEmbedLayout={mobilePanelEmbedLayout}
                />
              </>
            ) : null}
          </SidebarWrapper>
        )}
      </Transition>
      {showMobile && !mobilePanelEmbedLayout && !videoEmbeded ? <MobileBody /> : null}
    </React.Fragment>
  );
};

export default ContentWithSidebar;
