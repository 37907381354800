import QuickEditOverlay from 'components/admin-bridge/QuickEditOverlay';
import styled, { css } from 'styled-components';

export const StyledQuickEditOverlay = styled(QuickEditOverlay)<{ isAdmin: boolean; }>`
  display: flex;
  flex-grow: 1;
  min-width: 0;
  height: 100%;
  position: relative;

  ${({ isAdmin }) => isAdmin && css`
    & > div:first-child {
      position: relative;
    }
  `};
`;
