import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { SURFACE_1, TITLE_FONT_FAMILY, TEXT_100 } from 'style/constants';
import { mobileOnly, MULTI_LINE_ELLIPSIS } from 'style/mixins';
import Tags from './Tags';
import ShareButtons from './ShareButtons';
import { getShowVideoTitleFlag } from 'services/app';
import { PlayerSpot } from 'services/video';
import { IVideo } from 'models';
import { getVideoReferralUrl, trackVideo } from 'services/insights';

const VideoMetadataContainer = styled.div`
  margin-top: 20px !important;
  width: 100%;
  align-self: center;
  padding-inline: 20px;
  ${mobileOnly`
    margin-top: 0px !important;
    padding-top: 10px;
    background: ${SURFACE_1};
  `}
`;

const VideoTitle = styled.h1.attrs({
  maxLines: 2,
})`
  margin: 0;
  font-size: 24px;
  line-height: 24px;
  color: ${TEXT_100};
  font-family: ${TITLE_FONT_FAMILY};
  ${mobileOnly`
    font-size: 18px;
  `}
  ${MULTI_LINE_ELLIPSIS}
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  ${mobileOnly`
    flex-wrap: wrap;
    padding 0 10px;
    & > * {
      width: 100%;
    }
  `};

  &:empty {
    display: none;
  }
`;

const VideoMetadata: FC<{ spot: PlayerSpot; video: IVideo }> = ({
  video,
  spot,
}) => {
  const videoTags = video.tags ?? [];
  const shouldShowVideoTitle = useSelector(getShowVideoTitleFlag);

  const referralUrl = getVideoReferralUrl(video._id);

  const onShareTracking = useCallback(
    (platform: string) => {
      const url = video?.url;

      trackVideo(
        'control',
        { id: video._id, kind: 'share', spot, title: video.title },
        {
          kind: video.player,
          platform,
          url,
        },
      );
    },
    [video, spot],
  );

  return (
    <VideoMetadataContainer>
      {videoTags.length !== 0 && <Tags listTags={videoTags} />}
      <TitleWrapper>
        {shouldShowVideoTitle && <VideoTitle>{video.title}</VideoTitle>}
        <ShareButtons
          referralUrl={referralUrl}
          onShareTracking={onShareTracking}
          playerType={video.player}
        />
      </TitleWrapper>
    </VideoMetadataContainer>
  );
};

export default VideoMetadata;
