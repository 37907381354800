import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import TranslatedText from 'components/i18n/TranslatedText';
import { bw, rgba } from 'colors';
import RealtimeDocument from 'components/core/RealtimeDocument';
import { ADMIN_ALERT_WARNING, SURFACE_2 } from 'style/constants';
import { ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';

const Container = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  background-color: ${props => rgba(SURFACE_2(props), 0.9)};
  display: flex;
  flex-flow: row nowrap;
  width: 260px;
  justify-content: space-evenly;
  align-items: center;
  height: 30px;
  color: ${props => bw(SURFACE_2(props))};
`;

const Preview = styled(Container)`
  background-color: ${ADMIN_ALERT_WARNING};
  color: white;
  & + * {
    pointer-events: none;
  }
`;

export default class AdminOverlayEngagement extends React.Component {
  static propTypes = {
    broadcastId: PropTypes.string,
    broadcastKey: PropTypes.string,
    className: PropTypes.string,
    isAdmin: PropTypes.bool.isRequired,
    showEngaged: PropTypes.bool,
    views: PropTypes.number,
  };

  static defaultProps = {
    broadcastId: '',
    broadcastKey: '',
    className: '',
    showEngaged: false,
    views: 0,
  };

  state = {};

  onValue = (doc) => {
    const { engaged } = doc?.data?.state || {};
    const { engaged: oldEngaged } = this.state;

    if (oldEngaged !== engaged) {
      this.setState({
        engaged,
      });
    }
  };

  render() {
    const {
      broadcastId,
      broadcastKey,
      className,
      isAdmin,
      showEngaged,
      views,
    } = this.props;

    const { engaged = 0 } = this.state;

    if (broadcastKey?.startsWith('preview')) {
      return <Preview className={className}><TranslatedText stringKey="PREVIEW" /></Preview>;
    }

    const totalViews = Math.max(1, views, engaged);

    return isAdmin && (
      <Container className={className}>
        <div><TranslatedText stringKey="VIEWED" /> { totalViews || 1 }</div>
        {
          showEngaged && (
            <div>
              <TranslatedText stringKey="ENGAGED" /> { `${engaged} (${Math.trunc((engaged / totalViews) * 100)}%)` }
            </div>
          )
        }
        <RealtimeDocument collection="objects" id={broadcastId} onValue={this.onValue} />
      </Container>
    );
  }
}
