import React from 'react';
import { useSelector } from 'react-redux';
import { getDefaultNavigation, getNavigationSelectedInTheDropdown } from 'services/navigationv2';
import { makeParentAndChildHashMap } from 'services/navigationv2/utils';
import hash from 'json-stable-stringify';

const useNavigationHashMap = () => {
  const selectedNavigation = useSelector(getNavigationSelectedInTheDropdown);
  const defaultNavigation = useSelector(getDefaultNavigation);

  const { parentHashMap: selectedParentHashMap, childHashMap: selectedChildHashMap } = React.useMemo(() => (makeParentAndChildHashMap(selectedNavigation)), [hash(selectedNavigation)]);
  const { parentHashMap: defaultParentHashMap, childHashMap: defaultChildHashMap } = React.useMemo(() => (makeParentAndChildHashMap(defaultNavigation)), [hash(defaultNavigation)]);

  return {
    defaultParentHashMap,
    defaultChildHashMap,
    selectedParentHashMap,
    selectedChildHashMap,
  };
};

export default useNavigationHashMap;
