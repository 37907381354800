import styled from 'styled-components';
import AspectRatio from 'components/ui/AspectRatio';
import { desktopOnly } from 'style/mixins';

export const StyledAspectRatio = styled(AspectRatio)`
  ${desktopOnly`
    margin: auto;
    border-radius: 5px;
    overflow: hidden;
  `}
`;

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
`;

export const BoundingBox = styled.div<{ height?: string; width?: string; }>`
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: flex;
  flex-direction: column;
`;
