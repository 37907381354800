import React, { ComponentType, PropsWithChildren } from 'react';

export interface RenderConditionProps {
  shouldRender?: boolean;
}

export interface RenderConditionOptions {
  defaultCondition?: boolean;
}

export default function withRenderCondition<T>(
  wrappedComponent: ComponentType<T>,
  {
    defaultCondition,
  }: RenderConditionOptions = {},
) {
  const WrappedComponent = wrappedComponent as ComponentType;

  return ({
    children,
    shouldRender = defaultCondition,
    ...props
  }: T & PropsWithChildren<RenderConditionProps>) => {
    return shouldRender ? (
      <WrappedComponent {...props}>
        {children}
      </WrappedComponent>
    ) : null;
  };
}
