import React, { HTMLAttributes, ComponentType, ComponentProps, MouseEventHandler, ReactNode } from 'react';
import useConfirmation from 'hooks/use-confirmation';
import AdminConfirmationModal from 'components/admin2/AdminConfirmationModal';
import { StyledAdminConfirmationModal } from './styles';
import type { WithExtraProps } from 'utils';
import { TranslationKey } from 'hooks/use-translation';

export interface ConfirmationProps extends HTMLAttributes<HTMLElement> {
  action?: MouseEventHandler;
  descriptionTextKey?: TranslationKey;
  messageKey?: TranslationKey;
  shouldAskConfirmation?: boolean;
}

export default function withConfirmation<T>(
  wrappedComponent: T,
  styledConfirm?: ComponentType<ComponentProps<typeof AdminConfirmationModal>>,
): WithExtraProps<T, ConfirmationProps> {
  const WrappedComponent = wrappedComponent as any;
  const StyledConfirm = styledConfirm || StyledAdminConfirmationModal;

  return (
    ({
      action,
      messageKey = 'ADMIN_UNSAVED_CHANGES_WARNING',
      descriptionTextKey,
      shouldAskConfirmation = true,
      ...props
    }) => {
      const [showModal, onAsk, handleConfirm, handleCancel] = useConfirmation(action);

      return (
        <>
          <WrappedComponent
            onClick={shouldAskConfirmation ? onAsk : action}
            {...props}
          />
          {shouldAskConfirmation && showModal && (
            <StyledConfirm
              cancelKey="ACTION_DENY"
              continueKey="ACTION_CONFIRM"
              descriptionTextKey={descriptionTextKey}
              hideIcon={true}
              onBack={handleCancel}
              onConfirm={handleConfirm}
              textKey={messageKey}
            />
          )}
        </>
      );
    }
  ) as any;
}
