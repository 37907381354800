import React from 'react';
import PropTypes from 'prop-types';
import { secondsToFormattedTime } from 'utils';
import TimeElapsed from 'components/ui/TimeElapsed';
import { withEndUserT, DynamicTranslationType } from 'hooks/use-translation';

import {
  PlaylistThumbnail,
  PlaylistTimestamp,
  PlaylistMetadata,
  VideoTitle,
  PlaylistCreated,
  PlaylistThumbnailImage,
  DefaultThumbnailImage,
  PlayButton,
  StyledVideoLink,
  PlaylistItemWrapper,
} from '../styles';

export default withEndUserT(
  class PlaylistItem extends React.PureComponent {
    static propTypes = {
      created: PropTypes.number,
      doc: PropTypes.object, // eslint-disable-line react/forbid-prop-types
      duration: PropTypes.number,
      endUserT: PropTypes.func.isRequired,
      image: PropTypes.string.isRequired,
      index: PropTypes.number.isRequired,
      isEditing: PropTypes.bool.isRequired,
      isGridMobile: PropTypes.bool.isRequired,
      onClick: PropTypes.func,
      playlistId: PropTypes.string.isRequired,
      tag: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    };

    static defaultProps = {
      created: '',
      doc: undefined,
      duration: '',
      onClick: undefined,
    };

    getDefaultThumbnailImage = () => (
      <DefaultThumbnailImage>
        <PlayButton name="play" />
      </DefaultThumbnailImage>
    )

    render() {
      const {
        image,
        title,
        created,
        tag,
        duration,
        endUserT,
        isGridMobile,
        isEditing,
        doc,
        index,
        playlistId,
        onClick,
      } = this.props;

      let videoTitle = title;
      if (!isEditing) {
        videoTitle = endUserT([
          DynamicTranslationType.videoTitle,
          { videoId: doc._id },
        ]) || title;
      }

      return (
        <PlaylistItemWrapper>
          <StyledVideoLink
            onClick={onClick}
            plain
            playlistId={playlistId}
            playlistIndex={index}
            tag={tag}
          >
            <PlaylistThumbnail
              isGridMobile={isGridMobile}
            >
              <PlaylistThumbnailImage
                fallback={this.getDefaultThumbnailImage}
                src={image}
              />
              {
                  Boolean(duration) && (
                    <PlaylistTimestamp>
                      { Number(duration) > 0 &&
                        secondsToFormattedTime(duration) }
                    </PlaylistTimestamp>
                  )
                }
            </PlaylistThumbnail>
          </StyledVideoLink>
          <PlaylistMetadata>
            <StyledVideoLink onClick={onClick} plain tag={tag}>
              <VideoTitle maxLines={2}>{ videoTitle }</VideoTitle>
            </StyledVideoLink>
            {
                created && (
                  <PlaylistCreated>
                    <TimeElapsed date={created} />
                  </PlaylistCreated>
                )
              }
          </PlaylistMetadata>
        </PlaylistItemWrapper>
      );
    }
  },
);
