import React, { useState, useCallback } from 'react';
import RealtimeDocument from 'components/core/RealtimeDocument';
import CardImage from 'components/cards/CardImage';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import Link from 'components/ui/Link';
import { PEOPLE_ID } from 'components/objects/PanelV2/constants';
import { DynamicTranslationType, useEndUserTranslation } from 'hooks/use-translation';
import {
  DefaultProfilePic,
  ProfileIcon,
  PersonBio,
  ProfileTitle,
  Header,
  Image,
  ImageContainer,
  PersonData,
  PersonName,
  PersonTitle,
  LinkContainer,
  LinkIcon,
} from './styles';

interface IRenderer {
  cardType: string;
  id: string;
  personId: string;
  title: string;
}

interface IPersonCardProps {
  collapsed: boolean;
  onDismiss: () => void;
  openPanel: (panelData: any) => void;
  renderer: IRenderer;
  trackCard: (action: string, renderer: IRenderer) => void;
}

const PersonCard: React.FC<IPersonCardProps> = ({
  collapsed,
  onDismiss,
  openPanel,
  renderer,
  trackCard,
}) => {
  const { endUserT } = useEndUserTranslation();
  const [fetchedRenderer, setFetchedRenderer] = useState<any>(null);
  const { personId } = renderer;

  const onValue = useCallback((doc: any) => {
    setFetchedRenderer(doc ? doc.renderer : null);
  }, []);

  const handleImageClick = useCallback(() => {
    if (personId) {
      openPanel({
        doc: {
          renderer: {
            panelType: PEOPLE_ID,
            people: [{
              id: personId,
            }],
          },
        },
      });
      trackCard('engage', renderer);
    }
  }, [personId, openPanel, trackCard, renderer]);

  const handleLinkClick = useCallback((linkUrl: string) => () => {
    window.open(linkUrl);
  }, []);

  if (!personId) {
    return null;
  }

  return (
    <RealtimeDocument collection="objects" id={personId} onValue={onValue}>
      {fetchedRenderer && (
        <CardImage
          isCollapsed={collapsed}
          justify="flex-start"
          maxHeight={493}
          onClose={onDismiss}
          responsive={true}
        >
          <Header>
            <ProfileIcon />
            <ProfileTitle>
              {
                endUserT([DynamicTranslationType.personCardTitle, { cardId: renderer.id }]) ||
                renderer.title ||
                endUserT([DynamicTranslationType.cardPersonDefaultProfile], ['DEFAULT_PROFILE'])
              }
            </ProfileTitle>
          </Header>
          <ImageContainer onClick={handleImageClick}>
            {fetchedRenderer.personImageUrl ? (
              <Image src={fetchedRenderer.personImageUrl} />
            ) : <DefaultProfilePic />}
            <PersonData>
              <PersonName>
                { endUserT(
                  [
                    DynamicTranslationType.personName,
                    { personId: fetchedRenderer.id },
                  ],
                  ) || fetchedRenderer.personName
                }
              </PersonName>
              <PersonTitle>
                { endUserT(
                  [
                    DynamicTranslationType.personTitle,
                    { personId: fetchedRenderer.id },
                  ],
                  ) || fetchedRenderer.personTitle
                }
              </PersonTitle>
            </PersonData>
          </ImageContainer>
          <PersonBio>
            { endUserT(
              [
                DynamicTranslationType.personBio,
                { personId: fetchedRenderer.id },
              ],
              ) || fetchedRenderer.personBio
            }
          </PersonBio>
          {fetchedRenderer.personLinks?.length > 0 && (
            <LinkContainer>
              <NonKeyedListMapper list={fetchedRenderer.personLinks}>
                {(key, item: { linkIconName: string, linkUrl: string }) => item.linkIconName && (
                  <Link key={key} href={item.linkUrl} onClick={handleLinkClick(item.linkUrl)} plain={true}>
                    <LinkIcon name={item.linkIconName} />
                  </Link>
                )}
              </NonKeyedListMapper>
            </LinkContainer>
          )}
        </CardImage>
      )}
    </RealtimeDocument>
  );
};

export default PersonCard;
