import React from 'react';
import styled from 'styled-components';
import OptionalTranslated from 'components/admin2/ui/OptionalTranslated';
import { CloseButton, EditButton } from 'components/admin2/ui/Button';
import { ADMIN_ACCENT_PRIMARY, ADMIN_SURFACE_6, ADMIN_TEXT_100 } from 'style/constants';
import { rgba } from 'colors';
import withConfirmation from 'components/core/withConfirmation';
import { ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';
import type { TranslationKey } from 'hooks/use-translation';

interface EditorItemActions {
  onEditIconName?: string;
  title?: string;
  titleKey?: TranslationKey;
  onEdit?(): void;
  onMoveDown?(): void;
  onMoveUp?(): void;
  onRemove?(): void;
}

export default function EditorItemActions({
  onEdit,
  onRemove,
  title,
  titleKey,
  onEditIconName = 'cogV2',
  onMoveDown,
  onMoveUp,
}: EditorItemActions) {
  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && onRemove) {
      onRemove();
    }
  };

  return (
    <EditorItemActionsContainer onKeyDown={onKeyDown}>
      <OptionalTranslated
        component={Title}
        stringKey={titleKey}
      >
        {title}
      </OptionalTranslated>
      {onEdit && <StyledEditButton tooltipPosition="bottom" name={onEditIconName} onClick={onEdit} />}
      {onMoveDown && <ArrowButton tooltipKey="MOVE_DOWN" tooltipPosition="bottom" name="arrowBottom" onClick={onMoveDown} />}
      {onMoveUp && <ArrowButton tooltipKey="MOVE_UP" tooltipPosition="bottom" name="arrowUp" onClick={onMoveUp} />}
      {onRemove && (
        <RemoveButton
          action={onRemove}
          descriptionTextKey="ADMIN_LABEL_UNDO_WARNING"
          messageKey="ADMIN_PROMPT_CONFIRM_BLOCK_DELETION"
          tooltipKey="REMOVE"
          tooltipPosition="left"
        />
      )}
    </EditorItemActionsContainer>
  );
}

const StyledEditButton = styled(EditButton)`
  cursor: pointer;
  border-radius: 5px;
`;

const ArrowButton = styled(StyledEditButton)`
  background-color: ${ADMIN_SURFACE_6};
  & svg {
    height: 18px;
    width: 18px;
  }
`;

const EditorItemActionsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  padding: 4px;
  gap: 4px;
  width: 100%;
  z-index: 1;
`;

const RemoveButton = withConfirmation(styled(CloseButton).attrs(props => ({
  color: ADMIN_ACCENT_PRIMARY(props),
  containerCss: `
    margin-left: auto;
  `,
}))`
  height: 24px;
  width: 24px;
  border-radius: 5px;
`);

const Title = styled.div`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  pointer-events: none;
  height: 24px;
  text-transform: capitalize;
  background: ${props => rgba(ADMIN_ACCENT_PRIMARY(props), 0.2)};
  border: 1px solid ${ADMIN_ACCENT_PRIMARY};
  border-radius: 0px 5px 5px 5px;
  padding: 2px 10px 4px;
  color: ${ADMIN_TEXT_100};
`;
