import Icon from 'components/admin2/Icon';
import { ACCENT_PRIMARY, TEXT_100, TEXT_CONTRAST_ON_ACCENT_PRIMARY } from 'style/constants';
import styled from 'styled-components';
import { css } from 'styled-components';

export const Wrapper = styled.button<{ mobile?: boolean, ref: any}>`
  ${(props) =>
    props.mobile &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      align-self: stretch;
    `}
`;

export const RelativeContainer = styled.div`
  position: relative;
`;

export const ShoppingCartIcon = styled(Icon).attrs(() => ({
  name: 'shoppingCart',
}))<{ isCartOpen?: boolean, isEditing?: boolean }>`
  cursor: ${(props) => (props.isEditing ? 'default' : 'pointer')};
  & svg {
    width: 24px;
    height: 22px;
    & path {
      fill: ${(props) => (props.isCartOpen ? ACCENT_PRIMARY : TEXT_100)};
    }
    &:hover path {
      fill: ${ACCENT_PRIMARY};
    }
  }
`;

export const CartItemQuantityContainer = styled.div`
  position: absolute;
  top: 12px;
  right: -6px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${ACCENT_PRIMARY};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${TEXT_CONTRAST_ON_ACCENT_PRIMARY};
  font-size: 8px;
  font-weight: 700;
  line-height: 1;
`;
