import React from 'react';
import {
  INavigationParent,
  NAVIGATION_PARENT_TYPE,
} from 'models/INavigation';
import { RightArrow, SubMenuItem } from './styles';
import { NAVIGATION_BAR_ITEM_ID } from 'global-ids';
import { useSelector } from 'react-redux';
import { getPageId } from 'services/app';

interface IFloatingParentItemProps {
  href: string | undefined;
  onMouseEnter: () => void;
  parent: INavigationParent;
}

const FloatingParentItem: React.FC<IFloatingParentItemProps> = ({
  parent,
  onMouseEnter,
  href,
}) => {
  const currentPageId = useSelector(getPageId);
  const isCurrentPage = parent?.id === currentPageId;

  const { name: title, children, type } = parent;
  const isFolder = type === NAVIGATION_PARENT_TYPE.folder;
  const hasChildren = children.length > 0;

  return (
    <SubMenuItem
      href={href}
      isFolder={isFolder}
      onMouseEnter={onMouseEnter}
      data-testid={NAVIGATION_BAR_ITEM_ID}
      isCurrentPage={isCurrentPage}
    >
      {title}
      {hasChildren && <RightArrow />}
    </SubMenuItem>
  );
};

export default FloatingParentItem;
