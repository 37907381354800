// tslint:disable:jsx-no-lambda
// tslint:disable:jsx-boolean-value
import React, { useEffect } from 'react';
import { getVideoReferralUrl } from 'services/insights';
import {
  TwitterShareButton as TwitterIcon,
  FacebookShareButton as FacebookIcon,
} from 'react-share';
import { PlayerTypes } from 'models';
import { ControlsProps } from 'components/Video/video-controls/VideoOverlay/interfaces';
import { FacebookShareButton, TwitterShareButton } from './styles';
import MobileButton from './MobileButton';

interface ShareButtonsProps extends ControlsProps {
  onDesktopModalClose?(): void;
  onEmbedClick?(): void;
  onOpenLinkClick?(): void;
}

export default function ShareButtons({
  isTextModalOpen,
  onDesktopModalClose,
  setForceShowControls,
  socialsData: {
    facebook,
    twitter,
  } = {},
  trackVideo,
  video,
  videoKind = PlayerTypes.file,
  videoTitle = '',
  spot,
}: ShareButtonsProps) {
  const referralUrl = getVideoReferralUrl(video._id);

  const onShareTracking = (platform: string) => {
    const url = video?.url;

    trackVideo(
      'control',
      { id: video._id, kind: 'share', spot, title: videoTitle },
      {
        kind: videoKind,
        platform,
        url,
      },
    );
  };

  useEffect(() => {
    if (!isTextModalOpen) {
      onDesktopModalClose?.();
      setForceShowControls(false);
    }
  }, [isTextModalOpen]);

  return (
    <>
      {facebook?.active && (
        <MobileButton titleKey="ADMIN_ACTION_FACEBOOK_SHARE" onClick={() => onShareTracking('facebook')}>
          <FacebookIcon
            beforeOnClick={() => onShareTracking('facebook')}
            url={referralUrl}
          >
            <FacebookShareButton admin />
          </FacebookIcon>
        </MobileButton>
      )}
      {twitter?.active && (
        <MobileButton titleKey="ADMIN_ACTION_TWITTER_SHARE" onClick={async () => onShareTracking('twitter')}>
          <TwitterIcon
            beforeOnClick={async () => onShareTracking('twitter')}
            url={referralUrl}
          >
            <TwitterShareButton admin />
          </TwitterIcon>
        </MobileButton>
      )}
    </>
  );
}
