import React, { useEffect } from 'react';
import { completeShopifyPaymentProcess } from 'services/ecommerce';
import { useDispatch, useSelector } from 'react-redux';
import { getShopifyCheckout } from 'services/ecommerce/selectors';
import { useAdminTranslation } from 'hooks/use-translation';
import {
  NeedHelpWrapper,
  ProductSummaryContainer,
  ReceiptInfo,
  Separator,
  StyledButton,
  StyledLink,
  SuccessPurchaseTitle,
  SummaryContainer,
} from './styles';
import NeedHelpButton from 'components/payment/NeedHelpButton';
import { PaymentMethod } from '@stripe/stripe-js';
import ProductSummaryItem from './ProductSummaryItem';
import PaymentSummary from './PaymentSummary';
import useCheckout from '../Checkout/use-checkout';
import { MAESTRO_NEW_SUPPORT_TICKET_URL } from 'shared/constants';
import { BodySection, BodySectionTitle } from '../sharedStyles';

interface ISuccessViewProps {
  paymentMethod: PaymentMethod;
}

const SuccessView: React.FC<ISuccessViewProps> = ({ paymentMethod }) => {
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();
  const {
    cartItems,
    shopifyTotalTax,
    total,
  } = useCheckout();
  const currentCheckout = useSelector(getShopifyCheckout);
  // @ts-ignore
  const productsData = currentCheckout?.lineItems.edges;
  const handleClick = () => {
    dispatch(completeShopifyPaymentProcess());
  };

  useEffect(() => {
    return () => {
      dispatch(completeShopifyPaymentProcess());
    };
  }, []);

  if (!currentCheckout) {
    return null;
  }

  const email = currentCheckout.email || paymentMethod?.billing_details?.email;

  return (
    <>
      <SuccessPurchaseTitle>{t('THANKS_PURCHASE')}</SuccessPurchaseTitle>
      <SummaryContainer>
        <BodySection>
          <BodySectionTitle>{t('ADMIN_LABEL_PRODUCTS')}</BodySectionTitle>
          <ProductSummaryContainer>
          {productsData && productsData.map((element, index) => {
            const productVariant = element.node.variant;
            return (
              <>
                <ProductSummaryItem
                  key={`${element.node.title}-${index}`}
                  productImages={[
                    {
                      id: `id-${element.node.title}-${index}`,
                      altText: productVariant.image?.altText || '',
                      url: productVariant.image?.url || '',
                    },
                  ]}
                  productName={element.node.title}
                  productSpecs={productVariant.title}
                  productPrice={productVariant.priceV2?.amount || 0}
                  productQuantity={String(element.node.quantity)}
                  productAvailableForSale={true}
                />
                {index !== productsData.length - 1 && <Separator />}
              </>
            );
          })}
          </ProductSummaryContainer>
        </BodySection>
        <BodySection>
          <BodySectionTitle>{t('PAYMENT_METHOD')}</BodySectionTitle>
          <PaymentSummary
            cardLast4={paymentMethod?.card?.last4 || ''}
            amountOfProducts={cartItems.length}
            totalTax={shopifyTotalTax?.amount || '0.00'}
            totalAmount={total || 0}
            subtotal={currentCheckout?.subtotalPriceV2.amount || '0.00'}
            shippingCost={currentCheckout?.shippingLine?.priceV2.amount || ''}
          />
        </BodySection>
      </SummaryContainer>
      <StyledButton onClick={handleClick}>{t('ADMIN_LABEL_DONE')}</StyledButton>
      <NeedHelpWrapper>
        <ReceiptInfo>
          {t('ADMIN_BILLING_RECEIPT_HAS_BEEN_SENT_TO_X_EMAIL', { x: email || t('ADMIN_BILLING_YOUR_EMAIL') })}
          {email && `' ${t('ADMIN_BILLING_WRONG_EMAIL')} `}
          <StyledLink href={MAESTRO_NEW_SUPPORT_TICKET_URL}>
            {t('CLICK_HERE')}
          </StyledLink>
        </ReceiptInfo>
        <NeedHelpButton />
      </NeedHelpWrapper>
    </>
  );
};

export default SuccessView;
