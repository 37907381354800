import React, { useEffect, useState } from 'react';
import IState from 'services/state';
import { useSelector } from 'react-redux';
import { Container, Button, WrapList, Label, FixedWrapper, StyledIcon } from './styles';
import { getAdminBarWidth } from 'services/admin/selectors';
import { getIsFortniteLeaderboardEnabled, getIsQuestsBlockEnabled } from 'services/feature-gate/selectors';

interface IAddBlockModalProps {
  onAddChannelNavigator(): void;
  onAddEmbedCode(): void;
  onAddEventCalendar(): void;
  onAddFAQ(): void;
  onAddFortniteSinglesLeaderboard(): void;
  onAddHeroBanner(): void;
  onAddImageAndText(): void;
  onAddImageGallery(): void;
  onAddPlaylist(): void;
  onAddQuests(): void;
  onAddRichText(): void;
  onAddShopifyBlock(): void;
  onAddTaggedVideo(): void;
  onAddTextBanner(): void;
  onAddVideoSpotlight(): void;
  onClose(): void;
}

export default function AddBlockModal({
  onAddChannelNavigator,
  onAddImageGallery,
  onAddPlaylist,
  onAddImageAndText,
  onAddTaggedVideo,
  onAddTextBanner,
  onAddVideoSpotlight,
  onAddHeroBanner,
  onAddFAQ,
  onAddEventCalendar,
  onAddRichText,
  onAddShopifyBlock,
  onAddEmbedCode,
  onAddFortniteSinglesLeaderboard,
  onAddQuests,
  onClose,
}: IAddBlockModalProps) {
  // We can't use getHasAdminModal from services/admin/selectors
  // otherwise it updates with a long more than a second delay
  // for some unknown reason.
  const shouldBlur = useSelector((state: IState) => !!state.admin?.modalCount);
  const [blur, setBlur] = useState(shouldBlur);
  const adminBarWidth = useSelector(getAdminBarWidth);
  const isFortniteLeaderboardEnabled = useSelector(getIsFortniteLeaderboardEnabled);
  const isQuestsBlockEnabled = useSelector(getIsQuestsBlockEnabled);

  useEffect(() => {
    let timeout;
    if (shouldBlur) {
      setBlur(shouldBlur);
    } else {
      timeout = setTimeout(() => {
        setBlur(shouldBlur);
      }, 380);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [shouldBlur]);

  const handleStopPropagation = React.useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  if (blur) {
    return null;
  }

  return (
    <FixedWrapper sidebarWidth={adminBarWidth} onClick={onClose}>
      <Container onClick={handleStopPropagation}>
        <Label stringKey="LABEL_ADD_BLOCK" />
        <WrapList>
          <Button data-testid="landingContentAddHeroBanner">
            <StyledIcon
              name="modalHeroBanner"
              onClick={onAddHeroBanner}
            />
            <Label stringKey="LABEL_HERO_BANNER" />
          </Button>
          <Button data-testid="landingContentAddVideoSpotlight">
            <StyledIcon
              name="modalVideoSpotlight"
              onClick={onAddVideoSpotlight}
            />
            <Label stringKey="LABEL_VIDEO_SPOTLIGHT" />
          </Button>
          <Button data-testid="landingContentAddChannels">
            <StyledIcon
              name="modalChannelNavigator"
              onClick={onAddChannelNavigator}
            />
            <Label stringKey="LABEL_CHANNEL_NAVIGATOR" />
          </Button>
          <Button data-testid="landingContentAddPlaylist">
            <StyledIcon
              name="modalPlaylist"
              onClick={onAddPlaylist}
            />
            <Label stringKey="ADMIN_LABEL_PLAYLIST" />
          </Button>
          <Button data-testid="landingContentAddTaggedVideos">
            <StyledIcon
              name="modalTaggedVideo"
              onClick={onAddTaggedVideo}
            />
            <Label stringKey="ADMIN_LABEL_ADD_TAGGED_VIDEOS" />
          </Button>
          <Button data-testid="landingContentAddImageGallery">
            <StyledIcon
              name="modalImageGallery"
              onClick={onAddImageGallery}
            />
            <Label stringKey="ADMIN_LABEL_IMAGE_GALLERY" />
          </Button>
          <Button data-testid="landingContentAddTextBanner">
            <StyledIcon
              name="modalTextBanner"
              onClick={onAddTextBanner}
            />
            <Label stringKey="ADMIN_LABEL_ADD_TEXT_BLOCK" />
          </Button>
          <Button data-testid="landingContentAddFAQ">
            <StyledIcon
              name="modalFAQ"
              onClick={onAddFAQ}
            />
            <Label stringKey="ADMIN_LABEL_ADD_FAQ" />
          </Button>
          <Button data-testid="landingContentAddImageAndText">
            <StyledIcon
              name="modalImageAndText"
              onClick={onAddImageAndText}
            />
            <Label stringKey="ADMIN_LABEL_IMAGE_AND_TEXT" />
          </Button>
          <Button data-testid="landingContentAddEventCalendar">
            <StyledIcon
              name="modalEventCalendar"
              onClick={onAddEventCalendar}
            />
            <Label stringKey="LABEL_EVENT_CALENDAR" />
          </Button>
          <Button data-testid="landingContentAddRichText">
            <StyledIcon
              name="modalRichText"
              onClick={onAddRichText}
            />
            <Label stringKey="RICH_TEXT" />
          </Button>
          <Button data-testid="landingContentAddShopify">
            <StyledIcon
              name="modalShopifyBlock"
              onClick={onAddShopifyBlock}
            />
            <Label stringKey="ADMIN_SETTINGS_SHOPIFY" />
          </Button>
          <Button data-testid="landingContentAddEmbedCode">
            <StyledIcon
              name="modalEmbedCode"
              onClick={onAddEmbedCode}
            />
            <Label stringKey="LABEL_EMBED_CODE" />
          </Button>
          {
            isFortniteLeaderboardEnabled && (
              <Button data-testid="landingContentAddFortniteLeaderboard">
                <StyledIcon
                  name="modalFortniteLeaderboard"
                  onClick={onAddFortniteSinglesLeaderboard}
                />
                <Label stringKey="ADMIN_LABEL_LEADERBOARD" />
              </Button>
          )}
          {
            isQuestsBlockEnabled && (
              <Button data-testid="landingContentAddQuests">
                <StyledIcon
                  name="modalQuests"
                  onClick={onAddQuests}
                />
                <Label stringKey="ADMIN_LABEL_QUESTS" />
              </Button>
          )}
        </WrapList>
      </Container>
    </FixedWrapper>
  );
}
