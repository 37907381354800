import React from 'react';
import PropTypes from 'prop-types';
import useRealtimeDocumentList from 'hooks/use-realtime-document-list';
import PlaylistRow from '../PlaylistRow';
import { PlaylistSpinner } from '../styles';

export default function MaestroPlaylist({
  doc,
  isGridMobile,
  onClick,
  showBorder,
  slidesWidth,
  totalSlidesToShow,
  isEditing,
  onRemove,
  onEdit,
  onMoveDown,
  onMoveUp,
}) {
  const videos = useRealtimeDocumentList({ collection: 'video', idField: 'id', items: doc.members, keyField: 'id' });
  const playListVideos = videos.loadedDocuments || [];

  return (
    playListVideos ? (
      <PlaylistRow
        arrayId={doc?.arrayId}
        isEditing={isEditing}
        isGridMobile={isGridMobile}
        name={doc.title}
        onClick={onClick}
        onEdit={onEdit}
        onMoveDown={onMoveDown}
        onMoveUp={onMoveUp}
        onRemove={onRemove}
        playlistId={doc?._id}
        showBorder={showBorder}
        slidesWidth={slidesWidth}
        totalSlidesToShow={totalSlidesToShow}
        videos={playListVideos}
      />
    ) : (
      <PlaylistSpinner isGridMobile={isGridMobile} />
    ));
}

MaestroPlaylist.propTypes = {
  doc: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    members: PropTypes.arrayOf(PropTypes.object).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  isEditing: PropTypes.bool.isRequired,
  isGridMobile: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  onEdit: PropTypes.func,
  onMoveDown: PropTypes.func,
  onMoveUp: PropTypes.func,
  onRemove: PropTypes.func.isRequired,
  showBorder: PropTypes.bool.isRequired,
  slidesWidth: PropTypes.number.isRequired,
  totalSlidesToShow: PropTypes.number.isRequired,
};

MaestroPlaylist.defaultProps = {
  onClick: undefined,
  onEdit: () => {},
  onMoveDown: undefined,
  onMoveUp: undefined,
};
