import TranslatedText from 'components/i18n/TranslatedText';
import React, { MouseEventHandler, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device/selectors';
import { MobileName, MobileWrapper } from './styles';
import type { TranslationKey } from 'hooks/use-translation';

type MobileButtonProps = PropsWithChildren<{
  onClick?: MouseEventHandler<HTMLDivElement>;
  titleKey: TranslationKey;
}>;

export default function MobileButton({
  children,
  onClick,
  titleKey,
}: MobileButtonProps) {
  const isMobile = useSelector(isMobileLayout);
  return isMobile ? (
    <MobileWrapper onClick={onClick}>
      {children}
      <TranslatedText component={MobileName} stringKey={titleKey} />
    </MobileWrapper>
  ) : <>{children}</>;
}
