import styled from 'styled-components';
import { ADMIN_HIGHLIGHT_SECONDARY, ADMIN_SURFACE_4, ADMIN_TEXT_100, ADMIN_TEXT_200 } from 'style/constants';
import { ADMIN_TEXT_LABEL_M_BOLD, ADMIN_TEXT_LABEL_M_MEDIUM, ADMIN_TEXT_TITLE_S } from 'style/design-system/textStyles';
import DragButt from 'components/admin2/ui/DragButt';
import { ADMIN_SCROLL_BAR } from 'style/mixins';

export const Container = styled.div`
  background: ${ADMIN_SURFACE_4};
  padding-right: 10px;
  width: 375px;
  height: 100%;
  border-radius: 10px;
  padding: 30px;
  position: relative;
  max-height: calc(61vh + 65px);
  overflow-x: visible;
`;

export const ScrollableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 115px);
  align-items: flex-start;
  ${ADMIN_SCROLL_BAR}
  overflow-y: auto;
`;

export const TitleAndToggle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Title = styled.span`
  ${ADMIN_TEXT_TITLE_S}

  color: ${ADMIN_TEXT_100};
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  margin-top: 30px;
  margin-bottom: 14px;
`;

export const Subtitle = styled.span`
  ${ADMIN_TEXT_LABEL_M_BOLD}

  color: ${ADMIN_TEXT_100};
`;

export const Info = styled.span`
  ${ADMIN_TEXT_LABEL_M_MEDIUM}

  color: ${ADMIN_TEXT_200};
`;

export const Body = styled.div`
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  max-height: 25vh;
`;

export const MenuItemsText = styled.span`
  ${ADMIN_TEXT_LABEL_M_MEDIUM}

  color: ${ADMIN_TEXT_200};
  margin-bottom: 5px;
`;

export const ExternalLinksInputsWrapper = styled.div`
  padding: 0px 10px 0px 8px;
  width: 100%;
`;

export const ExternalLinksDragAndDropWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 4px;

  & > div {
    padding: 0;
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
`;

export const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledDragButt = styled(DragButt)`
  & > div div:after, > div div:before {
    background: ${ADMIN_HIGHLIGHT_SECONDARY};
  }
  margin-right: 8px;
  transform: scale(0.8);
`;

export const SpinnerContainer = styled.div`
  width: 100%;
`;
