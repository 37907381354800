import React from 'react';
import PropTypes from 'prop-types';
import TranslatedText from 'components/i18n/TranslatedText';
import Dragger from 'components/admin2/LegacyDragger';
import ChannelLibraryModal from 'components/admin2/ChannelLibraryModal';
import Label from 'components/admin2/ui/Label';
import AdminButton from 'components/admin2/ui/Button';
import { withT } from 'hooks/use-translation';
import RealtimePage from 'components/core/RealtimePage';
import {
  StyledTextInput,
  Container,
  Header,
  Left,
  Right,
  BackButton,
  Button,
  TitleInputContainer,
  StyledAdminModal,
  CloseIcon,
  ImageContainer,
  AddChannelContainer,
  AddChannelDescription,
  ChannelImage,
  NoChannelsSelected,
  ChannelDescription,
  DescriptionWrapper,
  ChannelAddContainer,
} from './styles';

export default withT(
  class ChannelSelectModal extends React.Component {
    static propTypes = {
      data: PropTypes.shape({
        arrayId: PropTypes.string,
        kind: PropTypes.string,
        pages: PropTypes.array,
      }).isRequired,
      handleAdd: PropTypes.func.isRequired,
      onClose: PropTypes.func.isRequired,
      onSave: PropTypes.func.isRequired,
    };

    state = {
      isAddingChannel: false,
      // eslint-disable-next-line react/destructuring-assignment
      pages: this.props?.data?.pages || [],
      // eslint-disable-next-line react/destructuring-assignment
      titleText: this.props?.data?.titleText || '',
    };

    onAdd = () => {
      const { handleAdd } = this.props;
      const { titleText, pages } = this.state;
      handleAdd({ pages, titleText });
    };

    onSave = () => {
      const { data: editingData, onSave, handleAdd } = this.props;
      const { titleText, pages } = this.state;
      const newPagesRow = {
        ...editingData,
        pages,
        titleText,
      };
      if (!editingData) {
        handleAdd(newPagesRow);
      } else {
        onSave(newPagesRow);
      }
    };

    handelAddChannel = () => {
      this.setState({ isAddingChannel: true });
    };

    handleSelectedChannel = (selectedChannel) => {
      const { seo, data, slug, _id } = selectedChannel;
      const { pages } = this.state;
      const image = data?.channelSelectActiveImageUrl;
      const newChannel = {
        image,
        page: {
          _id,
          seo,
          slug,
        },
      };

      const newPages = [...pages, newChannel];
      this.setState({ isAddingChannel: false, pages: newPages });
    };

    onDragEnd = (reorderedPages) => {
      this.setState({
        pages: reorderedPages,
      });
    };

    render() {
      const { onClose, t } = this.props;
      const { titleText, isAddingChannel, pages } = this.state;

      return (
        <StyledAdminModal fixedHeight maxWidth="610px" onClose={onClose}>
          <Container>
            <Header>
              <Left>
                <Label
                  labelKey="ADMIN_LABEL_CONFIGURE_ROW"
                  textSize="h3"
                />
              </Left>
              <Right>
                <Button data-testid="configRowCancel" onClick={onClose}>
                  <TranslatedText stringKey="ADMIN_ACTION_CANCEL" />
                </Button>
                <Button data-testid="configRowSave" onClick={this.onSave}>
                  <TranslatedText stringKey="ADMIN_ACTION_SAVE" />
                </Button>
                <BackButton data-testid="configRowBack" onClick={onClose} />
              </Right>
            </Header>
            <TitleInputContainer>
              <StyledTextInput
                inputTestId="configRowTitle"
                labelKey="ROW_TITLE"
                onChange={value => this.setState({ titleText: value })}
                value={titleText}
              />
            </TitleInputContainer>
            <AddChannelContainer>
              <AddChannelDescription>
                <Label>
                  <TranslatedText stringKey="ADMIN_LABEL_CHANNELS" />
                </Label>
                <DescriptionWrapper>
                  <TranslatedText stringKey="ADMIN_LABEL_ADD_CHANNELS_DESCRIPTION" />
                </DescriptionWrapper>
              </AddChannelDescription>
              <AdminButton data-testid="configRowAddChannel" onClick={this.handelAddChannel}>
                {t('ADMIN_CHANNEL_SELECT_ADD_CHANNEL')}
              </AdminButton>
            </AddChannelContainer>
            {
              !pages?.length ? (
                <NoChannelsSelected>
                  <TranslatedText stringKey="ADMIN_PAGE_NO_CHANNEL_ADDED" />
                </NoChannelsSelected>
              ) : (
                <Dragger
                  direction="vertical"
                  droppableStyles={{ maxHeight: '400px', overflow: 'hidden auto' }}
                  isDragDisabled={false}
                  keyField={item => item?.page?._id}
                  list={pages}
                  onDragEnd={this.onDragEnd}
                >
                  { (item, i) => {
                    const { page: { _id, seo } } = item;
                    return (
                      <RealtimePage id={_id}>
                        {(doc) => {
                          if (!doc) {
                            return null;
                          }
                          const channelName = doc.data.name || doc.slug || seo?.title;
                          const image = doc?.data?.channelSelectActiveImageUrl;
                          return (
                            <ChannelAddContainer>
                              <ImageContainer>
                                <ChannelImage alt="" src={image} />
                              </ImageContainer>
                              <ChannelDescription>
                                {channelName }
                                <CloseIcon
                                  name="cancel-thin"
                                  onClick={() => {
                                    const newPages = [...pages];
                                    newPages.splice(i, 1);
                                    this.setState({ pages: newPages });
                                  }}
                                />
                              </ChannelDescription>
                            </ChannelAddContainer>
                          );
                        }}
                      </RealtimePage>
                    );
                  } }
                </Dragger>
              )
          }
            {isAddingChannel && (
            <ChannelLibraryModal
              disableEditing
              disableSelecting={false}
              onClose={() => this.setState({ isAddingChannel: false })}
              onSelectItem={this.handleSelectedChannel}
            />
            )}
          </Container>
        </StyledAdminModal>
      );
    }
  },
);
