import { OPTION_TYPE } from 'components/Ecommerce/models';

export const OPTION_VALUE_SLICE_LENGTH_MAP: Record<keyof typeof OPTION_TYPE, number> = {
  color: 5,
  size: 12,
  generic: 14,
};

export const formatText = (text: string, type: keyof typeof OPTION_TYPE) => {
  const trimmedText = text.trim();
  const slicedText = trimmedText.slice(0, OPTION_VALUE_SLICE_LENGTH_MAP[type]);
  return slicedText;
};
