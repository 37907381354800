import styled, { css } from 'styled-components';

const coverParent = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled.div<{ $ready?: boolean }>`
  ${coverParent}
  background: #000;
  display: ${(p) => p.$ready ? 'block' : 'none'};
`;

export const AdVideo = styled.video`
  ${coverParent}
  pointer-events: none;
`;
