import styled from 'styled-components';
import Link from 'components/ui/Link';
import Image from 'components/ui/Image';
import { HIGHLIGHT_PRIMARY, SPACING_MEDIUM } from 'style/constants';
import QuickEditOverlay from 'components/admin-bridge/QuickEditOverlay';

interface StyleProps {
  disabled?: boolean;
  hasPendingChanges?: boolean;
}

export const StyledQuickEditOverlay = styled(QuickEditOverlay)`
  padding: 0 20px;
  padding-top: 17px;
  height: 100%;
  display: flex;
  flex-grow: 1;
`;

export const SponsorLinksWrapper = styled.div<StyleProps>`
  display: ${props => props.disabled ? 'none' : 'flex'};
  flex-flow: row wrap;
  align-items: center;
  justify-content: end;
  width: 100%;
  margin-bottom: 11px;
`;

export const SponsorLinksContainer = styled.div<StyleProps>`
  border: 2px solid ${props => props.hasPendingChanges ? 'gold' : 'transparent'};
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  position: relative;
`;

export const SponsorLink = styled(Link)`
  max-width: 150px;
  &:not(:last-child) {
    margin-right: ${SPACING_MEDIUM};
  }
`;

export const SponsorImage = styled(Image)`
  max-height: 60px;
  object-fit: contain;
  max-width: 150px;
`;

export const SponsorSvgImage = styled.div`
  max-height: 60px;
  object-fit: contain;
  max-width: 150px;
  & > svg {
    width: 100%;
    & > path {
      fill: ${HIGHLIGHT_PRIMARY};
    }
  }
`;
