/* eslint react/destructuring-assignment: off */
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import isEqual from 'lodash/isEqual';

const FETCH_PROPS = [
  'body',
  'headers',
  'method',
  'refreshKey',
  'responseType',
  'url',
  'params',
];

const VALUE_PROPS = [
  'onValue',
  'transform',
];

export default class AjaxFetch extends React.Component {
  static propTypes = {
    body: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    children: PropTypes.node,
    headers: PropTypes.objectOf(PropTypes.string.isRequired),
    method: PropTypes.string,
    onError: PropTypes.func,
    onFetch: PropTypes.func,
    onValue: PropTypes.func.isRequired,
    params: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    // eslint-disable-next-line react/forbid-prop-types, react/no-unused-prop-types
    refreshKey: PropTypes.any,
    responseType: PropTypes.string,
    transform: PropTypes.func,
    url: PropTypes.string.isRequired,
  };

  static defaultProps = {
    body: undefined,
    children: null,
    headers: undefined,
    method: 'get',
    onError: () => {},
    onFetch: () => {},
    params: {},
    refreshKey: undefined,
    responseType: 'json',
    transform: x => x,
  };

  cancelSource = null;

  state = {
    rawData: null,
  };

  componentDidMount() {
    this.updateProps({});
  }

  componentDidUpdate(prevProps) {
    this.updateProps(prevProps);
  }

  componentWillUnmount() {
    if (this.cancelSource) {
      this.cancelSource.cancel();
      this.cancelSource = null;
    }
  }

  async updateProps(oldProps) {
    const {
      body,
      headers,
      method,
      onError,
      onFetch,
      onValue,
      responseType,
      transform,
      url,
      params,
    } = this.props;

    const shouldFetch = FETCH_PROPS.some((prop) => {
      if (prop === 'headers' || prop === 'params' || prop === 'body') {
        return !isEqual(oldProps[prop] || null, this.props[prop] || null);
      }
      return oldProps[prop] !== this.props[prop];
    });
    const shouldValue = shouldFetch ||
      (!this.cancelSource && VALUE_PROPS.some(prop => oldProps[prop] !== this.props[prop]));
    let { rawData } = this.state;
    if (shouldFetch) {
      if (this.cancelSource) {
        this.cancelSource.cancel();
        this.cancelSource = null;
      }
      onFetch();
      this.cancelSource = axios.CancelToken.source();
      const axiosConfig = {
        cancelToken: this.cancelSource.token,
        data: body,
        headers,
        method,
        params,
        responseType,
        url,
      };
      let response;
      try {
        response = await axios(axiosConfig);
      } catch (error) {
        if (!axios.isCancel(error)) {
          onError(error);
        }

        return;
      } finally {
        this.cancelSource = null;
      }
      rawData = response.data;
      this.setState({ rawData });
    }
    if (shouldValue) {
      onValue(transform(rawData));
    }
  }

  render() {
    return this.props.children;
  }
}
