import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PageType } from 'models';
import { useFloatingCartButtonContext } from 'contexts/FloatingCartButtonContext';
import { getCartItemsQuantity } from 'services/ecommerce/selectors';
import { getPageViewType } from 'services/app';
import { useAdminTranslation } from 'hooks/use-translation';
import useEcommerceCart from 'hooks/ecommerce/useEcommerceCart';
import { formatPrice } from 'shared/string-utils';
import { CartFooter, CartFooterLink, CartFooterText, CartHeader, CartProductsList, CartSubtotal, CartSubtotalPrice, CartSubtotalSection, CartSubtotalTitle, CartTitle, CartTotalNumberOfItems, Button, Container, Info, RightArrow, LandingGoBackWrapper, ErrorMessage, FooterContainer } from './styles';
import CartProduct from './CartProduct';
import EmptyCart from './EmptyCart';
import GoBack from '../GoBack';
import { BackIcon } from '../GoBack/styles';
import { CART_ID } from 'global-ids';


interface ICartSettings {
  currency: string;
  returnPolicy?: string;
  termsOfUse?: string;
}

export interface ICartProps {
  settings: ICartSettings;
}

const Cart: React.FC<ICartProps> = ({
  settings: { currency, returnPolicy, termsOfUse },
}) => {
  const { t } = useAdminTranslation();
  const totalNumberOfItemsInCart = useSelector(getCartItemsQuantity);
  const pageType = useSelector(getPageViewType);
  const isChannel = pageType === PageType.CHANNEL;
  const isLanding = pageType === PageType.LANDING;
  const { productsData, onCheckout, subtotal, shouldPreventCheckout, preventShopifyCheckoutError } = useEcommerceCart();
  const { handleClick } = useFloatingCartButtonContext();

  const handleCheckout = () => {
    onCheckout();
  };

  const totalNumberOfItemsInCartText = useMemo(() => {
    if (totalNumberOfItemsInCart === 0) {
      return `${t('NO_ITEMS')}`;
    }
    if (totalNumberOfItemsInCart === 1) {
      return `${totalNumberOfItemsInCart} ${t('ITEM')}`;
    }
    return `${totalNumberOfItemsInCart} ${t('ITEMS')}`;
  }, [totalNumberOfItemsInCart]);

  const renderCart = () => {
    return (
      <>
        <CartProductsList data-testid="ecommerce-cart-products-list" isLanding={isLanding}>
          {productsData.map(({ product, preSelectedOptions }, index) => {
            return (
              <CartProduct
                key={`cartProduct-${product.id}-${index}`}
                cartCurrency={currency}
                preSelectedOptions={preSelectedOptions}
                product={product}
              />
            );
          })}
        </CartProductsList>

        <CartSubtotalSection>
          <CartSubtotal>
            <CartSubtotalTitle>
              {t('SUBTOTAL')} ({totalNumberOfItemsInCart})
            </CartSubtotalTitle>
            <CartSubtotalPrice>{formatPrice(currency, subtotal)}</CartSubtotalPrice>
          </CartSubtotal>
          <Info>{t('TAXES_AND_SHIPPING_CHECKOUT_INFO')}</Info>
        </CartSubtotalSection>
        <FooterContainer>
          <Button onClick={handleCheckout} disabled={shouldPreventCheckout}>
            {t('CONTINUE_TO_CHECKOUT')}
            <RightArrow />
          </Button>
          {shouldPreventCheckout && <ErrorMessage>{preventShopifyCheckoutError}</ErrorMessage>}

          {(returnPolicy || termsOfUse) && (
            <CartFooter>
              <CartFooterText>
                {returnPolicy && <CartFooterLink href={returnPolicy} target="_blank">{t('ADMIN_LABEL_RETURN_POLICY')}</CartFooterLink>}
                {returnPolicy && termsOfUse && ' & '}
                {termsOfUse && <CartFooterLink href={termsOfUse} target="_blank">{t('TERMS_OF_USE')}</CartFooterLink>}
              </CartFooterText>
            </CartFooter>
          )}
        </FooterContainer>
      </>
    );
  };

  return (
    <Container data-testid={CART_ID} id={CART_ID}>
      {isLanding && (
        <LandingGoBackWrapper onClick={handleClick}>
          <BackIcon />
          {t('GO_BACK')}
        </LandingGoBackWrapper>
      )}
      {isChannel && <GoBack>{t('GO_BACK')}</GoBack>}
      <CartHeader>
        <CartTitle>{t('YOUR_CART')}</CartTitle>
        <CartTotalNumberOfItems>{totalNumberOfItemsInCartText}</CartTotalNumberOfItems>
      </CartHeader>
      {productsData.length > 0 ? renderCart() : <EmptyCart />}
    </Container>
  );
};

export default Cart;
