import styled from 'styled-components';
import {
  SPACING_MEDIUM,
  ADMIN_TEXT_200,
  ADMIN_SURFACE_2,
  ADMIN_SURFACE_5,
  ADMIN_SURFACE_4,
} from 'style/constants';
import { ADMIN_TEXT_BODY_S_BOLD } from 'style/design-system/textStyles';

export const SubHeader: any = styled.div`
  ${ADMIN_TEXT_BODY_S_BOLD}
  background-color: ${ADMIN_SURFACE_4};
  padding: 0 ${SPACING_MEDIUM};
  display: flex;
  align-items: center;
  min-height: 41px;
  height: 41px;
  border-top: 1px solid ${ADMIN_SURFACE_2};
  border-bottom: 1px solid ${ADMIN_SURFACE_5};
  color: ${ADMIN_TEXT_200};
`;

export const RendererWrapper = styled.div`
  height: 100%;
`;

export const SidebarHeaderWrapper = styled.div`
  overflow: unset !important;
`;
