import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { NAVIGATION_BLOCK_SITE_NAVIGATION_EXTERNAL_LINK_ID } from 'global-ids';
import { Container, IconAndName, Name, TrashIcon } from './styles';
import { dismissModal, showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';
import { removeLinkFromNavigation } from 'services/navigationv2';
import { IExternalLink } from 'models/INavigation';
import { EXTERNAL_LINK_NAME_MAX_LENGTH } from '../../ExternalLinksInputs';
import { LinkIcon } from '../../SiteNavigationExternalLinksForm/icons';

interface IExternalLinkComponentProps {
  link: IExternalLink;
  navigationId: string;
}

const ExternalLink = ({ link, navigationId }: IExternalLinkComponentProps) => {
  const dispatch = useDispatch();
  const handleDelete = useCallback(() => {
    dispatch(
      showModal({
        kind: ModalKinds.adminConfirmation,
        data: {
          onConfirmClick: () => {
            dispatch(removeLinkFromNavigation(navigationId, link.id));
            dispatch(dismissModal('adminConfirmation'));
          },
          titleKey: 'DELETE_LINK_CONFIRMATION',
        },
      }),
    );
  }, [dispatch, navigationId, link.id]);

  return (
    <Container
      id={NAVIGATION_BLOCK_SITE_NAVIGATION_EXTERNAL_LINK_ID}
      data-testid={NAVIGATION_BLOCK_SITE_NAVIGATION_EXTERNAL_LINK_ID}
    >
      <IconAndName>
        <LinkIcon />
        <Name href={link.url} maxLength={EXTERNAL_LINK_NAME_MAX_LENGTH}>{link.name}</Name>
      </IconAndName>
      <TrashIcon
        tooltipKey="DELETE_LINK"
        onClick={handleDelete}
      />
    </Container>
  );
};

export default ExternalLink;
