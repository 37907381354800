import PropTypes from 'prop-types';
import { useEffect } from 'react';

const propTypes = {
  title: PropTypes.string.isRequired,
};

const DocumentTitle = (props) => {
  const { title } = props;

  useEffect(
    () => {
      document.title = title;
    },
    [title],
  );

  return null;
};

DocumentTitle.propTypes = propTypes;
export default DocumentTitle;
