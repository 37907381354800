import { connect } from 'react-redux';
import withErrorGuard from 'components/core/withErrorGuard';
import FontLoader from 'components/ui/FontLoader';
import { getAdminFonts } from 'services/app/selectors';
import IState from 'services/state';

const mapStateToProps = (state: IState) => ({
  fonts: getAdminFonts(state),
});

export default withErrorGuard()(
  connect(mapStateToProps)(FontLoader),
);
