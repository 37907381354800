import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import TranslatedText from 'components/i18n/TranslatedText';
import Label from 'components/admin2/ui/Label';
import { ADMIN_TEXT_100, SPACING_MEDIUM, SPACING_SMALL, SPACING_X_SMALL } from 'style/constants';
import { ADMIN_BACKGROUND_CONTENT } from 'style/mixins';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_TITLE_S } from 'style/design-system/textStyles';

const CONTAINER_PADDING = SPACING_MEDIUM;
const SECTION_SPACING = SPACING_MEDIUM;

const propTypes = {
  adjacentControls: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  descriptionKey: PropTypes.string,
  infoControls: PropTypes.node,
  infoKey: PropTypes.string,
  labelKey: PropTypes.string,
  titleControls: PropTypes.node,
  titleKey: PropTypes.string,
  vertical: PropTypes.bool,
};

const defaultProps = {
  adjacentControls: false,
  className: '',
  descriptionKey: null,
  infoControls: null,
  infoKey: null,
  labelKey: null,
  titleControls: null,
  titleKey: null,
  vertical: false,
};

const Container = styled.div`
  align-items: stretch;
  display: flex;
  flex-flow: column nowrap;
  margin-top: ${SECTION_SPACING};
  padding: ${CONTAINER_PADDING};
  ${ADMIN_BACKGROUND_CONTENT};
`;

const TitleRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: ${props => props.adjacentControls ? 'normal' : 'space-between'};
  margin-bottom: ${SPACING_X_SMALL};
  & > :not(:first-child) {
    margin-left: 16px;
  }
`;

const Title = styled.span`
  text-transform: uppercase;
${ADMIN_TEXT_TITLE_S}
`;

const Inner = styled.div`
  display: flex;
  flex-flow: ${props => props.vertical ? 'column' : 'row'} nowrap;
`;

export const Info = styled.div`
  opacity: 0.5;
  color: ${ADMIN_TEXT_100}
  ${ADMIN_TEXT_BODY_S_MEDIUM};
  flex: 1 0 33%;
  ${props => props.vertical ?
    `margin-bottom: ${SPACING_SMALL}` :
    `margin-right: ${SPACING_MEDIUM}`}
`;

const Content = styled.div`
  flex: 1 1 100%;
`;

const PageEditorSection = ({
  adjacentControls,
  children,
  className,
  descriptionKey,
  infoControls,
  infoKey,
  labelKey,
  titleControls,
  titleKey,
  vertical,
}) => {
  const shouldRenderHeader = Boolean(titleControls || titleKey);
  const shouldRenderLabel = Boolean(labelKey || descriptionKey);

  return (
    <Container className={className} data-testid="channelSettingsOptionsContainer">
      {shouldRenderHeader && (
        <TitleRow adjacentControls={adjacentControls}>
          {titleKey && (
            <Title><TranslatedText stringKey={titleKey} /></Title>
          )}
          {titleControls || null}
        </TitleRow>
      )}
      <Inner vertical={vertical}>
        <Info vertical={vertical}>
          {infoKey && (
            <TranslatedText stringKey={infoKey} />
          )}
          {shouldRenderLabel && (
            <Label
              descriptionKey={descriptionKey}
              labelKey={labelKey}
            />
          )}
          {infoControls || null}
        </Info>
        <Content>
          {children}
        </Content>
      </Inner>
    </Container>
  );
};

PageEditorSection.propTypes = propTypes;
PageEditorSection.defaultProps = defaultProps;
export default PageEditorSection;
