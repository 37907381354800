import styled, { css } from 'styled-components';
import Input from 'components/admin2/TextInput';
import { SINGLE_LINE_ELLIPSIS } from 'style/mixins';
import { ADMIN_ALERT_CONFIRMATION, ADMIN_ALERT_ERROR, ADMIN_SURFACE_1, ADMIN_SURFACE_3, ADMIN_SURFACE_4, ADMIN_TEXT_100, ADMIN_TEXT_300 } from 'style/constants';
import { ADMIN_TEXT_LABEL_M_MEDIUM, ADMIN_TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';

export const InputsSection = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1px;
  background: ${ADMIN_SURFACE_4};
`;

export const LinkNameInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 0px 6px;
  border-radius: 4px 4px 0 0;
  height: 34px;
  gap: 4px;
  background: ${ADMIN_SURFACE_3};
`;

export const NameInput = styled(Input)`
  padding: 0;
  flex: 1;
  height: 34px;
  & input {
    height: 34px;
    color: ${ADMIN_TEXT_100};
    ${ADMIN_TEXT_LABEL_M_MEDIUM};
    padding: 0;
    padding-right: 5px;
    flex: 1;
  }
`;

export const LinkURLInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
  align-items: center;
  align-self: stretch;
  min-height: 34px;
  border-radius: 0 0 4px 4px;
  width: 100%;
  gap: 2px;
  background: ${ADMIN_SURFACE_1};
`;

export const LinkURLRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  & > div {
    padding: 0;
  }
`;


const COLOR_MAP = css<{
  linkUrlStatus: 'typing' | 'valid' | 'invalid';
  value: string;
}>`
  color: ${props => {
    if (props.linkUrlStatus === 'invalid') {
      return ADMIN_ALERT_ERROR(props);
    }
    if (props.linkUrlStatus === 'valid') {
      return ADMIN_ALERT_CONFIRMATION(props);
    }
    if (props.linkUrlStatus === 'typing' && props.value) {
      return ADMIN_TEXT_100(props);
    }
    return ADMIN_TEXT_300(props);
  }};
`;

export const LinkURLLabel = styled.span<{
  linkUrlStatus: 'typing' | 'valid' | 'invalid';
  value: string;
}>`
  ${ADMIN_TEXT_LABEL_S_MEDIUM};
  display: inline-block;
  ${COLOR_MAP}
`;

export const LinkURLInput = styled(Input)<{
  linkUrlStatus: 'typing' | 'valid' | 'invalid';
  value: string;
}>`
  width: 100%;
  & input {
    padding: 0;
    height: fit-content;
    width: 100%;
    ${SINGLE_LINE_ELLIPSIS}
    background: transparent;
    ${COLOR_MAP}
    ${ADMIN_TEXT_LABEL_S_MEDIUM}
    ${props => props.value ? css`
      text-decoration-line: underline;
    ` : css`
      text-decoration-line: none;
    `}

    &::placeholder {
      color: ${ADMIN_TEXT_300};
      ${ADMIN_TEXT_LABEL_S_MEDIUM}
    }
    &::-webkit-input-placeholder { /* WebKit browsers */
      color:  ${ADMIN_TEXT_300};
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
      color:  ${ADMIN_TEXT_300};
      opacity: 1; /* Fix for Firefox */
    }
  }


`;

export const ErrorSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 12px;
  width: 100%;
  gap: 5px;
`;

export const ErrorText = styled.span`
  color: ${ADMIN_TEXT_300};

  ${ADMIN_TEXT_LABEL_S_MEDIUM};
`;
