import React, { useMemo } from 'react';
import { NAVIGATION_BLOCK_SITE_NAVIGATION_ITEM_ID } from 'global-ids';
import { Container, ItemName } from './styles';
import { INavigationItem } from 'models/INavigation';
import { NAV_ITEM_NAME_MAX_LENGTH, mapNavigationItemTypeToIcon } from 'services/navigationv2/utils';
import { SiteNavigationItemTypeIcon } from 'components/admin-bridge/SiteNavigation/SiteNavigationItem/styles';

interface INavigationBlockItem {
  isParent: boolean;
  item: INavigationItem;
}

const NavigationBlockItem = ({ isParent, item }: INavigationBlockItem) => {
  const iconName = useMemo(() => {
    return mapNavigationItemTypeToIcon(item.type);
  }, [item.type]);

  return (
    <Container
      isParent={isParent}
      id={NAVIGATION_BLOCK_SITE_NAVIGATION_ITEM_ID}
      data-testid={NAVIGATION_BLOCK_SITE_NAVIGATION_ITEM_ID}
    >
      <SiteNavigationItemTypeIcon
        iconName={iconName}
        isParent={isParent}
      />
      <ItemName maxLength={NAV_ITEM_NAME_MAX_LENGTH}>{item.name}</ItemName>
    </Container>
  );
};

export default NavigationBlockItem;
