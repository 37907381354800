import React, { useRef } from 'react';
import Notification from 'components/admin-bridge/Notification';
import PageNavigation from 'components/admin-bridge/PageNavigation';
import ToolbarView, { SharedToolbarViewProps } from './ToolbarView';
import { ToolbarWrapper } from './styles';
import { TOOL_BAR } from 'injection-classes';

export default function Toolbar({
  children,
  ...props
}: SharedToolbarViewProps) {
  const toolbarRef = useRef<HTMLDivElement>(null);

  return (
    <ToolbarWrapper ref={toolbarRef} id={TOOL_BAR}>
      <PageNavigation />
      <Notification />
      <ToolbarView
        {...props}
      >
        {children}
      </ToolbarView>
    </ToolbarWrapper>
  );
}
