import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import {
  HIGHLIGHT_SECONDARY,
  HIGHLIGHT_PRIMARY,
  ACCENT_PRIMARY,
  ADMIN_TEXT_100,
  SURFACE_1,
  SURFACE_2,
} from 'style/constants';
import {
  TEXT_H6_BOLD,
  TEXT_H5_BOLD,
  TEXT_P2_BOLD,
  TEXT_P2,
  MULTI_LINE_ELLIPSIS,
  SINGLE_LINE_ELLIPSIS,
  FLEX_CENTER,
  CIRCLE,
  desktopOnly,
  HIDE_SCROLLBAR,
} from 'style/mixins';

export const ProfileIcon = styled(Icon).attrs(props => ({
  color: HIGHLIGHT_SECONDARY(props),
  name: 'adminbarUserProfiles',
}))`
  margin-right: 14px;
  & svg {
    width: 16px;
    height: 16px;
  }
`;

export const ProfileTitle = styled.div`
  ${TEXT_H6_BOLD}
  color: ${HIGHLIGHT_SECONDARY};
`;

export const Header = styled.div`
  ${FLEX_CENTER}
  margin-bottom: 12px;
  text-transform: uppercase;
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  ${({ onClick }) => onClick && 'cursor: pointer;'}
  max-height: 212px;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    max-height: 212px;
    width: 100%;
    border-radius: 5px;
    background: linear-gradient(360deg, #091D21 -17.45%, rgba(0, 0, 0, 0) 49.99%, rgba(0, 0, 0, 0) 50%);
  }
`;

export const Image = styled.img`
  width: 100%;
  border-radius: 5px;
  max-height: 212px;
  object-fit: cover;
`;

export const DefaultProfilePic = styled(Icon).attrs(props=> ({
  color: HIGHLIGHT_PRIMARY(props),
  name: 'singleUser',
}))`
  ${FLEX_CENTER}
  height: 212px;
  width: 100%;
  border-radius: 5px;
  background: ${SURFACE_2};
  & svg {
    width: 80%;
    height: 80%;
  }
`;

export const PersonData = styled.div`
  position: absolute;
  dispĺay: flex;
  flex-directioon: column;
  max-width: 90%;
  bottom: 16px;
  left: 16px;
  z-index: 1;
`;

export const PersonName = styled.div`
  ${TEXT_H5_BOLD}
  font-size: 16px;
  color: ${ACCENT_PRIMARY};
  ${SINGLE_LINE_ELLIPSIS}
`;

export const PersonTitle = styled.div`
  ${TEXT_P2_BOLD}
  color: ${ADMIN_TEXT_100};
  ${SINGLE_LINE_ELLIPSIS}
`;

export const PersonBio = styled.div.attrs({
  maxLines: 8,
})`
  ${TEXT_P2}
  color: ${HIGHLIGHT_PRIMARY};
  text-align: justify;
  margin-top: 16px;
  ${MULTI_LINE_ELLIPSIS}
`;

export const LinkContainer = styled.div`
  ${HIDE_SCROLLBAR}
  display: flex;
  margin-top: 15px;
  margin-right: -17px;
  max-width: 100%;
  overflow: auto hidden;
  & > * {
    margin-right: 17px;
  }
`;

export const LinkIcon = styled(Icon).attrs(props => ({
  color: SURFACE_1(props),
  radius: '26px',
}))`
  ${CIRCLE}
  ${FLEX_CENTER}
  background: ${HIGHLIGHT_SECONDARY};
  ${desktopOnly`
    &:hover {
      background: ${ACCENT_PRIMARY};
    }
  `}
  & svg {
    width: 14px;
    height: 14px;
  }
`;
