import React from 'react';
import KeyedListMapper from 'components/core/KeyedListMapper';
import EditableFooterSponsors from 'components/admin-bridge/EditableFooterSponsors';
import withErrorGuard from 'components/core/withErrorGuard';
import { EMPTY, EmptyType, Sponsor } from 'models/IFooter';
import { SPONSORS_ID } from 'global-ids';
import {
  SponsorLinksContainer,
  SponsorLinksWrapper,
  StyledQuickEditOverlay,
} from './styles';
import { EMPTY_ARRAY } from 'services/utils';
import SponsorDisplay from './SponsorDisplay';
import { isEditMode } from 'services/admin';
import { useSelector } from 'react-redux';
import { useFeatureGateEnabled } from 'hooks';
import { Feature } from 'hooks/use-feature-gate-enabled';

type SponsorsOrEmpty = Sponsor[] | EmptyType;

interface SponsorLinksProps {
  isAdmin: boolean;
  sponsors: SponsorsOrEmpty;
  onSponsorEngage(data: Sponsor): void;
}

function SponsorLinks({
  isAdmin,
  onSponsorEngage,
  sponsors,
}: SponsorLinksProps) {
  const [isActionModalOpen, setIsActionModalOpen] = React.useState(false);
  const isEditing = useSelector(isEditMode);
  const isSimpleModeEnabled = useFeatureGateEnabled({
    feature: Feature.SIMPLE_MODE,
    type: 'feature',
  });

  const handleCloseActionModal = () => setIsActionModalOpen(false);
  const handleOpenActionModal = () => setIsActionModalOpen(true);

  const renderEditable = () => (
    <EditableFooterSponsors
      data={sponsors}
      isActionModalOpen={isActionModalOpen}
      onCloseActionModal={handleCloseActionModal}
    />
  );

  const handleSponsorsEngage = (data: Sponsor) => () => {
    onSponsorEngage(data);
  };

  const renderDefault = () => {
    const parsedSponsor = sponsors === EMPTY ? EMPTY_ARRAY : sponsors;

    return (
      <React.Fragment>
        {Boolean(sponsors?.length || isAdmin) && (
          <SponsorLinksWrapper disabled={!(sponsors.length)}>
            <SponsorLinksContainer id={SPONSORS_ID}>
              <KeyedListMapper keyField="arrayId" list={parsedSponsor}>
                {(key: string, { image, name, link }: Sponsor) => (
                  <SponsorDisplay
                    handleSponsorsEngage={handleSponsorsEngage({ image, name, link })}
                    image={image}
                    key={`sponsor-image-${key}`}
                    link={link}
                    name={name}
                  />
                )}
              </KeyedListMapper>
            </SponsorLinksContainer>
          </SponsorLinksWrapper>
        )}
      </React.Fragment>
    );
  };

  const renderSponsorLinks = () => {
    const shouldRenderEditable = isSimpleModeEnabled ? isAdmin : isEditing;
    if (shouldRenderEditable) {
      return renderEditable();
    }

    return renderDefault();
  };

  return (
    <StyledQuickEditOverlay
      testId="editSponsorsLanding"
      titleKey="ADMIN_FOOTER_SPONSORS_TITLE"
      onEdit={handleOpenActionModal}
    >
      {renderSponsorLinks()}
    </StyledQuickEditOverlay>
  );
}

export default withErrorGuard()(SponsorLinks);
