import styled, { css } from 'styled-components';
import { desktopOnly, mobileLandscapeOnly, mobileOnly } from 'style/mixins';
import {
  ADMIN_SURFACE_3,
  ADMIN_BODY_BORDER,
  ADMIN_EDIT_TRANSITION_DURATION,
  SURFACE_2,
} from 'style/constants';
import { bw } from 'colors';
import Content from 'components/regions/Content';
import Sidebar from 'components/regions/Sidebar';
import AdminBar from 'components/admin-bridge/AdminBar';
import { MOBILE_TAB_BAR_HEIGHT } from 'components/mobile/MobileTabBar/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

export const StyledAdminBar = styled(AdminBar)`
  ${({ hidden }) => hidden && 'display: none !important;'}
`;

export const AdminBarContainer = styled.div`
  background-color: none;
  color: ${props => bw(ADMIN_SURFACE_3(props))};
  ${mobileOnly`
    background: none;
  `}
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  overflow: hidden;
  width: 100vw;
  transition: filter 0.5s;
  ${mobileOnly`
    flex-flow: column nowrap;
  `}
  ${mobileLandscapeOnly`
    height: 100%;
    position: fixed;
    width: 100%;
  `}
`;

export const StyledContent = styled(Content)`
  display: flex;
  ${desktopOnly`flex-grow: 1;`}
  ${mobileLandscapeOnly`
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
    flex-grow: 1;
  `}
`;

export const SidebarWrapper = styled.div`
  transition: width 0.5s;
  width: ${({ state, width }) => state === 'entered' ? width : 0}px;
  max-width: 25vw;
  flex-grow: 0;
  flex-shrink: 0;
  background: ${SURFACE_2};
`;

export const StyledSidebar = styled(Sidebar)`
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  ${mobileOnly`
    flex: 1;
    width: 100%;
  `}
`;

// TODO: move this padding to <body/> with createGlobalStyle after upgrade to styled-components 4
export const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1;
  overflow: hidden auto;
  max-width: 100%;
  transition: border ${ADMIN_EDIT_TRANSITION_DURATION} ease-out;
  border: 0px solid ${ADMIN_SURFACE_3};
  ${props => props.isEditModeEnabled && css`
    border-left: ${ADMIN_BODY_BORDER} solid ${ADMIN_SURFACE_3};
    border-bottom: ${ADMIN_BODY_BORDER} solid ${ADMIN_SURFACE_3};
    border-right: ${ADMIN_BODY_BORDER} solid ${ADMIN_SURFACE_3};
  `}
  ${mobileOnly`
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.embed ? 'center' : 'initial'};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    // Space for the Mobile Tab Bar
    ${props => props.mobileLayout && css`margin-bottom: ${MOBILE_TAB_BAR_HEIGHT}px;`}
  `}
`;

export const FullScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
`;
