// TODO: The logo flashes in, which reflows all the header links, and it looks terrible.

import PropTypes from 'prop-types';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import {
  SIDEBAR_WIDTH_WITH_UNITS,
  SPACING_MEDIUM,
  SURFACE_2,
} from 'style/constants';
import HeaderImage from 'components/header/HeaderImage';
import HeaderCountdown from 'components/header/HeaderCountdown';
import HeaderLinksQuickOverlay from 'components/header/HeaderLinks/HeaderLinksQuickEditOverlay';
import SidebarAuthHeader from 'components/ui/SidebarAuthHeader';
import { HEADER_ID, CONTENT_INNER_CONTAINER_ID } from 'global-ids';

const StyledAuthHeader = styled(SidebarAuthHeader)`
`;

const slideIn = keyframes`
  0% {
    max-width: 0;
  }
  100% {
    max-width: ${SIDEBAR_WIDTH_WITH_UNITS};
  }
`;

// TODO: Padding?
const AuthContainer = styled.div`
  animation: 0.5s ease-out 0.5s ${slideIn};
  animation-fill-mode: backwards;
  flex: 0 0 auto;
  max-width: ${SIDEBAR_WIDTH_WITH_UNITS};
  overflow: hidden;
  white-space: nowrap;
  padding-right: 20px;
`;

const Container = styled.div`
  z-index: ${({ isScrollOnTop }) => isScrollOnTop ? 2 : 3};
  align-items: stretch;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: ${SPACING_MEDIUM};
  ${({ isLanding, isScrollOnTop }) => isLanding ? css`
    transition: background 0.5s;
    background: ${isScrollOnTop ? 'transparent' : SURFACE_2};
    position: sticky;
    top: 0;
  ` : css`
    background: transparent;
  `};
`;

export default class Header extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    countdownTitle: PropTypes.string,
    fullscreenActive: PropTypes.bool.isRequired,
    gateLogo: PropTypes.string,
    gateLogoLink: PropTypes.string,
    gateLogoTitle: PropTypes.string,
    hasHeader: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isChannelGateEnabled: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isGateActive: PropTypes.bool.isRequired,
    isInAccessMenu: PropTypes.bool.isRequired,
    isLanding: PropTypes.bool.isRequired,
    isLandscape: PropTypes.bool.isRequired,
    isMobileLayout: PropTypes.bool.isRequired,
    logoLink: PropTypes.string,
    logoTitle: PropTypes.string,
    logoUrl: PropTypes.string,
    saveGateLogo: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    shouldShowLogin: PropTypes.bool.isRequired,
    startTime: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    countdownTitle: '',
    gateLogo: undefined,
    gateLogoLink: undefined,
    gateLogoTitle: undefined,
    logoLink: undefined,
    logoTitle: undefined,
    logoUrl: undefined,
    saveGateLogo: undefined,
    startTime: undefined,
  };

  state = { isScrollOnTop: true };

  componentDidMount() {
    const siteContent = document.getElementById(CONTENT_INNER_CONTAINER_ID);
    if (siteContent) {
      siteContent.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    const siteContent = document.getElementById(CONTENT_INNER_CONTAINER_ID);
    if (siteContent) {
      siteContent.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = ({ target }) => {
    const isScrollOnTop = !target.scrollTop;
    const { isScrollOnTop: wasScrollOnTop } = this.state;
    if (isScrollOnTop !== wasScrollOnTop) {
      this.setState({ isScrollOnTop });
    }
  };

  render() {
    const {
      className,
      countdownTitle,
      fullscreenActive,
      gateLogo,
      gateLogoLink,
      gateLogoTitle,
      hasHeader,
      isAdmin,
      isEditMode,
      isInAccessMenu,
      isMobileLayout,
      isLanding,
      isLandscape,
      shouldShowLogin,
      logoUrl,
      logoLink,
      logoTitle,
      startTime,
      saveGateLogo,
      isGateActive,
      isChannelGateEnabled,
    } = this.props;

    const { isScrollOnTop } = this.state;

    const isHeaderEnabled = isEditMode || isInAccessMenu || hasHeader;
    const showHeader = isHeaderEnabled && !fullscreenActive;
    const showCountdown = (startTime || isAdmin) && !(isMobileLayout && isLandscape);
    const headerImageData = {
      logoLink: isInAccessMenu ||
        (isGateActive && isChannelGateEnabled) ? gateLogoLink : logoLink,
      logoTitle: isInAccessMenu ||
        (isGateActive && isChannelGateEnabled) ? gateLogoTitle : logoTitle,
      logoUrl: isInAccessMenu ||
        (isGateActive && isChannelGateEnabled) ? gateLogo : logoUrl,
    };

    return (
      showHeader && (
        <Container
          className={className}
          id={HEADER_ID}
          isLanding={isLanding}
          isScrollOnTop={isScrollOnTop}
          onScroll={this.handleScroll}
        >
          <HeaderImage
            data={{ ...headerImageData }}
            data-testid="change-logo-button"
            saveGateLogo={saveGateLogo}
          />
          <HeaderLinksQuickOverlay />
          {
            showCountdown && (
              <HeaderCountdown isAdmin={isAdmin} startTime={startTime} title={countdownTitle} />
            )
          }
          {
            !isInAccessMenu && shouldShowLogin && (
              <AuthContainer>
                <StyledAuthHeader isHeader />
              </AuthContainer>
            )
          }
        </Container>
      )
    );
  }
}
