import styled, { css } from 'styled-components';
import { ADMIN_FONT_FAMILY, ADMIN_SURFACE_3, ADMIN_SURFACE_5, ADMIN_TEXT_100, ADMIN_TEXT_200 } from 'style/constants';
import { ADMIN_TEXT_LABEL_M_BOLD, ADMIN_TEXT_LABEL_M_MEDIUM, ADMIN_TEXT_LABEL_XS_MEDIUM } from 'style/design-system/textStyles';

export const Container = styled.div`
  position: fixed;

  display: flex;
  width: 314px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  background: ${ADMIN_SURFACE_3};
  padding: 16px 0;
  gap: 20px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0 20px 0 20px;
`;

export const Title = styled.span`
  color: ${ADMIN_TEXT_100};

  font-family: ${ADMIN_FONT_FAMILY};
  ${ADMIN_TEXT_LABEL_M_BOLD}
`;

export const Body = styled.div`
  width: 100%;
`;

export const ItemContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 56px;
  padding: 7px 14px;
  gap: 8px;
  cursor: pointer;

  &:hover {
    background: ${ADMIN_SURFACE_5};
  }
  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: 0.5;
  `}
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 33px;
  padding: 4px 6px;
  gap: 10px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Name = styled.span`
  color: ${ADMIN_TEXT_100};
  ${ADMIN_TEXT_LABEL_M_MEDIUM}
`;

export const Description = styled.span`
  color: ${ADMIN_TEXT_200};
  ${ADMIN_TEXT_LABEL_XS_MEDIUM}
`;
