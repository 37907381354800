import React from 'react';
import { useSelector } from 'react-redux';
import { CustomExternalOverlay } from './styles';
import { getCustomVideoOverlayUrl } from 'services/app/selectors';

export const CustomOverlay = () => {

  const customVideoOverlayUrl = useSelector(getCustomVideoOverlayUrl);

  if (customVideoOverlayUrl) {
    return (
      <CustomExternalOverlay src={customVideoOverlayUrl} />
    );
  }

  return null;

};
