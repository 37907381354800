import React, { useMemo } from 'react';
import { css } from 'styled-components';
import KeyedListMapper from 'components/core/KeyedListMapper';
import OptionalTranslated from 'components/admin2/ui/OptionalTranslated';
import OptionRowButton from '../OptionRowButton';
import withLabel, { WithLabelProps } from 'components/core/withLabel';
import { OptionRow } from './styles';
import { TranslationKey } from 'hooks/use-translation';

export interface Option<T> {
  icon?: string;
  iconCss?: ReturnType<typeof css> | string;
  label?: string;
  labelKey?: TranslationKey;
  value: T;
}

interface OptionRowProps<T> {
  onChange: (value: T) => void;
  options: Option<T>[];
  value: T;
  wrapText?: boolean;
}


function OptionRowComponent<T>({
  onChange,
  options,
  value,
  wrapText = false,
}: OptionRowProps<T> & WithLabelProps) {
  const selectedOptionIndex = useMemo(() => (
    options.findIndex(option => option.value === value)
  ), [value, options]);

  const selectOption = (index: number) => () => {
    onChange(options[index].value);
  };

  return (
    <OptionRow options={options.length}>
      <KeyedListMapper keyField="value" list={options}>
        {(key: string, option: Option<T>, i: number) => (
          <OptionalTranslated
            key={key}
            active={selectedOptionIndex === i}
            component={OptionRowButton}
            icon={option.icon}
            iconCss={option.iconCss}
            onClick={selectOption(i)}
            stringKey={option.labelKey}
            wrapText={wrapText}
          >
            {option.labelKey}
          </OptionalTranslated>
        )}
      </KeyedListMapper>
    </OptionRow>
  );
}

export default withLabel(OptionRowComponent, { padding: '0' }) as typeof OptionRowComponent;
export * from './types';
