import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeNotification } from 'services/admin-notifications/actions';
import { NotificationQuery, StyleProps } from 'services/admin-notifications/models';
import { getCurrentNotification } from 'services/admin-notifications/selectors';
import { isEditMode } from 'services/admin/selectors';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import {
  Button,
  ButtonIcon,
  NotificationContainer,
  NotificationText,
  NotificationTransition,
  NotificationWrapper,
  StyledIcon,
} from './styles';

export default function Notification() {
  const dispatch = useDispatch();
  const [transitionClass, setTransitionClass] = useState('');
  const isInEditor = useSelector(isEditMode);
  const notification = useSelector(getCurrentNotification);
  const notificationToRemove = useRef<NotificationQuery | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const showNotification = () => setTransitionClass('show');
  const hideNotification = () => setTransitionClass('');

  const handleActionClick = (index: number) => {
    return () => {
      notification?.action(() => {
        hideNotification();
        notificationToRemove.current = {
          id: notification.id,
          priority: notification.priority,
        };
      }, index);
    };
  };

  const closeNotification = () => {
    const { current: query } = notificationToRemove;
    if (query) {
      dispatch(removeNotification(query));
      notificationToRemove.current = null;
    }
  };

  useEffect(() => {
    if (notification) {
      showNotification();
    }

    return () => {
      hideNotification();
    };
  }, [notification?.id]);

  return (
    <NotificationWrapper
      blockToolbar={!!notification && notification.priority === 'high'}
      hasNotification={!!notification}
    >
      <NotificationTransition
        className={transitionClass}
        onTransitionEnd={closeNotification}
      >
        {!isInEditor && notification ? (
          <NotificationContainer
            borderColor={notification.borderColor}
            data-testid="adminNotification"
            ref={containerRef}
          >
            <StyledIcon
              color={notification.icon.color}
              name={notification.icon.name}
            />
            <NotificationText
              background={notification.message.background}
              stringKey={notification.message.textKey}
              textColor={notification.message.textColor}
            >
              {notification.message.text}
            </NotificationText>
            <NonKeyedListMapper list={notification.buttons}>
              {(key: string, button: StyleProps, index: number) =>
              button.text || button.textKey || button.icon ? (
                <Button
                  key={key}
                  background={button.background}
                  buttonCss={button.buttonCss}
                  data-testid="adminNotificationButton"
                  onClick={handleActionClick(index)}
                  stringKey={button.textKey}
                  textColor={button.textColor}
                >
                  {button.text}
                  {button.icon && (
                    <ButtonIcon
                      color={button.textColor}
                      name={button.icon}
                    />
                  )}
                </Button>
              ) : null}
            </NonKeyedListMapper>
          </NotificationContainer>
        ) : null}
      </NotificationTransition>
    </NotificationWrapper>
  );
}
