import React, { KeyboardEventHandler, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Controls from 'components/Video/video-controls/Controls';
import CastingView from 'components/Video/video-controls/CastingView';
import VideoModal from 'components/Video/video-controls/VideoModal';
// @ts-ignore
import ModalContainer from 'components/modals/ModalContainer';
import debounce from 'lodash/debounce';
import clamp from 'lodash/clamp';
import MuteWarning from 'components/ui/MuteWarning';
import AdsOverlay from 'components/ui/AdsOverlay';
import useChromecast from 'hooks/use-chromecast';
import { VIDEO_OVERLAY_ID, VIDEO_CONTROLS_ID } from 'global-ids';
import { exitFullscreen, requestFullscreen } from '../fullScreenUtils';
import ClickPreventer from './ClickPreventer';
import {
  Overlay,
  PlayButton,
  OverlayControls,
  FullscreenClicker,
  PlayIcon,
} from './styles';
import {
  defaultKeyboardControls,
  KeyboardControls,
  OverlayProps,
} from './interfaces';
import { useFullScreenDetector } from 'contexts/FullScreenDetectorContext';
import useVideoOverlayState from 'components/Video/use-video-overlay-state';
import { PlayerTypes } from 'models';
import { default as useFeatureGateEnabled, Feature } from 'hooks/use-feature-gate-enabled';
import { useVideoPlayerContext } from 'components/Video/video-player/Player/Context';

interface VideoOverlayProps extends OverlayProps {
  hideMuteWarning?: boolean;
  isShowingNativeControls: boolean;
  keyboardControls?: KeyboardControls;
  seekStepSeconds?: number;
  volumeStep?: number;
}

export default function VideoOverlay({
  children,
  className,
  hideMuteWarning = false,
  isShowingNativeControls,
  keyboardControls = defaultKeyboardControls,
  seekStepSeconds = 10,
  volumeStep = 0.1,
}: VideoOverlayProps) {
  const { playerState: { highFrequencyPlaybackState } } = useVideoPlayerContext();
  const reduxProps = useVideoOverlayState();
  const { connected, deviceName, castPlayer } = useChromecast();
  const isAdsPlayerHidden = useFeatureGateEnabled({ feature: Feature.HIDE_ADS_PLAYER, type: 'feature' });

  const castRefs = useRef({
    connected,
    currentTime: castPlayer.currentTime,
    seek: castPlayer.seek,
    setVolume: castPlayer.setVolume,
  });

  const props = connected ? {
    ...reduxProps,
    currentTime: castPlayer.currentTime,
    isPlaying: !castPlayer.isPaused,
    muted: castPlayer.isMuted,
    videoTitle: castPlayer.title,
  } : reduxProps;

  const {
    fullscreenActive,
    isLive,
    isMobileLayout,
    isPlaying,
    muted,
    onPlaybackUpdate,
    seekVideo,
    setViewMode,
    trackVideo,
    video,
    videoDuration,
    videoKind,
    videoMIMEType,
    videoTitle,
    volume,
    videoUrl,
    spot,
  } = props;

  const durationRef = useRef(videoDuration);
  const [airplayIsConnected, setAirplayIsConnected] = useState(false);
  const [hoverEnabled, setHover] = useState(false);
  const [forceShowControls, setForceShowControls] = useState(false);
  const { isDocumentInFullScreen: isFull } = useFullScreenDetector();

  const url = video.url || '';
  const id = video._id;

  const showControls = forceShowControls || (isShowingNativeControls ? false : !isPlaying || hoverEnabled);

  const disableHover = useCallback(debounce(() => {
    if (isPlaying) {
      setHover(false);
    }
  }, 3000), [isPlaying]);

  const isFullscreen = isFull && fullscreenActive;

  const enableHover = useCallback(() => {
    setHover(true);
    disableHover();
  }, [setHover, disableHover, isMobileLayout]);

  const activateHover = useCallback(() => {
    setHover(true);
    if (isMobileLayout) {
      disableHover();
    }
  }, [setHover, disableHover, isMobileLayout]);

  const deactivateHover = useCallback(() => setHover(false), [setHover]);

  const toggleHover = useCallback(() => {
    if (showControls) {
      return deactivateHover();
    }
    activateHover();
  }, [activateHover, deactivateHover, showControls]);

  const changeVolume = useCallback(
    (value: number) => {
      enableHover();
      const clamped = clamp(value, 0, 1);

      trackVideo(
        'control',
        { id, kind: 'volume', spot, title: videoTitle },
        {
          kind: videoKind,
          url,
        },
      );

      onPlaybackUpdate({
        muted: clamped === 0.0,
        volume: clamped,
      });
      castRefs.current.setVolume(clamped);
    },
    [enableHover, id, url, trackVideo, onPlaybackUpdate],
  );

  const toggleMute = useCallback(
    () => {

      if (connected) {
        castPlayer.toggleMute();
      }

      trackVideo(
        'control',
        { id, kind: 'volume', spot, title: videoTitle },
        {
          kind: videoKind,
          url,
        },
      );

      onPlaybackUpdate({
        muted: !muted,
      });
    },
    [enableHover, id, url, connected, trackVideo, onPlaybackUpdate],
  );

  const trackPlayPause = useCallback(
    (paused: boolean) => {
      trackVideo(
        'control',
        { id, kind: paused ? 'pause' : 'play', spot, title: videoTitle },
        {
          [paused ? 'atTime' : 'fromTime']:
            highFrequencyPlaybackState.current.playedSeconds || 0,
          kind: videoKind,
          url,
        },
      );
    },
    [highFrequencyPlaybackState, videoKind, spot, videoTitle, id, url],
  );

  const togglePlay = useCallback(
    (e?: MouseEvent) => {
      e?.stopPropagation();
      enableHover();

      if (connected) {
        castPlayer.togglePlay();
      }

      // if currentlyPaying, will be paused
      const willBePaused = isPlaying;

      trackPlayPause(willBePaused);

      if (!isPlaying) {
        deactivateHover();
      }

      onPlaybackUpdate({
        playing: !isPlaying,
      });
    },
    [
      enableHover,
      deactivateHover,
      onPlaybackUpdate,
      trackPlayPause,
      connected,
      isPlaying,
      // FIXME: omitting castPlayer as deps since it's not properly memoized yet
    ],
  );

  useEffect(
    () => {
      // fire play button click event when changing videos
      trackPlayPause(false);
    },
    [trackPlayPause],
  );

  useEffect(
    () => {
      if (!isFull) {
        setViewMode(null);
      }
    },
    [isFull],
  );

  const seek = useCallback(
    (rawSeconds: number) => {
      enableHover();
      const seconds = clamp(rawSeconds, 0, durationRef.current);

      if (castRefs.current.connected) {
        castRefs.current.currentTime = seconds;
        castRefs.current.seek(seconds);
      }

      trackVideo(
        'control',
        { id, kind: 'seek', spot, title: videoTitle },
        {
          fromTime: highFrequencyPlaybackState.current.playedSeconds || 0,
          kind: videoKind,
          toTime: seconds,
          url,
        },
      );
      seekVideo({ playedSeconds: seconds });
    },
    [enableHover, id, url, trackVideo, seekVideo, highFrequencyPlaybackState],
  );

  const toggleFullscreen = useCallback(
    () => {
      enableHover();

      trackVideo(
        'control',
        { id, kind: 'true_fullscreen', spot, title: videoTitle },
        { action: isFullscreen ? 'disable' : 'enable', kind: videoKind, url },
      );
      setViewMode(isFullscreen ? null : 'fullscreen');
      if (!isFullscreen) {
        requestFullscreen('fullscreen', VIDEO_OVERLAY_ID);
        return;
      }
      exitFullscreen('fullscreen');
      exitFullscreen('theater');
    },
    [enableHover, id, url, isFullscreen, trackVideo],
  );

  const handleMuteWarningClick = () => {
    toggleMute();
  };

  const handleKeyboardEvents: KeyboardEventHandler<HTMLDivElement> = ({ nativeEvent: { code } }) => {
    enableHover();
    switch(code) {
      case keyboardControls.decreaseVolume:
        return changeVolume(volume - volumeStep);
      case keyboardControls.increaseVolume:
        return changeVolume(volume + volumeStep);
      case keyboardControls.playPause:
        return togglePlay();
      case keyboardControls.mute:
        return toggleMute();
      case keyboardControls.seekBack:
        if (isLive) {
          return;
        }
        return seek(highFrequencyPlaybackState.current.playedSeconds - seekStepSeconds);
      case keyboardControls.seekForward:
        if (isLive) {
          return;
        }
        return seek(highFrequencyPlaybackState.current.playedSeconds + seekStepSeconds);
    }
  };

  useEffect(() => {
    if (connected && videoUrl) {
      const media = new chrome.cast.media.MediaInfo(videoUrl, videoMIMEType);
      const request = {
        autoplay: true,
        duration: reduxProps.videoDuration,
        isMuted: reduxProps.muted,
        media,
        currentTime: highFrequencyPlaybackState.current.playedSeconds,
        activeTrackIds: [],
        customData: {},
        tracks: null,
      };
      castPlayer.loadMedia(request);
    }
  }, [connected, videoUrl]);

  useEffect(() => {
    const playedSeconds = castRefs.current.currentTime;
    if (!connected && playedSeconds > 0) {
      seekVideo({ playedSeconds });
    }
  }, [connected, castRefs.current.currentTime]);

  // need to use refs because draggable component callbacks use javascript
  // event listeners and we can't use React state there
  useEffect(() => {
    castRefs.current.connected = connected;
  }, [connected]);

  useEffect(() => {
    if (castPlayer.currentTime) {
      castRefs.current.currentTime = castPlayer.currentTime;
    }
  }, [castPlayer.currentTime]);

  useEffect(() => {
    castRefs.current.seek = castPlayer.seek;
  }, [castPlayer.seek]);

  useEffect(() => {
    castRefs.current.setVolume = castPlayer.setVolume;
  }, [castPlayer.setVolume]);

  useEffect(() => {
    durationRef.current = videoDuration;
  }, [videoDuration]);

  const controlsProps = {
    airplayIsConnected,
    changeVolume,
    isFull,
    isFullscreen,
    seekTo: seek,
    setAirplayIsConnected,
    setForceShowControls,
    showControls,
    toggleFullscreen,
    toggleMute,
    togglePlay,
  };

  return (
    <Overlay
      className={className}
      id={VIDEO_OVERLAY_ID}
      onMouseMove={isMobileLayout ? undefined : activateHover}
      onClick={isMobileLayout && !showControls ? toggleHover : undefined}
      onMouseLeave={deactivateHover}
      onKeyDown={handleKeyboardEvents}
      showControls={showControls}
      isPlaying={isPlaying}
      isFullscreen={isFullscreen}
      tabIndex={-1}
    >
      {(muted && !hideMuteWarning) && <MuteWarning handleClick={handleMuteWarningClick} mobile={isMobileLayout} />}
      {!isAdsPlayerHidden && videoKind && [PlayerTypes.file, PlayerTypes.livestream].includes(videoKind) && (
        <AdsOverlay videoId={video._id} />
      )}
      {showControls &&
      (
      <OverlayControls showControls={showControls} className="video-controls" id={VIDEO_CONTROLS_ID}>
        <PlayButton
          onClick={isMobileLayout ? toggleHover : togglePlay}
          showControls={showControls}
          isMobile={isMobileLayout}
          isPlaying={isPlaying}
        >
          <PlayIcon name={isPlaying ? 'pause' : 'playLarge'} onClick={isMobileLayout ? togglePlay : undefined} />
          <FullscreenClicker onDoubleClick={toggleFullscreen} />
        </PlayButton>
        <Controls {...props} {...controlsProps} />
        {isMobileLayout && <ClickPreventer showControls={showControls} />}
      </OverlayControls>
      )}
      {(connected || airplayIsConnected) && <CastingView deviceName={deviceName} />}
      <VideoModal {...props} {...controlsProps} />
      {!isMobileLayout && isFullscreen && <ModalContainer />}
      {children}
    </Overlay>
  );
}
