import PropTypes from 'prop-types';
import React from 'react';

import AjaxProvider from 'components/core/AjaxProvider';
import { SOCIAL_SERVICE_BASE_URL } from 'config';

const propTypes = {
  children: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

const defaultProps = {};

export default function TweetDataProvider({ children: render, id }) {
  if (!id) {
    return null; // TODO: Error out
  }

  const url = `${SOCIAL_SERVICE_BASE_URL}/twitter/${id}`;

  return (
    <AjaxProvider url={url}>
      {(data, error, loaded) => render(data, error, loaded)}
    </AjaxProvider>
  );
}

TweetDataProvider.propTypes = propTypes;
TweetDataProvider.defaultProps = defaultProps;
