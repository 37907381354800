import React, { useCallback, useMemo, useState } from 'react';
import useFocusArea from 'hooks/use-focus-area';
import ShareButtons from 'components/Video/video-controls/ShareButtons';
import { SHARE_ICON } from 'injection-classes';
import { ControlsProps } from 'components/Video/video-controls/VideoOverlay/interfaces';
import {
  ShareButtonContainer,
  ShareIconButton,
  SocialButtonsContainer,
} from './styles';

export default function ShareButton(props: ControlsProps) {
  const {
    className,
    isMobileLayout,
    showVideoModal,
  } = props;

  const [forceHover, setForceHover] = useState(false);
  const [isHovering, setHovering] = useState(false);

  const shouldHover = useMemo(() => forceHover || isHovering || props.videoModalId === 'shareModal', [forceHover, isHovering]);

  const containerRef = useFocusArea<HTMLDivElement>({
    onExit: () => setHovering(false),
    active: shouldHover,
  });

  const enableHover = () => setHovering(true);
  const disableHover = () => setHovering(false);
  const toggleHover = () => setHovering(hovering => !hovering);

  const hover = useCallback(() => setForceHover(true), [setForceHover]);
  const stopHover = useCallback(() => setForceHover(false), [setForceHover]);

  const openShareModal = () => showVideoModal('shareModal');

  return (
    <ShareButtonContainer
      className={`${className} ${SHARE_ICON}`}
      onClick={isMobileLayout ? openShareModal : toggleHover}
      onMouseEnter={isMobileLayout ? undefined : enableHover}
      onMouseLeave={isMobileLayout ? undefined : disableHover}
      ref={containerRef}
    >
      {!isMobileLayout && (
        <SocialButtonsContainer dropBottom={isMobileLayout} hovering={shouldHover}>
          <ShareButtons {...props} onDesktopModalClose={stopHover} onEmbedClick={hover} onOpenLinkClick={hover} />
        </SocialButtonsContainer>
      )}
      <ShareIconButton hovering={shouldHover} />
    </ShareButtonContainer>
  );
}
