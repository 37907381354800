import React from 'react';
import {
  PaymentMethodContainer,
  PaymentMethodItemLabel,
  PaymentMethodItemValue,
  PaymentMethodRow,
  PaymentMethodTotalAmountRow,
  TotalAmountLabel,
  TotalAmountValue,
} from './styles';
import { useAdminTranslation } from 'hooks/use-translation';

interface IPaymentSummaryProps {
  amountOfProducts: number;
  cardLast4: string;
  shippingCost: string;
  subtotal: string;
  totalAmount: number;
  totalTax: string;
}

const PaymentSummary: React.FC<IPaymentSummaryProps> = ({
  amountOfProducts,
  cardLast4,
  subtotal,
  totalAmount,
  totalTax,
  shippingCost,
}) => {
  const { t } = useAdminTranslation();
  return (
    <PaymentMethodContainer>
      <PaymentMethodRow>
        <PaymentMethodItemLabel>
          {t('ADMIN_LABEL_CARD_ENDING_IN')}
        </PaymentMethodItemLabel>
        <PaymentMethodItemValue>{cardLast4}</PaymentMethodItemValue>
      </PaymentMethodRow>
      <PaymentMethodRow>
        <PaymentMethodItemLabel>
          {`${t('ADMIN_LABEL_PRODUCTS')} (${amountOfProducts})`}
        </PaymentMethodItemLabel>
        <PaymentMethodItemValue>${subtotal}</PaymentMethodItemValue>
      </PaymentMethodRow>
      <PaymentMethodRow>
        <PaymentMethodItemLabel>{t('SALES_TAX')}</PaymentMethodItemLabel>
        <PaymentMethodItemValue>${totalTax}</PaymentMethodItemValue>
      </PaymentMethodRow>
      {shippingCost && (
        <PaymentMethodRow>
          <PaymentMethodItemLabel>
            {t('LABEL_SHIPPING_COST')}
          </PaymentMethodItemLabel>
          <PaymentMethodItemValue>${shippingCost}</PaymentMethodItemValue>
        </PaymentMethodRow>
      )}
      <PaymentMethodTotalAmountRow>
        <TotalAmountLabel>{t('TOTAL')}</TotalAmountLabel>
        <TotalAmountValue>${totalAmount.toFixed(2)}</TotalAmountValue>
      </PaymentMethodTotalAmountRow>
    </PaymentMethodContainer>
  );
};

export default PaymentSummary;
