import styled, { css } from 'styled-components';
import Icon from 'components/admin2/Icon';
import Input from 'components/admin2/TextInput';
import { ADMIN_TEXT_LABEL_M_MEDIUM, ADMIN_TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';
import { ADMIN_HIGHLIGHT_PRIMARY, ADMIN_SURFACE_3, ADMIN_SURFACE_5, ADMIN_TEXT_100, ADMIN_TEXT_300 } from 'style/constants';
import { ELLIPSIS } from 'style/mixins';

export const RemoveSection = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

export const Container = styled.div<{ isEditing?: boolean }>`
  display: flex;
  padding: 0px 10px 0px 8px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: 50px;
  width: 100%;
  height: 34px;
  min-height: 34px;
  cursor: pointer;
  &:hover {
    background: ${({ isEditing }) => isEditing ? ADMIN_SURFACE_3 : ADMIN_SURFACE_5};
  }
  background: ${({ isEditing }) => isEditing ? ADMIN_SURFACE_3 : 'transparent'};
`;

export const IconAndName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 6px;
  gap: 6px;
  width: 100%;
`;

export const NAME = css`
  color: ${ADMIN_TEXT_100};
  ${ADMIN_TEXT_LABEL_M_MEDIUM};
`;

export const Name = styled.span<{ maxLength: number }>`
  ${NAME}
  ${ELLIPSIS}
  display: inline-block;
  max-width: ${({ maxLength }) => maxLength}ch;
`;

export const NameInput = styled(Input)`

  & input {
    width: 100%;
    height: 100%;
    padding: 0;
    padding-right: 3px;
    margin-right: 6px;
    ${NAME};
  }
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const RemoveText = styled.span`
  color: ${ADMIN_TEXT_300};

  ${ADMIN_TEXT_LABEL_S_MEDIUM};
`;

export const RemoveIcon = styled(Icon).attrs(() => ({
  name: 'removeIconOutline',
  color: ADMIN_TEXT_300,
}))<{ iconName: string }>`
  display: flex;
  width: 16px;
  height: 16px;

  & > svg {
    path {
      fill: ${ADMIN_HIGHLIGHT_PRIMARY};
    }
  }

  margin-left: 6px;
  margin-right: 11.5px;
  transform: translateY(-4px);
`;
