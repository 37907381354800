import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import {
  TwitterShareButton as TwitterIcon,
  FacebookShareButton as FacebookIcon,
} from 'react-share';
import TranslatedText from 'components/i18n/TranslatedText';
import { LinkIcon } from 'components/footer/SocialLinks/styles';
import { useSelector } from 'react-redux';
import { TEXT_300 } from 'style/constants';
import {
  getIsPlayerFacebookShareActive,
  getIsPlayerTwitterShareActive,
} from 'services/app/selectors';
import { PlayerTypes } from 'models';

const ShareWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  & > div:not(:last-child) {
    margin-right: 5px;
  }
`;

const ShareLabel = styled.span`
  font-size: 14px;
  margin-right: 10px;
  color: ${TEXT_300};
  font-size: 16px;
`;

const SocialIcon = styled(LinkIcon)`
  cursor: pointer;
`;

const ShareButtons: FC<{
  onShareTracking: (platform: string) => unknown;
  playerType: PlayerTypes | undefined;
  referralUrl: string;
}> = ({ onShareTracking, referralUrl, playerType }) => {
  const isPlayerFacebookShareActive = useSelector(
    getIsPlayerFacebookShareActive,
  );
  const isPlayerTwitterShareActive = useSelector(getIsPlayerTwitterShareActive);

  const onHandleTrackShareFacebook = useCallback(() => {
    onShareTracking('facebook');
  }, [onShareTracking]);

  const onHandleTrackShareTwitter = useCallback(() => {
    onShareTracking('twitter');
  }, [onShareTracking]);

  if (!isPlayerFacebookShareActive && !isPlayerTwitterShareActive) {
    return null;
  }

  if ([PlayerTypes.youtube, PlayerTypes.twitch].includes(playerType!)) {
    return null;
  }

  return (
    <ShareWrapper>
      <TranslatedText component={ShareLabel} stringKey="SHARE" />
      {isPlayerFacebookShareActive && (
        <FacebookIcon
          beforeOnClick={onHandleTrackShareFacebook}
          url={referralUrl}
        >
          <SocialIcon name="facebook" title="facebook" />
        </FacebookIcon>
      )}
      {isPlayerTwitterShareActive && (
        <TwitterIcon
          beforeOnClick={onHandleTrackShareTwitter}
          url={referralUrl}
        >
          <SocialIcon name="twitter" title="twitter" />
        </TwitterIcon>
      )}
    </ShareWrapper>
  );
};

export default ShareButtons;
