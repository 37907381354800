import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import RealtimeDocument from 'components/core/RealtimeDocument';
import { setSite } from 'services/app/actions';
import { SITE_ID } from 'config';
import { ISite } from 'models';

export default function RealtimeSite() {
  const dispatch = useDispatch();

  const onUpdate = useCallback((site?: ISite | null, loaded?: boolean) => {
    if (loaded && site) {
      dispatch(setSite(site));
    }
  }, []);

  return (
    <RealtimeDocument collection="sites" id={SITE_ID} onValue={onUpdate} />
  );
}
