import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import { ADMIN_TEXT_200, ADMIN_SURFACE_5, ADMIN_SURFACE_2, ADMIN_TEXT_100 } from 'style/constants';
import { ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';

interface LinkInputProps {
  onChange: (text: string) => void;
  value: string;
}

export default function({
  onChange,
  value,
}: LinkInputProps) {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <LinkInput>
      <LinkIcon />
      <Input value={value} onChange={handleChange} />
    </LinkInput>
  );
}

const LinkInput = styled.div`
  display: flex;
  height: 40px;
`;

const Input = styled.input`
  border-radius: 0px 5px 5px 0px;
  background: ${ADMIN_SURFACE_2};
  color: ${ADMIN_TEXT_100};
  display: flex;
  align-items: center;
  flex: 1;
  border: 0;
  outline: 0;
  padding: 0 15px;
  ${ADMIN_TEXT_BODY_S_MEDIUM}
`;

const LinkIcon = styled(Icon).attrs(props => ({
  color: ADMIN_TEXT_200(props),
  name: 'linkOutline',
}))`
  background: ${ADMIN_SURFACE_5};
  width: 42px;
  height: 100%;
  border-radius: 5px 0px 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: 15px;
    height: 15px;
  }
`;
