import styled, { css } from 'styled-components';
import Icon from 'components/admin2/Icon';
import { ADMIN_HIGHLIGHT_SECONDARY, ADMIN_SURFACE_5, ADMIN_TEXT_100, ADMIN_TEXT_200 } from 'style/constants';
import { ADMIN_TEXT_BODY_XS_MEDIUM, ADMIN_TEXT_LABEL_M_MEDIUM } from 'style/design-system/textStyles';
import withTooltip from 'components/core/withTooltip';
import { ELLIPSIS } from 'style/mixins';
import Link from 'components/ui/Link';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  width: 100%;
  height: 33px;
  padding: 0px 10px 0px 6px;
  border-radius: 50px;

  background: transparent;

  &:hover {
    background: ${ADMIN_SURFACE_5};
    span {
      display: block;
    }
    svg {
      cursor: pointer;
    }
  }
`;

export const IconAndName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const Name = styled(Link)<{ maxLength: number }>`
  ${ADMIN_TEXT_LABEL_M_MEDIUM};
  color: ${ADMIN_TEXT_100};

  display: inline-block;
  ${ELLIPSIS}
  max-width: ${({ maxLength }) => maxLength}ch;

  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const TrashIcon = withTooltip(
  styled(Icon).attrs(() => ({
    name: 'trash',
    color: ADMIN_HIGHLIGHT_SECONDARY,
  }))`
    display: none;
    svg {
      width: 20px;
      height: 20px;
    }
  `,
  { admin: true,
    tooltipArrowCss: 'left: 81.5%;',
    tooltipCss: css`
      width: fit-content;
      height: auto;
      white-space: normal;
      text-align: left;
      padding: 10px;
      color: ${ADMIN_TEXT_200};
      ${ADMIN_TEXT_BODY_XS_MEDIUM}
      margin-right: 50px;
    `,
  },
);
