import styled, { css } from 'styled-components';
import Icon from 'components/ui/Icon';
import withTooltip from 'components/core/withTooltip';
import {
  SURFACE_1,
  TEXT_300,
  TEXT_200,
  TEXT_100,
} from 'style/constants';
import {
  CIRCLE,
  FLEX_CENTER,
  mobileOnly,
  SINGLE_LINE_ELLIPSIS,
} from 'style/mixins';
import { ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';

interface StyleProps {
  dropBottom?: boolean;
  hovering?: boolean;
}

export const ShareIcon = css`
  ${FLEX_CENTER}
  text-align: center;
`;

export const ColoredIcon = css<StyleProps>`
  ${ShareIcon}
  & svg > path {
    fill: ${TEXT_100};
  }
`;

export const CircledIcon = css`
  & svg {
    width: 20px;
    height: 20px;
    ${mobileOnly`
      width: 33px;
      height: 33px;
    `}
  }
`;

export const StyledIcon = css<{disabled?: boolean}>`
  ${ShareIcon as any}
  ${FLEX_CENTER as any}
  ${CIRCLE}
  background: ${({ disabled }) => disabled ? TEXT_300 : TEXT_100};
  & svg {
    width: 14px;
    height: 14px;
    ${mobileOnly`
      width: 22px;
      height: 22px;
    `}
  }
  & svg path {
    fill: ${SURFACE_1};
  }
`;

export const MobileWrapper = styled.div`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  max-width: 80px;
  cursor: pointer;
  & > *:last-child {
    ${SINGLE_LINE_ELLIPSIS}
    margin-top: 17px;
    max-width: 100%;
  }
  & > *[role="button"]:first-child {
    width: 100%;
    height: 100%;
    ${FLEX_CENTER}
  }
`;

export const MobileName = styled.span`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  color: ${TEXT_200};
`;

export const LinkIcon = withTooltip(styled(Icon).attrs(props => ({
  color: TEXT_100(props),
  name: 'adminbarLink',
  radius: '20px',
}))`
  ${StyledIcon}
  ${mobileOnly`
    min-width: 33px;
    min-height: 33px;
    max-width: 33px;
    max-height: 33px;
  ` as typeof StyledIcon}
`, { admin: true, tooltipKey: 'LINK', tooltipPosition: 'left' });

export const EmbedIcon = withTooltip(styled(Icon).attrs(props => ({
  color: TEXT_100(props),
  name: 'adminbarDeveloper',
  radius: '20px',
}))`
  ${StyledIcon}
  ${mobileOnly`
    min-width: 33px;
    min-height: 33px;
    max-width: 33px;
    max-height: 33px;
  ` as typeof StyledIcon}
`, { admin: true, tooltipKey: 'ADMIN_LABEL_EMBED_CODE', tooltipPosition: 'left' });

export const FacebookShareButton = withTooltip(styled(Icon).attrs({
  name: 'fbCircleColored',
})`${CircledIcon}`, { tooltipKey: 'ADMIN_ACTION_FACEBOOK_SHARE', tooltipPosition: 'left' });

export const TwitterShareButton = withTooltip(styled(Icon).attrs({
  name: 'twitterCircleColored',
})`${CircledIcon}`,
{ admin: true, tooltipKey: 'ADMIN_ACTION_TWITTER_SHARE', tooltipPosition: 'left' });
