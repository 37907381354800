import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import RealtimeDocument from 'components/core/RealtimeDocument';
import { getPageId } from 'services/app/selectors';
import { showConcurrents } from 'services/app/selectors';

export default function ConcurrentsLoader({ onLoad }: { onLoad: (concurrents: number) => void }) {
  const channelId = useSelector(getPageId);
  const concurrentsEnabled = useSelector(showConcurrents);

  const onReceiveConcurrents = useCallback((newConcurrents?: number | null) => {
    if (newConcurrents != null) {
      onLoad(newConcurrents);
    }
  }, [onLoad]);

  return (channelId && concurrentsEnabled) ? (
    <RealtimeDocument<number | null>
      collection="concurrents:v2:sessions"
      id={`channels:${channelId}`}
      onValue={onReceiveConcurrents}
    />
  ) : null;
}
