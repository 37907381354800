import React from 'react';
import { ThreeDotsButton, ThreeDotsContainer, ThreeDotsIcon } from 'components/ui/ThreeDotsMenu/styles';
import { INavigationItem } from 'models/INavigation';
import { getRectPositionCheckingBounds } from 'dom-utils';
import { useSelector } from 'react-redux';
import { getDraftHomeId } from 'services/admin';
import useFloatMenu from 'hooks/use-float-menu';
import CardsMenu from '../CardsMenuWrapper';

type MenuOptionsProps = {
  isParent: boolean;
  item: INavigationItem;
};

const MenuOptions = ({ item, isParent }: MenuOptionsProps) => {
  const homeId = useSelector(getDraftHomeId);

  const setMenuPosition = React.useCallback((menu: HTMLDivElement, menuContainer: HTMLDivElement) => {
    const menuContainerRect = menuContainer.getBoundingClientRect();
    const safePosition = getRectPositionCheckingBounds({
      elem: menu,
      position: {
        x: menuContainerRect.right,
        y: menuContainerRect.top,
      },

    });

    menu.style.top = `${safePosition.y - 5}px`;
    menu.style.left = `${safePosition.x + 22.5}px`;
  }, []);
  const { isOpen: isFloatMenuOpen, containerRef, positionMenu, toggleMenu } = useFloatMenu(setMenuPosition);
  const isHome = homeId === item.id;

  return (
    <ThreeDotsContainer style={{ zIndex: 2 }} ref={containerRef}>
      <ThreeDotsButton onClick={toggleMenu}>
        <ThreeDotsIcon isAdmin={true} />
      </ThreeDotsButton>
      {isFloatMenuOpen && (
        <CardsMenu
          item={item}
          isHome={isHome}
          isParent={isParent}
          type={item.type}
          ref={positionMenu}
          closeItself={toggleMenu}
        />
      )}
    </ThreeDotsContainer>
  );
};

export default MenuOptions;
