import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import hash from 'json-stable-stringify';
import { useScheduleContent } from 'hooks';
import FormattedTimestamp from 'components/i18n/FormattedTimestamp';
import { useAdminTranslation } from 'hooks/use-translation';
import ScheduleModal from './PageEditorScheduleModal';
import Section from '../PageEditorSection';
import VideoItem from '../PageEditorVideoItem';
import ScheduleItemWithProvider from './ScheduleItemWithProvider';
import { getDays } from './helpers';
import {
  DateHeader,
  DateRange,
  WideButton,
  LeftIcon,
  Pagination,
  RightIcon,
  SectionContents,
  Weekday,
} from './styles';

export { HalfButton, WideButton } from './styles';

const PLAYLIST = 'playlist';
const VIDEO = 'video';
const DAY_MS = 24 * 60 * 60 * 1000;
const WEEK_MS = 7 * DAY_MS;
const literal = value => PropTypes.oneOf([value]);
const legacyMongoReference = PropTypes.shape({ _id: PropTypes.string.isRequired });
const scheduleItemFields = {
  duration: PropTypes.number.isRequired,
  repeat: PropTypes.oneOf([
    'daily',
    'weekly',
    'none',
  ]),
  startTime: PropTypes.string.isRequired,
};

const propTypes = {
  onChange: PropTypes.func.isRequired,
  pageId: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        ...scheduleItemFields,
        kind: literal(VIDEO).isRequired,
        video: legacyMongoReference.isRequired,
      }),
      PropTypes.shape({
        ...scheduleItemFields,
        kind: literal(PLAYLIST).isRequired,
        playlist: legacyMongoReference.isRequired,
      }),
    ]),
  ).isRequired,
};

export default function PageEditorScheduleSection({ onChange, pageId, value }) {
  const { t } = useAdminTranslation();
  const [adding, setAdding] = useState(null);
  const [daysOfEvents, setDays] = useState([]);
  const [editModalItemIndex, setEditModalItemIndex] = useState(null);
  const [editModalItemType, setEditModalItemType] = useState(null);
  const [dateRange, setDateRange] = useState([Date.now(), Date.now() + WEEK_MS]);
  const [earliestTimestamp, latestTimestamp] = dateRange;
  const contentData = useScheduleContent(pageId, earliestTimestamp, latestTimestamp);

  const editingType = useMemo(() => editModalItemType || adding, [editModalItemType, adding]);
  const editingItem = useMemo(() => (
    adding && typeof editModalItemIndex === 'number' ? value[editModalItemIndex] : null
  ), [adding, hash(value), editModalItemIndex]);

  // build list of events on edit/add/paginate
  useEffect(
    () => {
      const days = getDays({
        contentData,
        end: latestTimestamp,
        schedule: value,
        start: earliestTimestamp,
      });
      setDays(days);
    },
    [hash(value), hash(contentData), hash(dateRange)],
  );

  const navigateToRange = (startTimestamp) => {
    if (startTimestamp > latestTimestamp) {
      let newEnd = latestTimestamp;
      while (newEnd < startTimestamp) {
        newEnd += WEEK_MS;
      }
      setDateRange([newEnd - WEEK_MS, newEnd]);
    }
    if (startTimestamp < earliestTimestamp) {
      let newStart = earliestTimestamp;
      while (newStart > startTimestamp) {
        newStart -= WEEK_MS;
      }
      setDateRange([newStart, newStart + WEEK_MS]);
    }
  };

  const closeEditModal = () => {
    setAdding(null);
    setEditModalItemType(null);
    setEditModalItemIndex(null);
  };

  const pageForward = () => setDateRange([latestTimestamp, latestTimestamp + WEEK_MS]);
  const pageBack = () => setDateRange([earliestTimestamp - WEEK_MS, earliestTimestamp]);
  const paginationControls = (
    <Pagination>
      <LeftIcon onClick={pageBack} />
      <DateRange>
        <FormattedTimestamp noLocale show="md" timestamp={earliestTimestamp} />
        &nbsp;-&nbsp;
        <FormattedTimestamp noLocale show="md" timestamp={latestTimestamp} />
      </DateRange>
      <RightIcon onClick={pageForward} />
    </Pagination>
  );

  const onAddScheduleItem = useCallback(
    (item) => {
      const newValue = [item, ...value];
      navigateToRange(new Date(item.startTime).getTime());
      onChange(newValue);
      closeEditModal();
    },
    [hash(value)],
  );

  const onEditScheduleItem = useCallback(
    (item) => {
      const newValue = [...value];
      newValue[editModalItemIndex] = item;
      navigateToRange(new Date(item.startTime).getTime());
      onChange(newValue);
      closeEditModal();
    },
    [hash(value), editModalItemIndex],
  );

  const setSchedulingItem = useCallback(
    (type) => () => {
      setEditModalItemType(type);
      setAdding(type);
    }, [setEditModalItemType, setAdding],
  );

  const contents = daysOfEvents.map(({ dayTimestamp, events }) => (
    <React.Fragment key={dayTimestamp}>
      <DateHeader>
        <FormattedTimestamp noLocale show="mdy" timestamp={dayTimestamp} />
        <Weekday>
          <FormattedTimestamp noLocale show="weekday" timestamp={dayTimestamp} />
        </Weekday>
      </DateHeader>
      {
        events.map((event) => {
          const { scheduleItemId, type } = event;
          const itemProps = {
            ...event,
            draggable: false,
            kind: type, // VideoItem kind is schedule event type
            onDelete: () => {
              const updatedSchedule = value.filter(s => s.arrayId !== scheduleItemId);
              onChange(updatedSchedule);
            },
            onEdit: () => {
              const scheduleIndex = value.findIndex(s => s.arrayId === scheduleItemId);
              setEditModalItemIndex(scheduleIndex);
              setEditModalItemType(event.type);
            },
            renderThumbnail: true,
          };
          // thumbnails are not on playlist model yet
          if (!event.title || event.type === PLAYLIST) {
            return (<ScheduleItemWithProvider {...itemProps} />);
          }
          return (<VideoItem {...itemProps} />);
        })
      }
    </React.Fragment>
  ));

  return (
    <Section
      infoKey="ADMIN_INFO_PAGE_SCHEDULE"
      stretch
      titleControls={paginationControls}
      titleKey="ADMIN_LABEL_PAGE_SCHEDULE"
      vertical
    >
      <SectionContents>
        {contents}
        <WideButton data-testid="scheduleVideoButton" onClick={setSchedulingItem(VIDEO)}>
          {t('ADMIN_ACTION_SCHEDULE_VIDEO')}
        </WideButton>
      </SectionContents>
      {
        editingType && (
          <ScheduleModal
            initialItem={editingItem}
            itemType={editingType}
            onAdd={!adding ? null : onAddScheduleItem}
            onClose={closeEditModal}
            onEdit={(typeof editModalItemIndex !== 'number') ? null : onEditScheduleItem}
          />
        )
      }
    </Section>
  );
}

PageEditorScheduleSection.propTypes = propTypes;
