import { isUserAdmin } from 'services/auth/selectors';
import { connect } from 'react-redux';

import AdminOverlayEngagement from './view';

const mapStateToProps = state => ({
  isAdmin: isUserAdmin(state),
});

export default connect(mapStateToProps)(AdminOverlayEngagement);
