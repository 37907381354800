import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFloatMenu from 'hooks/use-float-menu';
import { StyledQuickEditOverlay } from './styles';
import { isUserAdmin } from 'services/auth';
import { getPageNavigationEnabled } from 'services/app/selectors';
import NavigationBar from '../NavigationBar';
import NavigationBarBlockSettings from 'components/header/NavigationBar/BlockSettings';
import { setNavigationSelectedInTheDropdown } from 'services/navigationv2';

const HeaderLinksQuickEditOverlay = () => {
  const setMenuPosition = useCallback((menu: HTMLDivElement) => {
    menu.style.top = `46px`;
    menu.style.position = 'absolute';
  }, []);
  const { isOpen: isFloatMenuOpen, containerRef, positionMenu, toggleMenu } = useFloatMenu(setMenuPosition);
  const dispatch = useDispatch();
  const isAdmin = useSelector(isUserAdmin);
  const isNavigationEnabledOnPage = useSelector(getPageNavigationEnabled);

  const handleOpenActionModal = () => {
    dispatch(setNavigationSelectedInTheDropdown(''));
    toggleMenu();
  };

  return (
    <StyledQuickEditOverlay
      ref={containerRef}
      isAdmin={isAdmin}
      onEdit={handleOpenActionModal}
      testId="editNavigationLanding"
      titleKey="NAVIGATION"
    >
      {isNavigationEnabledOnPage && <NavigationBar />}
      {isFloatMenuOpen && (
        <div ref={positionMenu}>
          <NavigationBarBlockSettings />
        </div>
      )}
    </StyledQuickEditOverlay>
  );
};

export default HeaderLinksQuickEditOverlay;
