import React from 'react';
import { useSelector } from 'react-redux';
import { isProfilePanelOpen } from 'services/custom-panels/selectors';
import { getCartItemsQuantity } from 'services/ecommerce/selectors';
import FloatCartWrapper from 'components/Ecommerce/LandingPageFloatCartWrapper';
import {
  CartItemQuantityContainer,
  ShoppingCartIcon,
  RelativeContainer,
  Wrapper,
} from './styles';
import { getPageViewType } from 'services/app';
import { PageType } from 'models';
import { useFloatingCartButtonContext } from 'contexts/FloatingCartButtonContext';
import { isEditMode } from 'services/admin';

interface IFloatingCartButtonProps {
  mobile?: boolean;
}

const FloatingCartButton: React.FC<IFloatingCartButtonProps> = ({ mobile }) => {
  const cartItemsCount = useSelector(getCartItemsQuantity);
  const isProfileCustomPanelOpen = useSelector(isProfilePanelOpen);
  const pageType = useSelector(getPageViewType);
  const isLanding = pageType === PageType.LANDING;
  const isEditing = useSelector(isEditMode);

  const { containerRef, handleClick, isOpen, positionMenu } =
    useFloatingCartButtonContext();

  if (isProfileCustomPanelOpen) {
    return null;
  }

  return (
    <Wrapper
      ref={containerRef}
      data-testid="user-cart-action"
      mobile={mobile}
    >
      <RelativeContainer>
        <ShoppingCartIcon isEditing={isEditing} onClick={!isEditing ? handleClick : undefined}/>
        {cartItemsCount > 0 && (
          <CartItemQuantityContainer>
            {cartItemsCount}
          </CartItemQuantityContainer>
        )}
        {isOpen && isLanding && <FloatCartWrapper ref={positionMenu} />}
      </RelativeContainer>
    </Wrapper>
  );
};

export default FloatingCartButton;
