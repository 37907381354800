import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import FeatureGateModal from 'components/admin-bridge/FeatureGateModal';
import { useSelector } from 'hooks';
import {
  isFeatureEnabled,
  isOverlayEnabled,
  isPanelEnabled,
  Feature,
  Panel,
  Overlay,
} from 'services/feature-gate';

export { Feature, Panel } from 'services/feature-gate';

const allGateables = [
  ...Object.values(Feature),
  ...Object.values(Panel),
  ...Object.values(Overlay),
];

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.func.isRequired,
  ]).isRequired,
  feature: PropTypes.oneOf(allGateables).isRequired,
  onClose: PropTypes.func,
  type: PropTypes.oneOf(['feature', 'panel', 'overlay']),
};

const defaultProps = {
  type: 'feature',
};

const selectors = {
  feature: isFeatureEnabled,
  overlay: isOverlayEnabled,
  panel: isPanelEnabled,
};

export default function FeatureGate({ children, feature, onClose, type }) {
  const enabled = useSelector(state => (
    selectors[type](state, feature)
  ));
  const [modalOpen, setModalOpen] = useState(false);
  const showFeatureModal = () => setModalOpen(true);
  const hideFeatureModal = () => setModalOpen(false);

  // Don't show the modal for invalid states
  useEffect(
    () => {
      const canShowModal = !enabled && typeof children === 'function';
      if (modalOpen && !canShowModal) {
        setModalOpen(false);
      }
    },
    [enabled, children, modalOpen],
  );

  const handleClose = () => {
    onClose?.();
    setModalOpen(false);
  };

  let body = null;
  if (typeof children === 'function') {
    const render = children;
    body = render(enabled, showFeatureModal, hideFeatureModal);
  } else {
    body = enabled ? children : null;
  }

  return (
    <>
      {body}
      {modalOpen && (
        <FeatureGateModal feature={feature} onClose={handleClose} />
      )}
    </>
  );
}

FeatureGate.propTypes = propTypes;
FeatureGate.defaultProps = defaultProps;
