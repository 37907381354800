import React from 'react';
import { TranslationKey } from 'hooks/use-translation';
import EditorWrapper from 'components/admin-bridge/EditorWrapper';
import Content from './Content';
import { CTAEditorModalContext } from './Context';
import BehaviorAction from '../../ui/CTAButton/BehaviorAction';
import { CtaBehaviorData, CTAButtonProps, CTASize } from './utils';
import { CTAVariant } from 'components/ui/CTAButton';

type ICTAEditorProps = {
  blockTitleKey: TranslationKey;
  children: (text: string) => React.ReactNode;
  cta: CTAButtonProps;
  modalTitleKey: TranslationKey;
  onChangeBehavior: (behavior: CtaBehaviorData) => void;
  onChangeDesktopHeight: (value: CTASize) => void;
  onChangeFontSize?: (value: number) => void;
  onChangeMobileHeight: (value: CTASize) => void;
  onChangeText: (text: string) => void;
  onChangeVariant: (value: CTAVariant) => void;
  onModalClose?: () => void;
};

const CTAEditorModal: React.FC<ICTAEditorProps> = ({
  blockTitleKey,
  modalTitleKey,
  onChangeFontSize,
  onChangeVariant,
  onChangeText,
  children,
  onModalClose,
  onChangeBehavior,
  onChangeDesktopHeight,
  onChangeMobileHeight,
  cta,
  ...rest
}) => {
  return (
    <CTAEditorModalContext.Provider
      value={{
        cta,
        onChangeText,
        onChangeFontSize,
        onChangeVariant,
        onChangeBehavior,
        onChangeDesktopHeight,
        onChangeMobileHeight,
      }}
    >
      <EditorWrapper
        blockTitleKey={blockTitleKey}
        modalTitleKey={modalTitleKey}
        onModalClose={onModalClose}
        modalContent={<Content />}
        {...rest}
      >
        <BehaviorAction behavior={cta.behavior}>
          {children(cta.text)}
        </BehaviorAction>
      </EditorWrapper>
    </CTAEditorModalContext.Provider>
  );
};

export default CTAEditorModal;
