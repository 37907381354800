import styled from 'styled-components';
import Checkbox from 'components/admin2/ui/Checkbox';
import Button from 'components/admin2/ui/Button';
import { ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_BODY_XS_MEDIUM, ADMIN_TEXT_LABEL_XS_MEDIUM } from 'style/design-system/textStyles';

export const ThumbnailWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ThumbnailImageWrapper = styled.div`
  padding: 10px 0px;
  margin-left: 14px;
  position: relative;
  width: 132px;
  height: 93px;
  display: flex;
  align-items: center;
`;

export const ThumbnailImage = styled.img`
  position: absolute;
  width: 132px;
  height: 70%;
  border-radius: 5px;
`;

export const MediaLibraryTableWrapper = styled.div`
  & .rt-tbody {
    padding: 0px;
  }
  & .rt-tbody .rt-tr, .rt-tbody .rt-td {
    padding: 0px
  }
  & .rt-tbody .rt-tr {
    height: 93px;
    padding-right: 10px;
  }
  & .rt-tbody > div {
    margin: 0px 16px !important;
  }
`;

export const NameCellWrapper = styled.div`
  width: 100%;
  padding-inline: 12px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`;

export const NameCellLabel = styled.p`
  ${ADMIN_TEXT_BODY_S_BOLD}
  margin: 0px;
  margin-bottom: 7px;
  white-space: normal;
`;

export const NameCellDetails = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  & > p {
    ${ADMIN_TEXT_BODY_XS_MEDIUM}
  }

  & p {
    margin: 0px;
  }

  .progressbar-wrapper {
    flex: 1;
    margin: 0 12px;
  }
`;

export const VideoLibraryListWrapper = styled.div``;

export const CellCheckbox = styled(Checkbox).attrs({
  padding: '0px',
})`
  padding: 0px;
  width: 15px;
  z-index: 1;
  & label {
    width: 15px !important;
    height: 15px !important;
  }
`;

export const HeaderCheckbox = styled(Checkbox).attrs({
  padding: '0px',
})`
  margin-top: 2px;
  padding: 0px;
  width: 15px;
  z-index: 1;
  & label {
    width: 15px !important;
    height: 15px !important;
  }
`;

export const ActionButton = styled(Button)`
  padding: 8px;
  height: 24px;
  margin-right: 5px;
  ${ADMIN_TEXT_LABEL_XS_MEDIUM}
`;
