import styled, { css } from 'styled-components';
import IframeMemoized from 'components/core/IframeMemoized';
import { ADMIN_SURFACE_3 } from 'style/constants';

const fill = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  ${fill}
  background: ${ADMIN_SURFACE_3};
`;

export const Iframe = styled(IframeMemoized)<{ loading?: boolean }>`
  ${fill}
  opacity: ${({ loading }) => loading ? 0 : 1};
  transition: opacity .5s;
  pointer-events: ${({ loading }) => loading ? 'none' : ''};
`;
