import { MOBILE_TAB_BAR_HEIGHT } from 'components/mobile/MobileTabBar/styles';
import { SURFACE_3 } from 'style/constants';
import { desktopOnly, mobileOnly } from 'style/mixins';
import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  padding: 20px 20px 40px 20px;
  border-radius: 10px;
  background: ${SURFACE_3};
  max-height: 80vh;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;

  ${desktopOnly`
    width: 390px;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.55);
  `}

  ${mobileOnly`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    border-radius: 0;
    overflow-y: auto;
    max-height: none;
    margin-bottom: ${MOBILE_TAB_BAR_HEIGHT}px;
  `}
`;
