import styled from 'styled-components';
import { rgba } from 'colors';
import FormattedTimestamp from 'components/i18n/FormattedTimestamp';
import AdminIcon from 'components/admin2/AdminIcon';
import Icon from 'components/ui/Icon';

import { PASSIVE_OPACITY, SPACING_MEDIUM, SPACING_SMALL, ADMIN_TEXT_COLOR_TITLE_DISABLED, ADMIN_SURFACE_3, ADMIN_TEXT_200, ADMIN_TEXT_100 } from 'style/constants';
import {
  ACTION_NORMAL,
  ADMIN_BACKGROUND_TITLE,
} from 'style/mixins';
import { ADMIN_TEXT_BODY_L_REGULAR, ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_BODY_XS_BOLD, ADMIN_TEXT_BODY_XS_REGULAR } from 'style/design-system/textStyles';
import ActionIcon from '../ActionIcon';

const TIMESTAMP_WIDTH = '85px';
const TIMESTAMP_HEIGHT = '75px;';
const TABLE_THUMBNAIL_WIDTH = '80px';
const TABLE_THUMBNAIL_HEIGHT = '45px';

export const VideoItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  ${props => props.padLeft && `padding-left: ${SPACING_SMALL};`}
  position: relative;
  ${ADMIN_BACKGROUND_TITLE};
  margin-bottom: 2px;
  max-height: 61px;
  min-height: 61px;
  height: 61px;
  border-radius: 4px;
`;

export const ItemStart = styled.div`
  height: 100%;
  width: 100%;
  max-height: ${TIMESTAMP_HEIGHT};
  max-width: ${TIMESTAMP_WIDTH};
  min-width: ${TIMESTAMP_WIDTH};
  min-height: ${TIMESTAMP_HEIGHT};
  height: ${TIMESTAMP_HEIGHT};;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${props => rgba(ADMIN_SURFACE_3(props), 0.5)};
`;

export const ItemName = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
`;

export const ItemDetails = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: ${props => props.hasStart ? `calc(100% - ${TIMESTAMP_WIDTH})` : '100%'};
  padding: 16px;
  & > :not(:first-child) {
    margin-left: ${SPACING_MEDIUM}
  }
`;

export const StyledIcon = styled(Icon)`
  ${ADMIN_TEXT_BODY_L_REGULAR}
  opacity: 0.3;
`;

export const Thumbnail = styled.div`
  height: ${TABLE_THUMBNAIL_HEIGHT};
  background: url(${props => props.image}) no-repeat center;
  background-size: cover;
  flex: none;
  position: relative;
  text-align: center;
  width: ${TABLE_THUMBNAIL_WIDTH};
  z-index: 2;
`;

export const ThumbnailPlaceholder = styled.div`
  width: ${TABLE_THUMBNAIL_WIDTH};
  height: ${TABLE_THUMBNAIL_HEIGHT};
  border: 2px solid ${ADMIN_TEXT_COLOR_TITLE_DISABLED};
  background-size: cover;
  flex: none;
  position: relative;
  opacity: 0.5;
  ${ADMIN_TEXT_BODY_XS_REGULAR}
  color: ${ADMIN_TEXT_100};
  align-items: center;
  display: flex;
  opacity: 1;
  z-index: 2;
  &>*{
    margin: 0 auto;
  }
`;

export const Stack = styled.div`
  width: ${TABLE_THUMBNAIL_WIDTH};
  height: ${TABLE_THUMBNAIL_HEIGHT};
  border-right: 2px solid ${ADMIN_TEXT_COLOR_TITLE_DISABLED};
  border-bottom: 2px solid ${ADMIN_TEXT_COLOR_TITLE_DISABLED};
  z-index: 1;
  position: absolute;
  left: 4px;
  top: 4px;
  opacity: 1;
  width: ${TABLE_THUMBNAIL_WIDTH};
  height: ${TABLE_THUMBNAIL_HEIGHT};
`;

export const Stack2 = styled(Stack)`
  left: 8px;
  top: 8px;
  z-index: 0;
`;

export const PlaylistStack = styled.div`
  position: relative;
  top: -3px;
  left: -5px
`;

export const PencilIcon = styled(AdminIcon).attrs({
  name: 'pencil',
})`
  ${ACTION_NORMAL};
  cursor: pointer;
`;

export const DeleteIcon = styled(ActionIcon).attrs({
  name: 'cancel',
})`
  ${ACTION_NORMAL};
  cursor: pointer;
`;

export const HalfWidth = styled.span`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  flex-basis: 50%;
  min-width: 35%;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Column = styled.span`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  display: flex;
  flex-direction: row;
  flex: 1 1 20%;
`;

export const ActionColumn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1 1 15%;
  max-width: 80px;
`;

export const TypeColumn = styled.span`
  flex: 1 1 15%;
  ${ADMIN_TEXT_BODY_S_MEDIUM}
`;

export const StyledTimestamp = styled(FormattedTimestamp)`
  text-transform: uppercase;
  ${ADMIN_TEXT_BODY_XS_BOLD}
  margin-bottom: 3px;
`;

export const Duration = styled.div`
  text-transform: uppercase;
  ${ADMIN_TEXT_BODY_XS_BOLD}
`;

export const PlaylistLength = styled.span`
  text-transform: uppercase;
  ${ADMIN_TEXT_BODY_XS_BOLD}
  white-space: nowrap;
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0 !important;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
`;

export const Repeat = styled.div`
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_TEXT_200};
  opacity: ${PASSIVE_OPACITY};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
