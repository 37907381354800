import React from 'react';
import { Badge } from './styles';
import { useAdminTranslation } from 'hooks/use-translation';

interface ISoldOutBadgeProps {
    isRenderingOnPanel?: boolean;
    source: 'CartProduct' | 'ProductDetail' | 'ProductCard';
}

const SoldOutBadge: React.FC<ISoldOutBadgeProps> = ({ source, isRenderingOnPanel }) => {
    const { t } = useAdminTranslation();

    return (
        <Badge source={source} isRenderingOnPanel={isRenderingOnPanel}>
            {t('SOLD_OUT')}
        </Badge>
    );
};

export default SoldOutBadge;
