import Button from 'components/admin2/ui/Button';
import withConfirmation from 'components/core/withConfirmation';
import Icon from 'components/ui/Icon';
import { ADMIN_HIGHLIGHT_PRIMARY, ADMIN_SURFACE_4, ADMIN_SURFACE_5, ADMIN_SURFACE_6, ADMIN_TEXT_100 } from 'style/constants';
import { ADMIN_TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';
import { CIRCLE } from 'style/mixins';
import styled, { css } from 'styled-components';

export const RefreshIcon = styled(Icon).attrs({
  name: 'refresh',
})`
  height: inherit;
  display: flex;
  align-items: center;
  margin-right: 10px;
  & svg {
    width: 14px;
    height: 14px;
  }
`;

export const StyledButton = styled(Button).attrs(props => ({
  background: ADMIN_SURFACE_5(props),
  hoverBackground: ADMIN_SURFACE_6(props),
  textColor: ADMIN_TEXT_100(props),
  hoverTextColor: ADMIN_TEXT_100(props),
}))`
  ${ADMIN_TEXT_LABEL_S_MEDIUM};
  color: ${ADMIN_TEXT_100};
`;

export const RefreshStreamKeyButton = withConfirmation(StyledButton);

export const Wrapper = styled.div<{ isLive: boolean; isResetStreamKeyEnabled: boolean; }>`
  position: relative;
  ${({ isResetStreamKeyEnabled, isLive }) => isResetStreamKeyEnabled && !isLive && css`
    & :first-child {
      & button {
        padding-right: 30px;
      }
    }
  `}
`;

export const Menu = styled.div`
  position: fixed;
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid ${ADMIN_SURFACE_5};
  background: ${ADMIN_SURFACE_4};
  gap: 6px;
`;

export const Dots = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  cursor: pointer;
  padding: 5px;
  width: fit-content;
  position: absolute;
  right: 10px;
  top: 0;
`;

export const Dot = styled.span.attrs({
  radius: '3px',
})`
  ${CIRCLE}
  background: ${ADMIN_HIGHLIGHT_PRIMARY};
`;
