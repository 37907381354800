import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { parseInnerHTML } from 'utils';

const propTypes = {
  ctaRenderer: PropTypes.shape({
    html: PropTypes.string,
  }).isRequired,
};

const HtmlCardCta = (props) => {
  const { ctaRenderer } = props;
  const rawHtml = ctaRenderer?.html || '';
  const parsed = useMemo(
    () => {
      try {
        return parseInnerHTML(rawHtml);
      } catch (error) {
        console.error('HTML parsing error', error); // eslint-disable-line no-console
        return null;
      }
    },
    [rawHtml],
  );
  return parsed;
};

HtmlCardCta.propTypes = propTypes;
export default HtmlCardCta;
