import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import VideoDataProvider from 'components/admin2/VideoDataProvider';
import VideoLibraryModal from 'components/admin2/videoPanels/VideoLibraryModal';
import PlaylistLibraryModal from 'components/admin2/videoPanels/PlaylistLibraryModal';
import videoLibraryModalTypes from 'components/modals/videoLibraryModalTypes';
import TranslatedText from 'components/i18n/TranslatedText';
import PlaylistEditorModal from 'components/admin2/videoPanels/PlaylistEditorModal';
import UploadZone from 'components/admin2/UploadZone';
import { snakeFromCamel } from 'shared/string-utils';
import { ADMIN_SURFACE_1, ADMIN_SURFACE_3, SPACING_SMALL, CHAT_PADDING_SMALL_WITH_UNITS } from 'style/constants';
import { ACTION_NORMAL } from 'style/mixins';
import hash from 'json-stable-stringify';
import { useAdminTranslation } from 'hooks/use-translation';
import { VideoMetadataModalV2 } from 'components/modals/VideoMetadataModal';

import { ADMIN_TEXT_BODY_XS_BOLD } from 'style/design-system/textStyles';
import { HalfButton } from './PageEditorScheduleSection';
import Section, { Info } from './PageEditorSection';
import VideoItem from './PageEditorVideoItem';

const ModeSelectWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 180px;
  position: relative;
  top: -20px;
  max-height: 50px;
`;

const Contents = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
`;

const SectionInfoControls = styled.div`
  display: flex;
  flex-flow: row;
  margin-bottom: ${SPACING_SMALL};
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

const Spacer = styled.div`
  width: 2px;
`;

export const SectionColumnTitle = styled.div`
  text-transform: uppercase;
  ${ADMIN_TEXT_BODY_XS_BOLD}
  margin-bottom: ${CHAT_PADDING_SMALL_WITH_UNITS};
`;

const VIDEO = 'video';
const PLAYLIST = 'playlist';
const TV = 'tv';
const IMAGE = 'image';

const literal = value => PropTypes.oneOf([value]);
const mongoIdType = PropTypes.shape({ _id: PropTypes.string.isRequired });
const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    // tv mode + playlist
    PropTypes.shape({
      kind: literal(PLAYLIST),
      mode: literal(TV),
      playlist: mongoIdType,
    }),
    // tv mode + video
    PropTypes.shape({
      kind: literal(VIDEO),
      mode: literal(TV),
      video: mongoIdType,
    }),
    // image
    PropTypes.shape({
      image: PropTypes.string,
      mode: literal(IMAGE),
    }),
  ]),
};

const defaultProps = {
  value: {
    image: '',
    mode: 'image',
  },
};

const ModeSelect = styled.div`
  ${ACTION_NORMAL};
  border: 2px solid ${ADMIN_SURFACE_1};
  flex: 0 0 20%;
  text-align: center;
  padding: ${SPACING_SMALL};
  background-color: ${props => props.selected ? ADMIN_SURFACE_1 : ADMIN_SURFACE_3};
`;

export default function PageEditorOfflineSection({ onChange, value }) {
  const { t } = useAdminTranslation();
  const [isAddingVideo, setAddingVideo] = useState(false);
  const [isAddingPlaylist, setAddingPlaylist] = useState(false);
  const [videoTarget, setVideoTarget] = useState(null);
  const [playlistTarget, setPlaylistTarget] = useState(null);

  const onChangeTVContent = useCallback(
    (kind, content) => {
      const newValue = {
        ...content,
        kind,
        mode: TV,
      };
      onChange(newValue);
      setAddingVideo(false);
      setAddingPlaylist(false);
    },
    [hash(value)],
  );

  const onChangeImageContent = useCallback(
    (image) => {
      const newValue = {
        image,
        kind: 'image',
        mode: IMAGE,
      };
      onChange(newValue);
    },
    [hash(value)],
  );

  const onOfflineImageDelete = useCallback(() => {
    onChangeImageContent(null);
  }, [onChangeImageContent]);

  const { mode } = value;
  let contents = null;

  if (mode === TV) {
    const { kind } = value;
    const itemKey = (kind && snakeFromCamel(kind)) || '';
    const item = value[itemKey] || null;
    const id = item?._id;

    const buttonJsx = (
      <>
        <ButtonContainer>
          <HalfButton
            data-testid={(id && kind === PLAYLIST) ? 'changePlaylistButton' : 'addPlaylistButton'}
            onClick={() => setAddingPlaylist(true)}
          >
            {(id && kind === PLAYLIST) ? t('ADMIN_CHANNEL_CONTENT_CHANGE_PLAYLIST') : t('LABEL_ADD_PLAYLIST')}
          </HalfButton>
          <Spacer />
          <HalfButton
            data-testid={(id && kind === VIDEO) ? 'changeVideoButton' : 'addVideoButton'}
            onClick={() => setAddingVideo(true)}
          >
            {(id && kind === VIDEO) ? t('ADMIN_CHANNEL_CONTENT_CHANGE_VIDEO') : t('ADMIN_LABEL_ADD_VIDEO')}
          </HalfButton>
        </ButtonContainer>
        {
          isAddingVideo && (
            <VideoLibraryModal
              onClose={() => setAddingVideo(false)}
              onSelectItem={({ _id }) => onChangeTVContent(VIDEO, { video: { _id } })}
              type={videoLibraryModalTypes.OFFLINE_CONTENT}
            />
          )
        }
        {
          isAddingPlaylist && (
            <PlaylistLibraryModal
              onClose={() => setAddingPlaylist(false)}
              onSelectItem={({ _id }) => onChangeTVContent(PLAYLIST, { playlist: { _id } })}
            />
          )
        }
      </>
    );

    if (id) {
      contents = (
        <VideoDataProvider id={id} type={kind}>
          {(data, error) => {
            let itemProps = {
              onDelete: () => {
                onChange({
                  ...value,
                  playlist: null,
                  video: null,
                });
              },
              type: kind,
            };

            if (error || !data) {
              itemProps = {
                ...itemProps,
                title: error?.message || 'Not found',
              };
            } else {
              const { durationSeconds, title, members, thumbnail } = data;
              itemProps = {
                ...itemProps,
                duration: durationSeconds,
                members,
                onEdit: () => {
                  if (kind === VIDEO) {
                    setVideoTarget(data);
                  }
                  if (kind === PLAYLIST) {
                    setPlaylistTarget(id);
                  }
                },
                renderThumbnail: true,
                thumbnail,
                title,
                type: kind,
              };
            }
            return (
              <>
                <VideoItem {...itemProps} />
                {buttonJsx}
              </>
            );
          }}
        </VideoDataProvider>
      );
    } else {
      contents = buttonJsx;
    }
  } else if (mode === IMAGE) {
    const buttonJsx = (
      <>
        <TranslatedText component={SectionColumnTitle} stringKey="ADMIN_LOGO_IMAGE_LABEL" />
        <Info><TranslatedText stringKey="ADMIN_OFFLINE_IMAGE_UPLOAD_DESCRIPTION" /></Info>
        <UploadZone
          dimensionRequirements={{
            exactDimensions: true,
            pixelHeight: 1080,
            pixelWidth: 1920,
          }}
          imagePreview={value.image || null}
          onClearImage={onOfflineImageDelete}
          onFileSubmit={image => {
            onChangeImageContent(image);
          }}
        />
      </>
    );
    contents = buttonJsx;
  }

  return (
    <Section titleKey="ADMIN_LABEL_PAGE_OFFLINE" vertical>
      <SectionInfoControls>
        <Info><TranslatedText stringKey="ADMIN_INFO_PAGE_OFFLINE" /></Info>
        <ModeSelectWrapper>
          <ModeSelect
            data-testid="tvModeButton"
            onClick={() => onChangeTVContent(VIDEO, undefined)}
            selected={mode === TV}
          >
            {t('ADMIN_LABEL_VIDEO').toUpperCase()}
          </ModeSelect>
          <ModeSelect
            onClick={() => onChangeImageContent(null)}
            selected={mode === IMAGE}
          >
            {t('ADMIN_LABEL_IMAGE').toUpperCase()}
          </ModeSelect>
        </ModeSelectWrapper>
      </SectionInfoControls>
      <Contents>
        {contents}
      </Contents>
      {
        videoTarget && (
          <VideoMetadataModalV2
            data={{
              videos: [videoTarget],
            }}
            onDismiss={() => setVideoTarget(null)}
          />
        )
      }
      {
        playlistTarget && (
          <PlaylistEditorModal
            id={playlistTarget}
            onClose={() => setPlaylistTarget(null)}
            skipRefresh
          />
        )
      }
    </Section>
  );
}

PageEditorOfflineSection.propTypes = propTypes;
PageEditorOfflineSection.defaultProps = defaultProps;
