import styled from 'styled-components';
import Link from 'components/ui/Link';
import { BODY_FONT } from 'style/mixins';
import { ACCENT_PRIMARY, TEXT_300 } from 'style/constants';
import Image from 'components/ui/Image';
import QuickEditOverlay from 'components/admin-bridge/QuickEditOverlay';

interface StyleProps {
  disabled?: boolean;
}

export const StyledQuickEditOverlay = styled(QuickEditOverlay)`
  padding: 8px 20px;
  flex: 1;
  height: 100%;
  display: flex;
  min-height: 60px;
`;

export const FooterLinksWrapper = styled.div<StyleProps>`
  display: ${props => props.disabled ? 'none' : 'flex'};
  flex-flow: row wrap;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  /* max-width: calc(100% + 16px); */
  position: relative;
  margin-left: -16px;
  width: 100%;
  height: 100%;
  justify-content: start;
  gap: 10px;
`;

export const FooterLink = styled(Link)<{ isImageLink?: boolean }>`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  ${BODY_FONT};
  && {
    color: ${TEXT_300};
  }
  &:hover {
    color: ${ACCENT_PRIMARY};
  }
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  display: block;
`;

export const FooterImageLink = styled(Link)<{ isImageLink?: boolean }>`
  color: ${ACCENT_PRIMARY};
  display: flex;
  align-items: center;
  flex-shrink: 0;
  max-height: 100%;
  width: auto;
  max-width: 250px;
  ${BODY_FONT}
  overflow: ${({ isImageLink }) => isImageLink ? 'visible' : 'hidden'};
  max-width: 100%;
  text-overflow: ellipsis;
  display: block;
`;

export const StyledImage = styled(Image)`
  object-fit: contain;
  max-height: 100%;
  max-width: 100px;
`;

export const StyledImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
`;
