import React from 'react';
import { useRealtimeDocument } from 'hooks';
import { IPoll } from 'services/polls/models';
import CardImage from '../CardImage';
import { Body, Question, QuestionText, StyledButton, Title } from './styles';
import { useAdminTranslation } from 'hooks/use-translation';
import { useDispatch, useSelector } from 'react-redux';
import { isLoggedIn } from 'services/auth';
import { trackCard } from 'services/insights';
import { openPanel } from 'services/custom-panels';
import { getIsCardPanelOpen } from 'services/custom-panels/selectors';
import { RendererDoc } from 'services/custom-panels/models';

type PollCardProps = {
  doc: RendererDoc['doc'];
  isCollapsed: boolean;
  onDismiss: () => void;
  renderer: {
    pollId: string;
    title: string;
  };
};

const PollCard: React.FC<PollCardProps> = ({
  isCollapsed,
  doc,
  onDismiss,
  renderer: { pollId, title },
}) => {
  const loggedIn = useSelector(isLoggedIn);
  const dispatch = useDispatch();
  const [poll] = useRealtimeDocument<IPoll>('poll', pollId);
  const isCardPanelOpen = useSelector(getIsCardPanelOpen);
  const { t } = useAdminTranslation();

  const questionText = poll?.question;

  const handleClick = React.useCallback(() => {
    if (!isCardPanelOpen) {
      dispatch(openPanel({
        doc: {
          data: {
            anonymous: !loggedIn,
            cardDoc: doc,
            kind: 'multiple_choice',
            questions: [
              {
                kind: 'poll',
                poll: {
                  _id: pollId,
                  collection: 'poll',
                },
              },
            ],
          },
        },
      }));
      dispatch(trackCard('engage', doc.renderer));
    }
  }, [isCardPanelOpen, doc, loggedIn, pollId, dispatch, trackCard]);

  return (
    <CardImage isCollapsed={isCollapsed} onClose={onDismiss}>
      <Title>{title || t('AUDIENCE_POLL')}</Title>
      <Body>
        {questionText && (
          <>
            <Question>
              <QuestionText>{questionText}</QuestionText>
            </Question>
            <StyledButton onClick={handleClick}>
              {t('ACTION_VOTE_NOW')}
            </StyledButton>
          </>
        )}
      </Body>
    </CardImage>
  );
};

export default PollCard;
