import styled, { css, DefaultTheme, ThemeProps } from 'styled-components';
import withOptionalTranslation from 'components/core/withOptionalTranslation';
import Icon from 'components/ui/Icon';
import { FLEX_CENTER, SINGLE_LINE_ELLIPSIS } from 'style/mixins';
import { rgba } from 'colors';
import { ADMIN_SURFACE_1, ADMIN_TEXT_100 } from 'style/constants';
import { ADMIN_TEXT_BODY_M_MEDIUM, ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';
import { StyleProps as AdminStyleProps, Notification } from 'services/admin-notifications/models';

export interface StyleProps extends Pick<AdminStyleProps, 'background' | 'textColor' | 'buttonCss'>, Pick<Notification, 'borderColor'> {}

type Style<T extends keyof StyleProps> = Pick<StyleProps, T>;

export const NotificationWrapper = styled.div<{
  blockToolbar?: boolean;
  hasNotification?: boolean;
}>`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  & > * {
    pointer-events: auto;
  }
  z-index: 999;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.5s;
  ${({ blockToolbar }) => blockToolbar && css`
    background-color: rgba(0, 0, 0, 0.8);
    pointer-events: auto;
  `}
`;

export const NotificationTransition = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: 0 14px;
  transition: transform 0.25s;
  transform: translate(-50%, -200%);
  &.show {
    transform: translate(-50%, 22px);
  }
`;

export const NotificationContainer = styled.div<Style<'borderColor'>>`
  ${FLEX_CENTER}
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  max-width: 100%;
  height: 34px;
  padding: 0 6px;
  outline: 1px solid ${({ borderColor }) => borderColor};
  background: ${({ borderColor, ...props }) => typeof borderColor === 'function' ? rgba(borderColor(props), 0.3) : rgba(borderColor, 0.3)};
  color: ${ADMIN_TEXT_100};
  gap: 5px;
  border-radius: 5px;
  position: relative;
  &:after {
    content: "";
    background: ${ADMIN_SURFACE_1};
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    border-radius: 5px;
  }
`;

export const NotificationText = withOptionalTranslation(
  styled.span<Style<'background' | 'textColor'>>`
    ${ADMIN_TEXT_BODY_S_MEDIUM}
    ${SINGLE_LINE_ELLIPSIS}
    background: ${({ background }) => background};
    color: ${({ textColor }) => textColor};
  `,
);

export const Button = withOptionalTranslation(
  styled.button<Style<'background' | 'textColor' | 'buttonCss'>>`
    ${SINGLE_LINE_ELLIPSIS}
    ${FLEX_CENTER}
    ${ADMIN_TEXT_BODY_S_BOLD}
    background: ${({ background }) => background};
    color: ${({ textColor }) => textColor};
    height: 24px;
    padding: 0 20px;
    border-radius: 5px;
    text-transform: uppercase;
    ${({ buttonCss }) => buttonCss}
  `,
);

export const StyledIcon = styled(Icon)`
  color: ${({ color }) => color};
  ${ADMIN_TEXT_BODY_M_MEDIUM}
  margin: 0 2px;
  line-height: 0;
  & svg {
    width: 16px;
    height: 15px;
  }
`;

export const ButtonIcon = styled(Icon)`
  & svg {
    width: 8px;
    height: 8px;
  }
`;
