import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import CardButton from 'components/cards/CardButton';
import VideoLink from 'components/ui/VideoLink';

const StyledVideoLink = styled(VideoLink)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: normal;
`;

export default class PlayVideoCta extends React.Component {
  static propTypes = {
    ctaRenderer: PropTypes.shape({
      text: PropTypes.string,
      videoId: PropTypes.string,
    }).isRequired,
    onEngage: PropTypes.func.isRequired,
    testIdText: PropTypes.string,
  };

  static defaultProps = {
    testIdText: undefined,
  };

  render() {
    const { ctaRenderer, onEngage, testIdText } = this.props;
    const {
      text,
      videoId: id,
    } = ctaRenderer;
    return (
      <CardButton onClick={() => onEngage({ cta: 'play_video' })}>
        <StyledVideoLink data-testid={testIdText} tag={id}>
          { text }
        </StyledVideoLink>
      </CardButton>
    );
  }
}
