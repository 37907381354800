import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useFocusArea from 'hooks/use-focus-area';
import hash from 'json-stable-stringify';
import { getObject } from 'services/app';
import PageNavigationContext, { usePageNavigationContext } from './Context';
import SiteNavigation from '../SiteNavigation';
import SiteNavigationItem from '../SiteNavigation/SiteNavigationItem';
import { NavigationButton, PageNavigationWrapper, SiteNavigationExpandableWrapper } from './styles';

const PageNavigation = () => {
  const { closeNavigation, openNavigation, isOpen } = usePageNavigationContext();
  const page = useSelector(getObject);

  const pageNavRef = useFocusArea<HTMLDivElement>({
    onExit: closeNavigation,
    active: isOpen,
  });

  const toggleNavigation = () => {
    if (isOpen) {
      closeNavigation();
    } else {
      openNavigation();
    }
  };

  const renderNavigation = () => {
    return (
      <SiteNavigationExpandableWrapper
        isExpanded={isOpen}
        fixedExpandedHeight="calc(100vh - 51px)"
      >
        <SiteNavigation />
      </SiteNavigationExpandableWrapper>
    );
  };

  const pageData = useMemo(() => {
    return {
      id: page._id,
      name: page.data.name!,
      private: page.data.private,
      slug: page.slug,
      type: page.type,
    };
  }, [hash(page)]);

  return (
    <PageNavigationWrapper ref={pageNavRef}>
      <NavigationButton onClick={toggleNavigation}>
        <SiteNavigationItem
          isParent={true}
          item={pageData}
          source="toolbar"
          toolbarPointUp={isOpen}
        />
      </NavigationButton>
      {renderNavigation()}
    </PageNavigationWrapper>
  );
};

export default () => {
  return (
    <PageNavigationContext>
      <PageNavigation />
    </PageNavigationContext>
  );
};
