import axios from 'axios';
import { batchedPromises } from 'utils';
import {
  ADMIN_SERVICE_BASE_URL,
  VIDEO_SERVICE_BASE_URL,
  PLAYLIST_SERVICE_BASE_URL,
  QUEST_BASE_URL,
} from 'config';

const getDeleteUrl = (collection: string, objectId: string) => {
  switch (collection) {
    case 'videos':
      return `${VIDEO_SERVICE_BASE_URL}/${objectId}`;
    case 'playlists':
      return `${PLAYLIST_SERVICE_BASE_URL}/${objectId}`;
    case 'quests':
      return `${QUEST_BASE_URL}/${objectId}`;
    default:
      return `${ADMIN_SERVICE_BASE_URL}/objects/${objectId}`;
  }
};

const getValidationUrl = (collection: string, objectId: string) => {
  switch (collection) {
    case 'videos':
      return `${VIDEO_SERVICE_BASE_URL}/validate-references/${objectId}`;
    case 'playlists':
      return `${PLAYLIST_SERVICE_BASE_URL}/validate-references/${objectId}`;
    case 'quests':
      return `${QUEST_BASE_URL}/validate-references/${objectId}`;
    default:
      return `${ADMIN_SERVICE_BASE_URL}/validate-references/${objectId}`;
  }
};

const MAXIMUM_CONCURRENT_API_REQUESTS = 10;

export const validateReferences = batchedPromises(
  async (
    objectId: string,
    collection: string,
    primaryToken: string,
    siteId: string,
    captureCanceler?: (cancelFn: () => unknown) => unknown,
  ) => {
    const validationUrl = getValidationUrl(collection, objectId);

    const { data } = await axios({
      headers: {
        Authorization: `Bearer ${primaryToken}`,
        'x-maestro-client-id': siteId,
      },
      url: validationUrl!,
      cancelToken: new axios.CancelToken(
        (canceler) => captureCanceler?.(canceler),
      ),
    });

    return data as {
      collection: string;
      name: string;
    }[];
  },
  MAXIMUM_CONCURRENT_API_REQUESTS,
);

export const deleteVideo = batchedPromises(
  async (
    objectId: string,
    collection: string,
    primaryToken: string,
    siteId: string,
  ) => {
    const deleteUrl = getDeleteUrl(collection, objectId);

    await axios({
      headers: {
        Authorization: `Bearer ${primaryToken}`,
        'x-maestro-client-id': siteId,
      },
      method: 'DELETE',
      url: deleteUrl,
    });
  },
  MAXIMUM_CONCURRENT_API_REQUESTS,
);
