import { IExternalLink, INavigationParent } from 'models/INavigation';
import hash from 'json-stable-stringify';
import React, { useMemo } from 'react';
import {
  calculateMaxAmountOfNavItems,
  calculateSizeOfEachParentGroupInPx,
} from './utils';
import { throttle } from 'lodash';

/**
 * This hook is used to filter the navigation items based on the size of the container.
 * The goal is to render as many navigation items as possible without overflowing the container. While also
 * taking into consideration the text length of the navigation items.
 * @param parents - Array of Navigation Parents
 * @param externalLinks - Array of External Links
 */
const useFilterNavigationByParentSize = (
  parents: INavigationParent[],
  externalLinks: IExternalLink[],
) => {
  const [containerWidth, setContentWidth] = React.useState(0);
  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const setWidth = throttle(() => {
      if (containerRef.current) {
        setContentWidth(containerRef.current.offsetWidth);
      }
    }, 300);

    const node = containerRef.current;
    if (!node) {
      return;
    }

    setContentWidth(node.offsetWidth);
    window.addEventListener('resize', setWidth);
    return () => {
      window.removeEventListener('resize', setWidth);
    };
  }, []);

  return useMemo(() => {
    const hasLinks = externalLinks.length > 0;

    const totalSizeOfParents = calculateSizeOfEachParentGroupInPx(
      parents,
      externalLinks,
    );

    const maxItems = calculateMaxAmountOfNavItems(
      totalSizeOfParents,
      containerWidth,
      hasLinks,
    );

    return {
      maxParents: parents.slice(0, maxItems),
      reachedParentLimit: parents.length >= maxItems,
      moreParents: parents.slice(maxItems, parents.length),
      maxLinks: externalLinks.slice(0, maxItems),
      moreLinks: externalLinks.slice(maxItems, externalLinks.length),
      maxAmountOfNavItems: maxItems,
      containerRef,
    };
  }, [hash(parents), hash(externalLinks), containerWidth]);
};

export default useFilterNavigationByParentSize;
