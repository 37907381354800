import React from 'react';
import { Image, ImageContainer, NoImageText } from './styles';
import { IProductImage } from 'components/Ecommerce/models';
import { useAdminTranslation } from 'hooks/use-translation';
import SoldOutBadge from '../SoldOutBadge';
import { PRODUCT_IMAGE_ID } from 'global-ids';
import { AspectRatio } from 'components/page-blocks/Shopify/utils';

export interface IProductImageProps {
  aspectRatio: AspectRatio;
  imageRef?: (node: HTMLImageElement | null) => void;
  images: IProductImage[] | null;
  isAvailableForSale: boolean;
  isRenderingOnPanel?: boolean;
  name: string;
  onClick?: () => void;
  source: 'CartProduct' | 'ProductDetail' | 'ProductCard';
}

const ProductImage = ({ images, source, imageRef, aspectRatio, isAvailableForSale, isRenderingOnPanel, name, onClick }: IProductImageProps) => {
  const { t } = useAdminTranslation();
  return (
    <ImageContainer
      source={source}
      data-testid={PRODUCT_IMAGE_ID}
      id={PRODUCT_IMAGE_ID}
      onClick={onClick}
    >
      {!isAvailableForSale && <SoldOutBadge source={source} isRenderingOnPanel={isRenderingOnPanel} />}
      {images && images.length > 0 ?
        <Image ref={imageRef} aspectRatio={aspectRatio} id={images[0].id} src={images[0].url} alt={images[0].altText || name} /> :
        <NoImageText>{t('SHOPIFY_NO_IMAGE')}</NoImageText>
      }
    </ImageContainer>
  );
};

export default ProductImage;
