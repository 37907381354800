import { SURFACE_4, TEXT_100, TEXT_200 } from 'style/constants';
import { TEXT_TITLE_M } from 'style/design-system/textStyles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 24px;
  align-items: center;
  border-radius: 50px;
  background: ${SURFACE_4};
  padding: 2px;
  gap: 4px;
`;

export const QuantitySymbol = styled.button`
  all: unset;
  height: 100%;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${TEXT_TITLE_M}
  color: ${TEXT_200};
`;

export const Quantity = styled.label`
  user-select: none;
  color: ${TEXT_100};
`;
