import produce from 'immer';
import set from 'lodash/set';
import React from 'react';
import { IChannelOfflineContent, IScheduleItem } from 'models/IObject';
import JsonDebug from 'components/dev/JsonDebug';
import TranslatedText from 'components/i18n/TranslatedText';
import { useLocalizedPageOrChannelTitle } from 'hooks/localization/useLocalizedPageOrChannelTitle';
import Toolbar from 'components/admin-bridge/Toolbar/Toolbar';
import DocumentTitle from 'components/application/Site/DocumentTitle';
import ChannelContentToolbar from '../../Toolbar/ToolbarView/ChannelContent';
import OfflineSection from '../PageEditorOfflineSection';
import LiveStreamsSection from '../PageEditorLiveStreamsSection';
import ScheduleSection from '../PageEditorScheduleSection';
import {
  Container,
  ContentHeader,
  Priorities,
  PriorityHelper,
} from './styles';
import { getPendingPageDoc, setPendingPageDoc } from 'services/admin';
import { useDispatch, useSelector } from 'react-redux';
import { parseAdminQuery } from 'services/admin/selectors';
import { getObject } from 'services/app';
import { removeKeys } from 'services/app-router';
import PageFetcher from 'components/admin-bridge/Toolbar/PageFetcher';

const EMPTY_ARRAY: unknown[] = [];

const paths = {
  LANDING: 'data.landingContent',
  LIVESTREAMS: 'data.content.live.liveStreams',
  OFFLINE: 'data.content.offline',
  SCHEDULE: 'data.content.live.schedule',
};

export default function ChannelContentEditor() {
  const dispatch = useDispatch();
  const pendingDoc = useSelector(getPendingPageDoc);
  const query = useSelector(parseAdminQuery);
  const page = useSelector(getObject);

  const doc = pendingDoc || page;

  React.useEffect(() => {
    return () => {
      if (query.admin !== 'pages') {
        dispatch(removeKeys(['pageType', 'page']));
      }
    };
  }, [query.admin]);

  const [, pageTitle] = useLocalizedPageOrChannelTitle();

  const onScheduleContentChange = (newValue: IScheduleItem[]) => {
    const newObject = produce(doc, (draft) => set(draft, paths.SCHEDULE, newValue));
    dispatch(setPendingPageDoc(newObject._id, newObject));
  };

  const onLivestreamContentChange = (newValue: { _id: string }[]) => {
    const newObject = produce(doc, (draft) => set(draft, paths.LIVESTREAMS, newValue));
    dispatch(setPendingPageDoc(newObject._id, newObject));
  };

  const onOfflineContentChange = (newValue: IChannelOfflineContent) => {
    const newObject = produce(doc, (draft) => set(draft, paths.OFFLINE, newValue));
    dispatch(setPendingPageDoc(newObject._id, newObject));
  };

  return (
    <>
      <PageFetcher />
      <DocumentTitle title={pageTitle} />
      <Toolbar>
        {ChannelContentToolbar}
      </Toolbar>
      <Container>
        <TranslatedText
          component={ContentHeader}
          stringKey="ADMIN_PAGE_SECTION_CONTENT_PRIORITIES"
        />
        <PriorityHelper>
          <TranslatedText stringKey="ADMIN_LABEL_CONTENT_PRIORITIES_DESCRIPTION" />
          <TranslatedText
            component={Priorities}
            stringKey="ADMIN_LABEL_CONTENT_PRIORITIES"
          />
        </PriorityHelper>
        <ScheduleSection
          onChange={onScheduleContentChange}
          pageId={doc._id}
          value={doc.data?.content?.live?.schedule || EMPTY_ARRAY as React.ComponentProps<typeof ScheduleSection>['value']}
        />
        <LiveStreamsSection
          onChange={onLivestreamContentChange}
          value={doc.data?.content?.live?.liveStreams || EMPTY_ARRAY as { _id: string }[]}
        />
        <OfflineSection
          onChange={onOfflineContentChange}
          value={doc.data?.content?.offline}
        />
        <JsonDebug value={doc} />
      </Container>
    </>
  );
}
