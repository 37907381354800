import React from 'react';
import {
  ProductInfo,
  ProductName,
  ProductNameAndSpecs,
  ProductPrice,
  ProductPriceAndQuantityContainer,
  ProductQuantity,
  ProductRow,
  ProductSpecs,
} from './styles';
import ProductImage from '../sharedComponents/ProductImage';
import { IProductImage } from '../models';
import { useAdminTranslation } from 'hooks/use-translation';
import { DEFAULT_SHOPIFY, IShopifyBlockData } from 'components/page-blocks/Shopify/utils';
import { useSelector } from 'react-redux';
import { getShopifyBlockData } from 'services/ecommerce/selectors';
import useShopifyBlockAspectRatio from 'components/page-blocks/Shopify/use-shopify-block-aspect-ratio';

interface IProductSummaryItemProps {
  productAvailableForSale: boolean;
  productImages: IProductImage[];
  productName: string;
  productPrice: string;
  productQuantity: string;
  productSpecs: string;
}

const ProductSummaryItem: React.FC<IProductSummaryItemProps> = ({
  productAvailableForSale,
  productImages,
  productName,
  productSpecs,
  productPrice,
  productQuantity,
}) => {
  const { t } = useAdminTranslation();
  const shopifyBlockData: IShopifyBlockData = useSelector(getShopifyBlockData) || DEFAULT_SHOPIFY;
  const aspectRatio = useShopifyBlockAspectRatio(shopifyBlockData);

  return (
    <ProductRow>
      {productImages.length && (
        <ProductImage aspectRatio={aspectRatio} images={productImages} source={'CartProduct'}  name={productName} isAvailableForSale={productAvailableForSale} />
      )}
      <ProductInfo>
        <ProductNameAndSpecs>
          <ProductName>{productName}</ProductName>
          <ProductSpecs>{productSpecs}</ProductSpecs>
        </ProductNameAndSpecs>
        <ProductPriceAndQuantityContainer>
          <ProductPrice>{`$${productPrice}`}</ProductPrice>
          <ProductQuantity>{`${t('QTY')} ${productQuantity}`}</ProductQuantity>
        </ProductPriceAndQuantityContainer>
      </ProductInfo>
    </ProductRow>
  );
};

export default ProductSummaryItem;
