import { connect } from 'react-redux';
import { trackGrid } from 'services/insights';
import withErrorGuard from 'components/core/withErrorGuard';
import {
  isGridMobile,
  getSlidesWidth,
  getTotalSlidesToShow,
  getSlideContainerMaxWidth,
} from 'services/grid';
import { replace } from 'services/app-router';
import { isEditMode } from 'services/admin';
import GridItem from './view';

const mapStateToProps = state => ({
  isEditingGridRegion: isEditMode(state),
  isGridMobile: isGridMobile(state),
  slideContainerMaxWidth: getSlideContainerMaxWidth(state),
  slidesWidth: getSlidesWidth(state),
  totalSlidesToShow: getTotalSlidesToShow(state),
});

const mapDispatchToProps = dispatch => ({
  onSetTagPlaylist: ({ tag, videoId }) => {
    dispatch(replace({ query: { p: 'tag', tag, v: videoId } }));
  },
  trackClick: doc => dispatch(trackGrid('click', doc, {})),
});

export default withErrorGuard()(
  connect(mapStateToProps, mapDispatchToProps)(GridItem),
);
