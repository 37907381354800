import styled, { css } from 'styled-components';
import Icon from 'components/ui/Icon';
import withTooltip from 'components/core/withTooltip';
import {
  MAESTRO_WHITE,
} from 'style/constants';
import {
  NO_HIGHLIGHT,
} from 'style/mixins';

export interface VolumeSliderStyleProps {
  hovering?: boolean;
  isMobile?: boolean;
  landscape?: boolean;
  vertical?: boolean;
}

export const VolumeIcon = withTooltip(styled(Icon).attrs(() => ({
  color: MAESTRO_WHITE,
}))<VolumeSliderStyleProps>`
  ${NO_HIGHLIGHT}
  cursor: pointer;
  & svg {
    width: 19px;
    height: 16px;
  }
`, { admin: true });

export const SliderWrapper = styled.div<VolumeSliderStyleProps>`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const VolumeSliderContainer = styled.div<VolumeSliderStyleProps>`
  display: flex;
  align-items: center;
  ${({ hovering, isMobile, vertical }) => {
    const size = vertical ? 'height' : 'width';
    const otherSize = vertical ? 'width' : 'height';
    return css`
      ${vertical && 'flex-direction: column-reverse;'}
      & ${SliderWrapper} {
        transition: 0.5s ${size}, 0.5s max-${size};
        min-${otherSize}: max-content;
        ${size}: 0;
        max-${size}: 0px;
        ${otherSize}: 19px;
        padding-${vertical ? 'bottom' : 'left'}: ${isMobile ? 15 : 5}px;
        ${vertical && `
          flex-direction: column;
          justify-content: flex-end;
        `}
      }
      ${hovering ? `
        & ${SliderWrapper} {
          ${size}: 75px;
          max-${size}: 75px;
          ${isMobile && `
            ${size}: 115px;
            max-${size}: 115px;
          `}
        }
      ` : `
        & ${SliderWrapper} {
          & > div > div:after {
            display: none !important;
          }
        }
      `}
    `;
  }}
`;
