import React, { useCallback, useEffect, useState } from 'react';
import useSelector from 'hooks/use-selector';
import useDispatch from 'hooks/use-dispatch';
import useRenderAdmin from 'hooks/use-render-admin';
import useSiteLayout from 'hooks/use-site-layout';
import { useLocalizedPageOrChannelTitle } from 'hooks/localization/useLocalizedPageOrChannelTitle';
import {
  isEditMode,
  inAccessMenu,
} from 'services/admin/selectors';
import {
  setActiveAction,
} from 'services/admin/actions';
import {
  ContractStatus,
  getContractStatus,
  getPurchasePlanSuccess,
  getIsLegacyPlan,
} from 'services/billing';
import {
  isEmbed,
  isCustomReceipt,
  isTheaterActive,
} from 'services/user-layout/selectors';
import { isAdminViewingAccessTab } from 'services/gate/selectors';
import { CONTENT_ROOT_ID } from 'global-ids';
import CustomReceipt from 'components/CustomReceipt';
import DocumentTitle from 'components/application/Site/DocumentTitle';
// eslint-disable-next-line no-restricted-imports
import AccessGate from 'components/admin2/AccessGate';
import useFreeTrialNotifications from 'hooks/use-free-trial-notifications';
import usePrivatePageNotifications from 'hooks/use-private-page-notifications';
import Toolbar from 'components/admin-bridge/Toolbar';
import Background from 'components/regions/Content/Background';
import MobileLayout from 'components/mobile/MobileLayout';
import { PageEditorContextProvider } from 'contexts/PageEditorContext';
import ContentWithSidebar from './ContentWithSidebar';

import {
  AdminBarContainer,
  ContentContainer,
  Container,
  Wrapper,
  StyledAdminBar,
  FullScreenContainer,
} from './styles';

const Site = () => {
  const dispatch = useDispatch();
  const { Component: AdminComponent, isRenderingAdmin } = useRenderAdmin();
  const embedLayout = useSelector(isEmbed);
  const editModeEnabled = useSelector(isEditMode);
  const [localizedPageTitle] = useLocalizedPageOrChannelTitle();
  const customReceiptLayout = useSelector(isCustomReceipt);
  const theaterActive = useSelector(isTheaterActive);
  const showAccessTab = useSelector(isAdminViewingAccessTab);
  const isLegacyPlan = useSelector(getIsLegacyPlan);
  const contractStatus = useSelector(getContractStatus);
  let isInactiveContract = contractStatus !== ContractStatus.active;
  if (typeof contractStatus === 'undefined') {
    isInactiveContract = false;
  }
  const isPurchasePlanSuccess = useSelector(getPurchasePlanSuccess);
  const isInAccessMenu = useSelector(inAccessMenu);

  const [isFreeTrialNavVisible, setIsFreeTrialNavVisible] = useState(false);

  useFreeTrialNotifications(
    isLegacyPlan && (isInactiveContract || isFreeTrialNavVisible),
  );

  usePrivatePageNotifications();

  const { showMobileLayout } = useSiteLayout();

  const closeAdminComponent = () => {
    dispatch(setActiveAction(null));
  };

  const setFreeTrialNavBarActive = () => {
    setIsFreeTrialNavVisible(true);
  };

  const renderContent = useCallback(() => {
    if (showAccessTab) {
      return <AccessGate />;
    }
    return <ContentWithSidebar />;
  }, [showAccessTab]);

  useEffect(() => {
    if (isPurchasePlanSuccess) {
      setFreeTrialNavBarActive(true);
    }
  }, [isPurchasePlanSuccess]);

  if (customReceiptLayout) {
    return <CustomReceipt />;
  }

  return (
    <Container>
      <PageEditorContextProvider>
        <AdminBarContainer>
          <Background />
          <StyledAdminBar hidden={theaterActive} />
          {isRenderingAdmin ? (
            <FullScreenContainer>
              <AdminComponent onClose={closeAdminComponent} />
            </FullScreenContainer>
          ) : (
            <ContentContainer>
              {isInAccessMenu ? null : <Toolbar />}
              <Wrapper
                embed={embedLayout}
                id={CONTENT_ROOT_ID}
                isEditModeEnabled={editModeEnabled && !inAccessMenu}
                mobileLayout={showMobileLayout}
              >
                <DocumentTitle title={localizedPageTitle} />
                { renderContent() }
              </Wrapper>
            </ContentContainer>
          )}
          {showMobileLayout && <MobileLayout />}
        </AdminBarContainer>
      </PageEditorContextProvider>
    </Container>
  );
};

Site.propTypes = {};

export default Site;
