import { ObjectValues } from 'utils';

export interface IProductData {
  preSelectedOptions?: SelectedOption[];
  product: IProduct;
}

export interface ICartProduct {
  options: Option[];
  product: IProduct;
  productVariant: IProductVariant;
  selectedOptions: SelectedOption[];
}

export interface IProductImage {
  altText?: string;
  id: string;
  url: string;
}

export interface IProduct {
  availableForSale: boolean;
  description: string;
  id: string;
  images: IProductImage[] | null;
  name: string;
  options: Option[];
  quantity: number;
  variants: IVariant[];
}

type BaseOption = {
  id: string;
  name: string;
  type: OptionType;
  values: OptionData[];
};

export type SelectedOption = {
  name: string;
  type: OptionType;
  value: OptionData;
};

export const OPTION_TYPE = {
  color: 'color',
  size: 'size',
  generic: 'generic',
} as const;
export type OptionType = ObjectValues<typeof OPTION_TYPE>;

export type OptionData = {
  isAvailable?: boolean;
  name: string;
  value: string;
};

export interface IColorOption extends BaseOption {
  type: typeof OPTION_TYPE.color;
}

export interface ISizeOption extends BaseOption {
  type: typeof OPTION_TYPE.size;
}

export interface IGenericOption extends BaseOption {
  type: typeof OPTION_TYPE.generic;
}

export type Option = IColorOption | ISizeOption | IGenericOption;

/*
   a variant is an unique combination of options
   ex: a product with 2 options (color and size) and 3 values for each option (red, green, blue and small, medium, large)
   will have 9 variants (3 colors x 3 sizes) ex: red-small, red-medium, red-large, green-small, green-medium, green-large, blue-small, blue-medium, blue-large
*/

export interface IVariant {
  availableForSale: boolean;
  id: string;
  images: IProductImage[] | null;
  price: number;
  requiresShipping: boolean;
  selectedOptions: SelectedOption[];
  title: string;
}

export interface IProductVariant {
  availableForSale: IVariant['availableForSale'];
  description: IProduct['description'];
  images: IVariant['images'] | IProduct['images'];
  name: IProduct['name'];
  price: IVariant['price'];
  productId: IProduct['id'];
  quantity: IProduct['quantity'];
  requiresShipping: IVariant['requiresShipping'];
  selectedOptions: IVariant['selectedOptions'];
  variantId: IVariant['id'];
  variantTitle: IVariant['title'];
}
