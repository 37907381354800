import { ADMIN_ACCENT_PRIMARY, ADMIN_SURFACE_3, ADMIN_SURFACE_4, ADMIN_SURFACE_5, ADMIN_SURFACE_6, ADMIN_TEXT_200 } from 'style/constants';
import { ADMIN_TEXT_LABEL_M_MEDIUM } from 'style/design-system/textStyles';
import styled, { css } from 'styled-components';
import { GridTextAlignmentOptions } from './types';

export const Wrapper = styled.section`
  display: flex;
  gap: 6px;
  height: 90px;
`;

export const Grid = styled.div`
  border-radius: 5px;
  width: 90px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: ${ADMIN_SURFACE_3};
  border: 1px solid ${ADMIN_SURFACE_6};
  position: relative;
`;

export const GRID_TEXT_ALIGNMENT_FLEX_DIRECTION_ROW_CSS: Record<GridTextAlignmentOptions, ReturnType<typeof css>> = {
  topLeft: css`
    align-items: flex-start;
    justify-content: flex-start;
  `,
  topCenter: css`
    justify-content: center;
    align-items: start;
  `,
  topRight: css`
    justify-content: flex-end;
    align-items: flex-start;
  `,
  centerLeft: css`
    align-items: center;
    justify-content: flex-start;
  `,
  center: css`
    align-items: center;
    justify-content: center;
  `,
  centerRight: css`
    align-items: center;
    justify-content: flex-end;
  `,
  bottomLeft: css`
    align-items: flex-end;
    justify-content: flex-start;
  `,
  bottomCenter: css`
    align-items: flex-end;
    justify-content: center;
  `,
  bottomRight: css`
    align-items: flex-end;
    justify-content: flex-end;
  `,
};

export const TEXT_ALIGNMENT_CSS = (alignment: GridTextAlignmentOptions) => {
  switch (alignment) {
    case 'topCenter':
    case 'center':
    case 'bottomCenter':
      return css`
        text-align: center !important;
      `;
    case 'topRight':
    case 'centerRight':
    case 'bottomRight':
      return css`
        text-align: right !important;
      `;
    case 'topLeft':
    case 'centerLeft':
    case 'bottomLeft':
    default:
      return css`
        text-align: left !important;
      `;
  }
};

export const GRID_TEXT_ALIGNMENT_FLEX_DIRECTION_COLUMN_CSS: Record<GridTextAlignmentOptions, ReturnType<typeof css>> = {
  topLeft: css`
    justify-content: flex-start;
    align-items: flex-start;
  `,
  topCenter: css`
    justify-content: flex-start;
    align-items: center;
  `,
  topRight: css`
    justify-content: flex-start;
    align-items: flex-end;
  `,
  centerLeft: css`
    justify-content: center;
    align-items: flex-start;
  `,
  center: css`
    justify-content: center;
    align-items: center;
  `,
  centerRight: css`
    justify-content: center;
    align-items: flex-end;
  `,
  bottomLeft: css`
    justify-content: flex-end;
    align-items: flex-start;
  `,
  bottomCenter: css`
    justify-content: flex-end;
    align-items: center;
  `,
  bottomRight: css`
    justify-content: flex-end;
    align-items: flex-end;
  `,
};

export const Button = styled.button<{ alignment: GridTextAlignmentOptions; }>`
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Dot = styled.div`
  background-color: ${ADMIN_SURFACE_6};
  width: 3px;
  height: 3px;
`;

export const SelectedWrapper = styled.div<{ alignment: GridTextAlignmentOptions; }>`
  gap: 2px;
  display: flex;
  flex-direction: column;
  position: absolute;
  ${({ alignment }) => GRID_TEXT_ALIGNMENT_FLEX_DIRECTION_COLUMN_CSS[alignment]};
`;

export const First = styled.div`
  width: 12px;
  height: 4px;
  background: ${ADMIN_ACCENT_PRIMARY};
  border-radius: 10px;
`;

export const Second = styled.div`
  background: ${ADMIN_ACCENT_PRIMARY};
  height: 4px;
  width: 16px;
  border-radius: 10px;
`;

export const Third = styled.div`
  background: ${ADMIN_ACCENT_PRIMARY};
  width: 8px;
  height: 4px;
  border-radius: 10px;
`;

export const Example = styled.div<{ alignment: GridTextAlignmentOptions; }>`
  background-color: ${ADMIN_SURFACE_4};
  border: 1px solid ${ADMIN_SURFACE_5};
  border-radius: 5px;
  padding: 14px;
  flex: 1;
  display: flex;
  ${({ alignment }) => GRID_TEXT_ALIGNMENT_FLEX_DIRECTION_ROW_CSS[alignment]};
  ${ADMIN_TEXT_LABEL_M_MEDIUM}
  color: ${ADMIN_TEXT_200};
`;
