import { bw } from 'colors';
import Icon from 'components/ui/Icon';
import { SURFACE_4, TEXT_100 } from 'style/constants';
import { TEXT_LABEL_M_MEDIUM, TEXT_LABEL_L_MEDIUM } from 'style/design-system/textStyles';
import styled, { css } from 'styled-components';
import { darken } from 'colors';

export const Container = styled.div<{ isCartProduct?: boolean; isRenderingOnPanel?: boolean }>`
  display: flex;
  height: 35px;
  padding: 6px 14px 6px 8px;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  gap: 6px;
  border-radius: 50px;
  background: ${props => darken(SURFACE_4(props), 0.1)};
  position: relative;
  cursor: pointer;
  width: fit-content;
  ${props => props.isCartProduct && css`
    height: 24px;
    padding: 2px 8px 2px 6px;
    @media screen and (max-width: 1160px) {
      width: fit-content;
      padding: 6px 14px 6px 8px;
    }
  `}

  ${props => props.isRenderingOnPanel && css`
    width: 100%;
    height: 24px;
    padding: 2px 8px 2px 6px;
    margin-top: auto;
    @media screen and (max-width: 1160px) {
      width: fit-content;
      padding: 6px 14px 6px 8px;
    }
  `}

`;

export const ClickCaptureOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Separator = styled.span<{ isRenderingOnPanel: boolean }>`
  color: ${TEXT_100};
  ${props => props.isRenderingOnPanel && css`
    ${TEXT_LABEL_M_MEDIUM};
  `}
  ${props => !props.isRenderingOnPanel && css`
    ${TEXT_LABEL_L_MEDIUM};
  `}
  padding-left: 5px;
  padding-top: 2px;
`;

export const DownArrowIcon = styled(Icon).attrs(() => ({
  name: 'downArrow',
}))<{ isRenderingOnPanel: boolean; }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 3px;
  svg {
    ${props => props.isRenderingOnPanel ? css`
      width: 16px;
      height: 16px;
    ` : css`
      width: 24px;
      height: 24px;
    `}

    & path {
      fill: ${props => bw(SURFACE_4(props))};
    }
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
`;
