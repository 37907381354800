import styled from 'styled-components';
import {
  SURFACE_1,
  TEXT_100,
} from 'style/constants';
import {
  FLEX_CENTER,
  desktopOnly,
} from 'style/mixins';
import { ADMIN_TEXT_BODY_M_MEDIUM, ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';

export const CastingViewContainer = styled.div`
  ${FLEX_CENTER}
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  background: ${SURFACE_1};
  width: 100%;
  height: 100%;
`;

export const CastingViewWrapper = styled.div`
  ${FLEX_CENTER}
  flex-direction: column;
  width: 50%;
  height: 100%;
  position: relative;
`;

export const Title = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${TEXT_100};
  position: absolute;
  top: 50%;
  transform: translateY(60%);
  max-height: calc(100% - 100px);
  height: 146px;
  white-space: pre-wrap;
  ${desktopOnly`${ADMIN_TEXT_BODY_M_MEDIUM}`}
  & span:first-child {
    &:after {
      content: " ";
    }
  }
`;

export const Device = styled.span`
  &:before, &:after {
    content: '"';
  }
`;

export const ScreenIcon = styled.svg`
  height: calc(100% - 100px);
  width: 100%;
  max-width: 198px;
  max-height: 146px;
`;
