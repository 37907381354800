import Dropdown from 'components/admin2/ui/Dropdown';
import React from 'react';
import { CTABehavior, CTA_BEHAVIOR_DEFAULT_DATA, CtaBehaviorData, getCtaBehaviorDropdownOptions } from '../utils';
import { useAdminTranslation } from 'hooks/use-translation';

type BehaviorDropdownProps = {
  behavior: CtaBehaviorData;
  onChange: (behavior: CtaBehaviorData) => void;
};

const BehaviorDropdown = ({ behavior, onChange }: BehaviorDropdownProps) => {
  const { t } = useAdminTranslation();

  const handleChangeBehavior = React.useCallback((option: { value: CTABehavior; }) => {
    const newBehavior = option.value;
    if (behavior.type !== newBehavior) {
      onChange(CTA_BEHAVIOR_DEFAULT_DATA[newBehavior]);
    }
  }, [behavior.type, onChange]);


  const ctaBehaviorDropdownOptions = getCtaBehaviorDropdownOptions(t);
  const behaviorValue = React.useMemo(() => ctaBehaviorDropdownOptions.find((option) => option.value === behavior.type), [behavior.type]);

  return (
    <Dropdown
      onChange={handleChangeBehavior}
      options={ctaBehaviorDropdownOptions}
      value={behaviorValue}
      labelKey="BEHAVIOR"
      padding="0"
      isCtaBehavior={true}
      dropdownMaxHeight="unset"
    />
  );
};

export default BehaviorDropdown;
