import React from 'react';
import Button from './Button';
import { useDispatch } from 'react-redux';
import { setActiveAction, setAdminMode } from 'services/admin';

export default function ExitStreamMode() {
  const dispatch = useDispatch();

  const handleExitStreamMode = React.useCallback(() => {
    dispatch(setAdminMode(null));
    dispatch(setActiveAction(null));
  }, [dispatch]);

  return (
    <Button
      onClick={handleExitStreamMode}
      stringKey="ADMIN_EXIT_STREAM_MODE"
      data-testid="admin-livestream-preview-exit-stream-mode-btn"
    />
  );
}
