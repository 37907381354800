// tslint:disable:jsx-boolean-value
import React from 'react';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import AdminFooterLinks from 'components/admin-bridge/AdminFooterLinks';
import { EMPTY, EmptyType, FooterLink as FooterLinkData } from 'models/IFooter';
import { FOOTER_ID } from 'global-ids';
import {
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLink,
  FooterImageLink,
  StyledImage,
  StyledImageContainer,
  StyledQuickEditOverlay,
} from './styles';
import { EMPTY_ARRAY } from 'services/utils';
import { useSelector } from 'react-redux';
import { isUserAdmin } from 'services/auth';
import { isEditMode } from 'services/admin';
import { useFeatureGateEnabled } from 'hooks';
import { Feature } from 'hooks/use-feature-gate-enabled';

type FooterLinksOrEmpty = FooterLinkData[] | EmptyType;

interface FooterLinks {
  footerLinks: FooterLinksOrEmpty;
  onFooterEngage(footerLinks: Partial<FooterLinkData>): void;
}

export default function FooterLinks({
  onFooterEngage,
  footerLinks,
}: FooterLinks) {
  const isAdmin = useSelector(isUserAdmin);
  const isEditing = useSelector(isEditMode);
  const isSimpleModeEnabled = useFeatureGateEnabled({
    feature: Feature.SIMPLE_MODE,
    type: 'feature',
  });
  const [isActionModalOpen, setIsActionModalOpen] = React.useState(false);

  const handleCloseActionModal = () => setIsActionModalOpen(false);
  const handleOpenActionModal = () => setIsActionModalOpen(true);

  const parsedFooterLinks = footerLinks === EMPTY ? EMPTY_ARRAY : footerLinks;

  const renderEditable = () => {
    return (
      <AdminFooterLinks
        footerLinks={parsedFooterLinks}
        isActionModalOpen={isActionModalOpen}
        onCloseActionModal={handleCloseActionModal}
      />
    );
  };

  const renderDefault = () => {
    return (
      <FooterLinksWrapper disabled={!footerLinks.length}>
        <FooterLinksContainer id={FOOTER_ID}>
          <NonKeyedListMapper list={parsedFooterLinks}>
            {
              (key: string, doc: FooterLinkData) => {
                const { url, image, name, text, kind } = doc;
                const isImageLink = kind === 'image_link';
                const FooterLinkComponent = isImageLink ? FooterImageLink : FooterLink;
                const handleFooterEngage = () => onFooterEngage({ image, name, url });
                return (
                  <FooterLinkComponent
                    key={key}
                    href={url}
                    isImageLink={isImageLink}
                    onClick={handleFooterEngage}
                    plain
                  >
                    {
                      isImageLink ? (
                        <StyledImageContainer>
                          <StyledImage src={image} />
                        </StyledImageContainer>
                      ) : text
                    }
                  </FooterLinkComponent>
                );
              }
            }
          </NonKeyedListMapper>
        </FooterLinksContainer>
      </FooterLinksWrapper>
    );
  };

  const renderFooterLinks = () => {
    const shouldRenderEditable = isSimpleModeEnabled ? isAdmin : isEditing;
    if (shouldRenderEditable) {
      return renderEditable();
    }

    return renderDefault();
  };

  return (
    <StyledQuickEditOverlay
      testId="editFooterLanding"
      titleKey="ADMIN_LABEL_FOOTER"
      onEdit={handleOpenActionModal}
    >
      {renderFooterLinks()}
    </StyledQuickEditOverlay>
  );
}
