import styled from 'styled-components';
import AdminModal from 'components/admin2/ui/AdminModal';
import Icon from 'components/ui/Icon';
import AdminButton from 'components/admin2/ui/Button';
import TextInput from 'components/admin2/TextInput';
import AdminBackButton from 'components/admin2/NavControls/BackButton';
import { ADMIN_BACKGROUND_TITLE, ACTION_ICON_NORMAL } from 'style/mixins';
import {
  ADMIN_SURFACE_1,
  SPACING_MEDIUM,
  ADMIN_INPUT_BORDER_COLOR,
  SPACING_SMALL,
  SPACING_X_SMALL,
  ADMIN_TEXT_200,
  ADMIN_SURFACE_6,
  ADMIN_SURFACE_5,
  ADMIN_SURFACE_4,
} from 'style/constants';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';

export const StyledTextInput = styled(TextInput)`
    padding: ${SPACING_MEDIUM};
    width: 100%;
  `;

export const Container = styled.div`
    width: 610px;
  `;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    ${ADMIN_BACKGROUND_TITLE};
  `;

export const Left = styled.div`
    display: flex;
  `;

export const Right = styled.div`
    display: flex;
  `;

export const BackButton = styled(AdminBackButton)`
  margin-left: 16px;
`;

export const Button = styled(AdminButton)`
    margin-left: 16px;
  `;

export const TitleInputContainer = styled.div`
    height: 115px;
    display: flex;
  `;

export const StyledAdminModal = styled(AdminModal)`
    max-height: 90vh;
    background: ${ADMIN_SURFACE_4};
  `;

export const CloseIcon = styled(Icon)`
    ${ACTION_ICON_NORMAL}
    position: absolute;
    right: 0;
    top: 0;
  `;

export const ImageContainer = styled.div`
    width: 100px;
    height: 51.02px;
    margin-left: 10px;
    background: ${ADMIN_SURFACE_6};
    border: 2px solid ${ADMIN_SURFACE_5};
    box-sizing: border-box;
  `;

export const AddChannelContainer = styled.div`
    display: flex;
    margin-left: 26px;
    justify-content: space-between;
    align-items: center;
    width:560px;
    margin-bottom: ${SPACING_SMALL};
  `;

export const AddChannelDescription = styled.div`
  `;

export const ChannelImage = styled.img`
    height: 100%;
    object-fit: contain;
    width: 100%;
  `;

export const NoChannelsSelected = styled.div`
    width: 560px;
    height: 63px;
    margin-left: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    background-color: ${ADMIN_SURFACE_4};
    border: 2px dashed ${ADMIN_INPUT_BORDER_COLOR};
    margin-bottom: ${SPACING_MEDIUM};
    & > * {
      ${ADMIN_TEXT_BODY_XS_MEDIUM}
      color: ${ADMIN_TEXT_200};
    }
  `;

export const ChannelDescription = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
  display: block;
  flex: 1;
  margin: 14px;
  position: relative;
  `;

export const DescriptionWrapper = styled.div`
    & > span {
      ${ADMIN_TEXT_BODY_S_MEDIUM}
    };
  `;

export const ChannelAddContainer = styled.div`
    width: 560px;
    height: 63px;
    margin-left: 26px;
    background: ${ADMIN_SURFACE_1};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: ${SPACING_X_SMALL};
  `;
