import CopyButton from 'components/admin2/ui/CopyButton';
import useFloatMenu from 'hooks/use-float-menu';
import React from 'react';
import { useDispatch } from 'react-redux';
import { requestNewStream } from 'services/livestream';
import { Dot, Menu, RefreshIcon, RefreshStreamKeyButton, Wrapper, Dots } from './styles';
import { useAdminTranslation } from 'hooks/use-translation';
import { useFeatureGateEnabled } from 'hooks';
import { Feature } from 'hooks/use-feature-gate-enabled';

interface CopyStreamKeysButtonProps {
  streamKey: string;
  streamStatus: 'online' | 'offline';
}

const CopyStreamKeysButton: React.FC<CopyStreamKeysButtonProps>= ({ streamKey, streamStatus }) => {
  const { t } = useAdminTranslation();
  const isLive = streamStatus === 'online';
  const isResetStreamKeyEnabled = useFeatureGateEnabled({
    feature: Feature.RESET_STREAM_KEY,
    type: 'feature',
  });


  const dispatch = useDispatch();
  const setMenuPosition = React.useCallback((menu: HTMLDivElement, menuContainer: HTMLDivElement) => {
    const menuContainerRect = menuContainer.getBoundingClientRect();
    menu.style.top = `${menuContainerRect.height + 20}px`;
    menu.style.transform = `translateX(-20px)`;
  }, []);

  const { isOpen, containerRef, positionMenu, toggleMenu } = useFloatMenu(setMenuPosition);

  const handleRequestNewStream = React.useCallback(() => {
    dispatch(requestNewStream());
    toggleMenu();
  }, [dispatch, toggleMenu]);

  return (
    <Wrapper ref={containerRef} isResetStreamKeyEnabled={isResetStreamKeyEnabled} isLive={isLive}>
      <CopyButton
        data-testid="copyStreamKeyButton"
        copyText={streamKey}
        labelKey="STREAM_KEY"
      />
      { !isLive && isResetStreamKeyEnabled &&
        (
          <>
            <Dots data-testid="copyStreamKeyOptions" onClick={toggleMenu}>
              <Dot />
              <Dot />
              <Dot />
            </Dots>
            {
              isOpen && (
                <Menu ref={positionMenu}>
                  <RefreshStreamKeyButton
                    action={handleRequestNewStream}
                    descriptionTextKey="ADMIN_LABEL_UNDO_WARNING"
                    messageKey="ADMIN_LABEL_RESET_STREAM_KEY"
                    data-testid="resetStreamKeyButton"
                  >
                    <RefreshIcon />
                    {t('RESET_STREAM_KEY')}
                  </RefreshStreamKeyButton>
                </Menu>
              )
            }
          </>
        )
      }
    </Wrapper>
  );
};

export default CopyStreamKeysButton;
