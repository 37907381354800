import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRectPositionCheckingBounds } from 'dom-utils';
import { NAVIGATION_BLOCK_CUSTOM_NAVIGATION_MENU_ITEM_LINK_CARDS_MENU_ID, NAVIGATION_BLOCK_CUSTOM_NAVIGATION_MENU_ITEM_LINK_THREE_DOTS_MENU_ID, NAVIGATION_BLOCK_CUSTOM_NAVIGATION_REMOVE_ITEM_ID } from 'global-ids';
import { useAdminTranslation } from 'hooks/use-translation';
import useFloatMenu from 'hooks/use-float-menu';
import { getNavigationSelectedInTheDropdown, removeLinkFromNavigation } from 'services/navigationv2';
import { IExternalLink } from 'models/INavigation';
import ThreeDotsMenu from 'components/ui/ThreeDotsMenu';
import { IMenuAction } from 'components/ui/CardsMenu';
import { Container, IconAndName } from '../styles';
import { LinkIcon, LinkName } from './styles';
import { EXTERNAL_LINK_NAME_MAX_LENGTH } from '../../ExternalLinksInputs';

interface ILinkItem {
  link: IExternalLink;
}

const LinkItem = ({ link }: ILinkItem) => {
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();
  const selectedNavigation = useSelector(getNavigationSelectedInTheDropdown);

  const setMenuPosition = useCallback((menu: HTMLDivElement, menuContainer: HTMLDivElement) => {
    const menuContainerRect = menuContainer.getBoundingClientRect();
    const safePosition = getRectPositionCheckingBounds({
      elem: menu,
      position: {
        x: menuContainerRect.right,
        y: menuContainerRect.top,
      },
    });

    menu.style.left = `${safePosition.x + 5}px`;
    menu.style.top = `${safePosition.y}px`;
  }, []);
  const { isOpen: isFloatMenuOpen, containerRef, positionMenu, toggleMenu } = useFloatMenu(setMenuPosition);

  const removeLinkFromCustomNavigation = useCallback(() => {
    dispatch(removeLinkFromNavigation(selectedNavigation._id!, link.id));
  }, [dispatch, link.id, selectedNavigation._id]);

  const menuActions = useMemo<IMenuAction[]>(() => {
    return [
      {
        id: 'link-remove_from_custom_navigation',
        name: t('ADMIN_NAVIGATION_BLOCK_CUSTOM_MENU_ITEM_ACTIONS_REMOVE'),
        icon: 'trashV2',
        dataTestId: NAVIGATION_BLOCK_CUSTOM_NAVIGATION_REMOVE_ITEM_ID,
        handler: removeLinkFromCustomNavigation,
      },
    ];
  }, [removeLinkFromCustomNavigation]);

  return (
    <Container ref={containerRef}>
      <IconAndName>
        <LinkIcon />
        <LinkName href={link.url} maxLength={EXTERNAL_LINK_NAME_MAX_LENGTH}>{link.name}</LinkName>
      </IconAndName>
      <ThreeDotsMenu
        threeDotsMenuId={NAVIGATION_BLOCK_CUSTOM_NAVIGATION_MENU_ITEM_LINK_THREE_DOTS_MENU_ID}
        cardsMenuId={NAVIGATION_BLOCK_CUSTOM_NAVIGATION_MENU_ITEM_LINK_CARDS_MENU_ID}
        menuActions={menuActions}
        toggleMenu={toggleMenu}
        positionMenu={positionMenu}
        isFloatMenuOpen={isFloatMenuOpen}
        isAdmin={true}
      />
    </Container>
  );
};

export default LinkItem;
