import styled, { css } from 'styled-components';
import Slider from 'components/ui/Slider';
import {
  MAESTRO_WHITE,
  MAESTRO_BLACK,
} from 'style/constants';
import {
  desktopOnly,
  INNER_BACKGROUND,
  mobileOnly,
} from 'style/mixins';
import TimeProgress from '../TimeProgress';

interface StyleProps {
  isLandscape?: boolean;
  showControls?: boolean;
}

export const ControlsWrapper = styled.div.attrs({
  innerBackground: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #0E0F11 100%)`,
  innerBackgroundHeight: '100px',
}) <{ isPlaying?: boolean }>`
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  width: 100%;
  z-index: 2;
  ${INNER_BACKGROUND}
`;

export const ControlsContainer = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px;
  width: 100%;
  ${({ isLandscape }) => mobileOnly`
  padding: ${isLandscape ? '20px 40px' : '20px'};
  `};
`;

export const StyledTimeProgress = styled(TimeProgress)`
  color: ${MAESTRO_WHITE};
  ${mobileOnly`
    margin-bottom: 9px;
  `}
`;

export const StyledVideoSeekBar = styled(Slider).attrs(() => ({
  progressColor: MAESTRO_WHITE,
  trackBackground: MAESTRO_BLACK,
}))``;

export const Spacer = styled.div`
  flex: 1 !important;
  pointer-events: none !important;
  & ~ * {
    margin-left: 5px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const Header = styled.div.attrs({
  innerBackground: `linear-gradient(180deg, #0E0F11 0%, rgba(0, 0, 0, 0) 100%)`,
  innerBackgroundHeight: '100px',
}) <StyleProps>`
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  pointer-events: none;
  &:after {
    align-self: flex-start;
  }
  & > * {
    pointer-events: all;
    ${({ isLandscape, showControls }) => css`
      margin-top: ${isLandscape ? 30 : 10}px;
      &:first-child {
        margin-left: ${isLandscape ? 35 : 15}px;
      }
      &:last-child {
        margin-right: ${isLandscape ? 35 : 15}px;
      }
      ${!showControls && 'pointer-events: none;'}
    `}
  }
  ${INNER_BACKGROUND}
`;

export const Top = styled.div`
  ${desktopOnly`
    padding-left: 43px;
  `}
`;

export const Bottom = styled.div<StyleProps>`
  display: flex;
  align-items: center;
  flex: 1;
  ${({ isLandscape }) => css`
    ${mobileOnly`
      max-height: 10px;
      min-width: 100%;
      align-items: flex-end;
      & ${StyledVideoSeekBar} {
        min-width: 100%;
        & + div {
          margin-bottom: -5px;
          ${isLandscape && `
            margin-bottom: 24px;
            margin-left: -20px;
          `}
        }
      }
    `}
  `}
`;
