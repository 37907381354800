import PropTypes from 'prop-types';

import { useAjax } from 'hooks';
import { camelify } from 'shared/string-utils';

const AjaxProvider = (props) => {
  const {
    children: render,
    ...ajaxOptions
  } = props;

  const { data, error, loaded } = useAjax(ajaxOptions);

  return render(data, error, loaded);
};

AjaxProvider.propTypes = {
  body: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  children: PropTypes.func.isRequired,
  headers: PropTypes.objectOf(PropTypes.string.isRequired),
  method: PropTypes.string,
  params: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  // eslint-disable-next-line react/forbid-prop-types, react/no-unused-prop-types
  refreshKey: PropTypes.any,
  responseType: PropTypes.string,
  transform: PropTypes.func,
  url: PropTypes.string.isRequired,
};

AjaxProvider.defaultProps = {
  body: null,
  headers: null,
  method: 'GET',
  params: {},
  refreshKey: null,
  responseType: 'json',
  transform: camelify,
};

export default AjaxProvider;
