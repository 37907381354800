import styled, { css } from 'styled-components';
import Dropzone from 'react-dropzone';
import { ADMIN_SURFACE_2, ADMIN_ACCENT_PRIMARY, ADMIN_ALERT_ERROR, ADMIN_TEXT_200 } from 'style/constants';
import Icon from 'components/ui/Icon';
import { ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';

export const StyledDropzone = styled(Dropzone)<{ isDragOver: boolean }>`
  width: 100%;
  border: none;
  color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  z-index: 0;
  ${({ isDragOver }) =>
    isDragOver &&
    css`
      background: ${ADMIN_SURFACE_2};
      height: 265.49px;
    `};
`;

export const UploadLabel = styled.p`
  ${ADMIN_TEXT_BODY_S_BOLD}
  color: ${ADMIN_TEXT_200};
  & > a {
    color: ${ADMIN_ACCENT_PRIMARY};
    cursor: pointer;
  }
`;

export const UploadLableError = styled.p`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${ADMIN_ALERT_ERROR};
`;

export const StyledIcon = styled(Icon)`
  width: 52px;
  height: 35.49px;
  margin-right: 20px;

  /* grab last svg rect not first child */
  & svg rect:not(:first-child) {
    fill: ${ADMIN_ACCENT_PRIMARY};
  }
`;

export const WarningIcon = styled(Icon).attrs(() => ({
  name: 'warning',
}))`
  margin-right: 6.27px;
  & svg {
    width: 11.45px;
    height: 8.76px;
  }
  & svg path {
    fill: ${ADMIN_ALERT_ERROR};
  }
`;
