import { IExternalLink, INavigationParent } from 'models/INavigation';

const INITIAL_PADDING = -40; // Since we use flex gap, extremities are not counted
const INITIAL_SPACING = 200; // Since we have 100px horizontal padding + extra margin to keep the nav not too close to the right tab.
const HORIZONTAL_SIZE_OF_EACH_CHAR = 7;
const EACH_PADDING_SIZE = 55;
const LINKS_DIVIDER_SIZE = 80;

export const calculateMaxAmountOfNavItems = (
  sizeOfEachParentInPx: number[],
  parentContainerWidth: number,
  hasLinks: boolean = false,
): number => {
  let totalSize = hasLinks
    ? LINKS_DIVIDER_SIZE + INITIAL_SPACING
    : INITIAL_SPACING;
  let padding = INITIAL_PADDING;
  for (let i = 0; i < sizeOfEachParentInPx.length; i++) {
    totalSize += sizeOfEachParentInPx[i];
    padding += EACH_PADDING_SIZE;
    if (totalSize + padding > parentContainerWidth) {
      return i;
    }
  }
  return sizeOfEachParentInPx.length;
};

export const calculateSizeOfEachParentGroupInPx = (
  parents: INavigationParent[],
  links: IExternalLink[],
) => {
  const sizeOfEachParentInPx: number[] = [];
  if (parents.length > 0) {
    for (const parent of parents) {
      const parentSize = parent.name.length * HORIZONTAL_SIZE_OF_EACH_CHAR;
      sizeOfEachParentInPx.push(parentSize);
    }
  }
  if (links.length > 0) {
    for (const link of links) {
      const linkSize = link.name.length * HORIZONTAL_SIZE_OF_EACH_CHAR;
      sizeOfEachParentInPx.push(linkSize);
    }
  }
  return sizeOfEachParentInPx;
};

export const OTHERS_ID = 'others-nav-parent';
