import styled, { css } from 'styled-components';
import DraggableModal from 'components/modals/DraggableModal';
import { CloseButton } from 'components/admin2/ui/Button';
import { ADMIN_SURFACE_4, ADMIN_TEXT_100 } from 'style/constants';
import { ADMIN_TEXT_TITLE_S } from 'style/design-system/textStyles';

export const StyledDraggableModal = styled(DraggableModal)<{ draggableModalCss?: ReturnType<typeof css> | string; }>`
  ${({ draggableModalCss: style }) => style}
`;

export const Modal = styled.div<{
  itemSpacing: string;
}>`
  display: flex;
  flex-direction: column;
  cursor: inherit;
  gap: ${({ itemSpacing }) => itemSpacing};
  z-index: 100;
  width: 375px;
  padding: 30px;
  background: ${ADMIN_SURFACE_4};
  box-shadow: 0px 19px 47px rgba(0, 0, 0, 0.37);
  border-radius: 10px;
`;

export const ModalTitle = styled.span`
  ${ADMIN_TEXT_TITLE_S}
  color: ${ADMIN_TEXT_100};
`;

export const StyledCloseButton = styled(CloseButton).attrs({
  containerCss: css`
    position: absolute;
    top: 8px;
    right: 8px;
    & span {
      background-color: ${ADMIN_SURFACE_4};
    }
  `,
})``;
