import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CardCta from 'components/cards/CardCta';
import CardImage from 'components/cards/CardImage';
import { SURFACE_3, TEXT_100 } from 'style/constants';
import {
  TEXT_H5_ALT,
} from 'style/mixins';

const StyledCard = styled(CardImage)`
  padding: 14px 2px 2px 2px;
`;

const ChatBubble = styled.div`
  margin-bottom: 11px;
  background-color: ${SURFACE_3};
  color: ${TEXT_100};
  max-height: 80px;
  min-height: 28px;
  min-width: 96px;
  border-radius: 10px;
  padding: 14px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: -11px;
    left: 18px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 11px 10px 0 10px;
    border-color: ${SURFACE_3} transparent transparent transparent;
  }
`;

const Text = styled.div`
  ${TEXT_H5_ALT}
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 4px;
  margin-right: 4px;
`;

const Ellipsis = styled.div`
  display: flex;
  opacity: 0.7;
  margin: 6px 20px 8px 20px;
  & ${Dot} {
    background: ${SURFACE_3};
  }
`;

// TODO: RENDERER CTA
export default class MessageCard extends React.Component {
  static propTypes = {
    isCollapsed: PropTypes.bool,
    onDismiss: PropTypes.func.isRequired,
    renderer: PropTypes.shape({
      cta: PropTypes.object,
      ctaRenderer: PropTypes.object,
      message: PropTypes.string,
      title: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    isCollapsed: false,
  };

  render() {
    const { isCollapsed, renderer, onDismiss } = this.props;

    const hasCta = Boolean(renderer.ctaRenderer);

    return (
      <StyledCard isCollapsed={isCollapsed} onClose={onDismiss} responsive>
        <ChatBubble>
          <Text data-testid="messageCardMessage">{renderer.message}</Text>
        </ChatBubble>
        <Ellipsis>
          <Dot /><Dot /><Dot />
        </Ellipsis>
        {hasCta && <CardCta cardRenderer={renderer} data-testid="messageCardButton" />}
      </StyledCard>
    );
  }
}
