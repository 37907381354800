import React, { useMemo } from 'react';
import { ControlsProps } from 'components/Video/video-controls/VideoOverlay/interfaces';
import { FULLSCREEN_TOGGLE_ICON } from 'injection-classes';
import { canFullscreen } from '../fullScreenUtils';
import {
  FullscreenButton,
} from './styles';

export default function FullscreenToggleButton({
  className = '',
  isFullscreen,
  isMobileLayout,
  toggleFullscreen,
}: ControlsProps) {
  const fullClassName = useMemo(() => (
    `${className} ${FULLSCREEN_TOGGLE_ICON}`
  ), [className]);

  // not eligible
  if (!canFullscreen()) {
    return null;
  }

  return (
    <FullscreenButton
      className={fullClassName}
      name={isFullscreen ? 'exitFullscreen' : 'fullscreen'}
      onClick={toggleFullscreen}
      tooltipAlign="left"
      tooltipKey={isFullscreen ? 'ACTION_DISABLE_FULLSCREEN' : 'ACTION_FULLSCREEN'}
      tooltipPosition={isMobileLayout ? 'bottom' : 'top'}
    />
  );
}
