import PropTypes from 'prop-types';
import React from 'react';

export default class RendererProvider extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    id: PropTypes.string.isRequired, // Used by mapStateToProps
    // eslint-disable-next-line react/forbid-prop-types
    renderer: PropTypes.object, // Passed in via mapStateToProps
  };

  static defaultProps = {
    renderer: null,
  };

  render() {
    const {
      children: render,
      renderer,
    } = this.props;

    return render(renderer);
  }
}
