import styled, { css, IStyledProps } from 'styled-components';
import Icon from 'components/ui/Icon';
import { ADMIN_HIGHLIGHT_PRIMARY, ADMIN_HIGHLIGHT_SECONDARY, ADMIN_SURFACE_6, ADMIN_TEXT_100 } from 'style/constants';
import { ADMIN_TEXT_LABEL_M_MEDIUM } from 'style/design-system/textStyles';
import { ELLIPSIS } from 'style/mixins';

export const Container = styled.div<{ isParent: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 33px;
  padding: 4px 6px;

  ${({ isParent }) => !isParent && css`
    margin-left: 19px;
    padding-left: 14px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: -3px;
      bottom: -3px;
      border-left: 1px solid ${ADMIN_SURFACE_6};
      width: 0;
    }
  `};
`;

export const ItemName = styled.div<{ maxLength: number }>`
  color: ${ADMIN_TEXT_100};
  ${ADMIN_TEXT_LABEL_M_MEDIUM};
  margin-right: 6px;

  ${ELLIPSIS}
  max-width: ${({ maxLength }) => maxLength}ch;
`;
