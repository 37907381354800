import React from 'react';
import { useSelector } from 'react-redux';
import { NAVIGATION_BLOCK_SITE_NAVIGATION_EXTERNAL_LINKS_FORM_ID } from 'global-ids';
import { useAdminTranslation } from 'hooks/use-translation';
import { getDefaultNavigation } from 'services/navigationv2';
import ExternalLinksInputs from '../ExternalLinksInputs';
import useExternalLinksInputs from '../ExternalLinksInputs/useExternalLinksInputs';
import { AbsoluteWrapper, AddButton, Container, Content } from './styles';
import { LinkIcon } from './icons';
import ApplyNavigationButton from '../ApplyNavigationButton';
import useToggle from 'hooks/use-toggle';

const SiteNavigationExternalLinksForm = () => {
  const { t } = useAdminTranslation();
  const [isCreatingNewLinks, toggle] = useToggle(false);
  const defaultNavigation = useSelector(getDefaultNavigation);

  const { linkName, linkUrl, linkUrlStatus, setLinkName, handleLinkUrlChange, isSaveButtonDisabled } = useExternalLinksInputs();

  return (
    <Container
      id={NAVIGATION_BLOCK_SITE_NAVIGATION_EXTERNAL_LINKS_FORM_ID}
      data-testid={NAVIGATION_BLOCK_SITE_NAVIGATION_EXTERNAL_LINKS_FORM_ID}
    >
      <Content>
        {isCreatingNewLinks && (
          <AbsoluteWrapper>
            <ExternalLinksInputs
              linkName={linkName}
              linkUrl={linkUrl}
              linkUrlStatus={linkUrlStatus}
              setLinkName={setLinkName}
              handleLinkUrlChange={handleLinkUrlChange}
              closeItself={toggle}
              isSaveButtonDisabled={isSaveButtonDisabled}
              navigationId={defaultNavigation._id!}
            />
          </AbsoluteWrapper>
        )}
        <AddButton onClick={toggle}>
          <LinkIcon />
          {t('ADD_EXTERNAL_LINK')}
        </AddButton>
        <ApplyNavigationButton />
      </Content>
    </Container>
  );
};

export default SiteNavigationExternalLinksForm;
