import { INavigationParent } from 'models/INavigation';
import { cloneDeep } from 'lodash';

const doesMatch = (data: string, query: string): boolean => (data.toLowerCase().includes(query));

export const siteStructureSearch = (navigationParents: INavigationParent[], query: string): INavigationParent[] => {
  const result: INavigationParent[] = [];
  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < navigationParents.length; i++) {
    const parent = cloneDeep(navigationParents[i]);

    // push parent and all its children if folder matched query
    if (doesMatch(parent.name, query) && parent.type === 'folder') {
      result.push(parent);
      continue;
    }

    // push parent and filter its children based on query
    if (doesMatch(parent.name, query)) {
      // filter children for matched parent nav
      const { children } = parent;
      parent.children = children.filter((child) => doesMatch(child.name, query));
      result.push(parent);
      continue;
    }

    //  filter against children and show parent even if parent doesn't match query
    const filteredChildren = parent.children.filter((child) => doesMatch(child.name, query));
    if (!filteredChildren.length) continue;
    parent.children = filteredChildren;
    result.push(parent);
  }
  return result;
};
