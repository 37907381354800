import React, { useEffect, useState } from 'react';
import { MobileWrapper } from './styles';

interface ClickPreventerProps {
  showControls?: boolean;
}

export default function ClickPreventer({ showControls = false }: ClickPreventerProps) {
  const [isDisabled, setDisabled] = useState(false);

  const disable = () => {
    setDisabled(true);
  };

  useEffect(() => {
    setDisabled(showControls);
  }, [showControls]);

  return (
    <MobileWrapper disabled={isDisabled} onClick={disable} />
  );
}
