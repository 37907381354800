import React from 'react';
import AjaxFetch from 'components/core/AjaxFetch';
import { useSelector, useDispatch } from 'react-redux';
import { PAGE_SERVICE_BASE_URL, PAGE_SERVICE_V3_BASE_URL } from 'config';
import { IObject, PageType } from 'models';
import { getPrimaryToken } from 'services/auth/selectors';
import { removeKeys } from 'services/app-router';
import { isManagingPages } from 'services/admin/selectors';
import { setAdminMode, setActiveAction } from 'services/admin/actions';
import { getSiteId } from 'services/app/selectors';
import { getDevicePermanentId } from 'services/device/selectors';
import { camelify } from 'shared/string-utils';
import { getObjectSlug } from 'components/regions/Content';
import { getActiveSiteFeatures, getObject } from 'services/app/selectors/common';
import { Feature } from 'services/feature-gate';
import RealtimePage from 'components/core/RealtimePage';
import useSetPage from 'hooks/use-set-page';

// fetch page by slug and load realtime updates for admin views
// regions/Content loads page for viewers
export default function PageFetcher() {
  const dispatch = useDispatch();
  const featureGates = useSelector(getActiveSiteFeatures);
  const currentPage: IObject = useSelector(getObject);
  const primaryToken = useSelector(getPrimaryToken);
  const siteId = useSelector(getSiteId);
  const deviceId = useSelector(getDevicePermanentId);
  const isManagingChannelContent = useSelector(isManagingPages);
  const targetObjectSlug = useSelector(getObjectSlug);
  const setPage = useSetPage();
  const slug = targetObjectSlug || currentPage.slug;

  const pageServiceURL = featureGates[Feature.PAGES_V3] ?
      PAGE_SERVICE_V3_BASE_URL : PAGE_SERVICE_BASE_URL;

  const handleViewRedirect = (page) => {
    if (page?.type === PageType.LANDING && isManagingChannelContent) {
      dispatch(removeKeys(['admin']));
      dispatch(setAdminMode(null));
      dispatch(setActiveAction(null));
    }
  };

  const onPageResponse = (response: { channel: IObject }) => {
    const { channel } = response;
    if (channel && currentPage._id !== channel._id) {
      setPage(channel, true);
      handleViewRedirect(channel);
    }
  };

  const handlePageUpdate = (page?: IObject | null, loaded?: boolean) => {
    if (page) {
      setPage(page, loaded || false);
      handleViewRedirect(page);
    }
  };

  return currentPage.created ? (
    <>
      <AjaxFetch
        headers={{
          Authorization: `Bearer ${primaryToken}`,
          'x-maestro-client-id': siteId,
          'x-maestro-device-id': deviceId,
        }}
        onValue={onPageResponse}
        transform={camelify}
        url={`${pageServiceURL}/content/slug/${slug}`}
      />
      {currentPage?._id && (
        <RealtimePage
          id={currentPage._id}
          onValue={handlePageUpdate}
        />
      )}
    </>
  ) : null;
}
