import styled from 'styled-components';
import LabelToggle from 'components/admin2/ui/LabelToggle';
import {
  ADMIN_TEXT_200,
} from 'style/constants';
import { ADMIN_TEXT_LABEL_M_BOLD } from 'style/design-system/textStyles';

export const StyledLabelToggle = styled(LabelToggle).attrs({
  labelKey: 'ADMIN_LABEL_ENABLE_GATE',
  labelPadding: '10px',
  noSpaceBetween: true,
  styleVariant: 'squared',
  width: 'min-content',
})`
  & label div {
    margin-right: 10px;
    ${ADMIN_TEXT_LABEL_M_BOLD}
    color: ${ADMIN_TEXT_200};
  }
` as typeof LabelToggle;
