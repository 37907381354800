import styled from 'styled-components';
import Dropdown from 'components/admin2/ui/Dropdown';
import TextInput from 'components/admin2/TextInput';
import { ADMIN_ALERT_CONFIRMATION, ADMIN_ALERT_ERROR, ADMIN_TEXT_200, ADMIN_TEXT_300 } from 'style/constants';
import ErrorLabelComponent from 'components/admin2/ui/ErrorLabel';
import { HTTPS_PREFIX_ID } from 'global-ids';

export const StyledFontSizeDropdown = styled(Dropdown).attrs({
  padding: '0',
  labelPadding: '0 0 10px 0',
})`
  width: 70px;
` as typeof Dropdown;

export const URLInput = styled(TextInput)<{ valid: boolean; }>`
  & input, #${HTTPS_PREFIX_ID} {
    color: ${({ valid, value }) => {
      if (!value) {
        return ADMIN_TEXT_300;
      }

      return valid ? ADMIN_ALERT_CONFIRMATION : ADMIN_ALERT_ERROR;
    }};
  }
  & input::placeholder {
    color: ${ADMIN_TEXT_300};
  }
  & input::-webkit-input-placeholder { /* WebKit browsers */
    color:  ${ADMIN_TEXT_300};
  }

  & input::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:  ${ADMIN_TEXT_300};
    opacity: 1; /* Fix for Firefox */
  }
`;

export const ErrorLabel = styled(ErrorLabelComponent)`
  margin-top: 0;
`;


export const ButtonHeightGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 8px;
`;

export const ButtonHeightDropdown = styled(Dropdown).attrs(props => ({
  labelColor: ADMIN_TEXT_200(props),
  padding: '0',
  labelPadding: '0 0 10px 0',
}))`` as typeof Dropdown;
