import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setVideoSize } from 'services/video';
import {
  StyledAspectRatioVideo,
  VideoContentWrapper,
  VideoObjectWrapper,
  StyledQuickEditOverlay,
} from './styles';
import {
  isChromaKeyMode as isChromaKeyModeSelector,
  isVideoEmbed,
  isTheaterEmbed,
} from 'services/user-layout';
import { isMobileLayout } from 'services/device';
import useResizeObserver from 'hooks/use-resize-observer';
import {
  getShowMobileChannelSelector,
  isChannelSelectorSidebarButtonVisible,
} from 'services/app/selectors';
import { setActiveAction } from 'services/admin';
import { ActionKey } from 'services/admin/constants';
import { setAdminBarSubMenuQueue } from 'services/admin/actions';
import { OverlayRenderers } from '../video-content/OverlayRenderers';
import { VideoPlayback } from '../video-content/VideoPlayback';
import { MobileCountdown } from '../video-content/MobileCountdown';
import MaestroLivestreamPreview from 'components/admin-bridge/MaestroLivestreamPreview';
import { PlayerProps } from '../video-player/Player/interfaces';

export const VIDEO_OVERLAY_TRANSITION_MS = 250;

export type IVideoObjectProps = {
  className?: string;
  loop?: boolean;
} & (
  | {
      streamPreviewMode: true;
    }
  | ({
      streamPreviewMode?: false;
    } & Pick<
      PlayerProps,
      'video' | 'scheduledVideoStartTime' | 'spot' | 'onVideoComplete'
    >)
);

export const VideoObject = (props: IVideoObjectProps) => {
  const dispatch = useDispatch();
  const isChromaKey = useSelector(isChromaKeyModeSelector);
  const showMobileChannelSelector = useSelector(getShowMobileChannelSelector);
  const isMobile = useSelector(isMobileLayout);
  const channelSelectorVisible = useSelector(
    isChannelSelectorSidebarButtonVisible,
  );
  const videoEmbed = useSelector(isVideoEmbed);
  const theaterEmbed = useSelector(isTheaterEmbed);

  const [videoRef, videoSize] = useResizeObserver();

  useEffect(() => {
    dispatch(setVideoSize(videoSize));

    return () => {
      dispatch(setVideoSize({ width: 0, height: 0 }));
    };
  }, [videoSize]);

  const redirectToPages = React.useCallback(() => {
    dispatch(setAdminBarSubMenuQueue(['main']));
    dispatch(setActiveAction(ActionKey.content));
  }, []);

  const shouldHide = isMobile && showMobileChannelSelector;

  const video = props.streamPreviewMode ? undefined : props.video;

  return (
    <VideoObjectWrapper hidden={shouldHide}>
      <StyledQuickEditOverlay
        testId="editModeVideoComponent"
        isVideoEmbed={videoEmbed}
        isTheaterEmbed={theaterEmbed}
        channelSelectorVisible={channelSelectorVisible}
        titleKey="ADMIN_LABEL_VIDEO"
        onEdit={redirectToPages}
      >
        <StyledAspectRatioVideo>
          <VideoContentWrapper
            ref={videoRef}
            data-content-id={video?._id ?? 'stream-preview-player-wrapper'}
            isChromaKeyMode={isChromaKey}
            className={props.className}
          >
            {props.streamPreviewMode ? (
              <MaestroLivestreamPreview />
            ) : (
              <>
                <MobileCountdown />
                <OverlayRenderers />
                <VideoPlayback
                  width={String(videoSize.width)}
                  video={props.video}
                  scheduledVideoStartTime={props.scheduledVideoStartTime}
                  spot={props.spot}
                  onVideoComplete={props.onVideoComplete}
                  loop={props.loop}
                />
              </>
            )}
          </VideoContentWrapper>
        </StyledAspectRatioVideo>
      </StyledQuickEditOverlay>
    </VideoObjectWrapper>
  );
};
