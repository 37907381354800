import React, {
  forwardRef,
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { DefaultTheme, ThemeProps } from 'styled-components';
import OptionalTranslated from 'components/admin2/ui/OptionalTranslated';
import hash from 'json-stable-stringify';
import { css } from 'styled-components';
import { PositionKey } from 'components/ui/Tooltip';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device/selectors';
import { StyledTooltip, TooltipContainer } from './styles';
import { WithExtraProps } from 'utils';
import type { TranslationKey } from 'hooks/use-translation';

export type { PositionKey } from 'components/ui/Tooltip';

export type withTooltipProps = PropsWithChildren<{
  admin?: boolean;
  alwaysVisible?: boolean;
  className?: string;
  containerCss?: ReturnType<typeof css> | string;
  disable?: boolean;
  tooltip?: string;
  tooltipAlign?: PositionKey;
  tooltipArrowCss?: ReturnType<typeof css> | string;
  tooltipBackground?: string | ((props: ThemeProps<DefaultTheme>) => string);
  tooltipCss?: ReturnType<typeof css> | string;
  tooltipKey?: TranslationKey;
  tooltipPosition?: PositionKey;
  tooltipSeparation?: number;
  tooltipTextColor?: ReturnType<typeof css> | string | ((props: ThemeProps<DefaultTheme>) => string);
  tooltipWrapperCss?: ReturnType<typeof css> | string;
  useAbsolutePosition?: boolean;
}>;

export default function withTooltip<T>(
  wrappedComponent: T,
  {
    admin: adminFallback,
    containerCss: containerCssFallback,
    tooltip: tooltipFallback,
    tooltipAlign: tooltipAlignFallback,
    tooltipBackground: tooltipBackgroundFallback,
    tooltipKey: tooltipKeyFallback,
    tooltipPosition: tooltipPositionFallback = 'top',
    tooltipSeparation: tooltipSeparationFallback = 12,
    tooltipTextColor: tooltipTextColorFallback,
    tooltipWrapperCss: tooltipWrapperCssFallback,
    tooltipArrowCss: tooltipArrowCssFallback,
    tooltipCss: tooltipCssFallback,
    useAbsolutePosition: useAbsolutePositionFallback,
    disable: disableDefault = false,
  }: withTooltipProps = {},
) {
  const WrappedComponent = styled(wrappedComponent as any)`
    ${({ css: componentCss }: any) => componentCss}
  `;

  return forwardRef(
    (
      {
        children,
        admin = adminFallback,
        className,
        containerCss = containerCssFallback,
        tooltip = tooltipFallback,
        tooltipAlign = tooltipAlignFallback,
        tooltipBackground = tooltipBackgroundFallback,
        tooltipKey = tooltipKeyFallback,
        tooltipPosition = tooltipPositionFallback,
        tooltipSeparation = tooltipSeparationFallback,
        tooltipTextColor = tooltipTextColorFallback,
        tooltipWrapperCss = tooltipWrapperCssFallback,
        tooltipArrowCss = tooltipArrowCssFallback,
        tooltipCss = tooltipCssFallback,
        useAbsolutePosition = useAbsolutePositionFallback,
        alwaysVisible,
        disable = disableDefault,
        ...props
      }: PropsWithChildren<withTooltipProps>,
      ref,
    ) => {
      const [containerDimensions, setContainerDimensions] = useState(new DOMRect());
      const containerRef = useRef<HTMLDivElement>(null);
      const isMobile = useSelector(isMobileLayout);

      const hasTooltip = useMemo(() => {
        return !!(!isMobile && (tooltip || tooltipKey));
      }, [tooltip, tooltipKey, isMobile]);

      const updateContainerDimensions = useCallback(() => {
        const dimensions = containerRef.current?.getBoundingClientRect();
        if (!dimensions || hash(dimensions) === hash(containerDimensions)) {
          return;
        }
        setContainerDimensions(dimensions);
      }, [containerRef.current, hash(containerDimensions)]);

      const wrappedComponentProps = {
        className,
        css: hasTooltip ? undefined : containerCss,
        ...props,
      };

      if (hasTooltip) {
        return (
          <TooltipContainer
            ref={containerRef}
            onMouseEnter={useAbsolutePosition ? undefined : updateContainerDimensions}
            alwaysVisible={alwaysVisible}
            disable={disable}
            containerCss={containerCss}
          >
            <WrappedComponent
              {...wrappedComponentProps}
              ref={ref}
            >
              {children}
            </WrappedComponent>
            <OptionalTranslated
              admin={admin}
              align={tooltipAlign}
              background={tooltipBackground}
              containerDimensions={containerDimensions}
              component={StyledTooltip}
              position={tooltipPosition}
              separation={tooltipSeparation}
              stringKey={tooltipKey}
              textColor={tooltipTextColor}
              tooltipArrowCss={tooltipArrowCss}
              tooltipWrapperCss={tooltipWrapperCss}
              tooltipCss={tooltipCss}
              useAbsolutePosition={useAbsolutePosition}
            >
              {tooltip}
            </OptionalTranslated>
          </TooltipContainer>
        );
      }

      return (
        <WrappedComponent
          {...wrappedComponentProps}
          ref={ref}
        >
          {children}
        </WrappedComponent>
      );
    },
  ) as WithExtraProps<T, withTooltipProps>;
}
