import React from 'react';
import { PLAY_PAUSE } from 'injection-classes';
import { ControlsProps } from 'components/Video/video-controls/VideoOverlay/interfaces';
import { PlayPauseButton } from './styles';

export default function PlayPauseButtonComponent({
  className = '',
  isPlaying,
  togglePlay,
}: ControlsProps) {
  return (
    <PlayPauseButton
      onClick={togglePlay}
      className={`${className} ${PLAY_PAUSE}`}
      name={isPlaying ? 'pause' : 'playLarge'}
      tooltipAlign="right"
      tooltipKey={`ADMIN_LABEL_${isPlaying ? 'PAUSE' : 'PLAY'}`}
    />
  );
}
