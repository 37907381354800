import React, { ReactNode, useEffect, useMemo } from 'react';
import useRealtimeDocument from 'hooks/use-realtime-document';
import hash from 'json-stable-stringify';
import { useSelector } from 'react-redux';
import { getActiveSiteFeatures } from 'services/app/selectors/common';
import { Feature } from 'services/feature-gate';

type OnValueFn<T> = ((doc?: T | null, loaded?: boolean) => unknown);
interface IProps<T> {
  children?: ReactNode | OnValueFn<T>;
  id?: string | null;
  onValue?: OnValueFn<T>;
}

export default function RealtimePage<T>({
  children = null,
  id = null,
  onValue,
}: IProps<T>) {
  const featureGates = useSelector(getActiveSiteFeatures);
  const pageCollection = featureGates[Feature.PAGES_V3] ? 'pages' : 'objects';
  const [doc, loaded] = useRealtimeDocument<T>(pageCollection, id);

  useEffect(() => {
    onValue?.(doc, loaded);
  }, [hash(doc), loaded, onValue]);

  return (
    <>
      {(typeof children === 'function' ? children(doc, loaded) : children) || null}
    </>
  );
}
