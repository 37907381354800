import styled from 'styled-components';
import { SURFACE_4, TEXT_200 } from 'style/constants';
import { TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';

export const Container = styled.div`
  border-top: 1px solid ${SURFACE_4};
  padding-top: 30px;
  padding-bottom: 20px;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  gap: 8px;
`;

export const Name = styled.span`
  color: ${TEXT_200};
  ${TEXT_BODY_S_MEDIUM};
  white-space: normal;
  word-wrap: break-word;
  text-align: left;
`;

export const Row = styled.div`
  margin-top: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
