import { useNavigation } from 'hooks/use-navigation';
import React, { createContext, useContext } from 'react';

type Context = ReturnType<typeof useNavigation> & {
  floatMenuId: string | null;
  setFloatMenuId: (id: string | null) => void;
};

const NavigationBarContext = createContext<Context | null>(null);

type NavigationBarProviderProps = React.PropsWithChildren<{
  navigationId: string;
}>;

export const NavigationBarProvider: React.FC<NavigationBarProviderProps> = ({ navigationId, children }) => {
  const [floatMenuId, setFloatMenuId] = React.useState<string | null>(null);
  const { getPathBySlug, getSlugById, navigationData } = useNavigation(navigationId);

  const value: Context = React.useMemo(() => {
    return {
      navigationData,
      getPathBySlug,
      getSlugById,
      floatMenuId,
      setFloatMenuId,
    };
  }, [navigationData, getPathBySlug, floatMenuId, setFloatMenuId]);

  return (
    <NavigationBarContext.Provider value={value}>
      {children}
    </NavigationBarContext.Provider>
  );
};

export const useNavigationBarContext = () => {
  const context = useContext(NavigationBarContext);
  if (!context) {
    throw new Error('useNavigationBarContext must be used within a NavigationBarContext.Provider');
  }

  return context;
};

export default NavigationBarProvider;
