import { SURFACE_2, TEXT_100 } from 'style/constants';
import styled, { css } from 'styled-components';
import { IProductImageProps } from '.';
import { IMAGE_CSS } from 'components/page-blocks/Shopify/ProductCard/styles';
import { AspectRatio } from 'components/page-blocks/Shopify/utils';

export const ImageContainer = styled.div<{
  source: IProductImageProps['source'];
}>`
  position: relative;
  background: ${SURFACE_2};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;

  ${({ source }) => source === 'CartProduct' && css`
    min-width: 94px;
    width: 94px;
    height: fit-content;
    border-radius: 10px;
  `};

  ${({ source }) => source === 'ProductDetail' && css`
    border-radius: 10px;
    margin-bottom: 20px;
  `};

  ${({ source }) => source === 'ProductCard' && css`
    cursor: pointer;
  `};
`;

export const Image = styled.img<{
  aspectRatio: AspectRatio;
}>`
  ${IMAGE_CSS}
`;

export const NoImageText = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 21.09px;
  color: ${TEXT_100};
`;
