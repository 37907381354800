// tslint:disable no-console
import { forceMute } from 'services/video/selectors';
import {
  IVideoPlayback,
  ClosedCaptionSetting,
  PlayerSpot,
  VideoMIMETypes,
  HighFrequencyVideoPlaybackData,
} from 'services/video/interfaces';
import { getParentDomains } from 'services/app/selectors';
import {
  isEditMode,
  isLivestreamPreviewActive,
} from 'services/admin/selectors';
import { getParentOrigin } from 'services/iframe/selectors';
import { REFERER_URL } from 'config';
import { getFacebookId } from 'services/socials/selectors';
import { isChromaKeyMode } from 'services/user-layout/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { IVideo, PlayerTypes, VideoTypes } from 'models';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ReactPlayerProps } from 'react-player';
import { showTextModal } from 'services/modals';
import { getFileExtensionFromUrl } from 'url-utils';
import { initialStateVideoPlayback } from 'services/video';
import { persistenceService } from 'services/persistence';
import { useGlobalVideoContext } from 'contexts/GlobalVideoContext';

export type PlayerState = ReturnType<typeof usePlayerState>;

export type PlayerStateArgs = {
  onVideoComplete?: () => void;
  scheduledVideoStartTime: number | null;
  spot: PlayerSpot;
  video: IVideo;
};

/**
 * This hook is private to the VideoPlayer component
 */
export default function usePlayerState({
  scheduledVideoStartTime,
  spot,
  video,
  onVideoComplete,
}: PlayerStateArgs) {
  const dispatch = useDispatch();
  const volumeTimeout = useRef<any>();

  const { muted: muteByDefault, setMuted: setMutedByDefault } =
    useGlobalVideoContext();

  const isLivestream = video.type === VideoTypes.livestream;

  const isLive = isLivestream || scheduledVideoStartTime !== null;

  const isFilePlayer = [
    PlayerTypes.file,
    PlayerTypes.livestream,
  ].includes(video?.player!);

  const parentDomains = useSelector(getParentDomains);

  const [content, setContent] = useState({
    offset: scheduledVideoStartTime ? getOffset(scheduledVideoStartTime) : null,
    durationSeconds: undefined as number | undefined,
    videoId: video._id,
  });

  const [playback, setPlayback] = useState<IVideoPlayback>(
    initialStateVideoPlayback(muteByDefault),
  );

  const highFrequencyPlaybackState = useRef<HighFrequencyVideoPlaybackData>({
    loaded: 0,
    loadedSeconds: 0,
    played: 0,
    playedSeconds: 0,
  });

  // Don't need/shouldn't use an effect for resetting state when hot swapping `video`
  // https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
  if (video._id !== content.videoId) {
    Object.assign(highFrequencyPlaybackState.current, {
      loaded: 0,
      loadedSeconds: 0,
      played: 0,
      playedSeconds: 0,
    });

    setContent({
      offset: null,
      durationSeconds: undefined,
      videoId: video._id,
    });

    setPlayback((prev) => initialStateVideoPlayback(prev.muted));
  }

  const seekVideo = useCallback((offset: number) => {
    setContent((prev) => ({ ...prev, offset }));
  }, []);

  const playerState = {
    cc: playback.cc
      ? ClosedCaptionSetting.ENABLED
      : ClosedCaptionSetting.DISABLED,
    facebookId: useSelector(getFacebookId) as string,
    forceMute: useSelector(forceMute),
    hasError: (content as any).hasError as boolean,
    hideMuteWarning: video.player === PlayerTypes.iframe,
    isChromaKeyMode: useSelector(isChromaKeyMode),
    isEditMode: useSelector(isEditMode),
    isLivestreamPreview: useSelector(isLivestreamPreviewActive),
    isShowingNativeControls: !isFilePlayer,
    muted: playback.muted,
    offset: Number(content.offset),
    parentOrigin: useSelector(getParentOrigin) || REFERER_URL,
    playerType: video.player ?? PlayerTypes.file,
    playing: playback.playing,
    spot,
    video,
    volume: playback.volume,
    onPlaybackUpdate: useCallback((playState: Partial<IVideoPlayback>) => {
      setPlayback((prev) => ({
        ...prev,
        ...playState,
      }));
    }, []),
    updateHighFrequencyState: useCallback(
      ({
        loaded,
        loadedSeconds,
        played,
        playedSeconds,
      }: Partial<HighFrequencyVideoPlaybackData>) => {
        if (loaded !== undefined) {
          highFrequencyPlaybackState.current.loaded = loaded;
        }

        if (loadedSeconds !== undefined) {
          highFrequencyPlaybackState.current.loadedSeconds = loadedSeconds;
        }

        if (played !== undefined) {
          highFrequencyPlaybackState.current.played = played;
        }

        if (playedSeconds !== undefined) {
          highFrequencyPlaybackState.current.playedSeconds = playedSeconds;
        }
      },
      [],
    ),
    seekVideo,
    onVideoComplete,
  } as const;

  // keep scheduled video in sync with "realtime" position
  useEffect(() => {
    if (!scheduledVideoStartTime || !playback.playing) {
      return;
    }

    const interval = setInterval(() => {
      const newOffset = getOffset(scheduledVideoStartTime);

      if (
        Math.abs(newOffset - highFrequencyPlaybackState.current.playedSeconds) >
        5
      ) {
        seekVideo(newOffset);
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [scheduledVideoStartTime, playback, seekVideo]);

  const contentDuration = content.durationSeconds;

  // keep livestream videos no more than 5 seconds away from real time
  useEffect(
    () => {
      if (!isLivestream || !isFilePlayer || !contentDuration) {
        return;
      }

      if (Math.abs(contentDuration - highFrequencyPlaybackState.current.playedSeconds) > 5) {
        seekVideo(contentDuration);
      }
    },
    [isLivestream, isFilePlayer, contentDuration, seekVideo],
  );

  const { muted, volume } = playback;

  useEffect(() => {
    if (typeof volume === 'number' && !Number.isNaN(volume)) {
      persistenceService().write('site:volume', volume);
    }

    if (muted !== undefined) {
      persistenceService().write('site:muted', Boolean(muted));
      setMutedByDefault(muted);
    }
  }, [muted, volume]);

  const videoUrl = video.url;

  const videoMIMEType = useMemo(() => {
    try {
      const extension = getFileExtensionFromUrl(videoUrl!) || 'm3u8';
      return (
        VideoMIMETypes[extension as keyof typeof VideoMIMETypes] ||
        VideoMIMETypes.m3u8
      );
    } catch {
      return VideoMIMETypes.m3u8;
    }
  }, [videoUrl]);

  return {
    ...playerState,
    isFacebook: useMemo(() => {
      const type = playerState.playerType as any;
      return type === 'facebook_video' || type === 'facebook_live';
    }, [playerState.playerType]),
    playerClassName: isLive ? 'live' : ('onDemand' as string),
    isLive,
    videoMIMEType,
    /** # THIS VALUE IS NOT REACTIVE!
     * Please `useHighFrequencyPlaybackStateUpdates`
     * as further down the tree as possible
     */
    highFrequencyPlaybackState,
    twitchOptions: useMemo(
      () =>
        playerState.playerType === 'twitch'
          ? {
              parent: [...parentDomains],
            }
          : {},
      [playerState.playerType, parentDomains],
    ),
    duration: video.durationSeconds || content.durationSeconds || 0,
    onDuration: useCallback((duration: number) => {
      if (!duration) {
        return;
      }

      setContent((prev) => ({
        ...prev,
        durationSeconds: duration,
      }));
    }, []),
    onError: useCallback<NonNullable<ReactPlayerProps['onError']>>(
      (e, data) => {
        const hlsDetails = data?.details || '';
        console.warn(`Video player error: ${hlsDetails}`);
        console.warn(data || e);

        // Vimeo player doesn't handle error by itself
        // so we need to set content as null to avoid an infinite loop
        // Set video content as null when we have a "not found" response
        if (`${e}`.includes('was not found')) {
          dispatch(showTextModal('This video is unavailable'));
          onVideoComplete?.();
        }

        if (data?.fatal) {
          onVideoComplete?.();
        }
      },
      [playerState.playerType, playerState.video?.url, onVideoComplete],
    ),
    onPause: useCallback(() => {
      playerState.onPlaybackUpdate({
        playing: false,
      });
    }, [playerState.onPlaybackUpdate]),
    onPlay: useCallback(() => {
      playerState.onPlaybackUpdate({ playing: true });
    }, [playerState.onPlaybackUpdate, playerState.playing]),
    onVolumeChange: useCallback(
      (videoElement: HTMLVideoElement): HTMLVideoElement['onvolumechange'] => {
        return () => {
          if (playerState.muted && !videoElement.muted) {
            playerState.onPlaybackUpdate({
              muted: false,
              volume: videoElement.volume,
            });
          } else if (!playerState.muted && videoElement.muted) {
            playerState.onPlaybackUpdate({
              muted: true,
              volume: videoElement.volume,
            });
          } else {
            clearTimeout(volumeTimeout.current);
            volumeTimeout.current = setTimeout(() => {
              playerState.onPlaybackUpdate({ volume: videoElement.volume });
            }, 500);
          }
        };
      },
      [playerState.muted, playerState.onPlaybackUpdate],
    ),
  } as const;
}

const getOffset = (scheduledTime: number) =>
  Math.floor((Date.now() - scheduledTime) / 1000);
