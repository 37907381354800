import styled, { css } from 'styled-components';
import { ComponentType, ComponentProps } from 'react';
import { IconButtonBase } from 'components/ui/Button';
import {
  TEXT_500,
  TEXT_100,
  TEXT_CONTRAST_ON_ACCENT_PRIMARY,
} from 'style/constants';
import {
  SCROLL_BAR,
  desktopOnly,
  mobileOnly,
} from 'style/mixins';

type IconType = ComponentType<Omit<ComponentProps<typeof IconButtonBase>, 'name'>>;

interface StyleProps {
  dropBottom?: boolean;
  hovering?: boolean;
}

export const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > *:last-child {
    margin-top: 17px;
  }
`;

export const ShareIconButton = styled(IconButtonBase as any as IconType).attrs(props => ({
  textColor: TEXT_100(props),
  hoverBackground: TEXT_100(props),
  hoverTextColor: TEXT_500(props),
  name: 'share',
  variant: 'transparent',
}))`
  min-width: 25px;
  width: 25px;
  z-index: 1;
  & svg {
    margin-left: -1px;
    width: 14px;
    height: 14px;
    & path {
      fill: ${({ hovering }) => hovering ? TEXT_500 : TEXT_100} !important;
    }
  }
`;

export const SocialButtonsContainer = styled.div<StyleProps>`
  ${desktopOnly`
    &:hover {
      display: flex;
    }
  `}
  ${mobileOnly`
    ${SCROLL_BAR}
    max-height: 100px;
    overflow-y: auto;
    justify-content: initial;
  `}
  display: ${({ hovering }) => hovering ? 'flex' : 'none'};
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  position: absolute;
  ${({ dropBottom }) => {
    const direction = dropBottom ? 'top' : 'bottom';
    return css`
      ${direction}: 100%;
      padding-${direction}: 26px;
      margin-${direction}: -20px;
    `;
  }}
  border-radius: 5px;
  & > * {
    cursor: pointer;
    margin: 8px 0;
  }
`;

export const ShareButtonContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 99;
  margin-left: 12px;
  ${desktopOnly`
    &:hover > ${SocialButtonsContainer} {
      display: flex;
    }
    &:hover > ${ShareIconButton} {
      & svg path {
        fill: ${TEXT_CONTRAST_ON_ACCENT_PRIMARY};
      }
    }
  `}
`;
