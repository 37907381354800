import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NAVIGATION_BLOCK_CREATE_CUSTOM_NAVIGATION_ID, NAVIGATION_BLOCK_CUSTOM_NAVIGATION_ITEM_ID, NAVIGATION_BLOCK_DROPDOWN_ID, NAVIGATION_BLOCK_SITE_STRUCTURE_ID, NAVIGATION_BLOCK_CUSTOM_NAVIGATION_ITEM_ACTIVE_ID } from 'global-ids';
import hash from 'json-stable-stringify';
import { useAdminTranslation } from 'hooks/use-translation';
import INavigation from 'models/INavigation';
import { getNavigationAppliedInThePage, getNavigationSelectedInTheDropdown, getNavigations, setNavigationSelectedInTheDropdown, updateNavigation } from 'services/navigationv2';
import { Dropdown } from 'components/admin2/ui/Dropdown';
import { Container } from './styles';
import { NAV_NAME_MIN_LENGTH } from 'services/navigationv2/utils';

export type INavigationBlockDropdownOption = INavigation & {
  isApplied?: boolean;
  isEditing?: boolean;
  navName?: string;
  renameCustomNavigation?: (name: string) => void;
  setEditableCustomNavId?: () => void;
};

const NavigationBlockDropdown = React.forwardRef((_, ref) => {
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();
  const navigations = useSelector(getNavigations);
  const selectedNavigation = useSelector(getNavigationSelectedInTheDropdown);
  const appliedNavigation = useSelector(getNavigationAppliedInThePage);
  const [editableCustomNavId, setEditableCustomNavId] = useState<string | null>(null);
  const [navigationOptions, setNavigationOptions] = useState<INavigationBlockDropdownOption[]>([]);
  const applyDataTestId = (nav: INavigation) => {
    if (nav.type === 'default' ) return NAVIGATION_BLOCK_SITE_STRUCTURE_ID;
    return selectedNavigation._id === nav._id ? NAVIGATION_BLOCK_CUSTOM_NAVIGATION_ITEM_ID : NAVIGATION_BLOCK_CUSTOM_NAVIGATION_ITEM_ACTIVE_ID;
  };
  useEffect(() => {
    setNavigationOptions([
      { name: t('ADMIN_NAVIGATION_BLOCK_DROPDOWN_SELECT'), _id: '', disableSelection: true } as any,
      ...navigations.map(navigation => ({
        ...navigation,
        isEditing: editableCustomNavId === navigation._id,
        'data-testid': applyDataTestId(navigation),
        setEditableCustomNavId: () => setEditableCustomNavId((prev: string | null) => {
          if (prev === navigation._id) return null;
          return navigation._id!;
        }),
        renameCustomNavigation: (name: string) => {
          dispatch(updateNavigation({ ...navigation, name }));
        },
        isApplied: navigation._id === appliedNavigation._id,
      })),
      { name: t('ADMIN_NAVIGATION_BLOCK_DROPDOWN_CREATE_CUSTOM_NAVIGATION'), _id: '', 'data-testid':  NAVIGATION_BLOCK_CREATE_CUSTOM_NAVIGATION_ID } as any,
    ]);
  }, [hash(navigations), editableCustomNavId, appliedNavigation._id]);

  const getLabel = useCallback((option: INavigationBlockDropdownOption) => {
    if (!option) return '';
    return option.name;
  }, []);
  const updateSelectedNavigation = useCallback((option: INavigationBlockDropdownOption) => {
    if (!option?._id) return;
    dispatch(setNavigationSelectedInTheDropdown(option._id));
  }, []);

  const onInputChange = (inputValue: string) => {
    const navName = (() => {
      if (inputValue.length === 0) {
        return 'Custom Navigation';
      }
      if (inputValue.length < NAV_NAME_MIN_LENGTH) {
        return `Custom Navigation ${inputValue}`;
      }
      return inputValue;
    })();
    setNavigationOptions(prevOptions => {
      prevOptions[prevOptions.length - 1] = {
        ...prevOptions[prevOptions.length - 1],
        name: inputValue === '' ?
          t('ADMIN_NAVIGATION_BLOCK_DROPDOWN_CREATE_CUSTOM_NAVIGATION') :
          t('ADMIN_NAVIGATION_BLOCK_DROPDOWN_CREATE_CUSTOM_NAVIGATION_DYNAMIC', { navName: inputValue }),
        navName,
      };
      return prevOptions;
    });
  };

  return (
    <Container
      id={NAVIGATION_BLOCK_DROPDOWN_ID}
      data-testid={NAVIGATION_BLOCK_DROPDOWN_ID}
      ref={ref as any}
    >
      <Dropdown
        options={navigationOptions}
        valueKey="name"
        getOptionLabel={getLabel}
        value={selectedNavigation}
        onChange={updateSelectedNavigation}
        onInputChange={onInputChange}
        padding="0"
        isSearchable={true}
        isSiteNavigation={true}
        dropdownMaxHeight="25vh !important"
      />
    </Container>
  );
});

export default NavigationBlockDropdown;
