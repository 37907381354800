import { ADMIN_SCROLL_BAR } from 'style/mixins';
import styled from 'styled-components';

export const Content = styled.div`
  ${ADMIN_SCROLL_BAR}
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 3px;
`;
