import React, { PropsWithChildren } from 'react';
import { css } from 'styled-components';
import type { WithExtraProps } from 'utils';
import { WITH_PADDING } from 'css-classes';
import { PaddingWrapper } from './styles';

export type PaddedComponentProps = PropsWithChildren<{
  compact?: boolean;
  padding?: string;
  paddingWrapperCss?: ReturnType<typeof css> | string;
  width?: string;
}>;

export default function withPadding<T extends CallableOrClass>(WrappedComponent: T, defaultPadding: string = '10px 0') {
  return (({
    children,
    compact = false,
    padding = defaultPadding,
    paddingWrapperCss,
    width,
    ...props
  }: PaddedComponentProps) => {
    return compact ? (
      <WrappedComponent {...props}>
        {children}
      </WrappedComponent>
    ) : (
      <PaddingWrapper wrapperCss={paddingWrapperCss} padding={padding} width={width} className={WITH_PADDING}>
        <WrappedComponent {...props}>
          {children}
        </WrappedComponent>
      </PaddingWrapper>
    );
  }) as WithExtraProps<T, PaddedComponentProps>;
}
