import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  createContext,
  FC,
  PropsWithChildren,
} from 'react';
import { unbreakable } from 'utils/unbreakable';
import { IVideoLibraryListProps } from './VideoLibraryList';

/**
 * optimization to prevent re-rendering the entire list when a selection
 * changes
 */
const useSelectLogic = (
  videos: IVideoLibraryListProps['videos'],
  handleSelectionChanged?: IVideoLibraryListProps['handleSelectionChanged'],
) => {
  const [selectedVideos, setSelectedVideos] = useState(new Set<string>());

  useEffect(
    () => handleSelectionChanged?.(selectedVideos),
    [handleSelectionChanged, selectedVideos],
  );

  const onSelect = useCallback(
    (id: string) => setSelectedVideos(
      (prevSelected) => {
        const selected = new Set(prevSelected);

        if (!selected.has(id)) {
          selected.add(id);
        } else {
          selected.delete(id);
        }

        return selected;
      },
    ),
    [],
  );

  const onSelectAll = useCallback(
    () => setSelectedVideos(
      (prevSelected) => {
        if (prevSelected.size === videos.length) {
          const emptySet = new Set<string>();
          return emptySet;
        }

        const newValue = new Set(
          videos.map(
            (v) => v.id,
          ),
        );

        return newValue;
      },
    ),
    [videos],
  );

  const allItemsSelected = useMemo(
    () => {
      if (videos.length === 0)
        return false;

      for (const video of videos) {
        if (!selectedVideos.has(video.id))
          return false;
      }

      return true;
    },
    [videos, selectedVideos],
  );

  useEffect(() => {
    // Update selection when videos are deleted
    setSelectedVideos((selected) => {
      const newSelectedVideos = new Set<string>();
      const existingVideos = new Set(videos.map(({ id }) => id));
      selected.forEach((id) => {
        if (existingVideos.has(id)) {
          newSelectedVideos.add(id);
        }
      });
      return newSelectedVideos;
    });
  }, [videos]);

  return {
    allItemsSelected,
    onSelect,
    onSelectAll,
    selectedVideos,
  };
};

export const SelectionContext = createContext<ReturnType<typeof useSelectLogic>>(
  unbreakable(),
);

export const SelectionContextProvider: FC<PropsWithChildren<Pick<IVideoLibraryListProps, 'videos' | 'handleSelectionChanged'>>> = ({
  children,
  videos,
  handleSelectionChanged,
}) => (
  <SelectionContext.Provider
    value={useSelectLogic(videos, handleSelectionChanged)}
  >
    {children}
  </SelectionContext.Provider>
);
