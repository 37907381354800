import React from 'react';
import PropTypes from 'prop-types';
import { useAjax, useSelector } from 'hooks';
import { SITE_ID, VIDEO_SERVICE_BASE_URL } from 'config';
import { getPrimaryToken } from 'services/auth';
import GridItem from 'components/Grid/GridItem';

const TaggedVideosRow = ({ item, onEdit, onRemove, onMoveUp, onMoveDown }) => {
  const token = useSelector(getPrimaryToken);

  const sortByOption = () => {
    switch (item.sort) {
      case 'Most Views':
        return { sortBy: 'total', sortDirection: -1 };
      case 'Trending':
        return { sortBy: 'trending', sortDirection: -1 };
      case 'Most Recent':
        return { sortBy: 'created', sortDirection: -1 };
      default:
        return null;
    }
  };

  const { data, loaded } = useAjax({
    headers: {
      authorization: `Bearer ${token}`,
      'x-maestro-client-id': SITE_ID,
    },
    method: 'GET',
    params: { tagName: item.tag, ...sortByOption() },
    url: `${VIDEO_SERVICE_BASE_URL}/tags/videos`,
  });

  if (!(loaded && data)) { return null; }

  return (
    <GridItem
      doc={{
        arrayId: item.arrayId,
        members: data.map((video) => ({ id: video._id, schema: 'video' })),
        tag: item.tag,
        title: item.name,
      }}
      onEdit={onEdit}
      onMoveDown={onMoveDown}
      onMoveUp={onMoveUp}
      onRemove={onRemove}
      showBorder
    />
  );
};

TaggedVideosRow.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    sort: PropTypes.oneOf(['Most Views', 'Trending', 'Most Recent']),
    tag: PropTypes.string,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onMoveDown: PropTypes.func,
  onMoveUp: PropTypes.func,
  onRemove: PropTypes.func.isRequired,
};
export default TaggedVideosRow;
