import { VideoOverlayState } from 'components/Video/use-video-overlay-state';
import { Dispatch, SetStateAction } from 'react';

export type OverlayProps = React.PropsWithChildren<{
  className?: string;
}>;

export type ConnectedProps = VideoOverlayState & OverlayProps;

export interface ControlsProps extends ConnectedProps {
  airplayIsConnected: boolean;
  isFull: boolean;
  isFullscreen: boolean;
  setAirplayIsConnected: Dispatch<SetStateAction<boolean>>;
  setForceShowControls: Dispatch<SetStateAction<boolean>>;
  showControls: boolean;
  changeVolume(volume: number): void;
  seekTo(seconds: number): void;
  toggleFullscreen(): void;
  toggleMute(): void;
  togglePlay(): void;
}

export const defaultKeyboardControls = {
  decreaseVolume: 'ArrowDown',
  increaseVolume: 'ArrowUp',
  mute: 'KeyM',
  playPause: 'Space',
  seekBack: 'ArrowLeft',
  seekForward: 'ArrowRight',
};

export type KeyboardControls = Partial<typeof defaultKeyboardControls>;
