import React from 'react';
import { Container, Description, Name, StyledButton, Info, OptionsSelectorWrapper, ButtonText, GoBackWrapper } from './styles';
import { Price } from '../sharedStyles';
import { IProduct, SelectedOption } from '../models';
import OptionsSelector from '../sharedComponents/OptionsSelector';
import { formatPrice } from 'shared/string-utils';
import { useProductOptions } from 'hooks/ecommerce/useProductOptions';
import { useProductVariant } from 'hooks/ecommerce/useProductVariant';
import { useAdminTranslation } from 'hooks/use-translation';
import ProductImage from '../sharedComponents/ProductImage';
import { useDispatch, useSelector } from 'react-redux';
import { addCartItem, replaceEcommerceViews } from 'services/ecommerce';
import GoBack from '../GoBack';
import { TI18nKey } from 'components/i18n/TranslatedText/i18nKey';
import { getShopifyBlockData } from 'services/ecommerce/selectors';
import { DEFAULT_SHOPIFY } from 'components/page-blocks/Shopify/utils';
import useShopifyBlockAspectRatio from 'components/page-blocks/Shopify/use-shopify-block-aspect-ratio';
const PRODUCT_DETAIL_ID = 'ecommerce-product_detail';

export interface IProductComponentProps {
  currency: string;
  preSelectedOptions?: SelectedOption[];
  product: IProduct;
}

const ProductDetail: React.FC<IProductComponentProps> = ({
  currency,
  preSelectedOptions,
  product,
}) => {
  const { t } = useAdminTranslation();
  const { options, selectedOptions, handleOptionChange, selectedVariant } = useProductOptions(product, preSelectedOptions);
  const { productVariant } = useProductVariant(product, selectedOptions, selectedVariant);
  const [ctaStringKey, setCtaStringKey] = React.useState<TI18nKey>('ADMIN_ADD_TO_CART');
  const dispatch = useDispatch();
  const shopifyBlockData = useSelector(getShopifyBlockData) || DEFAULT_SHOPIFY;
  const aspectRatio = useShopifyBlockAspectRatio(shopifyBlockData);

  React.useEffect(() => {
    setCtaStringKey(productVariant?.availableForSale ? 'ADMIN_ADD_TO_CART' : 'SOLD_OUT');
  }, [productVariant?.availableForSale]);

  if (!productVariant) {
    // tslint:disable-next-line:no-console
    console.error('Ecommerce ProductDetail component cannot render because productVariant is null');
    return null;
  }

  const handleAddToCart = () => {
    dispatch(addCartItem({
      productId: product.id,
      quantity: productVariant.quantity,
      variantId: productVariant.variantId,
    }));
    dispatch(replaceEcommerceViews(['cart']));
  };

  return (
    <Container data-testid={PRODUCT_DETAIL_ID} id={PRODUCT_DETAIL_ID}>
      <GoBackWrapper><GoBack>{t('GO_BACK')}</GoBack></GoBackWrapper>
      <ProductImage
        images={productVariant.images}
        source="ProductDetail"
        isAvailableForSale={productVariant.availableForSale || false}
        name={productVariant.name}
        aspectRatio={aspectRatio}
      />
      <Name>{productVariant.name}</Name>
      {productVariant.description && <Description>{productVariant.description}</Description>}
      <Price>{formatPrice(currency, productVariant.price)}</Price>
      <Info>{t('SHIPPING_CHECKOUT_INFO')}</Info>
      <OptionsSelectorWrapper>
        <OptionsSelector
          options={options}
          selectedOptions={productVariant.selectedOptions}
          handleOptionChange={handleOptionChange}
        />
      </OptionsSelectorWrapper>
      <StyledButton
        onClick={handleAddToCart}
        disabled={!productVariant.availableForSale}
      >
        <ButtonText>{t(ctaStringKey)}</ButtonText>
      </StyledButton>
    </Container>
  );
};

export default ProductDetail;
