import { LargeButton } from 'components/admin2/ui/Button';
import { TEXT_100, TEXT_200 } from 'style/constants';
import { ADMIN_TEXT_BODY_S_REGULAR, ADMIN_TEXT_LABEL_L_BOLD } from 'style/design-system/textStyles';
import styled from 'styled-components';

export const NoContentTitle = styled.span`
  ${ADMIN_TEXT_LABEL_L_BOLD}
  color: ${TEXT_100};
  margin-bottom: 12px;
`;

export const NoContentDescription = styled.p`
  margin: 0;
  ${ADMIN_TEXT_BODY_S_REGULAR}
  color: ${TEXT_200};
`;

export const NoContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const ButtonModalWrapper = styled.div`
  padding-top: 20px;
`;

export const StyledLargeButton = styled(LargeButton)`
  padding: 0 20px;
`;
