import React, { ComponentProps, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModeTypes } from 'services/admin/constants';
import { setAdminMode } from 'services/admin/actions';
import { isLandingPageType } from 'services/app/selectors';
import Button from './Button';
import withRenderCondition from 'components/core/withRenderCondition';
import ActiveVideoMetadataModal from 'components/admin2/videoPanels/VideoLibraryModal/ActiveVideoMetadataModal';
import { usePageEditorContext } from 'contexts/PageEditorContext';

function EditChannelPage(
  props: Omit<ComponentProps<typeof Button>, 'stringKey'>,
) {
  const dispatch = useDispatch();
  const isLanding = useSelector(isLandingPageType);
  const [showVideoMetadataModal, setShowVideoMetadataModal] = useState(false);
  const { editableVideo } = usePageEditorContext();

  const [stringKey, testId] = useMemo(() => {
    if (editableVideo && isLanding) {
      return ['ACTION_EDIT_VIDEO' as const, 'editVideoButton'];
    }

    return isLanding
      ? ['ACTION_EDIT_PAGE' as const, 'editPageButton']
      : ['ACTION_EDIT_CHANNEL' as const, 'editChannelButton'];
  }, [isLanding, editableVideo]);

  const navToEditor = useCallback(() => {
    dispatch(setAdminMode(ModeTypes.EDIT));
  }, []);

  const openVideoMetadataModal = () => {
    setShowVideoMetadataModal(true);
  };

  const closeVideoMetadataModal = () => {
    setShowVideoMetadataModal(false);
  };

  return (
    <>
      <Button
        {...props}
        data-testid={testId}
        onClick={
          editableVideo && isLanding ? openVideoMetadataModal : navToEditor
        }
        stringKey={stringKey}
      />
      {showVideoMetadataModal && editableVideo && (
        <ActiveVideoMetadataModal
          video={editableVideo}
          onClose={closeVideoMetadataModal}
        />
      )}
    </>
  );
}

export default withRenderCondition(EditChannelPage);
