import styled from 'styled-components';
import Input from 'components/admin2/TextInput';
import { ADMIN_ACCENT_PRIMARY, ADMIN_SURFACE_1, ADMIN_SURFACE_3, ADMIN_SURFACE_4, ADMIN_SURFACE_6, ADMIN_TEXT_100, ADMIN_TEXT_300 } from 'style/constants';
import { ADMIN_TEXT_LABEL_M_MEDIUM, ADMIN_TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';
import { Button } from 'components/admin2/ui/Button';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
  background: ${ADMIN_SURFACE_4};
`;

export const FadingOverlay = styled.div<{ height: number }>`
  width: 100%;
  height: ${({ height }) => height || 30}px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), ${ADMIN_SURFACE_4});
    pointer-events: none;
  }
`;

export const AddButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;
  padding: 12px 24px 12px 16px;
  gap: 8px;
  border: 1px solid ${ADMIN_SURFACE_6};
  background-color: transparent !important;
  width: 100%;
  margin-top: 5px;

  &:hover {
    background-color: transparent !important;
    border: 1px solid ${ADMIN_ACCENT_PRIMARY};
  }

  color: ${ADMIN_TEXT_100};
  ${ADMIN_TEXT_LABEL_M_MEDIUM}

`;

export const LinkNameInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 34px;
  max-height: 34px;
  padding: 0px 10px 0px 6px;
  border-radius: 4px 4px 0 0;
  gap: 4px;
  background: ${ADMIN_SURFACE_3};
`;

export const NameInput = styled(Input)`
  color: ${ADMIN_TEXT_100};
  ${ADMIN_TEXT_LABEL_M_MEDIUM};
  height: 34px;
  padding: 0;
`;

export const LinkURLInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
  align-items: center;
  align-self: stretch;
  min-height: 34px;
  z-index: 2;
  border-radius: 0 0 4px 4px;
  width: 100%;
  gap: 2px;
  background: ${ADMIN_SURFACE_1};

  & > div {
    padding: 0;
  }
`;

export const ErrorSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 12px;
  width: 100%;
  gap: 5px;
`;

export const ErrorText = styled.span`
  color: ${ADMIN_TEXT_300};

  ${ADMIN_TEXT_LABEL_S_MEDIUM};
`;

export const SaveButton = styled(Button)`
  margin-top: 30px;

  display: flex;
  height: 40px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
`;

export const AbsoluteWrapper = styled.div`
  position: absolute;
  bottom: 100%;
  width: 100%;
`;
