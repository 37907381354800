import React from 'react';
import { Concurrents, Container, Eye } from './styles';
import { CONCURRENTS_ICON } from 'injection-classes';
import { useVideoPlayerContext } from 'components/Video/video-player/Player/Context';
import { useAdminTranslation } from 'hooks/use-translation';
import { useSelector } from 'react-redux';
import { getMinimumConcurrents, showConcurrents } from 'services/app/selectors';

type IConcurrentsIconProps = {
  className?: string;
  color?: string;
};

export default function ConcurrentsIconComponent({
  className,
  color,
}: IConcurrentsIconProps) {
  const { concurrents } = useVideoPlayerContext();
  const minimumConcurrents = useSelector(getMinimumConcurrents);
  const { t } = useAdminTranslation();
  const visible = useSelector(showConcurrents) && concurrents >= minimumConcurrents;

  return (
    <Container className={`${className} ${CONCURRENTS_ICON}`} visible={visible}>
      <Eye color={color} />
        <Concurrents color={color}>
          {concurrents.toLocaleString()} {concurrents === 1 ? t('ADMIN_LABEL_VIEWER') : t('ADMIN_LABEL_VIEWERS')}
        </Concurrents>
    </Container>
  );
}
