import styled from 'styled-components';
import { ADMIN_TEXT_200 } from 'style/constants';
import { ADMIN_TEXT_LABEL_M_MEDIUM } from 'style/design-system/textStyles';

export const Container = styled.div`
  width: 100%;
  gap: 20px;

  margin-top: 30px;
`;

export const Title = styled.div`
  color: ${ADMIN_TEXT_200};
  ${ADMIN_TEXT_LABEL_M_MEDIUM};

  margin-bottom: 15px;
`;

export const ExternalLinkList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5px;
`;
