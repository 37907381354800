import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ADMIN_SCROLL_BAR } from 'style/mixins';

import Icon from 'components/ui/Icon';

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
};

const defaultProps = {
  value: undefined,
};

const Container = styled.div.attrs(props => ({
  transform: `translate(${props.x}px, ${props.y}px)`,
}))`
  border: 2px solid black;
  opacity: 0.75;
  position: fixed;
  background-color: white;
  color: black;
  left: 190px;
  top: 50px;
  z-index: 3000;
`;

const Controls = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const Json = styled.pre`
  ${ADMIN_SCROLL_BAR}
  overflow: auto;
  font-family: monospace;
  margin: 0;
  padding: 1em;
  max-width: 500px;
  max-height: 500px;
`;

const JsonDebug = ({ value }) => {
  const [open, setOpen] = useState(() => true);
  const [dragging, setDragging] = useState(() => null);
  const [position, setPosition] = useState(() => ({
    x: Math.random() * 100,
    y: Math.random() * 100,
  }));
  useEffect(
    () => {
      if (!dragging) {
        return undefined;
      }

      const listener = (e) => {
        setPosition({
          x: e.clientX - dragging.x,
          y: e.clientY - dragging.y,
        });
      };
      document.addEventListener(
        'mousemove',
        listener,
      );

      return () => document.removeEventListener('mousemove', listener);
    },
    [dragging],
  );

  const startDrag = useCallback(
    (e) => {
      const bounds = e.target.getBoundingClientRect();
      const offsetX = e.clientX - bounds.x;
      const offsetY = e.clientY - bounds.y;
      e.preventDefault();
      setDragging({
        x: offsetX,
        y: offsetY,
      });
    },
  );

  const stopDrag = useCallback(
    (e) => {
      e.preventDefault();
      setDragging(null);
    },
  );

  const close = useCallback(
    () => {
      setOpen(false);
    },
  );

  if (!open) {
    return null;
  }

  return (
    <Container x={position.x} y={position.y}>
      <Controls>
        <Icon
          admin
          name="menu"
          onMouseDown={startDrag}
          onMouseUp={stopDrag}
        />
        <div style={{ flexGrow: 1 }} />
        <Icon name="cancel" onClick={close} />
      </Controls>
      <Json>{ JSON.stringify(value, null, 2) }</Json>
    </Container>
  );
};

JsonDebug.propTypes = propTypes;
JsonDebug.defaultProps = defaultProps;

export default process.env.NODE_ENV === 'development' ? JsonDebug : () => null;
