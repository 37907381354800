import styled from 'styled-components';
import withLabel from 'components/core/withLabel';
import { ADMIN_BUTTON_BORDER_RADIUS, ADMIN_SURFACE_5, ADMIN_TEXT_100 } from 'style/constants';
import { FLEX_CENTER } from 'style/mixins';
import Icon from 'components/ui/Icon';
import { ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';

export const ProviderDisplay = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 6px;
`;

export const ProviderLabel = withLabel(styled.label`
  ${FLEX_CENTER}
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${ADMIN_TEXT_100};
  text-transform: capitalize;
  background: ${ADMIN_SURFACE_5};
  min-width: 84px;
  height: 25px;
  text-align: center;
  border-radius: ${ADMIN_BUTTON_BORDER_RADIUS};
  padding: 0 8px;
`, {
  compact: true,
});

export const SettingsIcon = styled(Icon).attrs(props => ({
  color: ADMIN_TEXT_100(props),
  name: 'nav2Settings',
}))`
  cursor: pointer;
  & > svg {
    width: 13px;
    height: 13px;
  }
`;
