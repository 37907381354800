import React, {
  PropsWithChildren,
  useState,
  forwardRef,
  MouseEvent,
  MouseEventHandler,
  ReactNode,
  useRef,
  useImperativeHandle,
} from 'react';
import { css } from 'styled-components';
import EditorModal from 'components/admin-bridge/EditorModal';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin/selectors';
import EditableArea from 'components/admin2/ui/EditableArea';
import { TranslationKey } from 'hooks/use-translation';

interface EditorAction {
  confirmationMessageKey?: TranslationKey;
  descriptionTextKey?: TranslationKey;
  icon: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  tooltipKey?: TranslationKey;
  useAbsolutePosition?: boolean;
}

export type EditorWrapperProps = PropsWithChildren<{
  blockTitle?: string;
  blockTitleKey?: TranslationKey;
  borderStyle?: string;
  className?: string;
  'data-testid'?: string;
  editorActions?: EditorAction[];
  editorActionsCss?: ReturnType<typeof css> | string;
  externalModalOpen?: boolean;
  modalContent?: ReactNode;
  modalTitle?: string;
  modalTitleKey?: TranslationKey;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  onModalClose?: () => void;
}>;

export default forwardRef<HTMLDivElement, EditorWrapperProps>(({
  blockTitle,
  blockTitleKey,
  borderStyle = 'solid',
  children,
  'data-testid': testId = 'text-editor-input',
  // disablePointerEvents = false,
  className,
  editorActions = [],
  modalContent,
  modalTitle,
  modalTitleKey,
  onClick,
  onModalClose,
  editorActionsCss,
  externalModalOpen,
}, ref) => {
  const editorWrapperRef = useRef<HTMLDivElement>(null);
  const inEditMode = useSelector(isEditMode);
  const [modal, setModal] = useState<null | {
    rect: { height: number; width: number };
    x: number;
    y: number;
  }>(null);

  useImperativeHandle(ref, () => editorWrapperRef.current!);

  const closeModal = () => {
    onModalClose?.();
    setModal(null);
  };

  const openModal = (e: MouseEvent<HTMLDivElement>) => {
    if (inEditMode) {
      const { current: editorWrapper } = editorWrapperRef;
      const editorWrapperRect = editorWrapper?.getBoundingClientRect();
      const currentPosition = editorWrapperRect ? {
        x: editorWrapperRect.left,
        y: editorWrapperRect.top + editorWrapperRect.height,
        rect: { height: editorWrapperRect.height, width: editorWrapperRect.width },
      } : { x: 0, y: 0, rect: { height: 0, width: 0 } };

      setModal(currentPosition);
    }
    onClick?.(e);
  };

  const isModalOpen = externalModalOpen || !!modal;

  return (
    <>
      <EditableArea
        isEditing={inEditMode}
        blockTitle={blockTitle}
        blockTitleKey={blockTitleKey}
        borderStyle={borderStyle}
        className={className}
        editorActions={editorActions}
        editorActionsCss={editorActionsCss}
        editorWrapperRef={editorWrapperRef}
        keepBorder={isModalOpen}
        onClick={openModal}
        testId={testId}
      >
        {children}
      </EditableArea>
      <EditorModal
        initialPosition={modal ? { x: modal.x, y: modal.y } : undefined}
        isOpen={isModalOpen}
        modalTitle={modalTitle}
        modalTitleKey={modalTitleKey}
        onClose={closeModal}
      >
        {modalContent}
      </EditorModal>
    </>
  );
});
