import React from 'react';
import GateToggle from 'components/admin-bridge/Toolbar/actions/GateToggle';
import AutoSave from 'components/admin-bridge/Toolbar/actions/AutoSave';
import ToolbarButton from 'components/admin-bridge/Toolbar/actions/Button';
import { useDispatch } from 'react-redux';
import { setActiveAction } from 'services/admin/actions';
import { PublishButton } from 'components/admin-bridge/Toolbar/actions/PublishButton';

function ChannelContentView() {
  const dispatch = useDispatch();

  const handleGoToChannel = () => {
    dispatch(setActiveAction(null));
  };

  return (
    <>
      <GateToggle shouldRender={true} />
      <AutoSave shouldRender={true} />
      <ToolbarButton
        data-testid="goToChannelButton"
        onClick={handleGoToChannel}
        stringKey="ADMIN_ACTION_ENTER_CHANNEL"
      />
      <PublishButton />
    </>
  );
}

export default {
  toolbarRight: ChannelContentView,
};
