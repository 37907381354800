import React, { RefObject, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import hash from 'json-stable-stringify';
import { useSelector } from 'react-redux';
import { useDispatch } from 'hooks';
import { registerStudioIframe, getStudioSessionId, setStudioSessionReady, isLivestreamPublished } from 'services/livestream';
import { Container, Iframe } from './styles';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import { getPrimaryToken, getUserName } from 'services/auth';
import { setMaestroLivestreamPreview } from 'services/admin';
import { MAESTRO_STUDIO_BASE_URL } from 'config';
import { getCurrentChannelId } from 'services/app';
import useStreamModeState from 'hooks/use-stream-mode-state';
import { getStudioConfigData } from 'services/app/selectors';
import { getSiteId } from 'services/app/selectors';
import { dismissPendingNavigationActions, resumePendingNavigationActions, setNavigationBlocked } from 'services/navigation';
import { getIsUserActionRequested } from 'services/navigation';
import { AdminConfirmationModalProps } from 'components/modals/AdminConfirmationModal';
import { dismissModal, showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';
import { getAdminTheme } from 'services/themes';

const NavigationConfirmationModal = () => {
  const dispatch = useDispatch();
  const isUserActionRequested = useSelector(getIsUserActionRequested);
  const livestreamIsPublished = useSelector(isLivestreamPublished);
  const [isReadyToAttachNavigationInterceptor, setIsReadyToAttachNavigationInterceptor] = useState(false);

  useEffect(
    () => {
      const timeout = setTimeout(
        () => setIsReadyToAttachNavigationInterceptor(true),
        500,
      );
      return () => clearTimeout(timeout);
    },
    [],
  );

  useEffect(() => {
    return () => {
      dispatch(setNavigationBlocked(false));
    };
  }, []);

  useEffect(() => {
    if (!isReadyToAttachNavigationInterceptor)
      return;

    if (livestreamIsPublished) {
      dispatch(setNavigationBlocked(true));
    } else {
      dispatch(setNavigationBlocked(false));
    }
  }, [livestreamIsPublished, isReadyToAttachNavigationInterceptor]);

  useEffect(() => {
    if (isUserActionRequested) {
      const modalData: AdminConfirmationModalProps['data'] = {
        onConfirmClick: () => {
          dispatch(resumePendingNavigationActions());
          dispatch(dismissModal('adminConfirmation'));
        },
        onDismiss: () => dispatch(dismissPendingNavigationActions()),
        titleKey: 'ADMIN_LABEL_WARNING',
        subtitleKey: 'ADMIN_WARNING_LEAVING_STUDIO',
      };
      dispatch(
        showModal({
          kind: ModalKinds.adminConfirmation,
          data: modalData,
        }),
      );
    }
  }, [isUserActionRequested]);

  return null;
};

const MaestroStudioHost = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const iframeRef = useRef<HTMLIFrameElement>();
  const { streamKey, ingestUrl } = useStreamModeState();
  const studioConfigData = useSelector(getStudioConfigData);
  const studioSessionId = useSelector(getStudioSessionId);
  const maestroJwt = useSelector(getPrimaryToken);
  const currentChannelId = useSelector(getCurrentChannelId);
  const siteId = useSelector(getSiteId);
  const cachedUrl = useRef(new URL(MAESTRO_STUDIO_BASE_URL));
  const adminTheme = useSelector(getAdminTheme);
  const userName = useSelector(getUserName);

  const iframeSrc = useMemo(
    () => {
      if (!maestroJwt || !streamKey || !ingestUrl)
        return;

      const parentUrl = window.location.href;
      const studioParams = cachedUrl.current.searchParams;

      // when a new session is generated, this will trigger a state update
      // that will cause the iframe to reload, so we cache the first url
      // to prevent that
      if (
        shouldReloadStudio(
          {
            clientUrl: parentUrl,
            token: maestroJwt,
            channelId: currentChannelId,
            siteId,
            streamKey,
            ingestUrl,
          },
          studioParams,
        )
      ) {
        studioParams.set('clientUrl', parentUrl);
        studioParams.set('token', maestroJwt);
        studioParams.set('channelId', currentChannelId!);
        studioParams.set('maestroData', encodeURIComponent(studioConfigData));
        studioParams.set('siteId', siteId);
        studioParams.set('streamKey', streamKey);
        studioParams.set('ingestUrl', ingestUrl);
        studioParams.set('theme', hash(adminTheme));

        if (studioSessionId)
          studioParams.set('sessionId', studioSessionId);

        if (userName) {
          studioParams.set('userName', userName);
        }
      }

      return cachedUrl.current.href;
    },
    [
      studioSessionId,
      maestroJwt,
      currentChannelId,
      streamKey,
      ingestUrl,
      studioConfigData,
      siteId,
      adminTheme,
      userName,
    ],
  );

  useEffect(() => {
    dispatch(registerStudioIframe(iframeRef.current || null));
  }, [iframeRef.current]);

  useEffect(() => {
    return () => {
      dispatch(setStudioSessionReady(false));
      dispatch(setMaestroLivestreamPreview(false));
    };
  }, [dispatch]);

  const onLoad = useCallback(() => setLoading(false), []);

  return (
    <Container>
      {(loading || !iframeSrc) && <LoadingSpinner />}
      <Iframe
        ref={iframeRef as RefObject<HTMLIFrameElement>}
        src={iframeSrc}
        allow="camera *;microphone *;document-domain; clipboard-read; clipboard-write; display-capture;"
        onLoad={onLoad}
        loading={loading}
      />
      <NavigationConfirmationModal />
    </Container>
  );
};

const shouldReloadStudio = (params: Record<string, any>, studioUrlParams: URLSearchParams) => {
  for (const param in params) {
    if (!Object.hasOwn(params, param))
      continue;

    if (studioUrlParams.get(param) !== params[param])
      return true;
  }

  return false;
};

export default MaestroStudioHost;
