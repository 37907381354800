import type {
  IExternalLink,
  INavigationChild,
  INavigationParent,
} from 'models/INavigation';
import React, { useCallback, useMemo, useState } from 'react';
import {
  FloatingMenuContainer,
  SubMenuItem,
  FloatingColumnContianerOverflow,
  FloatingParentColumn,
  FloatingParentContainer,
  StyledDivider,
  HoverContainer,
} from './styles';
import FloatingParentItem from './FloatingParentItem';
import { getPageId, isLandingPageType } from 'services/app';
import { useSelector } from 'react-redux';
import { NAVIGATION_BAR_ITEM_ID } from 'global-ids';
import { OTHERS_ID } from '../utils';
import { useNavigationBarContext } from '../NavigationBarContext';

interface IFloatingParentsMenuProps {
  externalLinks: IExternalLink[];
  moreParents: INavigationParent[];
  parent?: INavigationParent;
}

const FloatingParentsMenu: React.FC<IFloatingParentsMenuProps> = ({
  moreParents,
  externalLinks = [],
  parent,
}) => {
  const currentPageId = useSelector(getPageId);
  const isCurrentPage = (id: string) => currentPageId === id;
  const { setFloatMenuId, getPathBySlug } = useNavigationBarContext();
  const [children, setChildren] = useState<INavigationChild[]>([]);
  const isLanding = useSelector(isLandingPageType);
  const { applyChildBoarder, applyParentBoarder } = useMemo(
    () => ({
      applyChildBoarder:
        moreParents.length + externalLinks.length < children.length,
      applyParentBoarder:
        !!children.length &&
        moreParents.length + externalLinks.length >= children.length,
    }),
    [moreParents.length, externalLinks.length, children.length],
  );

  const handleHoverChildLeave = () => {
    setChildren([]);
    setFloatMenuId(null);
  };

  const showChildItems = useCallback(() => {
    if (!children.length) return null;
    return (
      <FloatingColumnContianerOverflow applyBorder={applyChildBoarder}>
        {children?.map((child, index) => (
          <SubMenuItem
            href={getPathBySlug(child.slug)}
            key={`submenu-item-${index}`}
            data-testid={NAVIGATION_BAR_ITEM_ID}
            isCurrentPage={isCurrentPage(child.id)}
          >
            {child.name}
          </SubMenuItem>
        ))}
      </FloatingColumnContianerOverflow>
    );
  }, [applyChildBoarder, children, isLanding, isCurrentPage]);

  const showExternalLinks = useCallback(
    (hidden: boolean, needDivider: boolean = false) => {
      if (!hidden) return null;
      if (!externalLinks.length) return null;
      return (
        <FloatingParentColumn
          applyBorder={applyParentBoarder}
          forLinks={!!externalLinks.length && !!children.length}
        >
          {needDivider && <StyledDivider />}
          {externalLinks.map((link, index) => (
            <SubMenuItem
              href={link.url}
              target="_blank"
              key={`floating-child-submenu-${index}`}
              data-testid={NAVIGATION_BAR_ITEM_ID}
            >
              {link.name}
            </SubMenuItem>
          ))}
        </FloatingParentColumn>
      );
    },
    [children, applyChildBoarder, externalLinks, isLanding, isCurrentPage],
  );

  const moreParentsSorted = useMemo(() => {
    /**
     * In order to have better UX, we want to display parents that have children first
     */
    const sortedParents = moreParents.sort((a, b) => {
      if (a.children.length > b.children.length) return -1;
      if (a.children.length < b.children.length) return 1;
      return 0;
    },
    );
    return sortedParents;
  }, [moreParents]);

  const handleHoverEnter = () => {
    setFloatMenuId(parent?.id || OTHERS_ID);
  };

  const onMoreParentMouseEnter = (moreParent: INavigationParent) => () => {
    if (!children.length && !moreParent.children.length) {
      return;
    }

    setChildren(moreParent.children);
  };

  return (
    <HoverContainer
      onMouseEnter={handleHoverEnter}
      onMouseLeave={handleHoverChildLeave}
    >
      <FloatingMenuContainer hasChildren={!!children.length}>
        <FloatingParentContainer
          needBottom={!children.length}
          hasChildren={!!children.length}
          hidden={!moreParents.length}
        >
          <FloatingParentColumn applyBorder={applyParentBoarder}>
            {moreParentsSorted.map((moreParent, index) => (
              <FloatingParentItem
                key={index}
                parent={moreParent}
                onMouseEnter={onMoreParentMouseEnter(moreParent)}
                href={moreParent.type !== 'folder' ? getPathBySlug(moreParent.slug) : undefined}
                data-testid={NAVIGATION_BAR_ITEM_ID}
              />
            ))}
          </FloatingParentColumn>
          {showExternalLinks(!!children.length, true)}
        </FloatingParentContainer>
        <FloatingParentContainer
          hidden={!externalLinks.length || !!children.length}
          forLinks={!!externalLinks.length && !!children.length}
        >
          {showExternalLinks(!children.length)}
        </FloatingParentContainer>
        {showChildItems()}
      </FloatingMenuContainer>
    </HoverContainer>
  );
};

export default FloatingParentsMenu;
