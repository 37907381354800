import { connect } from 'react-redux';

import {
  getRenderer,
} from 'services/renderer';
import RendererProvider from './RendererProvider';

export * from 'services/renderer/ids';

const mapStateToProps = (state, { id }) => ({
  renderer: getRenderer(state, id),
});

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(RendererProvider);
