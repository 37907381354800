import styled from 'styled-components';
import { bw } from 'colors';
import { BODY_FONT, SCROLL_BAR } from 'style/mixins';
import { SURFACE_1 } from 'style/constants';
import { TINY_MCE_STYLES } from 'components/ui/RichTextEditorV2/styles';
import { ADMIN_SCROLL_BAR, mobileLandscapeOnly } from 'style/mixins';
import { css } from 'styled-components';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle<{
  isOrientationLocked?: boolean;
  orientation?: number;
}>`
  html {
    font-size: 16px;
    line-height: 1.3em;
    ${({ orientation, isOrientationLocked }) => isOrientationLocked ? mobileLandscapeOnly`
      top: 100%;
      height: 100vw;
      width: 100vh;

      ${orientation === -90 ? css`
        transform: rotate(90deg);
        transform-origin: right top;
        right: 0;
      ` : css`
        transform: rotate(-90deg);
        transform-origin: left top;
        left: 0;
      `}
    ` : css`transform: none;`
    };
  }

  * {
    box-sizing: border-box !important;
    -webkit-overflow-scrolling: touch;
  }

  body {
    background-color: black;
    ${ADMIN_SCROLL_BAR}
  }

  button {
    appearance: none;
    background: none;
    border: none;
    cursor: pointer;
    font-family: inherit;
    padding: 0;
  }

  [hidden] {
    display: none !important;
  }

  iframe {
    border: none;
  }

  video {
    object-fit: contain;
  }

  .live video::-webkit-media-controls-timeline {display:none;}
  .live video::-webkit-media-controls-current-time-display {display:none;}
  .live video::-webkit-media-controls-time-remaining-display {display:none;}


  // The dropdowns are not in the Rich Text DOM, so we need to style them globally
  ${TINY_MCE_STYLES}


  // Lock the orientation of the page to landscape
  ${mobileLandscapeOnly`
    html, body {
      position: fixed;
      height: 100vw;
      width: 100vh;
      overflow: hidden;
    }
  `}
`;

export const Background = styled.div`
  background-color: ${SURFACE_1};
  color: ${props => bw(SURFACE_1(props))};
  ${BODY_FONT};
  ${SCROLL_BAR}
  & *:focus {
    outline: none !important;
  }
`;
