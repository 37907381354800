import React from 'react';

import { IColorOption, OPTION_TYPE, OptionData, OptionType, SelectedOption } from '../../../models';
import { ColorSelectorContainer, ColorSelectorHeader, ColorOptionValues, SelectedColor, Title, SmallColorOption } from './styles';
import ColorOption from 'components/ui/ColorOption';

const COLOR_SELECTOR_ID = 'ecommerce-options_selector-color';

interface IColorSelectorProps {
  handleOptionChange: (type: OptionType, option: OptionData) => void;
  option: IColorOption;
  selectedOptionValue: SelectedOption;
}

const ColorSelector: React.FC<IColorSelectorProps> = ({
  handleOptionChange,
  option,
  selectedOptionValue: selected,
 }) => {
  const { values, type } = option;
  if (type !== OPTION_TYPE.color) {
    // tslint:disable-next-line:no-console
    console.error('ColorSelector component cannot render because type is not OPTION_TYPE.color');
    return null;
  }

  return (
    <ColorSelectorContainer data-testid={COLOR_SELECTOR_ID} id={COLOR_SELECTOR_ID}>
      <ColorSelectorHeader>
        <Title>{option.name}:</Title>
        <SelectedColor>{selected.value.name}</SelectedColor>
      </ColorSelectorHeader>
      <ColorOptionValues>
        {values.map(optionData => {
          const isDisabled = !optionData.isAvailable;
          const handleClick = () => !isDisabled && handleOptionChange(type, optionData);
          return (
            <ColorOption
              color={optionData.value}
              key={optionData.name}
              onClick={handleClick}
              selected={selected.value.value === optionData.value}
              isDisabled={isDisabled}
            />
          );
        })}
      </ColorOptionValues>
    </ColorSelectorContainer>
  );
};

export default ColorSelector;

export const SelectedColorOptionOnDisplay: React.FC<{ isRenderingOnPanel: boolean; selectedOption: SelectedOption; }> = ({ selectedOption, isRenderingOnPanel }) => {
  const { value } = selectedOption;
  const Component = isRenderingOnPanel ? SmallColorOption : ColorOption;
  return <Component color={value.value} />;
};
