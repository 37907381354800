import React from 'react';
import { useSelector, useAjax } from 'hooks';
import { getSiteId } from 'services/app/selectors';
import { getPrimaryToken } from 'services/auth';
import { getCustomReceipt } from 'services/user-layout';
import { WALLET_SERVICE_BASE_URL } from 'config';
import { Container, ContainerReceipt, TopColor, BorderColor, Table, Center, LogoImg, CenterSize, TableSummary, HrMargin, Label, Button } from './styles';

const CustomReceipt = () => {
  const siteId = useSelector(getSiteId);
  const receiptnumber = useSelector(getCustomReceipt);
  const token = useSelector(getPrimaryToken);

  const { data, error, loaded } = useAjax({
    headers: {
      Authorization: `Bearer ${token}`,
      'x-maestro-client-id': siteId,
    },
    url: `${WALLET_SERVICE_BASE_URL}/customreceipt?receiptNumber=${receiptnumber}`,
  });

  if (!loaded) {
    return (
      <Container><Label>Searching...</Label></Container>
    );
  }

  if (error) {
    return (
      <Container><Label>Receipt number not found!</Label></Container>
    );
  }

  const bg = data?.siteActiveColor;

  return (
    <Container>
      <ContainerReceipt>
        <TopColor bg={bg} />
        <BorderColor bg={bg}>
          <Table cellspacing="10">
            <tr>
              <td>
                <Center><LogoImg src={data?.urlLogo} /></Center>
              </td>
            </tr>
            <tr>
              <CenterSize>Receipt from {data.customerProfileName}</CenterSize>
            </tr>
            <tr>
              <td>
                <Center>Receipt #{data?.receiptNumber}</Center>
              </td>
            </tr>
            <tr>
              <td> AMOUNT PAID </td>
            </tr>
            <tr>
              <td><Label>{data?.amount}</Label></td>
            </tr>
            <tr>
              <td> DATE PAID </td>
            </tr>
            <tr>
              <td><Label>{data?.datePaid}</Label></td>
            </tr>
            <tr>
              <td>PAYMENT METHOD</td>
            </tr>
            <tr>
              <td><Label>{`${data?.paymentMethod?.network} ${data?.paymentMethod?.last4}`}</Label></td>
            </tr>
            <tr>
              <td>
                <TableSummary>
                  <tr>
                    <td>{`You have purchased a ${data?.subscriptionRecurrence} ${data?.subscriptionType}:`}</td>
                  </tr>
                  <tr>
                    <td><Label>{data?.subscriptionName}</Label></td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        To access the content, please visit <a href={data?.urlDomain} rel="noopener noreferrer" target="_blank">{data?.urlDomain}</a> and log in with the same credentials used to make your purchase.
                      </p>
                    </td>
                  </tr>
                </TableSummary>
              </td>
            </tr>
            <tr>
              <td>
                <Center><Button bg={bg} color={data?.txtButtonColor} href={data?.urlDomain} rel="noopener noreferrer" target="_blank">TAKE ME THERE</Button></Center>
              </td>
            </tr>
            <tr>
              <td>
                <HrMargin />
                <p>
                  {`If you have any questions, visit our Help Center at ${data?.maestroURLSupport} or email us at ${data?.maestroEmail}`}
                </p>
              </td>
            </tr>
          </Table>
        </BorderColor>
      </ContainerReceipt>
    </Container>
  );
};

export default CustomReceipt;
