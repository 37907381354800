import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import { ACCENT_PRIMARY, TEXT_CONTRAST_ON_ACCENT_PRIMARY } from 'style/constants';
import {
  TEXT_H6,
} from 'style/mixins';
import { Button as SiteThemeButton } from 'components/ui/Button';

interface CardButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  iconName?: string;
}

export const Text = styled.div``;

export const Button = styled(SiteThemeButton)`
  padding: 0 10px;
  height: 25px;
  border-radius: 4.54364px;
  text-align: left;
  ${TEXT_H6}
  letter-spacing: -0.01em;
  display: flex;
  align-items: center;
  max-width: 260px;
  &, & ${Text} {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: normal;
  }
`;

const StyledIcon = styled(Icon).attrs(props => ({
  color: TEXT_CONTRAST_ON_ACCENT_PRIMARY(props),
}))`
  margin-right: 5px;
  & svg {
    width: 15px;
    height: 15px;
  }
`;

export default function CardButton({ children, className, iconName, onClick, ...props }: CardButtonProps) {
  return (
    <Button
      className={className}
      data-testid="liveCardCtaButton"
      onClick={onClick}
      {...props}
    >
      {iconName && <StyledIcon name={iconName} />}
      <Text>{children}</Text>
    </Button>
  );
}
