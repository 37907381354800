import React, { ComponentType, PropsWithChildren } from 'react';
import TranslatedText from 'components/i18n/TranslatedText';

export interface OptionalTranslationProps {
  stringKey?: string;
}

export default function withOptionalTranslation<T>(wrappedComponent: ComponentType<T>) {
  const WrappedComponent = wrappedComponent as ComponentType;
  return ({
    children,
    stringKey,
    ...props
  }: T & PropsWithChildren<OptionalTranslationProps>) => {
    return stringKey ? (
      <TranslatedText
        component={WrappedComponent}
        componentProps={props}
        stringKey={stringKey}
      />
    ) : (
      <WrappedComponent {...props}>
        {children}
      </WrappedComponent>
    );
  };
}
