import React, { ReactNode, useEffect } from 'react';
import useRealtimeDocument from 'hooks/use-realtime-document';
import hash from 'json-stable-stringify';

type OnValueFn<T> = ((doc?: T | null, loaded?: boolean) => unknown);
interface IProps<T> {
  children?: ReactNode | OnValueFn<T>;
  collection: string;
  id?: string | null;
  onValue?: OnValueFn<T>;
}

export default function RealtimeDocument<T>({
  children = null,
  collection,
  id = null,
  onValue,
}: IProps<T>) {
  const [doc, loaded] = useRealtimeDocument<T>(collection, id);

  useEffect(() => {
    onValue?.(doc, loaded);
  }, [hash(doc), loaded, onValue]);

  return (
    <>
      {(typeof children === 'function' ? children(doc, loaded) : children) || null}
    </>
  );
}
