import React from 'react';
import axios from 'axios';
import { SponsorImage, SponsorLink, SponsorSvgImage } from './styles';

interface ISponsorDisplayProps {
  handleSponsorsEngage: () => void;
  image: string;
  key: string;
  link: string;
  name?: string;
}

const SponsorDisplay: React.FC<ISponsorDisplayProps> = ({
  handleSponsorsEngage,
  image,
  key,
  link,
  name,
}) => {
  const isSvgImage = React.useMemo(() => /\.svg$/.test(image), [image]);
  const [svgData, setSvgData] = React.useState('');

  React.useEffect(() => {
    if (isSvgImage) {
      axios.get(image).then(({ data }) => {
        setSvgData(data);
      });
    }
  }, [image]);

  return (
    <SponsorLink key={key} href={link} onClick={handleSponsorsEngage}>
      {isSvgImage ? (
        <SponsorSvgImage dangerouslySetInnerHTML={{ __html: svgData }} />
      ) : (
        <SponsorImage alt={name} src={image} />
      )}
    </SponsorLink>
  );
};

export default SponsorDisplay;
