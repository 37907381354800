import Button from 'components/ui/Button';
import { SURFACE_5, TEXT_100, TEXT_300, TEXT_CONTRAST_ON_ACCENT_PRIMARY } from 'style/constants';
import { TEXT_LABEL_L_BOLD, TEXT_LABEL_M_BOLD, TEXT_LABEL_M_MEDIUM } from 'style/design-system/textStyles';
import { mobileOnly } from 'style/mixins';
import styled, { css } from 'styled-components';

export const Price = styled.div`
  ${TEXT_LABEL_M_BOLD}
  color: ${TEXT_100};

  ${mobileOnly`
    ${TEXT_LABEL_L_BOLD};
  `};
`;

export const BodySection = styled.div<{ shopifyPaymentProcessing?: boolean; }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
  ${({ shopifyPaymentProcessing }) => shopifyPaymentProcessing && css`
    opacity: 0.5;
    pointer-events: none;
  `};
`;

export const BodySectionTitle = styled.span`
  ${TEXT_LABEL_M_MEDIUM}
  color: ${TEXT_100};
`;

export const StyledButton = styled(Button).attrs(props => ({
  disabledBackground: SURFACE_5(props),
  disabledColor: TEXT_300(props),
  textColor: TEXT_CONTRAST_ON_ACCENT_PRIMARY(props),
}))`
  width: 100%;
  padding: 13px 24px;
  ${TEXT_LABEL_L_BOLD}
`;
