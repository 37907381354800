import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import styled from 'styled-components';
import { MAESTRO_BLACK, MAESTRO_WHITE } from 'style/constants';
import Icon from 'components/ui/Icon';
import LanguageSelector from './LanguageSelector';
import { useFocusArea } from 'hooks';
import { useVideoPlayerContext, SUBTITLES_OFF } from 'components/Video/video-player/Player/Context';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device';
import { useFullScreenDetector } from 'contexts/FullScreenDetectorContext';

const BACKGROUND_ACTIVE = MAESTRO_WHITE;
const BACKGROUND_NORMAL = 'transparent';

const CCButton = styled.button<{ active: boolean }>`
  padding: 5px;
  border: 0;
  outline: 0;
  position: relative;
  background: ${BACKGROUND_NORMAL};
  color: ${BACKGROUND_ACTIVE};
  border-radius: 4px;
  &:hover${({ active }) => active && ', &'} {
    background: ${BACKGROUND_ACTIVE};
    color: ${MAESTRO_BLACK};
  }
  & svg {
    width: 23px;
    height: 15px;
  }
`;

interface CCButtonProps {
  setShowControls: Dispatch<SetStateAction<boolean>>;
}

export default function({ setShowControls }: CCButtonProps) {
  const { selectedSubtitle, subtitleOptions } = useVideoPlayerContext();
  const [languageSelectorOpen, setLanguageSelectorOpen] = useState(false);
  const isMobile = useSelector(isMobileLayout);
  const { isDocumentInFullScreen } = useFullScreenDetector();
  const fixedPosition = isMobile && !isDocumentInFullScreen;
  const active = useMemo(() => {
    const isActive = languageSelectorOpen || selectedSubtitle.value !== SUBTITLES_OFF.value;
    return isActive;
  }, [selectedSubtitle, languageSelectorOpen]);

  const closeLanguageSelector = () => {
    setLanguageSelectorOpen(false);
    setShowControls(false);
  };
  const toggleLanguageSelector = () => {
    setLanguageSelectorOpen(isActive => !isActive);
    setShowControls(false);
  };

  const buttonRef = useFocusArea<HTMLButtonElement>({
    onExit: () => {
      if (!fixedPosition) {
        closeLanguageSelector();
      }
    },
    active: languageSelectorOpen,
  });

  if(!subtitleOptions?.length || (subtitleOptions.length === 1 && subtitleOptions[0].label === SUBTITLES_OFF.label)) {
    return null;
  }

  return (
    <CCButton ref={buttonRef} active={active} onClick={toggleLanguageSelector}>
      <Icon name="captions" />
      <LanguageSelector open={languageSelectorOpen} onClose={closeLanguageSelector} />
    </CCButton>
  );
}
