import styled, { css } from 'styled-components';
import { Button as UIButton } from 'components/ui/Button';
import { TEXT_100, TEXT_300, TEXT_200, TEXT_CONTRAST_ON_ACCENT_PRIMARY, ALERT_ERROR } from 'style/constants';
import { TEXT_BODY_M_REGULAR, TEXT_LABEL_L_BOLD, TEXT_LABEL_S_BOLD, TEXT_TITLE_L } from 'style/design-system/textStyles';
import Link from 'components/ui/Link';
import Icon from 'components/ui/Icon';
import { mobileOnly } from 'style/mixins';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const LandingGoBackWrapper = styled.button`
  all: unset;
  display: none;
  color: ${TEXT_100};

  ${mobileOnly`
    display: flex;
    flex-direction: row;
    align-self: start;
    transform: translateX(-5px);
  `}
`;

export const CartHeader = styled.div`
  display: flex;
  flex-direction: row;
  height: 44px;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

export const CartTitle = styled.span`
  color: ${TEXT_100};
  ${TEXT_TITLE_L};

  font-size: 16px;
  font-weight: 500;
`;

export const CartTotalNumberOfItems = styled.span`
  color: ${TEXT_300};
  ${TEXT_BODY_M_REGULAR};

  font-size: 14px;
`;

export const CartProductsList = styled.div<{isLanding: boolean}>`
  width: 100%;
`;

export const CartSubtotalSection = styled.div`
  margin-top: 10px;

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin-bottom: 40px;
  ${mobileOnly`
    margin-bottom: 20px;
  `}
`;

export const CartSubtotal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Info = styled.span`
  color: ${TEXT_200};
  ${TEXT_BODY_M_REGULAR}
`;

export const CartSubtotalTitle = styled.span`
  color: ${TEXT_100};
  ${TEXT_LABEL_L_BOLD}
`;

export const CartSubtotalPrice = styled.span`
  color: ${TEXT_100};
  ${TEXT_LABEL_L_BOLD}
`;

export const Button = styled(UIButton).attrs(props => ({
  textColor: TEXT_CONTRAST_ON_ACCENT_PRIMARY(props),
}))`
  ${TEXT_LABEL_L_BOLD}
  height: 44px;
  width: 100%;
  padding: 13px 16px 13px 24px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  align-self: stretch;

  margin-top: auto;
  ${mobileOnly`
    margin-top: 0px;
  `}
`;

export const CartFooter = styled.div`
  margin-top: 20px;
`;

export const CartFooterText = styled.span`
  color: ${TEXT_300};
  text-align: center;
`;

export const CartFooterLink = styled(Link)`
  color: ${TEXT_300} !important;

  text-decoration-line: underline !important;
`;

export const RightArrow = styled(Icon).attrs({
  name: 'arrowRight',
})`
  display: flex;
  height: 100%;
  align-items: center;
  svg {
    width: 10px;
    height: 10px;
  }
`;

export const ErrorMessage = styled.span`
  ${TEXT_LABEL_S_BOLD}
  color: ${ALERT_ERROR};
  align-self: stretch;
  margin-top: 10px;
`;

export const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  text-align: center;
  ${mobileOnly`
    padding-bottom: 20px;
  `}
`;
