import React from 'react';
import EditableHeaderCountdown from 'components/admin-bridge/EditableHeaderCountdown';
import {
  useEndUserTranslation,
  DynamicTranslationType,
} from 'hooks/use-translation';
import { useSelector } from 'react-redux';
import { getPageId } from 'services/app/selectors';
import { isEditMode } from 'services/admin/selectors';
import { Placeholder, StyledCountdown, StyledQuickEditOverlay } from './styles';

interface IHeaderCountdownProps {
  className?: string;
  isAdmin: boolean;
  startTime: string;
  title?: string;
}

export default function HeaderCountdown({
  className = '',
  isAdmin,
  startTime,
  title,
}: IHeaderCountdownProps) {
  const { endUserT } = useEndUserTranslation();
  const pageId = useSelector(getPageId);
  const isEditing = useSelector(isEditMode);
  const [openCountdownModal, setOpenCountdownModal] = React.useState(false);

  const countdownTitle =
    endUserT([DynamicTranslationType.countdownTitle, { pageId }]) || title;

  const handleOpenCountdownModal = () => {
    setOpenCountdownModal(true);
  };

  const handleCloseCountdownModal = () => {
    setOpenCountdownModal(false);
  };

  const renderDefault = () => {
    const shouldRender = startTime;
    const dateInPast = new Date(startTime) < new Date();

    if (dateInPast || !shouldRender) {
      return <Placeholder />;
    }
    if (shouldRender) {
      return (
        <StyledCountdown
          className={className}
          message={countdownTitle}
          startTime={startTime}
        />
      );
    }
    return null;
  };

  const renderEditable = () => {
    return (
      <EditableHeaderCountdown
        // @ts-ignore
        className={className}
        message={!isEditing ? countdownTitle : title}
        onCloseCountdownModal={handleCloseCountdownModal}
        openCountdownModal={openCountdownModal}
        startTime={startTime}
      />
    );
  };

  const renderCountdown = () => {
    if (isAdmin) {
      return renderEditable();
    }

    return renderDefault();
  };

  const shouldDisplayCountdown = Boolean((isAdmin && isEditing) || startTime);

  if (!shouldDisplayCountdown) {
    return null;
  }

  return (
    <StyledQuickEditOverlay
      onEdit={handleOpenCountdownModal}
      testId="editModeCountdown"
      titleKey="ADMIN_LABEL_COUNTDOWN"
    >
      {renderCountdown()}
    </StyledQuickEditOverlay>
  );
}
