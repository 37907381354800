import styled from 'styled-components';
import {
  TEXT_LABEL3,
  TEXT_H6,
} from 'style/mixins';
import { HIGHLIGHT_TERTIARY } from 'style/constants';
import Link from 'components/ui/Link';

export const Container = styled.div`
  height: 100%;
  position: fixed;
  width: 100%;
  background-color: white;
  padding: 30px;
`;
export const ContainerReceipt = styled.div`
  border-radius: 5px;
  box-shadow: 0 7px 14px 0 gray, 0 3px 6px 0 gray;
  -moz-box-shadow: 0 7px 14px 0 gray, 0 3px 6px 0 gray;
  -webkit-box-shadow:0 7px 14px 0 gray, 0 3px 6px 0 gray;
  width: 98%;
  display: table;
  margin: 0 auto;
  max-width: 800px;
`;
export const TopColor = styled.div`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: ${props => props.bg};
  height: 30px;
`;
export const BorderColor = styled.div`
  border:${props => props.bg} thin solid;
`;
export const Table = styled.table`
  color:gray;
  ${TEXT_H6}
  width: 90%;
  background-color: white;
  margin: 0 auto;
`;
export const Center = styled.div`
  text-align: center;
`;
export const LogoImg = styled.img`
  max-width: 180px
`;
export const CenterSize = styled.div`
  ${TEXT_LABEL3}
  text-align: center;
`;
export const TableSummary = styled.table`
  margin-top:15px;
  padding-top: 5px;
  width: 100%;
  background-color: ${HIGHLIGHT_TERTIARY};
`;
export const Label = styled.label`
  font-weight:850;
  color:gray;
`;
export const HrMargin = styled.hr`
  margin:20px 0
`;
export const Button = styled(Link)`
  display: block;
  margin: auto;
  background: ${props => props.bg};
  color: ${props => props.color};
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 0;
  font-weight: 700;
  width: 80%;
  height: auto;
  outline: none;
  cursor: pointer;
  padding: 10px 0; letter-spacing: 1px; text-decoration: none;
`;
