import styled from 'styled-components';
import TermsOfUse from 'components/ui/TermsOfUse';
import LocaleSelect from 'components/ui/LocaleSelect';
import {
  ACCENT_PRIMARY,
  SURFACE_4,
  TEXT_100,
} from 'style/constants';
import { BODY_FONT } from 'style/mixins';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 100%;
  background: transparent;
  padding-bottom: 60px;
  & > div:first-child {
    border-bottom: 1px solid ${SURFACE_4};
  }
`;

export const FooterSocialSponsorWrapper = styled.div<{ hideLine?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ hideLine }) => hideLine && 'border-bottom: none !important;'}
  & > :last-child {
    margin-left: auto;
    margin-right: 0 !important;
  }
  & > :first-child + * {
    margin-left: 0;
  }
  height: 100%;
`;

export const FooterSiteInfo = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledTermsOfUse = styled(TermsOfUse)`
  ${BODY_FONT}
  color: ${ACCENT_PRIMARY};
  & > * > a:hover {
    opacity: 1.0;
    color: ${ACCENT_PRIMARY};
  }
  & a {
    margin-right: 0 !important;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const LocaleWrapper = styled.div`
  color: ${TEXT_100};
  align-items: center;
  display: flex;
  ${BODY_FONT}
  position: relative;
  padding-right: 20px;
  &:empty {
    display: none;
  }
`;

export const StyledLocaleSelect = styled(LocaleSelect).attrs({
  compact: true,
  dropUp: true,
})``;
