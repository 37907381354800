import React from 'react';
import { PropsWithChildren } from 'react';
import { dismissEcommerceView } from 'services/ecommerce';
import { useDispatch } from 'react-redux';
import { BackIcon, Wrapper } from './styles';

const GoBack: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();

  const handleGoBack = () => {
    dispatch(dismissEcommerceView());
  };

  return (
    <Wrapper onClick={handleGoBack}>
      <BackIcon />
      {children}
    </Wrapper>
  );
};

export default GoBack;
