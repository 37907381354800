import React, { PropsWithChildren, useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { getStripeElementStyles } from './styles';
import { loadStripe } from '@stripe/stripe-js';
import { ITheme } from 'models';
import { DARK_THEME } from 'services/themes/constants';
import { fontUrl } from 'services/app';

interface IProps extends PropsWithChildren {
  apiKey: string;
  theme: ITheme;
}

/**
 * @description Use this component as a wrapper every time you need to use Stripe Elements.
 */
const StripeElements = ({ children, theme, apiKey }: IProps) => {
  const stripe = loadStripe(apiKey);
  const stripeElementStyles = useMemo(() => getStripeElementStyles(theme || DARK_THEME), [theme]);

  const fonts = [theme.typography.headline, theme.typography.body]
  .map((name) => ({
    baseUrl: `${fontUrl}/${name}`,
    name,
  }));

  if (!theme) {
    return <>{children}</>;
  }

  return (
    <Elements
      key={`${theme._id}-${theme.typography.body}`}
      stripe={stripe}
      options={{
        appearance: stripeElementStyles,
        fonts: fonts.map(font => ({
          family: font.name,
          src: `url(${font.baseUrl}.ttf)`,
          weight: '600',
        })),
      }}
    >
      {children}
    </Elements>
  );
};

export default StripeElements;
