import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import TranslatedText from 'components/i18n/TranslatedText';
import { ADMIN_SURFACE_3, ADMIN_TEXT_200, ADMIN_ACCENT_PRIMARY, CURRENT_SIDEBAR_WIDTH_PX } from 'style/constants';
import { ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';

const BLOCK_WIDTH = 147.5;
const BLOCK_SINGLE_PADDING = 20;
const BLOCK_PADDING = BLOCK_SINGLE_PADDING * 2;
const COLUMN_GAP = 12;
const MAX_WIDTH = (BLOCK_WIDTH * 7) + BLOCK_PADDING + (COLUMN_GAP * 6);
const WIDTH_1110 = (BLOCK_WIDTH * 3) + BLOCK_PADDING + (COLUMN_GAP * 2);
const WIDTH_1375 = (BLOCK_WIDTH * 4) + BLOCK_PADDING + (COLUMN_GAP * 3);
const WIDTH_1641 = (BLOCK_WIDTH * 5) + BLOCK_PADDING + (COLUMN_GAP * 4);

export const FixedWrapper = styled.div`
  cursor: default;
  position: fixed;
  left: ${CURRENT_SIDEBAR_WIDTH_PX}px;
  top: 0;
  width: calc(100vw - ${CURRENT_SIDEBAR_WIDTH_PX}px);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
`;

export const Container = styled.div`
  box-shadow: 0 1px 1px rgba(0,0,0,0.11), 0 2px 2px rgba(0,0,0,0.11), 0 4px 4px rgba(0,0,0,0.11), 0 6px 8px rgba(0,0,0,0.11), 0 8px 16px rgba(0,0,0,0.11);
  padding: 15px ${BLOCK_SINGLE_PADDING}px ${BLOCK_SINGLE_PADDING}px;
  background: ${ADMIN_SURFACE_3};
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 4px;
  max-width: ${MAX_WIDTH}px;

  @media screen and (max-width: 1110px) {
    width: ${WIDTH_1110}px;
  }

  @media screen and (min-width: 1111px) and (max-width: 1375px) {
    width: ${WIDTH_1375}px;
  }

  @media screen and (min-width: 1376px) and (max-width: 1641px) {
    width: ${WIDTH_1641}px;
  }

  max-height: 80%;
  overflow-y: auto;
`;

const StyledLabel = styled.span`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${ADMIN_TEXT_200};
  text-transform: capitalize;
`;

export const Label = styled(TranslatedText).attrs(props => ({
  component: StyledLabel,
}))``;

export const WrapList = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: ${COLUMN_GAP}px;
  justify-content: flex-start;
`;

export const Button = styled.button`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: ${BLOCK_WIDTH}px;
`;

export const StyledIcon = styled(Icon)`
  position: relative;
  &:hover::after {
    content: "";
    border: 2px solid ${ADMIN_ACCENT_PRIMARY};
    position: absolute;
    left: 0;
    top: 0;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 10px;
    pointer-events: none;
  }
`;
