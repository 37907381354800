import React, { useMemo } from 'react';

import { IGenericOption, OPTION_TYPE, OptionData, OptionType, SelectedOption } from '../../../models';
import { GenericOptionCircleText, GenericSelectorContainer, GenericSelectorHeader, GenericOptionCircle, GenericOptionContainer, GenericOptionValues, Subtitle, Title } from './styles';
import { formatText } from '../utils';

const GENERIC_SELECTOR_ID = 'ecommerce-options_selector-generic';

interface IGenericSelectorProps {
  handleOptionChange: (type: OptionType, option: OptionData) => void;
  option: IGenericOption;
  selectedOptionValue: SelectedOption;
}

const GenericSelector: React.FC<IGenericSelectorProps> = ({
  handleOptionChange,
  option,
  selectedOptionValue: selected,
}) => {
  const { values, type } = option;
  if (type !== OPTION_TYPE.generic) {
    // tslint:disable-next-line:no-console
    console.error('GenericSelector component cannot render because type is not OPTION_TYPE.generic');
    return null;
  }

  return (
    <GenericSelectorContainer data-testid={GENERIC_SELECTOR_ID} id={GENERIC_SELECTOR_ID}>
      <GenericSelectorHeader>
        <Title>{option.name}</Title>
      </GenericSelectorHeader>
      <GenericOptionValues>
        {values.map(optionData => {
          const isDisabled = !optionData.isAvailable;
          const handleClick = () => !isDisabled && handleOptionChange(type, optionData);
          return (
            <GenericOptionCircle
              key={optionData.name}
              isSelected={optionData.value === selected.value.value}
              onClick={handleClick}
              isDisabled={isDisabled}
            >
              <GenericOptionCircleText isSelected={optionData.value === selected.value.value}>
                {optionData.name}
              </GenericOptionCircleText>
            </GenericOptionCircle>
          );
        })}
      </GenericOptionValues>
    </GenericSelectorContainer>
  );
};

export default GenericSelector;

export const SelectedGenericOptionOnDisplay: React.FC<{ isRenderingOnPanel: boolean; selectedOption: SelectedOption; }> = ({ selectedOption, isRenderingOnPanel })=> {
  const name = useMemo(() => {
    return formatText(selectedOption.value.name, selectedOption.type);
  }, [selectedOption.value.name, selectedOption.type]);

  return (
    <GenericOptionContainer isRenderingOnPanel={isRenderingOnPanel}>
      {name}
    </GenericOptionContainer>
  );
};
