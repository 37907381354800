import React, { HTMLAttributes } from 'react';
import { Option, OptionData, OptionType, SelectedOption } from '../../../models';
import { DesktopContainer, MobileDoneButton, Title, VariantName } from './styles';
import OptionsSelector from 'components/Ecommerce/sharedComponents/OptionsSelector';
import { useAdminTranslation } from 'hooks/use-translation';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device';
import MobileModalBottomSheet from 'components/mobile/MobileModalBottomSheet';
import ModalPortal from 'components/modals/ModalPortal';

const OPTIONS_SELECTOR_MODAL_ID = 'ecommerce-options_selector_modal';

interface IOptionsSelectorModalProps extends HTMLAttributes<HTMLDivElement> {
  handleOptionChange: (type: OptionType, variantData: OptionData) => void;
  onClose: () => void;
  options: Option[];
  selectedOptions: SelectedOption[];
  variantName: string;
}

const OptionsSelectorModal = React.forwardRef((props: IOptionsSelectorModalProps, ref) => {
  const { t } = useAdminTranslation();
  const isMobile = useSelector(isMobileLayout);
  const { handleOptionChange, options, selectedOptions, onClose, variantName } = props;

  if (!options || options.length === 0) {
    // tslint:disable-next-line:no-console
    console.error('OptionsSelectorModal component cannot render because options is empty');
    return null;
  }

  if (isMobile) {
    return (
      <ModalPortal>
        <MobileModalBottomSheet
          isOpen={true}
          onClose={onClose}
          data-testid={OPTIONS_SELECTOR_MODAL_ID}
          id={OPTIONS_SELECTOR_MODAL_ID}
          titleKey="ECOMMERCE_CHANGE_SELECTION"
        >
          <VariantName>{variantName}</VariantName>
          <OptionsSelector
            options={options}
            selectedOptions={selectedOptions}
            handleOptionChange={handleOptionChange}
          />
          <MobileDoneButton onClick={onClose}>{t('DONE')}</MobileDoneButton>
        </MobileModalBottomSheet>
      </ModalPortal>
    );
  }

  return (
    <DesktopContainer
      ref={ref as any}
      data-testid={OPTIONS_SELECTOR_MODAL_ID}
      id={OPTIONS_SELECTOR_MODAL_ID}
    >
      <Title>{t('ECOMMERCE_CHANGE_SELECTION')}</Title>
      <OptionsSelector
        options={options}
        selectedOptions={selectedOptions}
        handleOptionChange={handleOptionChange}
      />
    </DesktopContainer>
  );
});

export default OptionsSelectorModal;
