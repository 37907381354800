import React from 'react';
import PropTypes from 'prop-types';

import CardCta from 'components/cards/CardCta';
import CardImage from 'components/cards/CardImage';

export default class ImageCard extends React.Component {
  static propTypes = {
    dispatchCta: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
    isCollapsed: PropTypes.bool,
    onDismiss: PropTypes.func.isRequired,
    renderer: PropTypes.object.isRequired,
    trackCard: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isCollapsed: false,
  };

  /*
  For now, we are going to add the
  ability for the card image to dispatch
  the show panel cta type, but when we do the
  card refactor we need to rethink how the card
  image dispatches ctas. This componnt should have
  the ability tp dispatch all CTA's by clicking the image.
  */

  onEngage = (doc, extra) => this.props.trackCard('engage', doc, extra);

  handleClick = () => {
    const {
      renderer,
      renderer: {
        ctaRenderer,
        ctaRenderer: {
          cardCtaType,
          panelId,
          linkUrl,
        },
      },
      dispatchCta,
    } = this.props;

    if (Boolean(ctaRenderer) && cardCtaType != null) {
      if (cardCtaType === 'show_panel') {
        dispatchCta({ id: panelId });
        this.onEngage(renderer, { cta: 'show_panel' });
      } else if (cardCtaType === 'open_link') {
        window.open(linkUrl, '_blank');
      }
    }
  };

  render() {
    const {
      isCollapsed,
      onDismiss,
      renderer,
      renderer: { imageUrl, ctaRenderer },
    } = this.props;

    return (
      <CardImage
        isCollapsed={isCollapsed}
        onClick={this.handleClick}
        onClose={onDismiss}
        src={imageUrl}
      >
        {ctaRenderer && <CardCta cardRenderer={renderer} />}
      </CardImage>
    );
  }
}
