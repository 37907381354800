import React from 'react';
import { useSelector } from 'react-redux';
import { SITE_NAVIGATION_BODY_ID } from 'global-ids';
import { INavigationChild, INavigationItem, INavigationParent } from 'models/INavigation';
import { getDefaultNavigation, getEditableFolderIdInSiteNavigation, getSearchText } from 'services/navigationv2';
import Dragger from 'components/admin2/Dragger';
import SiteNavigationItem from '../SiteNavigationItem';
import { Container, Content, SITE_NAVIGATION_ITEM_HEIGHT, SiteNavigationItemContainer, StyledDragButt } from './styles';
import useInfiniteScroll from 'hooks/use-infinite-scroll';
import { usePageNavigationContext } from 'components/admin-bridge/PageNavigation/Context';
import { siteStructureSearch } from '../utils';

type BodyProps = {
  item: INavigationParent | INavigationChild;
};

const BodyItem: React.FC<BodyProps> = ({ item }) => {
  const navigation = useSelector(getDefaultNavigation);
  const searchText = useSelector(getSearchText);
  const isParent = !!navigation.parents.find(parent => parent.id === item.id);
  const isChild = !isParent;
  const parentOfTheChild = isChild ? navigation.parents.find(parent => {
    return parent.children.some(child => child.id === item.id);
  }) : undefined;

  if (isChild && !parentOfTheChild) {
    // tslint:disable-next-line:no-console
    console.error(`Could not find parent for child with id ${item.id}`);
    return null;
  }

  return (
    <SiteNavigationItemContainer
      key={item.id}
      isChild={isChild}
    >
      {!searchText && <StyledDragButt maxWidth="unset" />}
      <SiteNavigationItem
        item={item}
        isParent={isParent}
        source="site_navigation"
        {...(isChild && { parentItem: parentOfTheChild! })}
      />
    </SiteNavigationItemContainer>
  );
};

const CHUNK_SIZE = 10;
const getDefaultItemsPerPage = () => Math.floor((window.innerHeight * 0.8) / SITE_NAVIGATION_ITEM_HEIGHT);

const SiteNavigationBody = () => {
  const navigation = useSelector(getDefaultNavigation);
  const searchText = useSelector(getSearchText);
  const draggerRef = React.useRef<HTMLDivElement>(null);
  const editableFolderId = useSelector(getEditableFolderIdInSiteNavigation);
  const [itemsPerPage, setItemsPerPage] = React.useState(getDefaultItemsPerPage());
  const { onDragEnd } = usePageNavigationContext();

  const parents = React.useMemo(() => {
    if (!searchText) {
      return navigation.parents;
    }

    const matchSearch = searchText.toLowerCase();

    return siteStructureSearch(navigation.parents, matchSearch);
  }, [navigation.parents, searchText]);

  const parentAndChildrenLen = React.useMemo(() => {
    const totalChildren = parents.reduce((acc, { children }) => acc + children.length, 0);
    return parents.length + totalChildren;
  },[parents]);

  React.useEffect(() => {
    setItemsPerPage(getDefaultItemsPerPage());
    draggerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  }, [searchText]);

  useInfiniteScroll({
    onReachBottom: () => {
      if (itemsPerPage >= parentAndChildrenLen) {
        return;
      }
      setItemsPerPage(prev => Math.min(prev + CHUNK_SIZE, parentAndChildrenLen));
    },
    refElement: draggerRef,
    offsetPx: 100,
  });

  const renderItem = React.useCallback((data: any) => {
    return <BodyItem item={data.item as INavigationParent | INavigationChild} />;
  }, []);

  const onChange = React.useCallback(({
    items,
    dragItem,
  }: {
    dragItem: INavigationItem;
    items: INavigationParent[];
  }) => {
    // merge the items with the original navigation.parents based on the itemsPerPage to avoid losing the items that are not being displayed
    const newParents = [
      ...items,
      ...parents.slice(itemsPerPage),
    ];

    onDragEnd({
      dragItem,
      items: newParents,
    });
  }, [onDragEnd, parents, itemsPerPage]);

  const onConfirmDrag = ({ destinationParent, dragItem }) => {
    if (searchText) {
      // do not allow drag and drop when searching
      return false;
    }

    if (destinationParent?.type === 'channel') {
      // channel is receiving a child. do NOT allow it. channels can NOT have children
      return false;
    }
    if (dragItem.type === 'folder' && destinationParent !== null) {
      // folder is being dragged into a parent. do NOT allow it. folders can NOT be children
      return false;
    }
    return true;
  };

  const disableDrag = React.useCallback(({ item }) => item.id !== editableFolderId, [editableFolderId]);

  return (
    <Container
      id={SITE_NAVIGATION_BODY_ID}
      data-testid={SITE_NAVIGATION_BODY_ID}
    >
      <Content>
        <Dragger
          draggerRef={draggerRef}
          maxDepth={2}
          onChange={onChange}
          onConfirmChange={onConfirmDrag}
          disableDrag={disableDrag}
          renderItem={renderItem}
          source="site_navigation"
          treeData={parents.slice(0, itemsPerPage)}
        />
      </Content>
    </Container>
  );
};

export default SiteNavigationBody;
