import EditorItemActions from 'components/Grid/components/EditorItemActions';
import { TI18nKey } from 'components/i18n/TranslatedText/i18nKey';
import React, { PropsWithChildren, memo } from 'react';
import { useSelector } from 'react-redux';
import { isUserAdmin } from 'services/auth';
import { EditorItemActionsWrapper, Wrapper } from './styles';
import { isEditMode } from 'services/admin';
import { useFeatureGateEnabled } from 'hooks';
import { Feature } from 'hooks/use-feature-gate-enabled';

interface IQuickEditOverlayProps extends PropsWithChildren {
  testId: string;
  titleKey: TI18nKey;
  onEdit?(): void;
  onMoveDown?(): void;
  onMoveUp?(): void;
  onRemove?(): void;
}

const QuickEditOverlay = React.forwardRef(({ children, testId, titleKey, onEdit, onRemove, onMoveUp, onMoveDown, ...rest }: IQuickEditOverlayProps, ref) => {
  const isAdmin = useSelector(isUserAdmin);
  const isEditing = useSelector(isEditMode);
  const isSimpleModeEnabled = useFeatureGateEnabled({
    feature: Feature.SIMPLE_MODE,
    type: 'feature',
  });

  return (
    <Wrapper ref={ref as any} data-testid={testId} isAdmin={isAdmin} isSimpleModeEnabled={isSimpleModeEnabled} isEditing={isEditing} {...rest}>
      {isEditing && (
        <EditorItemActionsWrapper>
          <EditorItemActions
            titleKey={titleKey}
            onEditIconName="cogV2"
            onEdit={onEdit}
            onRemove={onRemove}
            onMoveUp={onMoveUp}
            onMoveDown={onMoveDown}
          />
        </EditorItemActionsWrapper>
      )}
      {children}
    </Wrapper>
  );
});

export default memo(QuickEditOverlay);
