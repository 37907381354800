import styled from 'styled-components';
import {
  TEXT_100,
} from 'style/constants';
import { ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';

export const Backslash = styled.span`
  &:after {
    content: "/";
  }
  padding: 0 5px;
`;

export const Container = styled.div`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  color: ${TEXT_100};
  line-height: 15px;
  display: flex;
  padding: 0 10px;
  align-items: center;
`;
