import React from 'react';
import { useDispatch } from 'react-redux';
import { SITE_NAVIGATION_ID } from 'global-ids';
import { useTrackAdminNavigation } from 'hooks';
import SearchInput from 'components/admin2/ui/SearchInput';
import { Container } from './styles';
import SiteNavigationHeader from './SiteNavigationHeader';
import SiteNavigationBody from './SiteNavigationBody';
import { setSearchText } from 'services/navigationv2';
import useDebounceWithFunction from 'hooks/use-debounce-with-function';

const SiteNavigation = () => {
  const [localSearchQuery, setLocalSearchQuery] = React.useState('');
  const dispatch = useDispatch();
  const trackAdminNavigation = useTrackAdminNavigation();

  const handleTrackSearchClick = React.useCallback(() => {
    trackAdminNavigation({ menu: 'channel_select', element: 'search' });
  }, [trackAdminNavigation]);

  const handleSearchInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalSearchQuery(e.target.value);
  }, []);

  useDebounceWithFunction(() => {
    dispatch(setSearchText(localSearchQuery));
  }, localSearchQuery, 400);

  return (
    <Container id={SITE_NAVIGATION_ID} data-testid={SITE_NAVIGATION_ID}>
      <SearchInput
        height={32}
        onClick={handleTrackSearchClick}
        onChange={handleSearchInputChange}
        padding="0 20px 0 0"
        searchPlaceholderKey="SEARCH_ALL_PAGES"
        short={true}
        testIdSearchInput="siteNavigationSearchInput"
        value={localSearchQuery}
      />
      <SiteNavigationHeader />
      <SiteNavigationBody />
    </Container>
  );
};

export default SiteNavigation;
