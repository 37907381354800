import React from 'react';
import PropTypes from 'prop-types';
import { CONTENT_INNER_CONTAINER_ID } from 'global-ids';
import { youtubeVideoFallbackThumbnail } from 'services/video/utils';
import MaestroPlaylist from '../MaestroPlaylist';
import VideoItem from '../VideoItem';

export default class GridItem extends React.Component {
  static propTypes = {
    doc: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    isEditingGridRegion: PropTypes.bool,
    isGridMobile: PropTypes.bool.isRequired,
    onEdit: PropTypes.func,
    onMoveDown: PropTypes.func,
    onMoveUp: PropTypes.func,
    onRemove: PropTypes.func,
    onSetTagPlaylist: PropTypes.func.isRequired,
    showBorder: PropTypes.bool.isRequired,
    slideContainerMaxWidth: PropTypes.number.isRequired,
    slidesWidth: PropTypes.number.isRequired,
    totalSlidesToShow: PropTypes.number.isRequired,
    trackClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isEditingGridRegion: false,
    onEdit: () => {},
    onMoveDown: undefined,
    onMoveUp: undefined,
    onRemove: undefined,
  };

  scrollToTop = () => {
    const contentElement = document.getElementById(CONTENT_INNER_CONTAINER_ID);
    contentElement?.scroll({
      behavior: 'smooth',
      top: 0,
    });
  };

  trackInteraction = (data) => {
    const { trackClick } = this.props;
    const { _id, title } = data || {};
    trackClick({ id: _id, kind: 'video', title });
  };

  handleTagPlaylist = (videoId) => {
    const { doc: { tag }, onSetTagPlaylist } = this.props;

    if (tag) {
      onSetTagPlaylist({ tag, videoId });
    }
  };

  onClick = (videoData) => {
    this.handleTagPlaylist(videoData._id);
    this.trackInteraction(videoData);
    this.scrollToTop();
  };

  render() {
    const {
      isGridMobile,
      slidesWidth,
      totalSlidesToShow,
      doc,
      showBorder,
      isEditingGridRegion,
      onEdit,
      onRemove,
      onMoveUp,
      onMoveDown,
      slideContainerMaxWidth,
    } = this.props;
    const onGridItemRemove = () => onRemove(doc._id);
    if (doc.members) {
      return (
        <MaestroPlaylist
          doc={doc}
          isEditing={isEditingGridRegion}
          isGridMobile={isGridMobile}
          onClick={this.onClick}
          onEdit={onEdit}
          onMoveDown={onMoveDown}
          onMoveUp={onMoveUp}
          onRemove={onRemove ? onGridItemRemove : undefined}
          showBorder={showBorder}
          slideContainerMaxWidth={slideContainerMaxWidth}
          slidesWidth={slidesWidth}
          totalSlidesToShow={totalSlidesToShow}
        />
      );
    }
    return !doc.archived && (
      <VideoItem
        doc={youtubeVideoFallbackThumbnail(doc)}
        isEditing={isEditingGridRegion}
        isGridMobile={isGridMobile}
        onClick={() => this.onClick(doc)}
        onEdit={onEdit}
        onMoveDown={onMoveDown}
        onMoveUp={onMoveUp}
        onRemove={onRemove ? onGridItemRemove : undefined}
        showBorder={showBorder}
        slidesWidth={slidesWidth}
      />
    );
  }
}
