import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrientationDegrees } from 'services/device/actions';
import { getOrientationDegrees } from 'services/device/selectors';

export default function ScreenOrientation() {
  const dispatch = useDispatch();
  const currentOrientation = useSelector(getOrientationDegrees);

  const updateOrientation = (newValue: number) => {
    if (currentOrientation !== newValue) {
      dispatch(updateOrientationDegrees(newValue));
    }
  };

  React.useEffect(() => {
    if (screen.orientation) {
      const handleEvent = () => {
        switch (screen.orientation.type) {
          case 'portrait-primary': updateOrientation(0); break;
          case 'portrait-secondary': updateOrientation(180); break;
          case 'landscape-primary': updateOrientation(90); break;
          case 'landscape-secondary': updateOrientation(-90); break;
        }
      };
      screen.orientation.addEventListener('change', handleEvent);
      handleEvent();

      return () => {
        screen.orientation.removeEventListener('change', handleEvent);
      };
    } else { // For iOS Safari
      const handleEvent = () => {
        if (typeof window.orientation === 'number') {
          updateOrientation(window.orientation);
        }
      };
      window.addEventListener('orientationchange', handleEvent);
      handleEvent();

      return () => {
        window.removeEventListener('orientationchange', handleEvent);
      };
    }
  }, []);

  return null;
}
