import { ObjectValues } from 'utils';

export const GRID_TEXT_ALIGNMENT = {
  topLeft: 'topLeft',
  topCenter: 'topCenter',
  topRight: 'topRight',
  centerLeft: 'centerLeft',
  center: 'center',
  centerRight: 'centerRight',
  bottomLeft: 'bottomLeft',
  bottomCenter: 'bottomCenter',
  bottomRight: 'bottomRight',
} as const;

export const GRID_TEXT_ALIGNMENT_WORD_MAP: Record<GridTextAlignmentOptions, string> = {
  topLeft: 'Top Left',
  topCenter: 'Top Center',
  topRight: 'Top Right',
  centerLeft: 'Center Left',
  center: 'Center',
  centerRight: 'Center Right',
  bottomLeft: 'Bottom Left',
  bottomCenter: 'Bottom Center',
  bottomRight: 'Bottom Right',
};

export type GridTextAlignmentOptions = ObjectValues<typeof GRID_TEXT_ALIGNMENT>;
