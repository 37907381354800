import styled from 'styled-components';
import { DROPDOWN_CONTAINER, DROPDOWN_LABEL } from 'css-classes';
import { ADMIN_SURFACE_3, ADMIN_SURFACE_4, ADMIN_SURFACE_5, ADMIN_TEXT_100, ADMIN_TEXT_200 } from 'style/constants';
import { ADMIN_TEXT_LABEL_M_MEDIUM, ADMIN_TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';

export const Container = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  margin-top: 16px;
  margin-bottom: 20px;
  z-index: 2;

  & input {
    width: 80%;
  }

  & .${DROPDOWN_CONTAINER} {
    height: 56px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 16px;
    background: ${ADMIN_SURFACE_3};
  }

  & .${DROPDOWN_LABEL} {
    height: 40px;
    background-color: ${ADMIN_SURFACE_4};
    color: ${ADMIN_TEXT_100};

    ${ADMIN_TEXT_LABEL_M_MEDIUM};
    &:hover {
      background-color: ${ADMIN_SURFACE_5};
    }
  }

  & .${DROPDOWN_LABEL}.first-row {
    height: 40px;

    color: ${ADMIN_TEXT_200};
    ${ADMIN_TEXT_LABEL_S_MEDIUM};
    &:hover {
      background-color: ${ADMIN_SURFACE_4};
    }
    cursor: default;
  }

  & .${DROPDOWN_LABEL}.last-row {
    background-color: ${ADMIN_SURFACE_3};
    &:hover {
      background-color: ${ADMIN_SURFACE_3};
    }
  }

  & .${DROPDOWN_LABEL}.is-editing {
    background-color: ${ADMIN_SURFACE_3};
    &:hover {
      background-color: ${ADMIN_SURFACE_3};
    }
  }
 `;
