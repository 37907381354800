import Icon from 'components/admin2/Icon';
import { SURFACE_4, SURFACE_6, TEXT_100, TEXT_200 } from 'style/constants';
import { TEXT_LABEL_L_BOLD, TEXT_LABEL_M_MEDIUM } from 'style/design-system/textStyles';
import styled from 'styled-components';

export const Container = styled.div`
  border-top: 1px solid ${SURFACE_4};
  padding-top: 30px;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  padding: 60px 0px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

export const Title = styled.span`
  color: ${TEXT_100};
  ${TEXT_LABEL_L_BOLD};
`;

export const Text = styled.span`
  color: ${TEXT_200};
  ${TEXT_LABEL_M_MEDIUM};
  text-align: center;
`;

export const ShoppingCartIcon = styled(Icon).attrs(() => ({
  name: 'shoppingCart',
}))<{ isCartOpen?: boolean }>`
  cursor: pointer;

  display: flex;
  width: 64px;
  height: 64px;
  justify-content: center;
  align-items: center;
  border-radius: 66.667px;
  background: ${SURFACE_4};

  & svg {
    width: 34.667px;
    height: 34.667px;
    transform: translate(-2px, 2px);
    & path {
      fill: ${SURFACE_6};
    }
  }
`;
