import React, { memo } from 'react';
import ReactPlayer, { ReactPlayerProps, Config } from 'react-player';
import Iframe from './players/Iframe';

import { PlayerTypes } from 'models';
import type { SubtitleOption } from '../Player/Context';

/**
 * The original type, `ReactPlayerProps`, isn't quite right since it
 * is a union with `{ [key: string]: any }`, which diminishes type safety
 */
type CleanReactPlayerProps = Pick<
  ReactPlayerProps,
  | 'url'
  | 'playing'
  | 'loop'
  | 'controls'
  | 'volume'
  | 'muted'
  | 'playbackRate'
  | 'width'
  | 'height'
  | 'style'
  | 'progressInterval'
  | 'playsinline'
  | 'playIcon'
  | 'previewTabIndex'
  | 'pip'
  | 'stopOnUnmount'
  | 'light'
  | 'fallback'
  | 'wrapper'
  | 'onReady'
  | 'onStart'
  | 'onPlay'
  | 'onPause'
  | 'onBuffer'
  | 'onBufferEnd'
  | 'onEnded'
  | 'onClickPreview'
  | 'onEnablePIP'
  | 'onDisablePIP'
  | 'onError'
  | 'onDuration'
  | 'onSeek'
  | 'onProgress'
>;

export type IReactPlayerWrapperProps = CleanReactPlayerProps & {
  className?: string;
  config?: Config;
  forwardRef?: (playerInstance: ReactPlayer | null) => unknown;
  id?: string;
  playerType: PlayerTypes;
  playing: boolean;
  selectedSubtitle?: SubtitleOption;
};

export const ReactPlayerWrapper = memo(
  ({
    playerType,
    config,
    forwardRef,
    ...playerProps
  }: IReactPlayerWrapperProps) => {
    switch (playerType) {
      case PlayerTypes.iframe:
        return <Iframe ref={forwardRef} {...playerProps} />;
      case PlayerTypes.dailymotion:
      case PlayerTypes.facebook:
      case PlayerTypes.mixcloud:
      case PlayerTypes.soundcloud:
      case PlayerTypes.streamable:
      case PlayerTypes.youtube:
      case PlayerTypes.vimeo:
      case PlayerTypes.wistia:
      case PlayerTypes.twitch:
        return (
          <ReactPlayer ref={forwardRef} {...playerProps} config={config} />
        );
      case PlayerTypes.livestream:
      case PlayerTypes.file:
      default:
        const patchedConfig =
          typeof playerProps.url === 'string'
            ? patchPlayerProps(playerProps.url)
            : undefined;

        return (
          <ReactPlayer
            ref={forwardRef}
            {...playerProps}
            config={patchedConfig?.config ?? config}
            url={patchedConfig?.url}
          />
        );
    }
  },
);

const patchPlayerProps = (url: string) => {
  const isMux = /^https\:\/\/stream\.mux\.com/.test(url);

  if (isMux && /\.m3u8$/.test(url)) {
    return {
      url: url.replace(/\.m3u8$/, ''),
      config: {
        mux: {
          attributes: {
            poster: '',
          },
        },
      } as Config,
    };
  }

  if (isMux && /\.m3u8\?token=/.test(url)) {
    const [playbackUrl, token] = url.split('.m3u8?token=');

    return {
      url: playbackUrl,
      config: {
        mux: {
          attributes: {
            'playback-token': token,
            poster: '',
          },
        },
      } as Config,
    };
  }

  return {
    url,
  };
};
