import styled, { keyframes } from 'styled-components';
import { ADMIN_ALERT_CONFIRMATION, ADMIN_SURFACE_2 } from 'style/constants';
import { ADMIN_TEXT_BODY_S_BOLD } from 'style/design-system/textStyles';
import { TOAST_PORTAL } from 'global-ids';

const ToastAnimation = keyframes`
  0%, 100% {
    opacity: 0;
    transform: translateX(-50%) translateY(-30px);
  }
  20%, 80% {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
`;

export const ToastsContainer = styled.div.attrs({ id: TOAST_PORTAL })`
  position: absolute;
  top: 0;
  left: 0;
  // toast should be above modal (z-index: 1000)
  z-index: 1001;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const Toast = styled.div<{ color?: string }>`
  position: absolute;
  top: 20px;
  left: 50%;
  max-width: 300px;
  padding: 8px 20px;
  background: ${ADMIN_SURFACE_2};
  color: ${({ color }) => color || ADMIN_ALERT_CONFIRMATION};
  ${ADMIN_TEXT_BODY_S_BOLD};
  border: 1px solid;
  border-radius: 5px;
  animation: ${ToastAnimation} 3s ease-out;
  opacity: 0;
  transform: translateX(-50%) translateY(-30px);
`;
