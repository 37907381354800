import React from 'react';
import Carousel from 'components/ui/Carousel';
import TranslatedText from 'components/i18n/TranslatedText';
import ShareButtons from 'components/Video/video-controls/ShareButtons';
import { ControlsProps } from 'components/Video/video-controls/VideoOverlay/interfaces';
import {
  ShareModalContainer,
} from './styles';

export default function ShareModal(props: ControlsProps) {
  return (
    <ShareModalContainer>
      <TranslatedText stringKey="SHARE" />
      <Carousel maxItems={4}>
        <ShareButtons {...props} />
      </Carousel>
    </ShareModalContainer>
  );
}
