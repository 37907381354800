import React, { ReactNode, useMemo } from 'react';
import MediaLibraryTable, { IColumn } from 'components/admin2/MediaLibraryTable';
import capitalize from 'lodash/capitalize';
import {
  MediaLibraryTableWrapper,
  HeaderCheckbox,
  VideoLibraryListWrapper,
} from './styles';
import { EditSettingsButton, DeleteButton } from 'components/admin2/ui/Button';
import SearchInput from 'components/admin2/ui/SearchInput';
import { IVideo } from 'models';
import { IVideoUploadFile } from 'services/video-upload/state';
import { SelectionContext, SelectionContextProvider } from './SelectionContext';
import { useCalculateElementWidth } from 'hooks/use-calculate-element-width';
import { ThumbanailCell } from './ThumbnailCell';
import { NameCell } from './NameCell';
import { useAdminTranslation } from 'hooks/use-translation';

const PLAYER_TYPE_KEYS = {
  dailymotion: 'LABEL_PLAYER_TYPE_DAILYMOTION',
  facebook: 'LABEL_PLAYER_TYPE_FACEBOOK',
  file: 'LABEL_PLAYER_TYPE_FILE',
  iframe: 'LABEL_PLAYER_TYPE_IFRAME',
  livestream: 'LABEL_PLAYER_TYPE_LIVESTREAM',
  mixcloud: 'LABEL_PLAYER_TYPE_MIXCLOUD',
  soundcloud: 'LABEL_PLAYER_TYPE_SOUNDCLOUD',
  streamable: 'LABEL_PLAYER_TYPE_STREAMABLE',
  twitch: 'LABEL_PLAYER_TYPE_TWITCH',
  vimeo: 'LABEL_PLAYER_TYPE_VIMEO',
  wistia: 'LABEL_PLAYER_TYPE_WISTIA',
  youtube: 'LABEL_PLAYER_TYPE_YOUTUBE',
} as const;

export type IVideoItem = (
  | IVideo
  | IVideoUploadFile
) & {
  id: string;
};

export interface IVideoLibraryListProps {
  actions?: (item: IVideoItem, index: number) => ReactNode;
  handleSelectionChanged?: (itemIds: Set<string>) => void;
  loading?: boolean;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  onRetryUpload: (id: string) => void;
  onSearch: (text: string) => void;
  videos: IVideoItem[];
}

const VideoLibraryList: React.FC<IVideoLibraryListProps> = ({
  videos,
  loading: isLoading = false,
  onRetryUpload,
  onEdit,
  onDelete,
  onSearch,
  actions,
  handleSelectionChanged,
}) => {
  const { t } = useAdminTranslation();
  const [actionsColumnWidth, calculateActionsColumnWidth] = useCalculateElementWidth();

  const videoLibraryListColumns = useMemo<IColumn[]>(
    () => [
      {
        id: 'thumbnail',
        testId: 'thumbnail',
        headerStyle: { pointerEvents: 'auto' },
        Header: () => (
          <SelectionContext.Consumer>
            {
              ({ allItemsSelected, onSelectAll }) => (
                <HeaderCheckbox
                  size={15}
                  width="15px"
                  checked={allItemsSelected}
                  onCheckedChange={onSelectAll}
                  labelKey="ADMIN_LABEL_THUMBNAIL"
                />
              )
            }
          </SelectionContext.Consumer>
        ),
        Cell: (cellData) => (
          <SelectionContext.Consumer>
            {
              ({ selectedVideos, onSelect }) => (
                <ThumbanailCell
                  id={cellData.index}
                  isSelected={selectedVideos.has(cellData.original.id)}
                  video={cellData.original}
                  onSelect={onSelect}
                />
              )
            }
          </SelectionContext.Consumer>
        ),
        width: 181,
      },
      {
        id: 'name',
        testId: 'name',
        headerKey: 'ADMIN_LABEL_NAME',
        Cell: (cellData) => (
          <NameCell
            id={cellData.index}
            video={cellData.original}
            onRetryUpload={onRetryUpload}
          />
        ),
      },
      {
        id: 'source',
        accessor: 'player',
        testId: 'source',
        headerKey: 'ADMIN_LABEL_SOURCE',
        transformValue: (value) => t(PLAYER_TYPE_KEYS[value]) || capitalize(value),
        width: 98,
      },
      {
        width: actionsColumnWidth,
        headerKey: 'ADMIN_LABEL_QUEST_ACTIONS',
        Cell: (cellData: any) => (
          <div
            ref={calculateActionsColumnWidth}
            style={{
              display: 'flex',
            }}
          >
            {actions && actions?.(cellData.original, cellData.index)}
            <EditSettingsButton
              onClick={onEdit.bind(null, cellData.original.id)}
              data-testid={`editSettingsButton-${cellData.index}`}
            />
            <div style={{ width: 5 }} />
            <DeleteButton
              onClick={onDelete.bind(null, cellData.original.id)}
              data-testid={`deleteButton-${cellData.index}`}
            />
          </div>
        ),
      },
    ],
    [
      onRetryUpload,
      onEdit,
      actions,
      onDelete,
      calculateActionsColumnWidth,
      actionsColumnWidth,
    ],
  );

  return (
    <SelectionContextProvider
      videos={videos}
      handleSelectionChanged={handleSelectionChanged}
    >
      <VideoLibraryListWrapper data-testid="videoLibraryList">
        <SearchInput
          searchPlaceholderKey="ADMIN_PLACEHOLDER_SEARCH"
          onSearch={onSearch}
          loading={isLoading}
          testIdSearchInput="videoLibraryListSearchInput"
        />
        <MediaLibraryTableWrapper data-testid="videoLibraryListTable">
          <MediaLibraryTable
            data={
              // to calculate column with, we only render the first row
              (actionsColumnWidth === undefined && videos.length)
              ? [videos[0]]
              : videos
            }
            columns={videoLibraryListColumns}
          />
        </MediaLibraryTableWrapper>
      </VideoLibraryListWrapper>
    </SelectionContextProvider>
  );
};

export default VideoLibraryList;
