import Color from 'color';
import memo from 'memoizee';
import { MAESTRO_BLACK, MAESTRO_WHITE } from 'style/constants';

declare type OffsetFn = (hex: string, amount: number) => string;
export const offset = memo<OffsetFn>((hex, amount) => {
  const color = Color(hex);
  return color[color.isDark() ? 'whiten' : 'blacken'](amount).hex();
}, {
  max: 500,
  primitive: true,
});

// https://stackoverflow.com/questions/3942878
declare type GetLFn = (c: number) => number;
const getL = memo<GetLFn>((c: number) => {
  const cP = c / 255.0;
  return cP <= 0.03928 ? cP / 12.92 : ((cP + 0.055) / 1.055) ** 2.4;
}, {
  max: 500,
  primitive: true,
});

type BwFn = (hex: string) => string;
export const bw = memo<BwFn>((hex) => {
  const { r, g, b } = Color(hex).rgb().object();
  const rL = getL(r);
  const gL = getL(g);
  const bL = getL(b);
  // This is adjusted based on this formula:
  // https://graphicdesign.stackexchange.com/questions/62368
  return (0.2126 * rL) + (0.7152 * gL) + (0.0722 * bL) > 0.5 ? MAESTRO_BLACK : MAESTRO_WHITE;
}, {
  max: 500,
  primitive: true,
});

declare type HexFn = (rgba: string, opacity: number) => string;
export const hexFromRgb = memo<HexFn>((rgb, opacity) => {
  return Color(rgb).alpha(opacity).hex();
}, {
  max: 500,
  primitive: true,
});

declare type RgbaFn = (hex: string, amount: number) => string;
export const rgba = memo<RgbaFn>((hex, opacity) => {
  return Color(hex).alpha(opacity).string();
}, {
  max: 500,
  primitive: true,
});

type LightenFn = (hex: string, value: number) => string;
export const lighten = memo<LightenFn>((hex, value) => {
  return Color(hex).lighten(value).string();
});

type DarkenFn = (hex: string, value: number) => string;
export const darken = memo<DarkenFn>((hex, value) => {
  return Color(hex).darken(value).string();
});

type LumiosityFn = (hex: string) => number;
export const luminosity = memo<LumiosityFn>(hex => {
  return Color(hex).luminosity();
});

const linearShadeRGB = (color: string, percentage: number) => {
  const i = parseInt;
  const r = Math.round;
  const [a, b, c, d] = color.split(',');
  let P: number | boolean = percentage < 0;
  const t = P ? 0 : 255 * percentage;
  P = P ? 1 + percentage : 1 - percentage;
  return 'rgb' + (d ? 'a(' : '(') + r(i(a[3] === 'a' ? a.slice(5) : a.slice(4)) * P + t) + ',' + r(i(b) * P + t) + ',' + r(i(c) * P + t) + (d ? ',' + d : ')');
};

function wc_hex_is_light(color: string, maxBrightnessPercentage = 155/256) {
  const hex = color.replace('#', '');
  const cR = parseInt(hex.substr(0, 2), 16);
  const cG = parseInt(hex.substr(2, 2), 16);
  const cB = parseInt(hex.substr(4, 2), 16);
  const brightness = ((cR * 299) + (cG * 587) + (cB * 114)) / 1000;
  return brightness > maxBrightnessPercentage * 256;
}

interface LightenDarkenParams {
  darkenValue?: number;
  hex: string;
  lightenValue?: number;
  maxBrightness?: number;
  percentage?: number;
}

// darkens if base color is too light, lightens if is too dark
type LightenDarkenFn = (params: LightenDarkenParams) => string;
export const lightenDarken = memo<LightenDarkenFn>(({ hex, maxBrightness, percentage, ...params }) => {
  const { lightenValue = percentage, darkenValue = percentage } = params;
  if (!lightenValue || !darkenValue) {
    return hex;
  }
  const color = Color(hex);
  const tooLight = wc_hex_is_light(hex, maxBrightness);
  const value = tooLight ? -lightenValue : darkenValue;
  return linearShadeRGB(`rgba(${color.red()},${color.green()},${color.blue()},${color.alpha()})`, value);
});

interface ContrastOnBackgroundParams {
  darkenPercentage?: number;
  lightenPercentage?: number;
  minDifference?: number;
  percentage?: number;
}

/** darkens colorHex if backgroundHex is too light, lightens if backgroundHex is too dark
 * @param {number} [minDifference] - [0-1] The minimum difference in luminosity to start applying changes to hex1
 */
export const contrastOnBackground = memo((colorHex: string, backgroundHex: string, {
  minDifference,
  percentage,
  ...params
}: ContrastOnBackgroundParams = {} as ContrastOnBackgroundParams) => {
  try {
    const color = Color(colorHex);
    const backgroundColor = Color(backgroundHex);
    const tooSimilar = minDifference
      ? Math.abs(color.luminosity() - backgroundColor.luminosity()) < minDifference
      : color.isDark() === backgroundColor.isDark();

    if (!tooSimilar) {
      return colorHex;
    }

    const {
      lightenPercentage = percentage || 1 - color.lightness() / 100,
      darkenPercentage = (percentage && -percentage) || 1 - color.lightness(),
    } = params;

    const value = color.isDark() ? lightenPercentage : darkenPercentage;
    return Color(linearShadeRGB(
      `rgba(${color.red()},${color.green()},${color.blue()},${color.alpha()})`,
      value,
    )).hex();
  } catch(err) {
    return colorHex;
  }
});
