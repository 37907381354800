import React, { ComponentType } from 'react';

import ErrorGuard from 'components/core/ErrorGuard';

function ErrorGuarded<T>(Component: ComponentType<T>) {
  return class extends React.Component<T> {
    static displayName = `WithErrorGuard(${Component.displayName || Component.name})`;

    render() {
      return (
        <ErrorGuard>
          <Component {...this.props} />
        </ErrorGuard>
      );
    }
  } as typeof Component;
}

export default function withErrorGuard() {
  return ErrorGuarded;
}
