import React from 'react';
import PropTypes from 'prop-types';
import CardButton from 'components/cards/CardButton';
import { PEOPLE_ID } from 'components/objects/PanelV2/constants';

export default class ShowPersonCardCta extends React.Component {
  static propTypes = {
    ctaRenderer: PropTypes.shape({
      panelId: PropTypes.string,
      personId: PropTypes.string,
      text: PropTypes.string,
    }).isRequired,
    onClick: PropTypes.func.isRequired,
    onEngage: PropTypes.func.isRequired,
    testIdText: PropTypes.string,
  };

  static defaultProps = {
    testIdText: undefined,
  };

  handleClick = () => {
    const {
      ctaRenderer,
      onClick,
      onEngage,
    } = this.props;
    onClick({
      doc: {
        renderer: {
          panelType: PEOPLE_ID,
          people: [{
            id: ctaRenderer.personId,
          }],
        },
      },
    });
    onEngage({ cta: 'show person' });
  };

  render() {
    const {
      ctaRenderer,
      testIdText,
    } = this.props;
    const { text } = ctaRenderer;
    return (
      <CardButton data-testid={testIdText} onClick={this.handleClick}>
        { text }
      </CardButton>
    );
  }
}
