import type { TranslationKey } from 'hooks/use-translation';
import { ModeTypes } from 'services/admin/constants';
import { ActionKey } from 'services/admin/constants';
import { RoleScope } from 'services/auth/models';
import { Feature } from 'services/feature-gate';
import { asElementTypes } from 'shared/type-utils';
import { STUDIO_APP_FLAVOR } from 'config';
import { getHelpCenterURL } from 'utils';
export { ActionKey } from 'services/admin/constants';

export const ADMIN_BAR_FOOTER_ID = 'admin-bar-footer';

export const INSIGHTS_TABS = ['engagement', 'retention', 'trends'];

export enum ActionType {
  adminQuery = 'adminQuery',
  clearActiveAction = 'clearActiveAction',
  openAdminMode = 'openAdminMode',
  openLink = 'openLink',
  openSubMenu = 'openSubMenu',
  openSuperAdminModal = 'openSuperAdminModal',
  openTrends = 'openTrends',
  openWizard = 'openWizard',
  openLibrary = 'openLibrary',
}

export interface Action {
  actionIcon?: string;
  actionKey: ActionKey;
  actionTitleKey?: string;
  actionType?: ActionType;
  actionValue?: string;
  canUseOutsideFreeTrial?: boolean;
  dividerBottom?: boolean;
  dividerTop?: boolean;
  featureGate?: string;
  hidden?: boolean;
  hideIfGated?: boolean;
  hideRightArrow?: boolean;
  scopeBlacklist?: RoleScope[];
  testId?: string;
}

export interface SubMenuProps {
  actions: Action[];
  backAction?: ActionKey;
  defaultAction?: ActionKey;
  showCurrentChannel?: boolean;
  subMenuNameKey?: TranslationKey;
  titleIcon?: string;
  titleKey?: TranslationKey;
}

export const SUB_MENU_MAP = asElementTypes<SubMenuProps>()({
  main: {
    subMenuNameKey: 'ADMIN_LABEL_MAIN_MENU',
    actions: [
      {
        actionKey: ActionKey.pageOrChannel,
        actionType: ActionType.clearActiveAction,
      },
      {
        actionKey: ActionKey.access,
        actionTitleKey: 'ADMIN_LABEL_GATE',
        actionIcon: 'nav2AccessStroke',
        actionType: ActionType.openAdminMode,
        actionValue: ModeTypes.ACCESS_CONTROL,
        hideRightArrow: true,
        scopeBlacklist: [RoleScope.Producer],
      },
      {
        actionKey: ActionKey.content,
        actionTitleKey: 'ADMIN_LABEL_PAGE_CONTENT',
        actionIcon: 'nav2ContentStroke',
        actionType: ActionType.adminQuery,
        actionValue: 'pages',
        hideRightArrow: true,
      },
      {
        actionKey: ActionKey.design,
        actionTitleKey: 'ADMIN_LABEL_DESIGN',
        actionIcon: 'nav2DesignStroke',
        actionType: ActionType.openAdminMode,
        actionValue: ModeTypes.DESIGN,
        hideRightArrow: true,
      },
      {
        actionKey: ActionKey.overlays,
        actionTitleKey: 'ADMIN_LABEL_OVERLAYS',
        actionIcon: 'nav2Overlays',
        actionType: ActionType.openAdminMode,
        actionValue: ModeTypes.BROADCAST,
      },
      {
        actionKey: ActionKey.panels,
        actionTitleKey: 'ADMIN_LABEL_PANELS',
        actionIcon: 'nav2Panels',
        actionType: ActionType.openAdminMode,
        actionValue: ModeTypes.PANELS,
      },
      {
        actionKey: ActionKey.pageOrChannelSettings,
        actionTitleKey: 'ADMIN_LABEL_SETTINGS',
        actionIcon: 'nav2SettingsStroke',
        hideRightArrow: true,
        scopeBlacklist: [RoleScope.Producer],
      },
      {
        actionKey: ActionKey.analytics,
        actionTitleKey: 'ADMIN_LABEL_ANALYTICS',
        actionIcon: 'nav2Analytics',
        actionType: ActionType.openSubMenu,
        actionValue: 'analytics',
        dividerTop: true,
        scopeBlacklist: [RoleScope.Producer],
      },
      {
        actionKey: ActionKey.community,
        actionTitleKey: 'ADMIN_LABEL_COMMUNITY',
        actionIcon: 'nav2Community',
        actionType: ActionType.adminQuery,
        actionValue: 'community',
        scopeBlacklist: [RoleScope.Producer],
      },
      {
        actionKey: ActionKey.library,
        actionTitleKey: 'ADMIN_LABEL_LIBRARY',
        actionIcon: 'nav2Library',
        actionType: ActionType.openLibrary,
        actionValue: 'library',
      },
      {
        actionKey: ActionKey.settings,
        actionTitleKey: 'ADMIN_LABEL_SITE_SETTINGS',
        actionIcon: 'nav2Settings',
        actionType: ActionType.openSubMenu,
        actionValue: 'settings',
        scopeBlacklist: [RoleScope.Producer],
      },
      {
        actionKey: ActionKey.wizard,
        actionTitleKey: 'ADMIN_LABEL_WIZARD',
        actionIcon: 'nav2Wizard',
        actionType: ActionType.openWizard,
        actionValue: 'wizard',
        dividerTop: true,
        scopeBlacklist: [RoleScope.Producer],
      },
      {
        actionKey: ActionKey.help,
        actionTitleKey: 'ADMIN_LABEL_HELP',
        actionIcon: 'nav2Help',
        actionType: ActionType.openLink,
        actionValue: getHelpCenterURL(STUDIO_APP_FLAVOR),
      },
    ],
  },
  analytics: {
    defaultAction: ActionKey.trends,
    titleIcon: 'nav2Analytics',
    titleKey: 'ADMIN_LABEL_ANALYTICS',
    actions: [
      {
        actionKey: ActionKey.trends,
        actionTitleKey: 'ADMIN_LABEL_TRENDS',
        actionIcon: 'nav2Trends',
        actionType: ActionType.openTrends,
        scopeBlacklist: [RoleScope.Producer],
      },
      {
        actionKey: ActionKey.earnings,
        actionTitleKey: 'ADMIN_LABEL_EARNINGS',
        actionIcon: 'nav2Earnings',
        actionType: ActionType.adminQuery,
        actionValue: 'earnings',
        featureGate: Feature.EARNINGS,
        scopeBlacklist: [RoleScope.Producer],
      },
      {
        actionKey: ActionKey.engagement,
        actionTitleKey: 'ADMIN_LABEL_ENGAGEMENT',
        actionIcon: 'nav2Engagement',
        actionType: ActionType.openTrends,
        scopeBlacklist: [RoleScope.Producer],
      },
      {
        actionKey: ActionKey.videos,
        actionTitleKey: 'ADMIN_LABEL_VIDEOS',
        actionIcon: 'nav2Videos',
        actionType: ActionType.adminQuery,
        actionValue: 'analytics-videos',
        scopeBlacklist: [RoleScope.Producer],
      },
      {
        actionKey: ActionKey.insights,
        actionTitleKey: 'ADMIN_LABEL_INSIGHTS',
        actionIcon: 'nav2Insights',
        actionType: ActionType.adminQuery,
        actionValue: 'analytics-insights',
        featureGate: Feature.INSIGHTS_ANALYTICS,
        hideIfGated: true,
        scopeBlacklist: [RoleScope.Producer],
      },
    ],
  },
  // TODO: check with product and clean hidden and orphaned actions that are not being used, ex logos subs
  orphans: {
    // needed to be able to dispatch navigateToAdminBarAction for actions without a menu
    actions: [
      {
        actionKey: ActionKey.editor,
        actionType: ActionType.openAdminMode,
        actionValue: ModeTypes.EDIT,
        hidden: true,
      },
      {
        actionKey: ActionKey.streaming,
        actionType: ActionType.openAdminMode,
        actionValue: ModeTypes.STREAM_METHOD_SELECT,
        featureGate: Feature.STREAMING,
        hidden: true,
      },
      {
        actionKey: ActionKey.subs,
        actionType: ActionType.adminQuery,
        actionValue: 'entitlements',
        testId: 'adminbarEntitlements',
        scopeBlacklist: [RoleScope.Producer],
        hidden: true,
      },
      {
        actionKey: ActionKey.logos,
        hidden: true,
      },
    ],
  },
  settings: {
    titleIcon: 'nav2Settings',
    titleKey: 'ADMIN_LABEL_SITE_SETTINGS',
    actions: [
      {
        actionKey: ActionKey.thirdParty,
        actionTitleKey: 'ADMIN_LABEL_3RD_PARTY',
        actionIcon: 'nav2ThirdParty',
        scopeBlacklist: [RoleScope.Producer],
      },
      {
        actionKey: ActionKey.siteAds,
        actionTitleKey: 'ADS',
        actionIcon: 'nav2SiteAds',
        featureGate: Feature.ADS,
        hideIfGated: true,
      },
      {
        actionKey: ActionKey.achievements,
        actionTitleKey: 'ADMIN_LABEL_ACHIEVEMENTS',
        actionIcon: 'nav2Achievements',
        actionType: ActionType.adminQuery,
        actionValue: 'achievements',
        featureGate: Feature.ACHIEVEMENTS,
        testId: 'adminbarAchievements',
        scopeBlacklist: [RoleScope.Producer],
      },
      {
        actionKey: ActionKey.admins,
        actionTitleKey: 'ADMIN_LABEL_ADMINS',
        actionIcon: 'nav2Admins',
        testId: 'adminAdmins',
        scopeBlacklist: [RoleScope.Producer],
      },
      {
        actionKey: ActionKey.billing,
        actionTitleKey: 'ADMIN_LABEL_BILLING',
        actionIcon: 'nav2Billing',
        canUseOutsideFreeTrial: true,
        featureGate: Feature.BILLING,
        scopeBlacklist: [RoleScope.Producer],
      },
      {
        actionKey: ActionKey.developer,
        actionTitleKey: 'ADMIN_LABEL_DEVELOPER',
        actionIcon: 'nav2Developer',
        featureGate: Feature.DEVELOPER,
        scopeBlacklist: [RoleScope.Producer],
      },
      {
        actionKey: ActionKey.icons,
        actionTitleKey: 'ADMIN_LABEL_ICONS',
        actionIcon: 'nav2Icons',
        scopeBlacklist: [RoleScope.Producer],
      },
      {
        actionKey: ActionKey.localization,
        actionTitleKey: 'ADMIN_LABEL_LOCALIZATION',
        actionIcon: 'nav2Localization',
        scopeBlacklist: [RoleScope.Producer],
        featureGate: Feature.END_USER_LOCALIZATION,
        hideIfGated: true,
      },
      {
        actionKey: ActionKey.login,
        actionTitleKey: 'ADMIN_LABEL_LOG_IN',
        actionIcon: 'nav2Login',
        scopeBlacklist: [RoleScope.Producer],
      },
      {
        actionKey: ActionKey.receipts,
        actionTitleKey: 'ADMIN_LABEL_RECEIPTS',
        actionIcon: 'nav2Receipts',
        scopeBlacklist: [RoleScope.Producer],
      },
      {
        actionKey: ActionKey.userProfile,
        actionTitleKey: 'ADMIN_LABEL_USER_PROFILES',
        actionIcon: 'nav2UserProfile',
        scopeBlacklist: [RoleScope.Producer],
      },
      {
        actionKey: ActionKey.videoControls,
        actionTitleKey: 'ADMIN_LABEL_VIDEO_PLAYER',
        actionIcon: 'adminbarVideoPlayer',
        scopeBlacklist: [RoleScope.Producer],
      },
      {
        actionKey: ActionKey.superAdmin,
        actionTitleKey: 'ADMIN_LABEL_SUPER_ADMIN',
        actionIcon: 'nav2SuperAdmin',
        actionType: ActionType.openSuperAdminModal,
        scopeBlacklist: [RoleScope.Producer],
      },
    ],
  },
});

export const SCOPE_BLACKLIST_ROLE_MAP = [
    ...SUB_MENU_MAP.main.actions,
    ...SUB_MENU_MAP.analytics.actions,
    ...SUB_MENU_MAP.settings.actions,
    ...SUB_MENU_MAP.orphans.actions,
  ]
  .reduce((roles, action) => {
    if (action?.scopeBlacklist?.length) {
      for (const role of action.scopeBlacklist) {
        roles[role].push(action.actionKey);
      }
    }
    return roles;
  }, {
    [RoleScope.ChatModerator]: [],
    [RoleScope.Page]: [],
    [RoleScope.Producer]: [],
    [RoleScope.Quest]: [],
    [RoleScope.SiteAdmin]: [],
  } as Record<RoleScope, ActionKey[]>);

export type SubMenuKey = keyof typeof SUB_MENU_MAP;

export const SUB_MENU_MAP_KEYS = Object.keys(SUB_MENU_MAP) as SubMenuKey[];

export const SUB_MENU_ACTION_MAP = SUB_MENU_MAP_KEYS.reduce((map, subMenuKey) => {
  const currentSubMenu = SUB_MENU_MAP[subMenuKey];
  currentSubMenu.actions.forEach(({ actionKey }) => map[actionKey] = subMenuKey);
  return map;
}, {} as { [K in ActionKey]: SubMenuKey });


