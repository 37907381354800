// tslint:disable:no-bitwise
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ID_SIDEBAR } from 'services/renderer/ids';
import { ActionKey } from 'services/admin/constants';
import { getObject, getPageId } from 'services/app/selectors';
import {
  navigateToAdminBarAction,
  setActiveAction,
  clearEditTarget,
  resetAllPendingAdminChanges,
  clearRegionRendererDraft,
  clearPendingPageDoc,
  clearPendingThemesDocs,
  setDraftTheme,
  setPreviewActiveTheme,
  disablePanels,
  clearPendingGateChange,
  clearPendingPanelChange,
  setAdminMode,
} from 'services/admin/actions';
import { getForkedTheme } from 'services/themes';
import { getPlanFeatures } from 'services/billing';
import { isNil } from 'lodash';
import { GateKind } from 'models/IGate';
import { ModalKinds } from 'services/modals/types';
import { showModal } from 'services/modals';
import { getEditTarget, getPanelsActive } from 'services/admin';
import { getActiveAction } from 'services/admin/selectors';

export default function useHandlers() {
  const dispatch = useDispatch();
  const pageId = useSelector(getPageId);
  const forkedTheme = useSelector(getForkedTheme);
  const planFeatures = useSelector(getPlanFeatures);
  const panelsEnabled = useSelector(getPanelsActive);
  const currentPageOrChannel = useSelector(getObject);
  const gate = currentPageOrChannel?.data?.gate;
  const isPanelActive = useSelector(getPanelsActive);
  const editModeTarget = useSelector(getEditTarget);
  const activeAction = useSelector(getActiveAction);

  const [gateExists, isGateEnabled] = useMemo(() => {
    return [
      !isNil(gate) && gate.kind !== GateKind.NONE,
      gate?.active,
    ];
  }, [gate]);

  const clearPendingChanges = useCallback(() => {
    dispatch(clearEditTarget());
    dispatch(resetAllPendingAdminChanges());
    dispatch(clearPendingPageDoc(pageId));
    dispatch(clearPendingThemesDocs());
    dispatch(clearRegionRendererDraft(ID_SIDEBAR));
    dispatch(setDraftTheme(forkedTheme));
    dispatch(setPreviewActiveTheme(null));
    dispatch(clearPendingGateChange(pageId));
    dispatch(clearPendingPanelChange(pageId));
    if (panelsEnabled) {
      dispatch(disablePanels());
    }
  }, [pageId, forkedTheme, panelsEnabled]);

  const navToStreaming = useCallback(() => {
    const planCanHoldFreeEvents = !!planFeatures?.freeEvents;

    if (!planCanHoldFreeEvents && !gateExists) {
      dispatch(
        showModal({
          kind: ModalKinds.setUpAccess,
        }),
      );
      return;
    }

    if (!planCanHoldFreeEvents && !isGateEnabled) {
      dispatch(
        showModal({
          kind: ModalKinds.upgradePlan,
          data: {
            planWarningMessage: 'ADMIN_UPGRADE_PLAN_LANDING_CHANNEL_WITHOUT_GATE_ERROR',
            preSelectedPlan: 'custom',
          },
        }),
      );
      return;
    }

    dispatch(navigateToAdminBarAction({
      actionKey: ActionKey.streaming,
    }));
  }, [gateExists, isGateEnabled, planFeatures]);


  const navToGateEditor = useCallback(() => {
    dispatch(navigateToAdminBarAction({
      actionKey: ActionKey.access,
    }));
  }, []);

  const exitEditor = useCallback(() => {
    dispatch(setAdminMode(null));
    if (activeAction && activeAction !== ActionKey.content) {
      dispatch(setActiveAction(null));
    }

    if (isPanelActive) {
      dispatch(disablePanels());
    }

    if (editModeTarget) {
      dispatch(clearEditTarget());
    }
  }, [activeAction, isPanelActive, editModeTarget]);

  return {
    clearPendingChanges,
    navToStreaming,
    navToGateEditor,
    exitEditor,
  };
}
