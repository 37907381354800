import React, { useCallback, useMemo, useState } from 'react';
import { NavigationParentTag, NavigationParentWrapper } from './styles';
import FloatingNavigationMenu from './FloatingNavigationMenu';
import { IExternalLink, INavigationParent } from 'models/INavigation';
import hash from 'json-stable-stringify';
import { useAdminTranslation } from 'hooks/use-translation';
import FloatingParentsMenu from './FloatingNavigationMenu/FloatingParentsMenu';
import { DownArrow } from './FloatingNavigationMenu/styles';
import { getCurrentPageId } from 'services/app/selectors';
import { useSelector } from 'react-redux';
import { NAVIGATION_BAR_ITEM_ID, NAVIGATION_BAR_MORE_ID } from 'global-ids';
import { OTHERS_ID } from './utils';
import { useNavigationBarContext } from './NavigationBarContext';


interface INavigationParentProps {
  externalLinks?: IExternalLink[];
  moreParents?: INavigationParent[];
  parent?: INavigationParent;
}

const NavigationParent: React.FC<INavigationParentProps> = ({
  parent,
  moreParents = [],
  externalLinks = [],
}) => {
  const { setFloatMenuId, floatMenuId, getPathBySlug } = useNavigationBarContext();
  const hasMore = useMemo(
    () => !!moreParents.length || !!externalLinks.length,
    [hash(moreParents), hash(externalLinks)],
  );
  const { t } = useAdminTranslation();
  const title = hasMore ? t('MORE') : parent?.name || '';
  const hasChildren = parent?.children?.length! > 0;
  const currentPageId = useSelector(getCurrentPageId);
  const isCurrentPage = parent?.id === currentPageId;

  const isMenuOpen =
    (parent?.id && floatMenuId === parent.id) ||
    (floatMenuId === OTHERS_ID && hasMore);

  const handleHoverEnter = () => {
    if (!hasChildren && parent) {
      return;
    }
    const id = parent?.id ?? OTHERS_ID;
    setFloatMenuId(id);
  };

  const handleHoverLeave = () => {
    setFloatMenuId(null);
  };

  const renderMenu = useCallback(() => {
    if (hasMore) {
      return (
        <FloatingParentsMenu
          moreParents={moreParents}
          externalLinks={externalLinks}
          parent={parent}
        />
      );
    }

    return <FloatingNavigationMenu navParent={parent} />;
  }, [hasMore, hash(moreParents), hash(externalLinks), hash(parent)]);
  const isFolder = parent?.type === 'folder';

  return (
    <NavigationParentWrapper data-testid={hasMore ? NAVIGATION_BAR_MORE_ID : NAVIGATION_BAR_ITEM_ID}>
      <NavigationParentTag
        href={parent && !isFolder ? getPathBySlug(parent.slug) : undefined}
        isFolder={isFolder}
        onMouseEnter={handleHoverEnter}
        onMouseLeave={handleHoverLeave}
        isCurrentPage={isCurrentPage}
      >
        {title}
        {Boolean(hasChildren || hasMore) && (
          <DownArrow flipped={isMenuOpen} />
        )}
      </NavigationParentTag>
      {isMenuOpen && <span>{renderMenu()}</span>}
    </NavigationParentWrapper>
  );
};

export default NavigationParent;
