import PropTypes from 'prop-types';
import React, { ErrorInfo } from 'react';

const instanceSet = new Set<ErrorGuard>();

if (process.env.NODE_ENV === 'development') {
  window.pleaseUnderstand = () => {
    instanceSet.forEach((instance) => {
      instance.setState({ hasError: false });
    });
  };
}

export default class ErrorGuard extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  state = {
    hasError: false,
  };

  // TODO: Log error to Insights!
  componentDidCatch(error: Error, info: ErrorInfo) {
    /* tslint:disable no-console */
    console.error('ErrorGuard caught error:');
    console.error(error);
    console.error(info.componentStack);
    /* tslint:enable */
    this.setState({ hasError: true });
  }

  componentDidMount() {
    if (process.env.NODE_ENV === 'development') {
      instanceSet.add(this);
    }
  }

  componentWillUnmount() {
    if (process.env.NODE_ENV === 'development') {
      instanceSet.delete(this);
    }
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return null;
    }

    return children;
  }
}
