import React, { useMemo } from 'react';

import { ISizeOption, OPTION_TYPE, OptionData, OptionType, SelectedOption } from '../../../models';
import { SizeOptionCircleText, SizeSelectorContainer, SizeSelectorHeader, SizeOptionCircle, SizeOptionContainer, SizeOptionValues, Subtitle, Title } from './styles';
import { useAdminTranslation } from 'hooks/use-translation';
import { formatText } from '../utils';

const SIZE_SELECTOR_ID = 'ecommerce-options_selector-size';

interface ISizeSelectorProps {
  handleOptionChange: (type: OptionType, option: OptionData) => void;
  option: ISizeOption;
  selectedOptionValue: SelectedOption;
}

const SizeSelector: React.FC<ISizeSelectorProps> = ({
  handleOptionChange,
  option,
  selectedOptionValue: selected,
}) => {
  const { t } = useAdminTranslation();
  const { values, type } = option;
  if (type !== OPTION_TYPE.size) {
    // tslint:disable-next-line:no-console
    console.error('SizeSelector component cannot render because type is not OPTION_TYPE.size');
    return null;
  }

  return (
    <SizeSelectorContainer data-testid={SIZE_SELECTOR_ID} id={SIZE_SELECTOR_ID}>
      <SizeSelectorHeader>
        <Title>{option.name}</Title>
        <Subtitle>{t('ECOMMERCE_SIZE_CHART')}</Subtitle>
      </SizeSelectorHeader>
      <SizeOptionValues>
        {values.map(optionData => {
          const isDisabled = !optionData.isAvailable;
          const handleClick = () => !isDisabled && handleOptionChange(type, optionData);
          return (
            <SizeOptionCircle
              key={optionData.name}
              isSelected={optionData.value === selected.value.value}
              onClick={handleClick}
              isDisabled={isDisabled}
            >
              <SizeOptionCircleText isSelected={optionData.value === selected.value.value}>
                {optionData.name}
              </SizeOptionCircleText>
            </SizeOptionCircle>
          );
        })}
      </SizeOptionValues>
    </SizeSelectorContainer>
  );
};

export default SizeSelector;

export const SelectedSizeOptionOnDisplay: React.FC<{ isRenderingOnPanel: boolean; selectedOption: SelectedOption; }> = ({ selectedOption, isRenderingOnPanel })=> {
  const name = useMemo(() => {
    return formatText(selectedOption.value.name, selectedOption.type);
  }, [selectedOption.value.name, selectedOption.type]);

  return (
    <SizeOptionContainer isRenderingOnPanel={isRenderingOnPanel}>
      {name}
    </SizeOptionContainer>
  );
};
