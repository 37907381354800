import React, { useCallback, useMemo, useRef } from 'react';
import {
  NavigationBarContainer,
  NavigationParentTag,
  NavigationLinksContainer,
  StyledParentDivider,
} from './styles';
import { NAVIGATION_BAR_ID } from 'global-ids';
import { useSelector } from 'react-redux';
import { getNavigationAppliedInThePage } from 'services/navigationv2';
import hash from 'json-stable-stringify';
import NavigationParent from './NavigationParent';
import { getCurrentPageId } from 'services/app/selectors';
import useFilterNavigationByParentSize from './use-filter-navigation-by-parent-size';
import { hidePrivatePagesFromNavigation } from 'services/navigationv2/utils';
import NavigationBarProvider, { useNavigationBarContext } from './NavigationBarContext';

const NavigationBar: React.FC = () => {
  const {
    navigationData,
  } = useNavigationBarContext();
  const { parents, externalLinks } = useMemo(() => hidePrivatePagesFromNavigation(navigationData), [navigationData]);
  const currentPageId = useSelector(getCurrentPageId);
  const isCurrentPage = parent?.id === currentPageId;

  const {
    maxLinks,
    maxParents,
    moreLinks,
    moreParents,
    reachedParentLimit,
    maxAmountOfNavItems,
    containerRef,
  } = useFilterNavigationByParentSize(
    parents,
    externalLinks,
  );

  const showMaxParentsAndMore = useCallback(() => {
    const shouldRenderMore = moreParents.length + externalLinks.length > 0;
    return (
      <>
        {maxParents.map((parent) => (
          <NavigationParent
            key={parent.id}
            parent={parent}
          />
        ))}
        {shouldRenderMore && (
          <NavigationParent
            moreParents={moreParents}
            externalLinks={externalLinks}
          />
        )}
      </>
    );
  }, [
    hash(moreParents),
    hash(externalLinks),
    hash(maxParents),
  ]);

  const showMaxLinksAndMore = useCallback(() => {
    return (
      <>
        {maxLinks.map((link) => (
          <NavigationParentTag
            href={link.url}
            key={link.id}
            isFolder={false}
            target="_blank"
          >
            {link.name}
          </NavigationParentTag>
        ))}
        {!!moreLinks.length && (
          <NavigationParent
            externalLinks={moreLinks}
          />
        )}
      </>
    );
  }, [
    hash(maxLinks),
    hash(moreLinks),
  ]);

  const showDefaultDisplay = useCallback(() => {
    const spotsRemaining = maxAmountOfNavItems - maxParents.length;
    const adjustedLinks = maxLinks.slice(0, spotsRemaining);
    const remainingLinks = [
      ...maxLinks.slice(spotsRemaining, maxLinks.length),
      ...moreLinks,
    ];
    const shouldRenderMore = remainingLinks.length > 0;

    return (
      <>
        {maxParents.map((parent) => (
          <NavigationParent
            key={parent.id}
            parent={parent}
          />
        ))}
        {!!externalLinks.length && (
          <>
            <StyledParentDivider />
            {adjustedLinks.slice().map((link, index) => (
              <NavigationParentTag
                href={link.url}
                key={String(index)}
                isFolder={false}
                target="_blank"
                isCurrentPage={isCurrentPage}
              >
                {link.name}
              </NavigationParentTag>
            ))}
          </>
        )}
        {shouldRenderMore && (
          <NavigationParent
            externalLinks={remainingLinks}
          />
        )}
      </>
    );
  }, [
    hash(maxParents),
    hash(maxLinks),
    hash(moreLinks),
    maxAmountOfNavItems,
  ]);

  const renderAppliedNavigation = () => {
    if (reachedParentLimit) return showMaxParentsAndMore();
    if (externalLinks.length && !parents.length) return showMaxLinksAndMore();
    return showDefaultDisplay();
  };

  return (
    <NavigationBarContainer
      id={NAVIGATION_BAR_ID}
      data-testid={NAVIGATION_BAR_ID}
    >
      <NavigationLinksContainer ref={containerRef}>
        {renderAppliedNavigation()}
      </NavigationLinksContainer>
    </NavigationBarContainer>
  );
};

export default () => {
  const appliedNavigation = useSelector(getNavigationAppliedInThePage);
  return (
    <NavigationBarProvider navigationId={appliedNavigation._id!}>
      <NavigationBar />
    </NavigationBarProvider>
  );
};
