import React, { PropsWithChildren } from 'react';
import { ILandingPageContent, ILandingPageContentBlockKinds, IPagesRowBlock, ITaggedVideosBlock } from 'services/app/models/ILandingPageContent';
import { getCountdownTitle, getCountdownStartTime } from 'services/app/selectors';
import { isMobileLayout } from 'services/device';
import { AddButton, GridContainer, HoverBoundary, ModalWrapper, Wrapper } from './styles';
import Countdown from 'components/ui/Countdown';
import { useSelector } from 'react-redux';
import LandingContentItem from 'components/LandingContentItem';

interface AdminGridViewProps extends PropsWithChildren {
  activeModalIndex: number;
  landingContent: ILandingPageContent[];
  onAdd: (index: number) => void;
  onGridContentEdit: (kind: ILandingPageContentBlockKinds, index: number) => void;
  onPageBlockEdit: (data: any) => void;
  onPagesRowEdit: (index: number, data: IPagesRowBlock) => void;
  onRemove: (index: number) => void;
  onReorder: (landingContent: ILandingPageContent[]) => void;
  onTaggedVideoEdit: (block: ITaggedVideosBlock) => void;
}

const AddHover = ({ onAdd, newPageBlockIndex, children: nestedChildren }) => {
  const handleAdd = () => {
    onAdd(newPageBlockIndex);
  };

  return (
    <HoverBoundary>
      <ModalWrapper>
        {nestedChildren}
        <AddButton onClick={handleAdd} />
      </ModalWrapper>
    </HoverBoundary>
  );
};

const AdminGridView: React.FC<AdminGridViewProps> = ({
  activeModalIndex,
  landingContent,
  onAdd,
  onReorder,
  children,
  onPagesRowEdit,
  onGridContentEdit,
  onPageBlockEdit,
  onRemove,
  onTaggedVideoEdit,
}) => {
  const isMobile = useSelector(isMobileLayout);
  const countdownMessage = useSelector(getCountdownTitle);
  const countdownStartTime = useSelector(getCountdownStartTime);

  const onMoveUp = (index: number) => () => {
    const newLandingContent = [...landingContent];
    const item = newLandingContent[index];
    newLandingContent[index] = newLandingContent[index - 1];
    newLandingContent[index - 1] = item;
    onReorder(newLandingContent);
  };

  const onMoveDown = (index: number) => () => {
    const newLandingContent = [...landingContent];
    const item = newLandingContent[index];
    newLandingContent[index] = newLandingContent[index + 1];
    newLandingContent[index + 1] = item;
    onReorder(newLandingContent);
  };

  return (
    <GridContainer>
      {isMobile && (
        <Countdown message={countdownMessage} startTime={countdownStartTime} />
      )}
      {landingContent?.length ? null : children}
      <Wrapper>
        {landingContent.map((item, index) => (
          <React.Fragment key={`${item.arrayId}`}>
            <AddHover newPageBlockIndex={index} onAdd={onAdd}>
              {activeModalIndex === index ? children : null}
            </AddHover>
            <LandingContentItem
              index={index}
              item={item}
              onGridContentEdit={onGridContentEdit}
              onPageBlockEdit={onPageBlockEdit}
              onPagesRowEdit={onPagesRowEdit}
              onRemove={onRemove}
              onTaggedVideoEdit={onTaggedVideoEdit}
              onMoveDown={index === landingContent.length - 1 ? undefined : onMoveDown(index)}
              onMoveUp={index === 0 ? undefined : onMoveUp(index)}
            />
            {index === landingContent.length - 1 && (
              <AddHover newPageBlockIndex={index + 1} onAdd={onAdd}>
                {activeModalIndex === index + 1 ? children : null}
              </AddHover>
            )}
          </React.Fragment>
        ))}
      </Wrapper>
    </GridContainer>
  );
};

export default AdminGridView;
