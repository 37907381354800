import React, { useMemo } from 'react';
import styled from 'styled-components';
import Dropdown from 'components/admin2/ui/Dropdown';
import range from 'lodash/range';

export interface PercentageDropdownProps {
  className?: string;
  compactDropdown?: boolean;
  labelKey?: string;
  onValueChange?: (value: number) => void;
  value: number;
}

const PERCENTAGES = range(10, 110, 10).map(n => ({
  label: `${n}%`,
  value: n / 100,
}));

export default function({
  className,
  compactDropdown,
  value,
  labelKey,
  onValueChange,
}: PercentageDropdownProps) {
  const handleChange = (option: typeof PERCENTAGES[number]) => {
    onValueChange?.(option.value);
  };

  const selectedValue = useMemo(() => (
    PERCENTAGES.find(option => option.value === value)
  ), [value]);

  return (
    <PercentageDropdown
      className={className}
      compactDropdown={compactDropdown}
      hideSearchIcon={true}
      labelKey={labelKey}
      onChange={handleChange}
      options={PERCENTAGES}
      value={selectedValue}
    />
  );
}

const PercentageDropdown = styled(Dropdown).attrs({
  padding: '0',
  labelPadding: '0 0 10px 0',
  width: 'auto',
})`
  width: 80px;
` as typeof Dropdown;
