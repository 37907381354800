import React from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import { getUserId } from 'services/auth';
import { connect } from 'react-redux';
import type IState from 'services/state';

const PLAYER_ID_PREFIX = 'Iframe-player-';

class Iframe extends ReactPlayer {
  static displayName = 'Iframe';
  container: null | HTMLIFrameElement = null;
  currentTime: number = 0;
  loadTimeout: ReturnType<typeof setTimeout> | null = null;
  playerID: string = PLAYER_ID_PREFIX + randomString();
  playTime: null | number = null;
  state = {
    loaded: false,
  };

  static canEnablePIP() {
    return false;
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps: ReactPlayerProps) {
    if (prevProps.url !== this.props.url) {
      this.load();
    }
  }

  componentWillUnmount(): void {
    if (this.loadTimeout) {
      clearTimeout(this.loadTimeout);
    }
  }

  getCurrentTime(): number {
    let playing = 0;

    if (this.playTime) {
      playing = (Date.now() - this.playTime) / 1000;
    }

    return this.currentTime + playing;
  }

  getDuration(): number {
    return Infinity;
  }

  getSecondsLoaded = () => {
    return Infinity;
  }

  load() {
    const { onReady } = this.props;
    const { loaded } = this.state;

    if (loaded) {
      if (onReady) {
        onReady(this);
      }

      return;
    }

    this.loadTimeout = setTimeout(() => {
      this.load();
    }, 100);
  }

  mute() {
    // no-op as that's not supported
    return;
  }

  pause() {
    this.currentTime = this.getCurrentTime();
    this.playTime = null;

    if (this.props.onPause) {
      this.props.onPause();
    }
  }

  play() {
    this.playTime = Date.now();
    if (this.props.onPlay) {
      this.props.onPlay();
    }
  }

  postAccountDataToIframe = () => {
    if (this.state.loaded) {
      this.container?.contentWindow?.postMessage(
        {
          account: {
            _id: this.props.userId,
          },
          location: window.location.href,
        },
        '*',
      );
    }
  }

  ref = (container: HTMLIFrameElement) => {
    if (!this.container && container) {
      this.setState({ loaded: true });
    }
    this.container = container;
  }

  render() {
    const style = {
      height: '100%',
      width: '100%',
    };

    const pauseWrapperStyle = {
      alignItems: 'center',
      background: 'rgba(255,255,255,0.3)',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      width: '100%',
    };

    const pauseStyle = {
      borderStyle: 'double',
      borderWidth: '0px 0px 0px 50px',
      color: 'gray',
      height: '60px',
    };

    const { url, playing } = this.props;
    const { loaded } = this.state;

    if (playing || !loaded) {
      return (
        <iframe
          id={this.playerID}
          onLoad={this.postAccountDataToIframe}
          ref={this.ref}
          src={
            playing && typeof url === 'string'
              ? url.replace(/^iframe:/i, '')
              : undefined
          }
          frameBorder="0"
          scrolling="no"
          style={style}
          allowFullScreen={true}
          allow="encrypted-media"
        />
      );
    }

    // pause flow for iframe
    return (
      <div style={style}>
        <div style={pauseWrapperStyle}>
          <div className="pause" style={pauseStyle} />
        </div>
      </div>
    );
  }

  stop() {
    this.currentTime = this.getCurrentTime();
    this.playTime = null;

    this.props.onPaus?.();
  }

  unmute() {
    // no-op as that's not supported
    return;
  }
}

const mapStateToProps = (state: IState) => ({
  userId: getUserId(state),
});

function randomString()  {
  return Math.random().toString(36).substr(2, 5);
}

export default connect(mapStateToProps)(Iframe);
