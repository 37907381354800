import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BroadcastOverlay, CustomOverlay } from 'components/Video/video-content/Overlays';
import { getOverlays, getOverlayBeingPreviewed, getLastOverlay, IOverlayGeneric } from 'services/overlays/selectors';
import styled from 'styled-components';

export const Wrapper = styled.div`
`;

export const OverlayRenderers = (_props: {}) => {

  const overlayList = useSelector(getOverlays);
  const previewingOverlay = useSelector(getOverlayBeingPreviewed);
  const lastOverlay = useSelector(getLastOverlay);

  const chooseTargetOverlay = (): IOverlayGeneric | null => previewingOverlay || lastOverlay;

  const [targetOverlay, setTargetOverlay]: [IOverlayGeneric | null, any] = useState(chooseTargetOverlay());

  useEffect(() => {
    setTargetOverlay(chooseTargetOverlay());
  }, [previewingOverlay, overlayList, lastOverlay]);

  return (
    <Wrapper>
      <CustomOverlay />
      <BroadcastOverlay overlay={targetOverlay} />
    </Wrapper>
  );
};
