import React, { useCallback, useMemo } from 'react';
import { useAdminTranslation } from 'hooks/use-translation';
import { useDispatch, useSelector } from 'react-redux';
import { getObject } from 'services/app';
import { getNavigationAppliedInThePage, getNavigationSelectedInTheDropdown, setNavigationAppliedInThePage } from 'services/navigationv2';
import { Container } from './styles';
import { getPendingPage } from 'services/admin/selectors';
import { setPendingPageDoc } from 'services/admin';
import produce from 'immer';
import set from 'lodash/set';

const ApplyNavigationButton = () => {
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();
  const selectedNavigation = useSelector(getNavigationSelectedInTheDropdown);
  const appliedNavigation = useSelector(getNavigationAppliedInThePage);
  const pendingPageDoc = useSelector(getPendingPage);
  const page = useSelector(getObject);

  const isDisabled = useMemo(() => {
    return appliedNavigation._id === selectedNavigation._id;
  }, [appliedNavigation._id, selectedNavigation._id]);

  const handleClick = useCallback(() => {
    if (isDisabled) return;
    dispatch(setNavigationAppliedInThePage(selectedNavigation._id!, page._id));
    // update the pending page doc if pending changes exist
    if (pendingPageDoc) {
      dispatch(setPendingPageDoc(
        pendingPageDoc._id,
        produce(pendingPageDoc, (draft) => set(draft, 'data.navigation_id', selectedNavigation._id)),
      ));
    }
  }, [selectedNavigation._id, page._id, isDisabled, dispatch, pendingPageDoc]);


  return (
    <Container onClick={handleClick} disabled={isDisabled}>
      {t('ADMIN_NAVIGATION_BLOCK_APPLY')}
    </Container>
  );
};

export default ApplyNavigationButton;
