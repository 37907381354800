import React, { FC } from 'react';
import type { IVideoItem } from './VideoLibraryList';
import { ThumbnailWrapper, CellCheckbox, ThumbnailImageWrapper } from './styles';
import { ThumbnailImage } from 'components/Grid/styles';
import { DEFAULT_VIDEO_PLACEHOLDER_ICON } from 'shared/constants';

export const ThumbanailCell: FC<{
  id: number,
  isSelected: boolean;
  onSelect: (id: string) => void;
  video: IVideoItem;
}> = ({ id, video, onSelect, isSelected }) => {
  const onChange = () => onSelect(video.id);

  return (
    <ThumbnailWrapper>
      <CellCheckbox
        size={15}
        width="15px"
        checked={isSelected}
        onChange={onChange}
      />
      <ThumbnailImageWrapper>
        <ThumbnailImage
          src={video.thumbnail ?? DEFAULT_VIDEO_PLACEHOLDER_ICON}
          alt={video.title}
          data-testid={`thumbnailCardImage-${id}`}
        />
      </ThumbnailImageWrapper>
    </ThumbnailWrapper>
  );
};
