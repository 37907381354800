import React, { useCallback, useEffect, useState } from 'react';
import hash from 'json-stable-stringify';
import { useSelector } from 'hooks';
import { getAdminTheme } from 'services/themes';
import { getStudioGuestModeInviteCode } from 'services/livestream';
import { Container, Iframe } from './styles';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import { MAESTRO_STUDIO_BASE_URL } from 'config';
import { getSiteId } from 'services/app/selectors';
import { getUserName } from 'services/auth';

const MaestroStudioGuest = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [studioUrl, setStudioUrl] = useState(new URL(MAESTRO_STUDIO_BASE_URL));
  const studioInviteCode = useSelector(getStudioGuestModeInviteCode);
  const siteId = useSelector(getSiteId);
  const adminTheme = useSelector(getAdminTheme);
  const userName = useSelector(getUserName);

  useEffect(
    () => {
      if (studioInviteCode) {
        const parentUrl = window.location.href;
        const url = new URL(studioInviteCode, MAESTRO_STUDIO_BASE_URL);
        url.searchParams.set('clientUrl', parentUrl);
        url.searchParams.set('siteId', siteId);
        url.searchParams.set('theme', hash(adminTheme));

        if (userName) {
          url.searchParams.set('userName', userName);
        }

        setStudioUrl(url);
      }
    },
    [studioInviteCode, adminTheme, userName],
  );

  const onLoad = useCallback(
    () => setLoading(false),
    [],
  );

  return (
    <Container>
      {loading && <LoadingSpinner />}
      <Iframe
        src={studioUrl.href}
        allow="camera *;microphone *;document-domain; clipboard-read; clipboard-write; display-capture;"
        onLoad={onLoad}
        loading={loading}
      />
    </Container>
  );
};

export default MaestroStudioGuest;
