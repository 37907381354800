import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { allowNewAdmin } from 'services/admin';
import { isUserAdmin } from 'services/auth';
import { isDesktopLayout } from 'services/device';

const wrappedComponentPropTypes = {
  showAdminLoadable: PropTypes.bool.isRequired,
};

const createMapStateToProps = oldAdmin => state => ({
  showAdminLoadable: (oldAdmin || allowNewAdmin(state)) &&
    isUserAdmin(state) &&
    isDesktopLayout(state),
});

const AdminLoadable = ({ loader, name = '?', oldAdmin = false }) => {
  const LoadableComponent = React.lazy(loader);

  const WrappedComponent = (props) => {
    const { showAdminLoadable, ...rest } = props;

    if (!showAdminLoadable) {
      return null;
    }

    return (
      <React.Suspense fallback={null}>
        <LoadableComponent {...rest} />
      </React.Suspense>
    );
  };

  WrappedComponent.displayName = `AdminLoadable(${name})`;
  WrappedComponent.propTypes = wrappedComponentPropTypes;

  return connect(createMapStateToProps(oldAdmin))(WrappedComponent);
};

export default AdminLoadable;
