import styled, { keyframes, css } from 'styled-components';
import { mobileOnly } from 'style/mixins';

export const BROADCAST_OVERLAY_TRANSITION_TIME_IN_MILLISECONDS = 250;

export const ToggleOverlay = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
`;

export const CustomExternalOverlay = styled.iframe.attrs(() => ({
  allowtransparency: true,
}))`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;


export const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: none;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: none;
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
`;

export const OverlayWrapper = styled.div<{limitOverlayWidth: boolean, showVideoControls: boolean, showVideoControlsMobile: boolean}>`
  position: absolute;
  left: 0;
  bottom: 0;
  ${({ showVideoControls }) => (
    showVideoControls ?
      css`
        margin-bottom: 55px;
        ${mobileOnly`
          margin-bottom: 2px;
      `}
      `
    : ''
  )};
  ${({ limitOverlayWidth }) => (
    limitOverlayWidth ?
      css`
        margin: 0px 0px 55px 20px;
        max-width: 545px;
        min-width: 490px;
        width: 55%;
        ${mobileOnly`
          margin: 0 2px;
          width: 99%;
          min-width: auto;
        `}
      `
    : ''
  )};
  ${({ showVideoControlsMobile }) => (
    showVideoControlsMobile ?
    mobileOnly`
      margin-bottom: 55px;`
    : ''
    )};
  z-index: 2;
  &.entering {
    animation: ${fadeIn} ${BROADCAST_OVERLAY_TRANSITION_TIME_IN_MILLISECONDS}ms ease-out;
  }
  &.exiting {
    animation: ${fadeOut} ${BROADCAST_OVERLAY_TRANSITION_TIME_IN_MILLISECONDS}ms ease-in;
    opacity: 0;
  }
`;

