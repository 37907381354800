import { ADMIN_SURFACE_5, ADMIN_TEXT_100 } from 'style/constants';
import { css } from 'styled-components';
import { ObjectValues } from 'utils';
import { Option } from '.';
import { CTAVariant } from 'components/ui/CTAButton';

export enum Alignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export const IMAGE_LAYOUT = {
  contentInside: 'contentInside',
  contentBelow: 'contentBelow',
} as const;
export type ImageLayout = ObjectValues<typeof IMAGE_LAYOUT>;

export const OVERFLOW_BEHAVIOR = {
  slider: 'slider',
  stack: 'stack',
} as const;
export type OverflowBehavior = ObjectValues<typeof OVERFLOW_BEHAVIOR>;

export const BACKGROUND_TYPES = {
  color: 'color',
  image: 'image',
} as const;
export type BackgroundType = ObjectValues<typeof BACKGROUND_TYPES>;

export const GRADIENT_MASK_TYPES = {
  none: 'none',
  gradient: 'gradient',
  blur: 'blur',
} as const;
export type GradientMaskOption = ObjectValues<typeof GRADIENT_MASK_TYPES>;

export const LAYOUT = {
  Left: 'left',
  Stacked: 'stacked',
  Right: 'right',
} as const;

export type Layout = ObjectValues<typeof LAYOUT>;

const BUTTON_STYLE_CSS = css`
  width: 20px;
  height: 20px;
  & > svg path {
    &, &.outline {
      fill: ${ADMIN_TEXT_100};
    }
    &.fill {
      fill: ${ADMIN_SURFACE_5};
    }
  }
`;

const ALIGNMENT_ICON_CSS = css`
  & svg {
    width: 15.67px;
    height: 9.5px;
  }
`;

const LAYOUT_CSS = css`
  & svg {
    width: 51.68px;
    height: 16.35px;
  }
`;

const LAYOUT_STACKED_CSS = css`
  & svg {
    width: 24.85px;
    height: 27.69px;
  }
`;

export const BUTTON_STYLE_OPTIONS_ROW: Option<CTAVariant>[] = [
  {
    icon: 'rectSolid',
    iconCss: BUTTON_STYLE_CSS,
    labelKey: 'ADMIN_LABEL_SOLID',
    value: 'solid',
  }, {
    icon: 'rectOutline',
    iconCss: BUTTON_STYLE_CSS,
    labelKey: 'ADMIN_LABEL_OUTLINE',
    value: 'outline',
  },
];

export const TEXT_ALIGNMENT_OPTIONS: Option<Alignment>[] = [
  {
    icon: 'textAlignmentLeft',
    iconCss: ALIGNMENT_ICON_CSS,
    labelKey: 'ADMIN_LABEL_LEFT',
    value: Alignment.Left,
  },
  {
    icon: 'textAlignmentCenter',
    iconCss: ALIGNMENT_ICON_CSS,
    labelKey: 'ADMIN_LABEL_CENTER',
    value: Alignment.Center,
  },
  {
    icon: 'textAlignmentRight',
    iconCss: ALIGNMENT_ICON_CSS,
    labelKey: 'ADMIN_LABEL_RIGHT',
    value: Alignment.Right,
  },
];

export const LAYOUT_OPTIONS_ROW: Option<Layout>[] = [
  {
    icon: 'layoutLeft',
    iconCss: LAYOUT_CSS,
    labelKey: 'ADMIN_LABEL_LEFT',
    value: LAYOUT.Left,
  },
  {
    icon: 'layoutStacked',
    iconCss: LAYOUT_STACKED_CSS,
    labelKey: 'ADMIN_LABEL_STACK',
    value: LAYOUT.Stacked,
  },
  {
    icon: 'layoutRight',
    iconCss: LAYOUT_CSS,
    labelKey: 'ADMIN_LABEL_RIGHT',
    value: LAYOUT.Right,
  },
];

export const OVERFLOW_BEHAVIOR_OPTIONS: Option<OverflowBehavior>[] = [
  {
    icon: 'flowSlider',
    labelKey: 'ADMIN_LABEL_SLIDER',
    value: 'slider',
  },
  {
    icon: 'flowStack',
    labelKey: 'ADMIN_LABEL_STACK',
    value: 'stack',
  },
];

export const BACKGROUND_TYPE_OPTIONS: Option<BackgroundType>[] = [
  {
    labelKey: 'ADMIN_LABEL_COLOR_PICKER_COLOR',
    value: 'color',
  },
  {
    labelKey: 'LABEL_IMAGE',
    value: 'image',
  },
];

export const GRADIENT_MASK_OPTIONS: Option<GradientMaskOption>[] = [
  {
    labelKey: 'ADMIN_LABEL_NONE',
    value: 'none',
  },
  {
    labelKey: 'GRADIENT',
    value: 'gradient',
  },
  {
    labelKey: 'BLUR',
    value: 'blur',
  },
];

export const IMAGE_LAYOUT_OPTIONS_ROW: Option<ImageLayout>[] = [
  {
    icon: 'layoutContentInside',
    labelKey: 'ADMIN_LABEL_CONTENT_INSIDE',
    value: 'contentInside',
  },
  {
    icon: 'layoutContentBelow',
    labelKey: 'ADMIN_LABEL_CONTENT_BELOW',
    value: 'contentBelow',
  },
];

export const LAYOUT_WITH_VIDEO_OPTIONS_ROW: Option<Layout>[] = [
  {
    icon: 'layoutWithVideoLeft',
    iconCss: css`
      & svg {
        width: 66px;
        height: 34px;
      }
    `,
    labelKey: 'ADMIN_LABEL_LEFT',
    value: LAYOUT.Left,
  },
  {
    icon: 'layoutWithVideoStacked',
    iconCss: css`
      & svg {
        width: 34px;
        height: 34px;
      }
    `,
    labelKey: 'ADMIN_LABEL_STACK',
    value: LAYOUT.Stacked,
  },
  {
    icon: 'layoutWithVideoRight',
    iconCss: css`
      & svg {
        width: 54px;
        height: 34px;
      }
    `,
    labelKey: 'ADMIN_LABEL_RIGHT',
    value: LAYOUT.Right,
  },
];

export const IMAGE_DESIGN = {
  cover: 'cover',
  contain: 'contain',
} as const;

export type ImageDesign = ObjectValues<typeof IMAGE_DESIGN>;

export const IMAGE_DESIGN_OPTIONS: Option<ImageDesign>[] = [
  {
    labelKey: 'ADMIN_LABEL_COVER',
    value: 'cover',
  },
  {
    labelKey: 'ADMIN_LABEL_CONTAIN',
    value: 'contain',
  },
];
