import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NAVIGATION_BLOCK_CUSTOM_NAVIGATION_DROPDOWN_ID, NAVIGATION_BLOCK_CUSTOM_NAVIGATION_SELECT_OPTION_CREATE_FOLDER_ID, NAVIGATION_BLOCK_CUSTOM_NAVIGATION_SELECT_OPTION_CREATE_LINK_ID, NAVIGATION_BLOCK_CUSTOM_NAVIGATION_SELECT_OPTION_ITEM_ID } from 'global-ids';
import { addItemToCustomNavigation, getNavigationSelectedInTheDropdown } from 'services/navigationv2';
import { Dropdown } from 'components/admin2/ui/Dropdown';
import { INavigationItem, NavigationParentType } from 'models/INavigation';
import { Container, Content, PlusIcon } from './styles';
import { useAdminTranslation } from 'hooks/use-translation';
import { NAV_ITEM_NAME_MAX_LENGTH, mapNavigationItemTypeToIcon, treatFolderRenaming, treatNavItemName } from 'services/navigationv2/utils';
import ApplyNavigationButton from '../ApplyNavigationButton';
import { StyledDragButt } from '../styles';
import useNavigationHashMap from '../use-navigation-hash-map';

export interface ICustomNavigationBlockDropdownOption {
  folderName?: string;
  icon?: string;
  id: string;
  linkName?: string;
  name: string;
  slug: string;
  type: NavigationParentType;
}

const CustomNavigationBlockDropdown = () => {
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();
  const selectedNavigation = useSelector(getNavigationSelectedInTheDropdown);
  const { defaultParentHashMap, defaultChildHashMap, selectedParentHashMap, selectedChildHashMap } = useNavigationHashMap();

  const getNavigationIcon = useCallback((type: NavigationParentType) => {
    return mapNavigationItemTypeToIcon(type);
  }, []);
  const getLabel = useCallback((option: ICustomNavigationBlockDropdownOption) => {
    if (!option) return '';
    return option.id ? option.name.slice(0, NAV_ITEM_NAME_MAX_LENGTH) : option.name;
  }, []);
  const updateSelectedItem = useCallback((option: ICustomNavigationBlockDropdownOption) => {
    if (!option.id) return '';
    const newParent = { ...option, children: [] };
    delete newParent.icon;
    dispatch(addItemToCustomNavigation(newParent, selectedNavigation._id!));
  }, [selectedNavigation]);

  const filteredNavigationItemsOptions = useMemo(() => {
    const suggestedItems: INavigationItem[] = [];

    for (const [key, value] of defaultParentHashMap) {
      if (selectedParentHashMap.has(key)) continue;
      if (selectedChildHashMap.has(key)) continue;
      if (value.private) continue;
      suggestedItems.push(value);
    }
    for (const [key, value] of defaultChildHashMap) {
      if (selectedParentHashMap.has(key)) continue;
      if (selectedChildHashMap.has(key)) continue;
      if (value.private) continue;
      suggestedItems.push(value);
    }

    return suggestedItems;
  }, [defaultParentHashMap, defaultChildHashMap, selectedParentHashMap, selectedChildHashMap]);

  const [menuItemOptions, setMenuItemOptions] = useState<ICustomNavigationBlockDropdownOption[]>([]);
  useEffect(() => {
    setMenuItemOptions([
      { name: t('ADMIN_NAVIGATION_BLOCK_CUSTOM_DROPDOWN_SELECT'), slug: '', id: '', type: '', icon: '' } as any,
      ...filteredNavigationItemsOptions.map(item => ({
        name: item.name,
        slug: item.slug,
        type: item.type,
        icon: getNavigationIcon(item.type),
        id: item.id,
        'data-testid': NAVIGATION_BLOCK_CUSTOM_NAVIGATION_SELECT_OPTION_ITEM_ID,
      })),
      { name: t('ADMIN_NAVIGATION_BLOCK_CUSTOM_DROPDOWN_CREATE_FOLDER'), slug: '', id: '', type: '', icon: '', 'data-testid': NAVIGATION_BLOCK_CUSTOM_NAVIGATION_SELECT_OPTION_CREATE_FOLDER_ID } as any,
      { name: t('ADMIN_NAVIGATION_BLOCK_CUSTOM_DROPDOWN_CREATE_LINK'), slug: '', id: '', type: '', icon: '', 'data-testid': NAVIGATION_BLOCK_CUSTOM_NAVIGATION_SELECT_OPTION_CREATE_LINK_ID } as any,
    ]);
  }, [filteredNavigationItemsOptions]);

  const onInputChange = (inputValue: string) => {
    const value = treatNavItemName(inputValue);
    const folderName = treatFolderRenaming(value);
    const linkName = value;

    setMenuItemOptions(prevOptions => {
      prevOptions[prevOptions.length - 2] = { // folder
        ...prevOptions[prevOptions.length - 2],
        name: value === '' ?
          t('ADMIN_NAVIGATION_BLOCK_CUSTOM_DROPDOWN_CREATE_FOLDER') :
          t('ADMIN_NAVIGATION_BLOCK_CUSTOM_DROPDOWN_CREATE_FOLDER_DYNAMIC', { folderName: value }),
        folderName,
        linkName,
      };
      prevOptions[prevOptions.length - 1] = { // link
        ...prevOptions[prevOptions.length - 1],
        name: value === '' ?
          t('ADMIN_NAVIGATION_BLOCK_CUSTOM_DROPDOWN_CREATE_LINK') :
          t('ADMIN_NAVIGATION_BLOCK_CUSTOM_DROPDOWN_CREATE_LINK_DYNAMIC', { linkName: value }),
        folderName,
        linkName,
      };
      return prevOptions;
    });
  };

  return (
    <Container
      id={NAVIGATION_BLOCK_CUSTOM_NAVIGATION_DROPDOWN_ID}
      data-testid={NAVIGATION_BLOCK_CUSTOM_NAVIGATION_DROPDOWN_ID}
    >
      <Content>
        <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
          <StyledDragButt maxWidth="unset" />
          <PlusIcon />
          <Dropdown
            options={menuItemOptions}
            valueKey="navigationTitle"
            getOptionLabel={getLabel}
            onChange={updateSelectedItem}
            onInputChange={onInputChange}
            padding="0"
            isSearchable={true}
            placeholder={t('ADMIN_NAVIGATION_BLOCK_CUSTOM_DROPDOWN_PLACEHOLDER')}
            isCustomNavigation={true}
            disableSelection={true}
            dropdownMaxHeight="24vh !important"
            dropUp={true}
          />
        </div>
        <ApplyNavigationButton />
      </Content>
    </Container>
  );
};

export default CustomNavigationBlockDropdown;
