import styled from 'styled-components';
import { DROPDOWN_BUTTON, DROPDOWN_CONTAINER, DROPDOWN_LABEL, DROPDOWN_TITLE } from 'css-classes';
import { ADMIN_HIGHLIGHT_SECONDARY, ADMIN_SURFACE_3, ADMIN_SURFACE_4, ADMIN_SURFACE_5, ADMIN_TEXT_100, ADMIN_TEXT_200, ADMIN_TEXT_300 } from 'style/constants';
import { ADMIN_TEXT_LABEL_M_MEDIUM, ADMIN_TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';
import Icon from 'components/ui/Icon';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  background: ${ADMIN_SURFACE_4};
  margin-top: 10px;
  width: 100%;
  gap: 8px;

  & input {
    width: 90%;
  }

  & .${DROPDOWN_CONTAINER} {
    height: 34px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 16px;
    background: ${ADMIN_SURFACE_3};
  }

  & .${DROPDOWN_LABEL} {
    height: 40px;
    background-color: ${ADMIN_SURFACE_4};
    color: ${ADMIN_TEXT_100};

    ${ADMIN_TEXT_LABEL_M_MEDIUM};
    &:hover {
      background-color: ${ADMIN_SURFACE_5};
    }
  }

  & .${DROPDOWN_TITLE} {
    color: ${ADMIN_TEXT_300};
    ${ADMIN_TEXT_LABEL_M_MEDIUM};
  }

  & .${DROPDOWN_LABEL}.first-row {
    height: 42px;

    color: ${ADMIN_TEXT_200};
    ${ADMIN_TEXT_LABEL_S_MEDIUM};
    &:hover {
      background-color: ${ADMIN_SURFACE_4};
    }
    cursor: default;

    & > div {
      transform: translateX(5px);
    }
  }

  & .${DROPDOWN_LABEL}.create-folder {
    background-color: ${ADMIN_SURFACE_3};
    &:hover {
      background-color: ${ADMIN_SURFACE_3};
    }
  }

  & .${DROPDOWN_LABEL}.create-link {
    background-color: ${ADMIN_SURFACE_3};
    &:hover {
      background-color: ${ADMIN_SURFACE_3};
    }
  }

  & .${DROPDOWN_BUTTON} {
    margin-left: 25px;

    & > div {
      width: 85%;
    }
  }
`;

export const PlusIcon = styled(Icon).attrs({ name: 'plus' })`
  position: absolute;
  left: 35px;
  bottom: 50%;
  z-index: 1;
  width: 20px;
  height: 20px;
  transform: translateY(50%);

  & svg {
    & path {
      fill: ${ADMIN_HIGHLIGHT_SECONDARY};
    }
  }
`;
