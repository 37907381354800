import INavigation, { NAVIGATION_CHILD_TYPE, NAVIGATION_PARENT_TYPE, NAVIGATION_TYPE } from 'models/INavigation';

export const MOCK_NAVIGATION: INavigation = {
  _id: '64f7db19ff9863c31f088b6c',
  name: 'Site Navigation',
  parents: [
    {
      id: '64f7db19ff9863c31f088b6b',
      name: 'Channel 1012984',
      type: NAVIGATION_PARENT_TYPE.channel,
      slug: 'channel-1',
      children: [],
    },
    {
      id: '64f7db19ff9863c31f088b1a',
      name: 'Channel 10-23',
      type: NAVIGATION_PARENT_TYPE.channel,
      slug: 'channel-10',
      children: [],
    },
    {
      id: '64f7db19ff9863c31f088b1b',
      name: 'Page 1 Very Long',
      type: NAVIGATION_PARENT_TYPE.landing,
      slug: 'page-1',
      children: [
        {
          id: '64f7db19ff9863c31f088b1c',
          name: 'Child Page 1bqiwfbbqfbqwyfgoeygfouyqg',
          type: NAVIGATION_CHILD_TYPE.landing,
          slug: 'child-page-1',
        },
        {
          id: '64f7db19ff9863c31f088b1d',
          name: 'Child Page 2 Very Long Page Name WoW this is so long',
          type: NAVIGATION_CHILD_TYPE.landing,
          slug: 'child-page-2',
        },
        {
          id: '64f7db19ff9863c31f088b2a',
          name: 'Child Channel 112323',
          type: NAVIGATION_CHILD_TYPE.channel,
          slug: 'child-channel-1',
        },
        {
          id: '64f7db19ff9863c31f088b2b',
          name: 'Child Page 3',
          type: NAVIGATION_CHILD_TYPE.landing,
          slug: 'child-page-3',
        },
      ],
    },
    {
      id: '64f7db19ff9863c31f088b2c',
      name: 'Folder 1-131',
      type: NAVIGATION_PARENT_TYPE.folder,
      slug: 'folder-1',
      children: [
        {
          id: '64f7db19ff9863c31f088b2d',
          name: 'Child Page 4-313',
          type: NAVIGATION_CHILD_TYPE.landing,
          slug: 'child-page-4',
        },
        {
          id: '64f7db19ff9863c31f088b2e',
          name: 'Child Page 5',
          type: NAVIGATION_CHILD_TYPE.landing,
          slug: 'child-page-5',
        },
      ],
    },
    {
      id: '64f7db19ff9863c31f088b2f',
      name: 'Channel 20=1',
      type: NAVIGATION_PARENT_TYPE.channel,
      slug: 'channel-20',
      children: [],
    },
    {
      id: '64f7db19ff9863c31f088b3a',
      name: 'Channel 21-1',
      type: NAVIGATION_PARENT_TYPE.channel,
      slug: 'channel-21',
      children: [],
    },
    {
      id: '64f7db19ff9863c31f088b3b',
      name: 'Channel 22-313',
      type: NAVIGATION_PARENT_TYPE.channel,
      slug: 'channel-22',
      children: [],
    },
    {
      id: '64f7db19ff9863c31f088b3c',
      name: 'Channel 23',
      type: NAVIGATION_PARENT_TYPE.channel,
      slug: 'channel-23',
      children: [],
    },
    {
      id: '64f7db19ff9863c31f088b3d',
      name: 'Channel 24',
      type: NAVIGATION_PARENT_TYPE.channel,
      slug: 'channel-24',
      children: [],
    },
    {
      id: '64f7db19ff9863c31f088b3e',
      name: 'Page 25wqrr',
      type: NAVIGATION_PARENT_TYPE.landing,
      slug: 'page-25',
      children: [
        {
          id: 'child_id_page_1',
          name: 'Child Page 1',
          type: NAVIGATION_CHILD_TYPE.landing,
          slug: 'child-page-1',
        },
        {
          id: 'child_id_page_2',
          name: 'Child Page 2',
          type: NAVIGATION_CHILD_TYPE.landing,
          slug: 'child-page-2',
        },
        {
          id: 'child_id_page_3',
          name: 'Child Page 3',
          type: NAVIGATION_CHILD_TYPE.landing,
          slug: 'child-page-3',
        },
        {
          id: 'child_id_page_4',
          name: 'Child Page 4',
          type: NAVIGATION_CHILD_TYPE.landing,
          slug: 'child-page-4',
        },
        {
          id: 'child_id_page_5',
          name: 'Child Page 5heh',
          type: NAVIGATION_CHILD_TYPE.landing,
          slug: 'child-page-5',
        },
        {
          id: 'child_id_page_6',
          name: 'Child Page 6',
          type: NAVIGATION_CHILD_TYPE.landing,
          slug: 'child-page-6',
        },
        {
          id: 'child_id_page_7',
          name: 'Child Page 7',
          type: NAVIGATION_CHILD_TYPE.landing,
          slug: 'child-page-7',
        },
        {
          id: 'child_id_page_8',
          name: 'Child Page 8',
          type: NAVIGATION_CHILD_TYPE.landing,
          slug: 'child-page-8',
        },
        {
          id: 'child_id_page_9',
          name: 'Child Page 9',
          type: NAVIGATION_CHILD_TYPE.landing,
          slug: 'child-page-9',
        },
        {
          id: 'child_id_page_10',
          name: 'Child Page 10',
          type: NAVIGATION_CHILD_TYPE.landing,
          slug: 'child-page-10',
        },
      ],
    },
    {
      id: 'parent_id_channel_27',
      name: 'Channel 27',
      type: NAVIGATION_PARENT_TYPE.channel,
      slug: 'channel-24',
      children: [],
    },
    {
      id: 'parent_id_channel_20',
      name: 'Channel 20',
      type: NAVIGATION_PARENT_TYPE.channel,
      slug: 'channel-20',
      children: [],
    },
  ],
  externalLinks: [
    { id: '111', name: 'Facebook', url: 'https://facebook.com' },
    { id: '112', name: 'Meta', url: 'https://meta.com' },
    { id: '113', name: 'Twitter', url: 'https://twitter.com' },
    { id: '114', name: 'X', url: 'https://x.com' },
    { id: '115', name: 'Instagram', url: 'https://instagram.com' },
    { id: '116', name: 'Youtube', url: 'https://youtube.com' },
  ],
  siteId: '5fc93a06210445002de88fc3',
  type: NAVIGATION_TYPE.default,
};

export const MOCK_CUSTOM_NAVIGATION: INavigation = {
  _id: '64f7db19ff9863c31f088baa',
  name: 'Custom Navigation',
  parents: [
    {
      id: '64f7db19ff9863c31f088b1a',
      name: 'Channel 10',
      type: NAVIGATION_PARENT_TYPE.channel,
      slug: 'channel-10',
      children: [],
    },
    {
      id: '64f7db19ff9863c31f088b2c',
      name: 'Folder 1',
      type: NAVIGATION_PARENT_TYPE.folder,
      slug: 'folder-1',
      children: [],
    },
  ],
  externalLinks: [
    { id: '5fc93a06210445002de88baa', name: 'Instagram', url: 'https://instagram.com' },
    { id: '5fc93a06210445002de88bab', name: 'Youtube', url: 'https://youtube.com' },
  ],
  siteId: '5fc93a06210445002de88fc3',
  type: NAVIGATION_TYPE.custom,
};
