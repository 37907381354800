import styled from 'styled-components';
import Icon from 'components/admin2/Icon';
import { ADMIN_HIGHLIGHT_PRIMARY, ADMIN_HIGHLIGHT_SECONDARY, HIGHLIGHT_PRIMARY } from 'style/constants';

export const LinkIcon = styled(Icon).attrs(props => ({
  color: ADMIN_HIGHLIGHT_PRIMARY(props),
  name: 'linkOutline',
}))`
  padding: 0px 6px;
  transform: translateY(-1px);
  svg {
    width: 18px;
    height: 18px;
  }
`;

export const WarningIcon = styled(Icon).attrs(props => ({
  color: ADMIN_HIGHLIGHT_SECONDARY(props),
  name: 'warning',
}))`
  transform: translateY(-2px);
  svg {
    width: 12px;
    height: 12px;
  }
`;

export const TrashIcon = styled(Icon).attrs(props => ({
  color: ADMIN_HIGHLIGHT_SECONDARY(props),
  name: 'trashV2',
}))`
  svg {
    width: 16px;
    height: 16px;
    &:hover > path {
      fill: ${HIGHLIGHT_PRIMARY};
    }
  }
  cursor: pointer;

  &:hover {
    & path {
      fill: ${ADMIN_HIGHLIGHT_PRIMARY};
    }
  }
`;

export const CheckmarkIcon = styled(Icon).attrs(props => ({
  color: ADMIN_HIGHLIGHT_SECONDARY(props),
  name: 'checkmarkCircle',
}))`
  svg {
    width: 16px;
    height: 16px;
    &:hover > path {
      fill: ${HIGHLIGHT_PRIMARY};
    }
  }
  cursor: pointer;

  &:hover {
    & path {
      fill: ${ADMIN_HIGHLIGHT_PRIMARY};
    }
  }
`;
