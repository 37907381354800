import { OptionRow } from 'components/admin-bridge/EditorModal';
import React from 'react';
import { useCTAEditorModalContext } from '../Context';
import { BUTTON_STYLE_OPTIONS_ROW } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow';

const VariantOption = () => {
  const { cta, onChangeVariant } = useCTAEditorModalContext();

  return (
    <OptionRow
      value={cta.variant}
      labelKey="ADMIN_LABEL_STYLE"
      onChange={onChangeVariant}
      options={BUTTON_STYLE_OPTIONS_ROW}
    />
  );
};

export default VariantOption;
