import React, { useState, useRef } from 'react';
import { FileWithPreview } from 'react-dropzone';
import { UploadLabel, UploadLableError, StyledDropzone, StyledIcon, WarningIcon } from './styles';
import TranslatedText from 'components/i18n/TranslatedText';
import { TranslationKey } from 'hooks/use-translation';

export interface IVideoUploaderZoneProps {
  className?: string;
  disabled?: boolean;
  maxSize?: number;
  onDrop: (acceptedFiles: FileWithPreview[], status: 'accepted' | 'rejected') => void;
}

type RejectedStatusTypes = 'format-not-supported' | 'size-too-large';

const DEFAULT_MAX_SIZE = 53687091200; // 50 GB in bytes
const FILE_ACCEPTED_TYPE = 'video/mp4';

const VideoUploaderZone: React.FC<IVideoUploaderZoneProps> = ({
  className = '',
  disabled = false,
  maxSize = DEFAULT_MAX_SIZE,
  onDrop,
}) => {
  const dropzoneRef = useRef<any>(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [rejectedStatus, setRejectedStatus] = useState<RejectedStatusTypes | null>(null);

  const handleOnDragEnter = () => {
    if (!isDragOver) {
      setIsDragOver(true);
    }
  };

  const handleOnDragLeave = () => {
    if (isDragOver) {
      setIsDragOver(false);
    }
  };

  const openFileDialog = () => {
    dropzoneRef.current?.open?.();
  };

  const updateRejectedStatus = (rejectedFiles: FileWithPreview[]) => {
    const update = (newStatus: RejectedStatusTypes | null) => {
      if (rejectedStatus !== newStatus) {
        setRejectedStatus(newStatus);
      }
    };

    if (!rejectedFiles.length) {
      update(null);
      return;
    }

    const rejectedFile = rejectedFiles[0];
    if (rejectedFile.size > maxSize) {
      update('size-too-large');
      return;
    }

    if (rejectedFile.type !== FILE_ACCEPTED_TYPE) {
      update('format-not-supported');
      return;
    }

    update(null);
  };

  const onHandleDrop = (
    acceptedFiles: FileWithPreview[],
    rejectedFiles: FileWithPreview[],
  ) => {
    updateRejectedStatus(rejectedFiles);

    const status = acceptedFiles.length > 0 ? 'accepted' : 'rejected';
    onDrop(acceptedFiles, status);

    setIsDragOver(false);
  };

  const renderDropzone = () => {
    if (isDragOver) {
      return (
        <>
          <StyledIcon name="dragAndDropVideo" />
          <UploadLabel>
            <TranslatedText stringKey="VIDEO_LIBRARY_DROP_ZONE_DRAG_LABEL" />
          </UploadLabel>
        </>
      );
    }
    if (rejectedStatus) {
      const textMap: Record<RejectedStatusTypes, TranslationKey> = {
        'format-not-supported': 'VIDEO_LIBRARY_DROP_ZONE_DRAG_FORMAT_ERROR',
        'size-too-large': 'VIDEO_LIBRARY_DROP_ZONE_DRAG_SIZE_ERROR',
      };

      return (
        <>
          <StyledIcon name="dragAndDropVideoError" />
          <UploadLableError>
            <WarningIcon />
            <TranslatedText stringKey={textMap[rejectedStatus]} />
          </UploadLableError>
        </>
      );
    }

    return (
      <>
        <StyledIcon name="dragAndDropVideo" />
        <UploadLabel>
          <TranslatedText stringKey="VIDEO_LIBRARY_DROP_ZONE_DROP_MP4_FILE_LABEL" />{' '}
          <a onClick={openFileDialog}>
            <TranslatedText stringKey="VIDEO_LIBRARY_DROP_ZONE_DROP_MP4_CLICK_SELECT" />
            {' '}
          </a>
          <TranslatedText stringKey="VIDEO_LIBRARY_DROP_ZONE_DROP_MP4_DEVICE_LABEL" />
        </UploadLabel>
      </>
    );
  };

  return (
    <StyledDropzone
      data-testid="videoUploaderZone"
      ref={dropzoneRef}
      className={className}
      disabled={disabled}
      disableClick={true}
      isDragOver={isDragOver}
      onDragEnter={handleOnDragEnter}
      onDragLeave={handleOnDragLeave}
      maxSize={maxSize}
      onDrop={onHandleDrop}
      accept={FILE_ACCEPTED_TYPE}
    >
      {renderDropzone()}
    </StyledDropzone>
  );
};

export default VideoUploaderZone;
