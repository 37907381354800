import {
  ACCENT_PRIMARY,
  SURFACE_4,
  SURFACE_5,
  TEXT_100,
  TEXT_200,
  TEXT_300,
} from 'style/constants';
import {
  TEXT_BODY_S_MEDIUM,
  TEXT_BODY_XS_MEDIUM,
  TEXT_LABEL_L_BOLD,
  TEXT_LABEL_M_MEDIUM,
  TEXT_LABEL_S_BOLD,
} from 'style/design-system/textStyles';
import styled from 'styled-components';
import Button from 'components/ui/Button';
import Link from 'components/ui/Link';
import { BodySectionTitle } from '../sharedStyles';

export const SuccessPurchaseTitle = styled(BodySectionTitle)`
  text-align: center;
`;

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ProductSummaryContainer = styled.div`
  background-color: ${SURFACE_4};
  width: 100%;
  border-radius: 4px;
  justify-content: space-between;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ProductRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const ProductNameAndSpecs = styled.div`
  max-width: 100%;
  white-space: normal;
  overflow-wrap: break-word;
`;

export const ProductName = styled.div`
  ${TEXT_BODY_XS_MEDIUM}
  color: ${TEXT_200};
`;

export const ProductSpecs = styled(ProductName)`
  margin-top: 8px;
`;

export const ProductPriceAndQuantityContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const ProductPrice = styled.div`
  ${TEXT_BODY_S_MEDIUM}
  color: ${TEXT_100};
`;

export const ProductQuantity = styled(ProductPrice)`
  color: ${TEXT_300};
  text-transform: uppercase;
`;

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${SURFACE_5};
`;

export const PaymentMethodContainer = styled(ProductSummaryContainer)`
  gap: 10px;
`;

export const PaymentMethodRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PaymentMethodItemLabel = styled.div`
  ${TEXT_BODY_XS_MEDIUM}
  color: ${TEXT_200};
  text-align: left;
`;

export const PaymentMethodItemValue = styled(PaymentMethodItemLabel)`
  text-align: right;
`;

export const PaymentMethodTotalAmountRow = styled(PaymentMethodRow)`
  margin-top: 7px;
`;

export const TotalAmountLabel = styled.div`
  ${TEXT_LABEL_M_MEDIUM}
  color: ${TEXT_100};
`;

export const TotalAmountValue = styled.div`
  color: ${TEXT_100};
  ${TEXT_LABEL_L_BOLD}
`;

export const StyledButton = styled(Button)`
  min-height: 44px;
  width: 100%;
  ${TEXT_LABEL_L_BOLD}
  padding: 13px 24px 13px 24px;
`;

export const NeedHelpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ReceiptInfo = styled.div`
  ${TEXT_BODY_XS_MEDIUM}
  color: ${TEXT_200};
  text-align: center;
  margin-bottom: 20px;
`;

export const StyledLink = styled(Link)`
  color: ${ACCENT_PRIMARY};
  ${TEXT_LABEL_S_BOLD}
  margin-left: 5px;
`;
