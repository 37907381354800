import React from 'react';
import styled from 'styled-components';
import { rgba } from 'colors';
import { ACCENT_PRIMARY } from 'style/constants';
import { HIDE_SCROLLBAR, desktopOnly, mobileOnly } from 'style/mixins';
import { TEXT_LABEL_S_MEDIUM, TEXT_LABEL_XS_MEDIUM } from 'style/design-system/textStyles';

const VideoTagsContainer = styled.ul`
  display: flex;
  list-style-type: none;
  flex-wrap: nowrap;
  gap: 5px;
  margin: 0px;
  padding: 0 0 6px 0;
  ${mobileOnly`padding: 0 6px 6px 6px;`}
  ${HIDE_SCROLLBAR}
  overflow: auto hidden;
`;

const VideoTag = styled.li`
  padding-inline: 10px;
  ${desktopOnly`
    height: 28px;
    ${TEXT_LABEL_S_MEDIUM}
  `}

  ${mobileOnly`
    height: 32px;
    ${TEXT_LABEL_XS_MEDIUM}
  `}
  border-radius: 50px;
  color: ${ACCENT_PRIMARY};
  background: ${props => rgba(ACCENT_PRIMARY(props), 0.15)};
  white-space: nowrap;

  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ITags {
  listTags: string[];
}

const Tags: React.FC<ITags> = ({ listTags, ...props }) => {
  if (!listTags.length) {
    return null;
  }

  return (
    <VideoTagsContainer {...props}>
      {listTags.map((tag, index) => (
        <VideoTag key={`${tag}-${index}`}>#{tag}</VideoTag>
      ))}
    </VideoTagsContainer>
  );
};

export default Tags;
