import { useEffect } from 'react';
import { VIDEO_ID } from 'global-ids';
/*
  FULL SCREEN INTERACTIONS
  docs
  https://www.sitepoint.com/use-html5-full-screen-api/
*/

export const CHANGE_EVENTS: string[] = [
  'fullscreenchange',
  'webkitfullscreenchange',
  'mozfullscreenchange',
  'MSFullscreenChange',
];

export interface IShowControls {
  controls: boolean | null;
  onExit: boolean | null;
}

export interface ISelectedVideoElement extends HTMLVideoElement {
  mozFullScreenElement: Element;
  mozFullScreenEnabled: boolean;
  mozRequestFullScreen: Element['requestFullscreen'];
  msFullscreenElement: Element;
  msFullscreenEnabled: boolean;
  msRequestFullscreen: Element['requestFullscreen'];
  webkitEnterFullscreen: Element['requestFullscreen'];
  webkitFullscreenElement: Element;
  webkitFullscreenEnabled: boolean;
  webkitRequestFullscreen: Element['requestFullscreen'];
  webkitSupportsFullscreen: boolean;
}

const fullScreenControl = () => {
  const showControls: IShowControls = {
    controls: null,
    onExit: null,
  };
  return (toggleSource: string, onExit?: string): boolean | null => {
    showControls.onExit = false;
    if (onExit) {
      showControls.onExit = true;
    }
    if (toggleSource) {
      showControls.controls = toggleSource === 'fullscreen';
    }
    if (showControls.onExit === true) {
      showControls.controls = false;
    }
    return showControls.controls;
  };
};

/**
 * Recursively searches VIDEO_ID for a video element, considering
 * that some players use WebComponents
 */
export const queryVideoElement = (): ISelectedVideoElement | null => {
  const recurse = (node: Element): HTMLVideoElement | undefined => {
    if (node instanceof HTMLVideoElement) {
      return node;
    }

    if (node.shadowRoot) {
      // dealing with a WebComponent here
      for (const child of node.shadowRoot.children) {
        const found = recurse(child);

        if (found) {
          return found;
        }
      }
    }

    for (const child of node.children) {
      const found = recurse(child);

      if (found) {
        return found;
      }
    }
  };

  const videoContainer = document.getElementById(VIDEO_ID);

  if (!videoContainer) {
    return null;
  }

  return (recurse(videoContainer) ?? null) as ISelectedVideoElement | null;
};

export const determineFullScreenControls =  fullScreenControl();

export const requestFullscreen = (toggleSource: string, ElementId: string, { navigationUI = 'hide', ...options }: FullscreenOptions = {}): void => {
  const selectedElement = {
    FullscreenRequest: document.getElementById(ElementId) as ISelectedVideoElement,
    TheaterRequest: document.documentElement  as ISelectedVideoElement,
  };

  const element = toggleSource === 'fullscreen' ? selectedElement.FullscreenRequest : selectedElement.TheaterRequest;
  const videoElement = queryVideoElement();

  if (!element) {
    return;
  }

  const fullscreenOptions = {
    navigationUI,
    ...options,
  };

  if (element.requestFullscreen) { // standard
    element.requestFullscreen(fullscreenOptions);
  } else if (element.mozRequestFullScreen) { // gecko
    element.mozRequestFullScreen(fullscreenOptions);
  } else if (element.webkitRequestFullscreen) { // macOS
    element.webkitRequestFullscreen(fullscreenOptions);
  } else if (videoElement?.webkitEnterFullscreen) { // iOS, iPadOS
    videoElement?.webkitEnterFullscreen();
  } else if (element.msRequestFullscreen) { // internet explorer
    element.msRequestFullscreen(fullscreenOptions);
  }

  determineFullScreenControls(toggleSource);

};

export const canFullscreen = (): boolean => {
  const videoElement = queryVideoElement();
  return Boolean(
    document.fullscreenEnabled ||
    document.webkitFullscreenEnabled ||
    videoElement?.webkitSupportsFullscreen ||
    document.mozFullScreenEnabled ||
    document.msFullscreenEnabled,
  );
};

export const exitFullscreen = (toggleSource: string): void => {
  if (document.fullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }

  determineFullScreenControls(toggleSource, 'exitFullScreen');
};

export const isFullscreenCurrently = (): boolean => {
  const fullscreenElement = document;

  const currentFullScreenElement = fullscreenElement.fullscreenElement ||
  fullscreenElement.webkitFullscreenElement ||
  fullscreenElement.mozFullScreenElement ||
  fullscreenElement.msFullscreenElement ||
    null;
  // If no element is in full-screen
  return currentFullScreenElement !== null;
};

export const useFullscreenHandler = (handleFullScreen: () => void) => {
  return useEffect(
    () => {
      CHANGE_EVENTS.forEach((eventType) => {
        document.addEventListener(eventType, handleFullScreen, false);
      });
      return () => {
        CHANGE_EVENTS.forEach((eventType) => {
          document.removeEventListener(eventType, handleFullScreen);
        });
      };
    },
    [handleFullScreen],
  );
};
