import React, { useCallback } from 'react';
import { SITE_STRUCTURE_CREATE_BUTTON_ID, SITE_NAVIGATION_HEADER_ID } from 'global-ids';
import { getRectPositionCheckingBounds } from 'dom-utils';
import useFloatMenu from 'hooks/use-float-menu';
import CreateModal from '../CreateModal';
import { Container, Title, Text, InfoTooltip, Options, CreateButton, PlusIcon, CreateButtonContainer } from './styles';
import { useAdminTranslation } from 'hooks/use-translation';

const SiteNavigationHeader = () => {
  const { t } = useAdminTranslation();
  const setMenuPosition = useCallback((menu: HTMLDivElement, menuContainer: HTMLDivElement) => {
    const menuContainerRect = menuContainer.getBoundingClientRect();
    const safePosition = getRectPositionCheckingBounds({
      elem: menu,
      position: {
        x: menuContainerRect.right,
        y: menuContainerRect.top,
      },
    });

    menu.style.left = `${safePosition.x + 20}px`;
    menu.style.top = `${safePosition.y}px`;
  }, []);
  const { isOpen: isFloatMenuOpen, containerRef, positionMenu, toggleMenu } = useFloatMenu(setMenuPosition);

  return (
    <Container id={SITE_NAVIGATION_HEADER_ID} data-testid={SITE_NAVIGATION_HEADER_ID}>
      <Title>
        <Text>{t('SITE_STRUCTURE')}</Text>
        <InfoTooltip tooltipKey="ADMIN_SITE_STRUCTURE_TOOLTIP"/>
      </Title>
      <Options>
       <CreateButtonContainer ref={containerRef} isSelected={isFloatMenuOpen}>
          <CreateButton onClick={toggleMenu} data-testid={SITE_STRUCTURE_CREATE_BUTTON_ID}>
            <PlusIcon />
            {t('ADMIN_LABEL_CREATE')}
          </CreateButton>
          {isFloatMenuOpen && (
            <CreateModal
              ref={positionMenu}
              toggleMenu={toggleMenu}
            />
          )}
       </CreateButtonContainer>
      </Options>
    </Container>
  );
};

export default SiteNavigationHeader;
