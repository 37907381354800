import React from 'react';
import useCheckout from './use-checkout';
import { useDispatch } from 'react-redux';
import { setShippingMethod } from 'services/ecommerce';
import { BodySection, BodySectionTitle } from '../sharedStyles';
import StripeAddress from 'components/ui/v2/Inputs/Address';
import { useAdminTranslation } from 'hooks/use-translation';
import { StripeAddressElementChangeEvent } from '@stripe/stripe-js';
import { IAddress } from 'services/account-address';
import { setShippingAddressField } from 'services/shipping-address';
import useStripeContacts from 'hooks/use-stripe-contacts';

const CheckoutShippingAddress = () => {
  const { hasCheckout, shopifyPaymentProcessing } = useCheckout();
  const { contacts, loading } = useStripeContacts();
  const dispatch = useDispatch();
  const { t } = useAdminTranslation();

  const handleAddressChange = (event: StripeAddressElementChangeEvent) => {
    const value = event?.value;
    if (!value) {
      return;
    }

    // in order to clear preselected shipping method
    dispatch(setShippingMethod(''));

    const name = value.name || '';
    const address = value.address || {};
    const phone = value.phone || '';

    const nameParts = name.split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts[nameParts.length - 1];

    const addressBase: IAddress = {
      address1: address.line1,
      address2: address.line2 || '',
      countryCode: address.country,
      postalCode: address.postal_code,
      state: address.state,
      firstName,
      lastName,
      city: address.city,
      phoneNumber: phone,
      complete: true,
    };

    dispatch(setShippingAddressField({
      address1: addressBase.address1,
      address2: addressBase.address2,
      city: addressBase.city,
      countryCode: addressBase.countryCode,
      firstName: addressBase.firstName,
      lastName: addressBase.lastName,
      phoneNumber: addressBase.phoneNumber,
      postalCode: addressBase.postalCode,
      state: addressBase.state,
    }));

    if (event.complete && hasCheckout) {
      // clearing the shipping method when user updates the shipping address
      dispatch(setShippingMethod(''));
    }
  };

  if (loading) {
    return null;
  }

  return (
    <BodySection shopifyPaymentProcessing={shopifyPaymentProcessing}>
      <BodySectionTitle>{t('LABEL_SHIPPING_ADDRESS')}</BodySectionTitle>
      <StripeAddress
        onChange={handleAddressChange}
        contacts={contacts}
        mode="shipping"
        validation={{
          phone: {
            required: 'always',
          },
        }}
      />
    </BodySection>
  );
};

export default CheckoutShippingAddress;
