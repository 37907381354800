import React, { SyntheticEvent, useCallback, useState } from 'react';
import { useTheme } from 'styled-components';
import TranslatedText from 'components/i18n/TranslatedText';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import Button from 'components/admin2/ui/Button';
import studioLogo from 'assets/studio.svg';
import thirdPartyLogo from 'assets/3rd-party-stream.svg';

import { STUDIO_APP_FLAVOR } from 'config';
import {
  Container,
  Content,
  Header,
  HeaderText,
  StreamModes,
  StreamMode,
  Image,
  TitleAndDescription,
  Title,
  Description,
  Highlight,
  BrokenCredentials,
  TitleText,
  WarningText,
  WarningIcon,
  BetaWrapper,
} from './styles';
import { ModeTypes, setAdminMode } from 'services/admin';
import { isFeatureEnabled, Feature } from 'services/feature-gate';
import {
  isPublishedWithStudioStream,
  isPublishedWithMaestroStream,
  getStreamProviderAccount,
  requestNewStream,
} from 'services/livestream';
import { getChannelLivestreamStatus } from 'services/app/selectors';
import { useAdminTranslation } from 'hooks/use-translation';

const StreamModeSelect = () => {
  const dispatch = useDispatch();
  const { t } = useAdminTranslation();

  const [isRetryBtnDisabled, setIsRetryBtnDisabled] = useState(false);

  const channelLivestreamStatus = useSelector(getChannelLivestreamStatus);

  const isStudioEnabled = useSelector(
    (state) => isFeatureEnabled(state, Feature.STUDIO_STREAM),
  );
  const isLiveWithStudio = useSelector(isPublishedWithStudioStream);
  const isLiveWith3rdParty = useSelector(isPublishedWithMaestroStream);

  const is3rdPartyAccount = !!useSelector(getStreamProviderAccount);

  const handleSelectMaestroStudioMode = useCallback(
    () => dispatch(setAdminMode(ModeTypes.STUDIO_STREAM)),
    [],
  );

  const handleSelectThirdPartyMode = useCallback(
    () => dispatch(setAdminMode(ModeTypes.MAESTRO_STREAM)),
    [],
  );

  const handleRetryStreamCreation = useCallback(
    (ev: SyntheticEvent) => {
      ev.preventDefault();
      setIsRetryBtnDisabled(true);
      dispatch(requestNewStream());
    },
    [],
  );

  const {
    replaceImgColors: fixStudioImgColors,
    imgSrc: studioImg,
    imgLoaded: studioImgLoaded,
  } = useReplaceSvgColors(studioLogo);

  const {
    replaceImgColors: fix3rdPartyColors,
    imgSrc: thirdPartyImg,
    imgLoaded: thirdPartyImgLoaded,
  } = useReplaceSvgColors(thirdPartyLogo);

  if (!isStudioEnabled || isLiveWith3rdParty) {
    handleSelectThirdPartyMode();
    return null;
  }

  if (isLiveWithStudio) {
    handleSelectMaestroStudioMode();
    return null;
  }

  return (
    <Container>
      {channelLivestreamStatus === 'error' ? (
        <Content>
          <BrokenCredentials>
            <WarningIcon />
            <TranslatedText component={TitleText} stringKey="LABEL_ERROR_MODAL_MESSAGE" />
            <WarningText>
              {
                is3rdPartyAccount
                  ? t('ADMIN_ERROR_SETTING_UP_STREAM_ERROR')
                  : t('ADMIN_ERROR_STREAM_CREDENTIALS_UNAVAILABLE')
              }
            </WarningText>
            <Button
              disabled={isRetryBtnDisabled}
              onClick={handleRetryStreamCreation}
            >
              {t('ADMIN_LABEL_RETRY')}
            </Button>
          </BrokenCredentials>
        </Content>
      ) : (
        <Content>
          <Header>
            <TranslatedText component={HeaderText} stringKey="ADMIN_LABEL_STREAM_MODE_SELECT_HEADER" />
          </Header>
          <StreamModes data-testid="maestro-stream-modes">
            <StreamMode onClick={handleSelectMaestroStudioMode} data-testid="maestro-studio-stream-mode">
              <Image
                src={studioImg}
                style={{ opacity: studioImgLoaded ? 1 : 0 }}
                onLoad={fixStudioImgColors}
              />
              <TitleAndDescription>
                <Title>
                  {STUDIO_APP_FLAVOR === 'maestro' ? 'Maestro' : ''}
                  {' '}
                  <BetaWrapper>
                    Studio
                    <Highlight>{t('ADMIN_LABEL_BETA')}</Highlight>
                  </BetaWrapper>
                </Title>
                <TranslatedText component={Description} stringKey="ADMIN_LABEL_STREAM_MODE_SELECT_MAESTRO_STUDIO" />
              </TitleAndDescription>
            </StreamMode>
            <StreamMode onClick={handleSelectThirdPartyMode} data-testid="third-party-stream-mode">
              <Image
                src={thirdPartyImg}
                style={{ opacity: thirdPartyImgLoaded ? 1 : 0 }}
                onLoad={fix3rdPartyColors}
              />
              <TitleAndDescription>
                <Title>{t('ADMIN_STREAM_MODE_THIRD_PARTY')}</Title>
                <TranslatedText component={Description} stringKey="ADMIN_LABEL_STREAM_MODE_SELECT_THIRD_PARTY_SOFTWARE" />
              </TitleAndDescription>
            </StreamMode>
          </StreamModes>
        </Content>
      )}
    </Container>
  );
};

const useReplaceSvgColors = (originalSrc: string) => {
  const { colors } = useTheme().admin;

  const [imgSrc, setImgSrc] = useState(originalSrc);
  const [imgLoaded, setImgLoaded] = useState(false);

  const replaceImgColors = useCallback(
    () => {
      if (imgLoaded)
        return;

      (async () => {
        const svgData = await fetch(originalSrc);
        const svgTextData = await svgData.text();
        setImgSrc(
          'data:image/svg+xml,' + encodeURIComponent(
            svgTextData
              .replace(/#FC223B/gi, colors.accentPrimary)
              .replace(/#363C4D/gi, colors.surface5)
              .replace(/#1B1E26/gi, colors.surface3)
              .replace(/#F3F7FF/gi, colors.highlightPrimary)
              .replace(/#12141A/gi, colors.surface2)
              .replace(/#3E4759/gi, colors.surface6)
              .replace(/#363C4D/gi, colors.surface5),
          ),
        );
        setImgLoaded(true);
      })();
    },
    [colors.accentPrimary, imgLoaded, originalSrc],
  );

  return {
    imgSrc,
    imgLoaded,
    replaceImgColors,
  };
};


export default StreamModeSelect;
