import React, { useEffect, useState } from 'react';
import { StyledVideoSeekBar } from 'components/Video/video-controls/Controls/styles';
import { ControlsProps } from 'components/Video/video-controls/VideoOverlay/interfaces';
import { useHighFrequencyPlaybackStateUpdates } from 'components/Video/video-player/Player/Context';

export default function VideoSeekBarComponent({
  isLive,
  isMobileLayout,
  isPlaying,
  seekTo,
  videoDuration,
}: ControlsProps) {
  const [seeking, setSeeking] = useState(false);
  const [seekIsComplete, setSeekIsComplete] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const duration = Math.ceil(videoDuration);

  const { loadedSeconds, playedSeconds } = useHighFrequencyPlaybackStateUpdates();
  const loaded = Math.ceil(loadedSeconds);

  const handleSeekEnd = (seconds: number) => {
    seekTo(seconds);
    setSeeking(false);
  };

  const handleSeekStart = () => {
    setSeeking(true);
    setSeekIsComplete(false);
  };

  useEffect(() => {
    if (!seeking) {
      setSeekIsComplete(true);
    }
  }, [playedSeconds]);

  return isLive ? null : (
    <StyledVideoSeekBar
      max={duration}
      onAfterChange={handleSeekEnd}
      onBeforeChange={handleSeekStart}
      onChange={setSliderValue}
      value={seekIsComplete ? playedSeconds : sliderValue}
      secondaryValue={loaded}
      showThumb={isMobileLayout || !isPlaying}
    />
  );
}
