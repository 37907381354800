import styled, { css } from 'styled-components';
import Button from 'components/ui/Button';
import Icon from 'components/ui/Icon';
import {
  MAESTRO_WHITE,
  MAESTRO_BLACK,
} from 'style/constants';

interface StyleProps {
  connected?: boolean;
}

export const ChromecastButtonContainer = styled(Button).attrs(({ disabled, ...props }) => ({
  admin: true,
  containerCss: `
    ${disabled && 'display: none;'}
  `,
  background: 'transparent',
  textColor: MAESTRO_WHITE,
  hoverBackground: MAESTRO_WHITE,
  hoverTextColor: MAESTRO_BLACK,
  variant: 'transparent',
}))<StyleProps>`
  position: relative;
  padding: 0;
  min-width: 31px;
  & google-cast-launcher {
    position: absolute;
    overflow: hidden;
    min-width: 100%;
    max-height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    ${({ connected }) => connected && css`
      max-width: 0;
      max-height: 0;
      min-width: 0;
      min-height: 0;
      pointer-events: none;
      visibility: hidden;
      top: -99999999px;
      left: -99999999px;
      z-index: -99999999px;
    `}
  }
`;

export const ChromecastIcon = styled(Icon).attrs(props => ({
  color: MAESTRO_BLACK,
}))<StyleProps>`
  ${({ connected }) => connected && css`
    & svg path {
      fill: ${MAESTRO_BLACK};
    }
  `}
`;
