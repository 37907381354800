import React, { ErrorInfo, ReactNode } from 'react';

interface ErrorBoundaryProps {
  fallback: (data: { error: Error, errorInfo: ErrorInfo | null }) => ReactNode;
}

interface ErrorBoundaryState {
  error: Error | null
  errorInfo: ErrorInfo | null
}

export default class ErrorBoundary extends React.Component<
  React.PropsWithRef<React.PropsWithChildren<ErrorBoundaryProps>>,
  ErrorBoundaryState
  > {
  state: ErrorBoundaryState = { error: null, errorInfo: null };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    const { error, errorInfo } = this.state;
    const { fallback } = this.props;

    if (error) {
      return fallback({
        error,
        errorInfo,
      });
    }

    return this.props.children;
  }
}
