import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isMobileLayout as isMobileDeviceLayout } from 'services/device/selectors';
import MobileOverlayCountdown from 'components/mobile/MobileOverlayCountdown';
import { getVodIdFromQuery } from 'services/video';

export const MobileCountdown = () => {
  const [isCountdownDismissed, setIsCountdownDismissed] = useState<boolean>(false);
  const isMobileLayout = useSelector(isMobileDeviceLayout);
  const isActiveVod = Boolean(useSelector(getVodIdFromQuery));

  const dismissCountdown = useCallback(() => setIsCountdownDismissed(true), []);

  if (isMobileLayout && !isCountdownDismissed && !isActiveVod) {
    return <MobileOverlayCountdown onCancelClick={dismissCountdown} />;
  }

  return null;
};
