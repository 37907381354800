import React, { useCallback, useEffect, useState } from 'react';
import { ControlsProps } from 'components/Video/video-controls/VideoOverlay/interfaces';
import useChromecast from 'hooks/use-chromecast';
import debounce from 'lodash/debounce';
import {
  ChromecastButtonContainer,
  ChromecastIcon,
} from './styles';

export default function ChromecastToggleButton({ isMobileLayout }: ControlsProps) {
  const cast = useChromecast();
  const [isConnected, setConnected] = useState(cast.connected);

  const delaySetConnect = useCallback(debounce(setConnected, 500), []);

  useEffect(() => {
    delaySetConnect(cast.connected);
    return delaySetConnect.cancel;
  }, [cast.connected]);

  return cast.initialized ? (
    <ChromecastButtonContainer
      connected={isConnected}
      disabled={!cast.available}
      hovering={cast.connected}
      onClick={cast.connected ? cast.stopCasting : cast.requestCasting}
      tooltipAlign="left"
      tooltipPosition={isMobileLayout ? 'bottom' : 'top'}
      tooltipKey={cast.connected ? 'LABEL_STOP_CASTING' : 'LABEL_CHROMECAST'}
    >
      <ChromecastIcon connected={cast.connected} name={cast.connected ? 'chromecastDisconnect' : 'chromecast'} />
    </ChromecastButtonContainer>
  ) : null;
}
