import React from 'react';
import AddNewPaymentMethodButton from 'components/payment/PaymentMethodsV3/AddNewPaymentMethodButton';
import { BodySection, BodySectionTitle, StyledButton } from '../sharedStyles';
import { PaymentMethod } from '@stripe/stripe-js';
import PaymentMethodCards from 'components/payment/PaymentMethodsV3/PaymentMethodCards';
import { dismissEcommerceView, pushEcommerceView } from 'services/ecommerce';
import { useDispatch } from 'react-redux';
import GoBack from '../GoBack';
import { useAdminTranslation } from 'hooks/use-translation';

interface ISelectPaymentMethodsProps {
  onConfirmPaymentMethod: (pm: PaymentMethod) => void;
  paymentMethod?: PaymentMethod;
}

const SelectPaymentMethods: React.FC<ISelectPaymentMethodsProps> = ({ paymentMethod, onConfirmPaymentMethod }) => {
  const dispatch = useDispatch();
  const { t } = useAdminTranslation();

  const handleConfirm = React.useCallback(() => {
    dispatch(dismissEcommerceView());
  }, []);

  const handleAddNewPaymentMethod = React.useCallback(() => {
    dispatch(pushEcommerceView('addPaymentMethod'));
  }, []);

  return (
    <BodySection>
      <GoBack>{t('ADMIN_LABEL_GO_BACK')}</GoBack>
      <BodySectionTitle>{t('SELECT_A_PAYMENT_METHOD')}</BodySectionTitle>
      <PaymentMethodCards selectedPaymentMethod={paymentMethod} onChange={onConfirmPaymentMethod} />
      <AddNewPaymentMethodButton onClick={handleAddNewPaymentMethod}>
        + {t('ADD_NEW_PAYMENT_METHOD')}
      </AddNewPaymentMethodButton>
      <StyledButton onClick={handleConfirm}>{t('ADMIN_LABEL_CONTINUE')}</StyledButton>
    </BodySection>
  );
};

export default SelectPaymentMethods;
