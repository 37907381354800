import styled from 'styled-components';
import Button from 'components/ui/Button';
import { SINGLE_LINE_ELLIPSIS, MULTI_LINE_ELLIPSIS } from 'style/mixins';
import { HIGHLIGHT_SECONDARY, ACCENT_PRIMARY, TEXT_100, TITLE_FONT_FAMILY } from 'style/constants';
import { TEXT_LABEL_L_MEDIUM, TEXT_LABEL_S_BOLD } from 'style/design-system/textStyles';

export const Body = styled.div`
  padding: 5px;
  padding-top: 20px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Question = styled.div`
  padding: 0 15px 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;
`;

export const QuestionText = styled.span`
  ${TEXT_LABEL_L_MEDIUM}
  ${MULTI_LINE_ELLIPSIS}
`;

export const StyledButton = styled(Button).attrs((props) => ({
  background: ACCENT_PRIMARY(props),
  fullwidth: true,
  textColor: TEXT_100(props),
}))`
  ${TEXT_LABEL_S_BOLD}
  font-family: ${TITLE_FONT_FAMILY};
  height: 28px;
  text-transform: uppercase;
`;

export const Title = styled.div`
  ${TEXT_LABEL_S_BOLD}
  ${SINGLE_LINE_ELLIPSIS}
  color: ${HIGHLIGHT_SECONDARY};
  position: absolute;
  top: 10px;
  left: 17px;
  max-width: calc(100% - 60px);
`;
