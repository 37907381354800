import React, { ComponentType, PropsWithChildren, useMemo } from 'react';
import Label, { LabelProps } from 'components/admin2/ui/Label';
import type { WithExtraProps } from 'utils';
import styled from 'styled-components';
import type { TranslationKey } from 'hooks/use-translation';

const LabelWrapper = styled.div`
  display: flex;
`;

export interface WithLabelProps extends PropsWithChildren<LabelProps> {
  description?: string;
  labelColor?: string;
  labelIcon?: string;
  labelIconSize?: number;
  labelIconSpacing?: number;
  labelKey?: TranslationKey;
  labelPadding?: string;
  labelTextSize?: string;
  labelTooltip?: JSX.Element;
  labelUppercase?: boolean;
  labelWhiteIcon?: boolean;
  noLabel?: boolean;
}

interface LabelOptions {
  compact?: boolean;
  padding?: string;
  styledLabel?: ComponentType<LabelProps>;
}

export default function withLabel<T extends CallableOrClass>(
  WrappedComponent: T,
  { compact, padding, styledLabel }: LabelOptions = {},
) {
  const StyledLabel = styledLabel || Label;
  return (({
    children,
    compact: compactLabelProp,
    description,
    descriptionKey,
    labelColor,
    labelIcon,
    labelIconSize,
    labelIconSpacing,
    label,
    labelKey,
    labelPadding = padding,
    labelTextSize,
    labelUppercase,
    labelWhiteIcon,
    labelTooltip,
    admin,
    ...props
  }: WithLabelProps) => {
    const displayLabel = useMemo(() => !!(
      labelKey ||
      label ||
      descriptionKey ||
      description ||
      labelIcon
    ) && !props.noLabel, [labelKey, label, descriptionKey, description, labelIcon,props.noLabel]);
    return (
      <>
        {displayLabel && (
          <LabelWrapper>
            <StyledLabel
              color={labelColor}
              compact={compact !== undefined ? compact : compactLabelProp}
              description={description}
              descriptionKey={descriptionKey}
              icon={labelIcon}
              iconSize={labelIconSize}
              iconSpacing={labelIconSpacing}
              label={label}
              labelKey={labelKey}
              padding={labelPadding}
              textSize={labelTextSize}
              uppercase={labelUppercase}
              whiteIcon={labelWhiteIcon}
              admin={admin}
            />
            {labelTooltip}
          </LabelWrapper>
        )}
        <WrappedComponent admin={admin} {...props}>{children}</WrappedComponent>
      </>
    );
  }) as WithExtraProps<T, WithLabelProps>;
}
