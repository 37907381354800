import styled from 'styled-components';

import Link from 'components/ui/Link';
import { ACTION_DISABLED, ACTION_NORMAL } from 'style/mixins';

const ActionLink = styled(Link).attrs((props) => ({
  'data-testid': props['data-testid'],
  plain: true,
}))<{disabled?: boolean}>`
  && {
    ${props => props.disabled ?
    ACTION_DISABLED :
    ACTION_NORMAL
};
  }
`;

export default ActionLink;
