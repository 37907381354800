import React from 'react';
import PropTypes from 'prop-types';
import CardButton from 'components/cards/CardButton';

export default class OpenLinkCardCta extends React.Component {
  static propTypes = {
    // cardRenderer: PropTypes.object.isRequired,
    ctaRenderer: PropTypes.shape({
      linkText: PropTypes.string,
      linkUrl: PropTypes.string,
    }).isRequired,
    onEngage: PropTypes.func.isRequired,
    testIdText: PropTypes.string,
  };

  static defaultProps = {
    testIdText: undefined,
  };

  handleOnClick = () => {
    const { ctaRenderer, onEngage } = this.props;
    const { linkUrl: url } = ctaRenderer;
    window.open(url, '_blank');

    onEngage({
      cta: 'open_link',
      url,
    });
  };

  render() {
    const { ctaRenderer, testIdText } = this.props;
    const {
      linkText: text,
    } = ctaRenderer;
    return (
      <CardButton
        data-testid={testIdText}
        onClick={this.handleOnClick}
      >
        {text}
      </CardButton>
    );
  }
}
