import React from 'react';
import { computeEntries } from 'array-utils';
import RealtimePage from 'components/core/RealtimePage';
import { useSelector, useDispatch } from 'hooks';
import {
  DynamicTranslationType,
  useEndUserTranslation,
} from 'hooks/use-translation';
import { trackGrid } from 'services/insights';
import { isEditMode } from 'services/admin';
import { isMobileLayout } from 'services/device';
import ScrollSlider from 'components/ui/ScrollSlider';
import {
  isGridMobile,
  getTotalSlidesToShow,
} from 'services/grid';
import {
  ClickPreventer,
  PagesGridButton,
  getSizes,
  PagesRowContainer,
  MobileGridButton,
  PageSlideItem,
  PagePlaceholder,
  EmptyButton,
  RowTitle,
} from '../styles';
import { IPagesRowBlock } from 'services/app/models/ILandingPageContent';
import { IObject } from 'models';

interface IPagesRowProps {
  data: IPagesRowBlock;
  onEdit: (data: IPagesRowBlock) => void;
  onMoveDown?: () => void;
  onMoveUp?: () => void;
  onRemove: () => void;
}

const PagesRow = ({
  data: rowData,
  onEdit,
  onRemove,
  onMoveUp,
  onMoveDown,
}: IPagesRowProps) => {
  const isMobile = useSelector(isMobileLayout);
  const totalSlidesToShow = useSelector(getTotalSlidesToShow);
  const isEditing = useSelector(isEditMode);
  const mobileGrid = useSelector(isGridMobile);
  const currentPageId = useSelector(state => state.app.object._id);
  const dispatch = useDispatch();
  const { endUserT } = useEndUserTranslation();

  const pages = rowData.pages ? rowData.pages : [];

  const gridSizes = getSizes(mobileGrid);
  const slidesToShow = Math.min(totalSlidesToShow, pages.length);
  const flushLeft = slidesToShow === pages.length;
  const GridButton = mobileGrid ? MobileGridButton : PagesGridButton;
  const ButtonContainer = flushLeft ? EmptyButton : GridButton;
  let { titleText } = rowData;
  const localizedTitleText = endUserT([
    DynamicTranslationType.pageRowTitle,
    { blockId: rowData.arrayId, pageId: currentPageId },
  ]);

  if (!isEditing && localizedTitleText) {
    titleText = localizedTitleText;
  }

  const trackClick = (page) => {
    const trackingDoc = {
      id: null,
      kind: 'channel_row',
    };
    const meta = {
      action: 'goto_page',
      page_id: page._id,
      page_name: page.data.name,
    };
    dispatch(trackGrid('engage', trackingDoc, meta));
  };

  const handleEdit = React.useCallback(() => onEdit(rowData), [rowData]);
  const handleOnClick = (page) => trackClick(page);
  const renderHeader = React.useCallback(() => {
    if (!mobileGrid && titleText) {
      return <RowTitle data-testid="rowTitle">{titleText}</RowTitle>;
    }
    return null;
  }, [mobileGrid, titleText]);

  return (
    <PagesRowContainer
      fullwidth={!isMobile}
      isGridMobile={mobileGrid}
      onEdit={handleEdit}
      onRemove={onRemove}
      onMoveUp={onMoveUp}
      onMoveDown={onMoveDown}
      showEditingBorder={isEditing}
      testId={`pagesRow-${rowData.arrayId}`}
      titleKey="LABEL_CHANNEL_NAVIGATOR"
    >
      <ClickPreventer preventClicks={isEditing}>
        <ScrollSlider
          arrowLeftIconName="arrowLeft"
          arrowRightIconName="arrowRight"
          flushLeft={flushLeft}
          hasTooltips={true}
          hideArrows={false}
          isGrid={false}
          isGridMobile={mobileGrid}
          isTouchSlider={isMobile}
          items={computeEntries(pages, 'page._id', false).map(({ key, value }) => ({ ...value, key }))}
          mobileTitle={titleText}
          renderHeader={renderHeader}
          slideAmount={Math.max(1, slidesToShow - 1)}
          slideButton={ButtonContainer}
          slideMargin={gridSizes.THUMBNAIL_MARGIN}
          slidesHeight={gridSizes.CHANNEL_ROW_HEIGHT}
          slidesShown={totalSlidesToShow}
          slideWidth={gridSizes.THUMBNAIL_WIDTH_SMALL}
        >
          {
            ({ page }) => (
              <RealtimePage id={page._id}>
                {
                  doc => (
                    doc ? (
                      <PageSlideItem
                        active={page._id === currentPageId}
                        onClick={handleOnClick}
                        page={doc as IObject}
                        width={gridSizes.THUMBNAIL_WIDTH_SMALL}
                      />
                    ) : (
                      <PagePlaceholder
                        height={gridSizes.THUMBNAIL_WIDTH_SMALL / 2}
                        width={gridSizes.THUMBNAIL_WIDTH_SMALL}
                      />
                    )
                  )
                }
              </RealtimePage>
            )
          }
        </ScrollSlider>
      </ClickPreventer>
    </PagesRowContainer>
  );
};

export default PagesRow;
