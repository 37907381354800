import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getShopifyCheckout, getShopifyCheckoutAvailableShippingLines, getShopifyPaymentProcessing } from 'services/ecommerce/selectors';
import { BodySection, BodySectionTitle } from '../sharedStyles';
import { useAdminTranslation } from 'hooks/use-translation';
import { RadioInputCard } from 'components/ui/RadioInputCard';
import { setShippingMethod, updateShopifyShippingLine } from 'services/ecommerce';
import { ShippingRateInfo, ShippingRateName, StyledSpinner } from './styles';
import { formatPrice } from 'shared/string-utils';
import { getShippingAddress } from 'services/shipping-address';
import { hasMatchingShippingAddress } from 'services/ecommerce/utils';
import hash from 'json-stable-stringify';

interface IShopifyShippingMethodsProps {
  shippingHandle: string;
}

const ShopifyShippingMethods: React.FC<IShopifyShippingMethodsProps> = ({ shippingHandle }) => {
  const shopifyCheckoutAvailableShippingLines = useSelector(getShopifyCheckoutAvailableShippingLines);
  const shopifyPaymentProcessing = useSelector(getShopifyPaymentProcessing);
  const currentCheckout = useSelector(getShopifyCheckout);
  const shippingAddress = useSelector(getShippingAddress);
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();
  const hasSameShippingAddress = useMemo(() => (
    hasMatchingShippingAddress(currentCheckout, shippingAddress)
  ), [hash(currentCheckout), hash(shippingAddress)]);

  const handleChangeShippingRateValue = React.useCallback((value: string) => {
    dispatch(setShippingMethod(value));
    dispatch(updateShopifyShippingLine(value));
  }, [dispatch]);

  // return null for non-shippable items
  if (!shopifyCheckoutAvailableShippingLines || !shopifyCheckoutAvailableShippingLines.ready) {
    return null;
  }

  if (!hasSameShippingAddress) {
    return (
      <BodySection shopifyPaymentProcessing={shopifyPaymentProcessing}>
        <BodySectionTitle>{t('LABEL_SHIPPING_METHOD')}</BodySectionTitle>
        <StyledSpinner />
      </BodySection>
    );
  }

  return (
    <BodySection shopifyPaymentProcessing={shopifyPaymentProcessing}>
      <BodySectionTitle>{t('LABEL_SHIPPING_METHOD')}</BodySectionTitle>
      {
        shopifyCheckoutAvailableShippingLines.shippingRates.map(shippingRate => (
          <RadioInputCard
            key={shippingRate.handle}
            checked={shippingHandle === shippingRate.handle}
            name="shopify-shipping-methods"
            value={shippingRate.handle}
            onChangeValue={handleChangeShippingRateValue}
          >
            <ShippingRateInfo>
              <ShippingRateName>{shippingRate.title}</ShippingRateName>
              <span>{Number(shippingRate.priceV2.amount) > 0 ? formatPrice(shippingRate.priceV2.currencyCode, Number(shippingRate.priceV2.amount)) :  t('FREE')}</span>
            </ShippingRateInfo>
          </RadioInputCard>
        ))
      }
    </BodySection>
  );
};

export default ShopifyShippingMethods;
