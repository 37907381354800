import { useMemo, useRef, useState } from 'react';
import useLinkInputValidation from 'hooks/use-link-input-validation';

const useExternalLinksInputs = () => {
  const [linkName, setLinkName] = useState('');
  const { linkUrl, setLinkUrl, linkUrlStatus, setLinkUrlStatus, onChange } = useLinkInputValidation('');

  const isSaveButtonDisabled = useMemo(() => {
    return linkUrlStatus !== 'valid' || linkName.trim() === '';
  }, [linkUrlStatus, linkName]);

  return {
    linkName,
    setLinkName,
    linkUrl,
    setLinkUrl,
    linkUrlStatus,
    setLinkUrlStatus,
    handleLinkUrlChange: onChange,
    isSaveButtonDisabled,
  };
};

export default useExternalLinksInputs;
