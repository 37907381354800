import { ADMIN_ACCENT_PRIMARY, ADMIN_TEXT_100 } from 'style/constants';
import styled from 'styled-components';
import Icon from 'components/ui/Icon';

export const GridContainer = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex: 0 0 auto;
`;

export const HoverBoundary = styled.div`
  position: relative;
  height: 0;
  z-index: 2;
  left: 20px;
  top: -12px;
  width: calc(100% - 40px);
  pointer-events: none;
`;

export const ModalWrapper = styled.div`
  position: relative;
  width: 100%;
  height: min-content;
  display: flex;
  align-items: center;
  & > * {
    pointer-events: auto;
  }
`;

export const AddButton = styled(Icon).attrs(props => ({
  color: ADMIN_ACCENT_PRIMARY(props),
  name: 'plusCircle',
}))`
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  border-radius: 50%;
  background: radial-gradient(${ADMIN_TEXT_100} 50%, ${ADMIN_ACCENT_PRIMARY} 50%);
  cursor: pointer;
  margin: 0 auto;
  & > svg {
    width: 24px;
    height: 24px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  &:hover {
    & ${AddButton} {
      opacity: 1;
    }
  }
`;
