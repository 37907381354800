import styled, { css } from 'styled-components';
import Tooltip from 'components/ui/Tooltip';

export const StyledTooltip = styled(Tooltip)`
  display: none;
  user-select: none;
`;

type TooltipContainerProps = {
  alwaysVisible?: boolean;
  containerCss?: ReturnType<typeof css> | string;
  disable: boolean;
};

export const TooltipContainer = styled.div<TooltipContainerProps>`
  display: flex;
  align-items: center;
  position: relative;
  & > *:first-child {
    flex: 1;
  }
  ${({ alwaysVisible, disable }) => (
    alwaysVisible ? css`
      & > ${StyledTooltip} {
        display: ${disable ? 'none' : 'block'};
      }
    ` : css`
      &:hover > ${StyledTooltip} {
        display: ${disable ? 'none' : 'block'};
      }
    `
  )}
  ${(props) => props.containerCss}
`;
