import React, { useMemo } from 'react';
import { StreamElapsedTime } from 'components/admin2/StreamElapsedTime';
import { useStreamStartTime } from 'hooks/use-stream-start-time';
import {
  LiveIndicator,
} from './styles';
import { useAdminTranslation } from 'hooks/use-translation';

interface StreamStatusIndicatorProps {
  activeSessionId: string;
  streamStatus: 'online' | 'offline';
}

export default function StreamStatusIndicator({
  activeSessionId,
  streamStatus,
}: StreamStatusIndicatorProps) {
  const isLive = useMemo(() => (
    streamStatus === 'online'
  ), [streamStatus]);
  const streamStartTime = useStreamStartTime();
  const { t } = useAdminTranslation();

  return (
    <LiveIndicator
      defaultColor={isLive ? true : !activeSessionId}
      live={isLive}
      data-testid="admin-livestream-preview-live-indicator"
    >
      {t(isLive ? 'LIVE' : !activeSessionId ? 'CHANNEL_STATUS_OFFLINE' : 'ADMIN_LABEL_PREVIEW')}
      {isLive && streamStartTime ? (
        <>
          {' '}<StreamElapsedTime startTime={streamStartTime} />
        </>
      ) : null}
    </LiveIndicator>
  );
}
