import React, { useMemo } from 'react';
import useFloatMenu from 'hooks/use-float-menu';
import { Container, Separator, DownArrowIcon, ClickCaptureOverlay, OptionsContainer } from './styles';
import { OPTION_TYPE, OptionData, Option, SelectedOption } from '../../models';
import OptionsSelectorModal from './OptionsSelectorModal';
import { SelectedColorOptionOnDisplay } from 'components/Ecommerce/sharedComponents/OptionsSelector/ColorSelector';
import { SelectedSizeOptionOnDisplay } from 'components/Ecommerce/sharedComponents/OptionsSelector/SizeSelector';
import { SelectedGenericOptionOnDisplay } from 'components/Ecommerce/sharedComponents/OptionsSelector/GenericSelector';
import { SHOPIFY_DEFAULT_TITLE } from 'services/shopify/constants';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device';

const OPTIONS_VIEW_ID = 'ecommerce-options_view';

interface OptionsViewProps {
  handleOptionChange: (type: string, value: OptionData) => void;
  isCartProduct?: boolean;
  isRenderingOnPanel?: boolean;
  options: Option[];
  selectedOptions: SelectedOption[];
  setMenuPosition: (menu: HTMLDivElement, menuContainer: HTMLDivElement) => void;
  variantName: string;
}

const OptionsView: React.FC<OptionsViewProps> = ({
  handleOptionChange,
  options,
  selectedOptions,
  isRenderingOnPanel,
  setMenuPosition,
  isCartProduct,
  variantName,
}) => {
  const isMobile = useSelector(isMobileLayout);
  const [isMobileOptionVariantOpen, setIsMobileOptionVariantOpen] = React.useState(false);
  const { isOpen: isDesktopOptionVariantOpen, containerRef, positionMenu, toggleMenu: toggleDesktopOptionVariant } = useFloatMenu(setMenuPosition);

  const handleClose = React.useCallback(() => {
    if (isMobile) {
      setIsMobileOptionVariantOpen(false);
      return;
    }

    toggleDesktopOptionVariant();
  }, [isMobile, toggleDesktopOptionVariant]);

  const handleOpen = React.useCallback(() => {
    if (isMobile) {
      setIsMobileOptionVariantOpen(true);
      return;
    }

    toggleDesktopOptionVariant();
  }, [isMobile, toggleDesktopOptionVariant]);

  const renderedOptions = useMemo(() => {
    // Max of only two options
    const limitedSelectedOptions = selectedOptions.slice(0,2);
    // Reorder to have the Color option first
    const genericOptions = limitedSelectedOptions.filter((option) => option.type === OPTION_TYPE.generic);
    const sizeOptions = limitedSelectedOptions.filter((option) => option.type === OPTION_TYPE.size);
    const colorOption = limitedSelectedOptions.filter((option) => option.type === OPTION_TYPE.color);
    const orderedOptions = [...colorOption, ...sizeOptions, ...genericOptions];

    const displayedOptions = orderedOptions.map((selectedOption) => {
      if (selectedOption.type === OPTION_TYPE.color) {
        return (
          <SelectedColorOptionOnDisplay
            isRenderingOnPanel={isRenderingOnPanel || isCartProduct || false}
            selectedOption={selectedOption}
            key={selectedOption.name}
          />
        );
      }

      if (selectedOption.type === OPTION_TYPE.size) {
        return (
          <SelectedSizeOptionOnDisplay
            isRenderingOnPanel={isRenderingOnPanel || false}
            selectedOption={selectedOption}
            key={selectedOption.name}
          />
        );
      }

      return (
        <SelectedGenericOptionOnDisplay
          isRenderingOnPanel={isRenderingOnPanel || false}
          selectedOption={selectedOption}
          key={selectedOption.name}
        />
      );
    });

    const displayedOptionsWithSeparators = displayedOptions.reduce((acc, curr, index) => {
      if (index === 0) {
        return [curr];
      }

      return [...acc, <Separator key={index} isRenderingOnPanel={isRenderingOnPanel || false}>/</Separator>, curr];
    }, [] as React.ReactNode[]);

    return displayedOptionsWithSeparators;
  }, [selectedOptions]);

  const isDefaultShopifyTitle = selectedOptions.some((option) => option.value.value.toLowerCase() === SHOPIFY_DEFAULT_TITLE);
  const isOpen = isDesktopOptionVariantOpen || isMobileOptionVariantOpen;

  if (isDefaultShopifyTitle || !renderedOptions.some(Boolean)) {
    return null;
  }

  return (
    <Container ref={containerRef} data-testid={OPTIONS_VIEW_ID} id={OPTIONS_VIEW_ID} isRenderingOnPanel={isRenderingOnPanel} isCartProduct={isCartProduct}>
      <ClickCaptureOverlay onClick={handleOpen} />
      <OptionsContainer>
        {renderedOptions}
      </OptionsContainer>
      <DownArrowIcon isRenderingOnPanel={isRenderingOnPanel || false}/>
      {isOpen && (
        <OptionsSelectorModal
          ref={positionMenu}
          handleOptionChange={handleOptionChange}
          options={options}
          selectedOptions={selectedOptions}
          variantName={variantName}
          onClose={handleClose}
        />
      )}
    </Container>
  );
};

export default OptionsView;
