import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import Icon from 'components/ui/Icon';
import { ADMIN_ACCENT_PRIMARY, ADMIN_SURFACE_5, ADMIN_TEXT_100, ADMIN_SURFACE_2 } from 'style/constants';
import { INNER_BORDER, FLEX_CENTER } from 'style/mixins';
import { ADMIN_TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';

type OptionRowButtonProps = PropsWithChildren<{
  active: boolean;
  icon?: string;
  iconCss?: ReturnType<typeof css> | string;
  onClick?: () => void;
  wrapText: boolean;
}>;

export default function({
  active,
  children,
  icon,
  iconCss,
  onClick,
  wrapText,
}: OptionRowButtonProps) {
  return (
    <OptionRowButton active={active} onClick={onClick} wrapText={wrapText}>
      {icon ? <StyledIcon iconCss={iconCss} name={icon} /> : null}
      {children}
    </OptionRowButton>
  );
}

const OptionRowButton = styled.div.attrs(props => ({
  border: `1px solid ${ADMIN_ACCENT_PRIMARY(props)}`,
  borderPx: 1,
  borderRadius: '5px',
}))<{active: boolean; wrapText: boolean}>`
  border-radius: 5px;
  padding: 13px;
  ${FLEX_CENTER};
  flex: 1;
  ${({ wrapText }) => wrapText && `
    flex-direction: column;
    text-align: center;
  `}
  gap: 8px;
  background: ${ADMIN_SURFACE_2};
  color: ${ADMIN_TEXT_100};
  user-select: none;
  cursor: pointer;
  ${ADMIN_TEXT_LABEL_S_MEDIUM}
  ${({ active }) => active && css`
    ${INNER_BORDER}
    background: ${ADMIN_SURFACE_5};
  `}
`;

const StyledIcon = styled(Icon)<{ iconCss?: ReturnType<typeof css> | string}>`
  ${FLEX_CENTER}
  & svg {
    width: 20px;
    height: 20px;
  }
  ${({ iconCss }) => iconCss}
`;
