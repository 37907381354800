import React, { ChangeEventHandler } from 'react';
import styled from 'styled-components';
import range from 'lodash/range';
import { CIRCLE } from 'style/mixins';
import {
  ADMIN_ACCENT_PRIMARY,
  ADMIN_TEXT_100,
  ADMIN_SURFACE_3,
  ADMIN_SURFACE_5,
  ADMIN_TEXT_300,
  ADMIN_SURFACE_6,
} from 'style/constants';
import { ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';

interface SliderProps {
  className?: string;
  max?: number;
  min?: number;
  onChange: (value: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  showProgress?: boolean;
  showStepIndicators?: boolean;
  step?: number;
  value: number;
}

export default function({
  className,
  max = 100,
  min = 0,
  onChange,
  showProgress = false,
  showStepIndicators = false,
  step = 0.001,
  value,
}: SliderProps) {
  const length = max - min;
  const rangePercentage = (value - min) * 100 / length;
  const stepCount = showStepIndicators ? 100 / (length / step) : 0;
  const steps = stepCount ? [...range(0, 100 + stepCount, stepCount)] : [];
  const valueSteps = stepCount ? range(min, max + 1, step) : [];

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const inputValue = parseInt(e.target.value, 10);
    if (!isNaN(inputValue)) {
      onChange(inputValue, e);
    }
  };

  return (
    <SliderWrapper className={className}>
      <Slider>
        <Input
          max={max}
          min={min}
          onChange={handleChange}
          step={step}
          type="range"
          value={value}
        />
        <Track
          rangePercentage={rangePercentage}
        >
          {showProgress && <TrackProgress />}
          <TrackThumb />
          {steps.map((stepPos) => <TrackStep key={stepPos} stepPos={stepPos}/>)}
        </Track>
      </Slider>
      <ValuesRow>
        {valueSteps.map((v) => (
          <StepValue key={v} active={value === v}>
            {v}
          </StepValue>
        ))}
      </ValuesRow>
    </SliderWrapper>
  );
}

const SliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 14px;
`;

const Slider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin: 5.5px 0;
`;

const ValuesRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StepValue = styled.span<{active: boolean}>`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  line-height: 14px;
  color: ${({ active }) => active ? ADMIN_TEXT_100 : ADMIN_TEXT_300};
`;

const Input = styled.input`
  position: absolute;
  cursor: pointer;
  left: -0.5em;
  width: calc(100% + 1em);
  height: 20px;
  opacity: 0;
  margin: 0;
  padding: 0;
`;

const TrackThumb = styled.div.attrs({
  radius: '14px',
})`
  position: absolute;
  ${CIRCLE}
  padding: 4px;
  z-index: 1;
  background: ${ADMIN_ACCENT_PRIMARY};
  transform: translateX(-50%);
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background: ${ADMIN_SURFACE_6};
  }
`;

const TrackProgress = styled.div`
  background: ${ADMIN_SURFACE_6};
  height: 100%;
`;

const Track = styled.div<{
  rangePercentage: number;
}>`
  display: flex;
  flex: 1;
  align-items: center;
  background: ${ADMIN_SURFACE_3};
  height: 3px;
  border-radius: 50px;
  pointer-events: none;
  & ${TrackProgress} {
    width: ${({ rangePercentage }) => rangePercentage}%;
  }
  & ${TrackThumb} {
    left: ${({ rangePercentage }) => rangePercentage}%;
  }
`;

const TrackStep = styled.div.attrs({
  radius: '3px',
})<{stepPos: number}>`
  position: absolute;
  left: ${({ stepPos }) => stepPos}%;
  background: ${ADMIN_SURFACE_5};
  ${CIRCLE}
`;
