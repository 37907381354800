import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { POST_AUTH_REDIRECT, SITE_ID } from 'config';
import RealtimeChannel from 'components/core/RealtimeChannel';
import QuestProgressFetcher from 'components/data/QuestProgressFetcher';
import Site from 'components/application/Site';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import { getLoaded } from 'services/app/selectors';
import { receiveOverlay } from 'services/overlays';
import { OnEventFn } from 'hooks/use-realtime-channel';
import styled from 'styled-components';

const FullViewLoadingSpinner = styled(LoadingSpinner)`
  height: 100vh;
  width: 100vw;
`;

export default function SiteWrapper() {
  const dispatch = useDispatch();
  const loaded = useSelector(getLoaded);

  const onReceiveOverlay: OnEventFn = (overlay) => dispatch(receiveOverlay(overlay));

  return (
    <>
      {
        (loaded && !POST_AUTH_REDIRECT) ? <Site /> : <FullViewLoadingSpinner />
      }
      <RealtimeChannel channel={`sites:${SITE_ID}:broadcast`} onMessage={onReceiveOverlay} />
      <QuestProgressFetcher />
    </>
  );
}
