import Icon from 'components/ui/Icon';
import { rgba } from 'colors';
import { ADMIN_ACCENT_PRIMARY, ADMIN_ALERT_WARNING, ADMIN_FONT_FAMILY, ADMIN_HIGHLIGHT_PRIMARY, ADMIN_HIGHLIGHT_SECONDARY, ADMIN_HIGHLIGHT_TERTIARY, ADMIN_SURFACE_3, ADMIN_SURFACE_5, ADMIN_TEXT_100, ADMIN_TEXT_300 } from 'style/constants';
import styled, { css } from 'styled-components';
import { IStyledProps } from 'styled-components';
import Input from 'components/admin2/TextInput';
import { ADMIN_TEXT_LABEL_M_MEDIUM, ADMIN_TEXT_LABEL_XS_MEDIUM } from 'style/design-system/textStyles';
import { ELLIPSIS } from 'style/mixins';

export const Container = styled.div<{ isCurrentPage: boolean, isEditingFolder: boolean, source: 'site_navigation' | 'toolbar' }>`
  height: 100%;
  width: 100%;
  padding: 5px 12.5px 5px 12.5px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-radius: 100px;

  cursor: pointer;
  position: relative;

  & > a:first-child {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  ${({ isCurrentPage, isEditingFolder, source, ...props }) => {
    if (source === 'site_navigation') {
      if (isEditingFolder) {
        return css`
          background: ${ADMIN_SURFACE_3};
        `;
      }
      if (isCurrentPage) {
        return css`
          border: 1px solid ${ADMIN_ACCENT_PRIMARY};
          background: ${rgba(ADMIN_ACCENT_PRIMARY(props), 0.1)};

          &:hover {
            background: ${ADMIN_SURFACE_5};
          }
        `;
      }
      return css`
        &:hover {
          background: ${ADMIN_SURFACE_5};
        }
      `;
    }
  }};
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SiteNavigationItemTypeIcon = styled(Icon).attrs((props: IStyledProps & {
  iconName: string;
  isParent?: boolean;
}) => ({
  name: props.iconName,
}))<{ iconName: string; isParent?: boolean; }>`
  display: flex;
  width: 18px;
  height: 18px;

  & > svg {
    width: 18px;
    height: 18px;
    path {
      fill: ${({ isParent }) => isParent === false ? ADMIN_HIGHLIGHT_SECONDARY : ADMIN_HIGHLIGHT_PRIMARY};
    }
  }

  margin-left: 6px;
  margin-right: 11.5px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UnpublishedChangesContainer = styled.div`
  margin-top: 2.5px;
`;

const ItemNameText = css`
  ${ADMIN_TEXT_LABEL_M_MEDIUM};
`;

export const Div = styled.div`
  border: 1px solid red;
`;

export const ItemNameInput = styled(Input)<{ setInputRef: React.RefObject<HTMLInputElement> }>`
  width: 80%;
  padding: 0;
  padding-right: 3px;
  margin-right: 6px;

  ${ItemNameText};
`;

export const ItemName = styled.div<{ maxLength: number }>`
  color: ${ADMIN_TEXT_100};
  margin-right: 6px;
  ${ELLIPSIS}
  max-width: ${({ maxLength }) => maxLength}ch;

  ${ItemNameText};
`;

export const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ItemNameCounter = styled.span`
  color: ${ADMIN_TEXT_300};

  font-family: ${ADMIN_FONT_FAMILY};
  ${ADMIN_TEXT_LABEL_XS_MEDIUM};
`;

export const HomeIcon = styled(Icon).attrs(() => ({
  name: 'homeFilled',
  color: ADMIN_ACCENT_PRIMARY,
}))`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 14px;
  height: 14px;

  svg {
    width: 14px;
    height: 14px;
  }

  margin-right: 5px;
`;

export const PrivateIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.64px;
  border-radius: 50%;
  background-color: ${ADMIN_ALERT_WARNING};

  width: 20px;
  height: 20px;

  margin-right: 7px;
`;

export const PrivateIcon = styled(Icon).attrs(props => ({
  name: 'tipsEyeHide',
  color: ADMIN_HIGHLIGHT_TERTIARY(props),
}))`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 12.73px;
  height: 12.73px;

  svg {
    width: 12.73px;
    height: 12.73px;
  }
`;

export const ArrowIcon = styled(Icon).attrs(props => ({
  color: ADMIN_HIGHLIGHT_SECONDARY(props),
  name: 'navArrow',
}))<{pointUp?: boolean}>`
  display: flex;
  transition: transform 0.5s;
  transform: rotate(${({ pointUp }) => pointUp ? 90 : -90}deg);
  margin-left: 12.5px;
  margin-right: 5px;
  & > svg {
    width: 7px;
    height: 13px;
  }
`;
