import React from 'react';
import { ErrorLabel, URLInput } from '../styles';
import { Checkbox } from 'components/admin2/ui/Checkbox';
import { OpenLinkBehaviorData } from '../utils';
import { isUrl } from 'url-utils';
import { useDebouncedState } from 'hooks';
import { removeHttpsFromUrl, cleanAndFormatUrl } from 'utils';
import { BehaviorsProps } from '.';

const OpenLinkInputs = ({ behavior, onChange }: BehaviorsProps<OpenLinkBehaviorData>) => {
  const [debouncedUrl, setUrl, url] = useDebouncedState(behavior.url, 500);

  React.useEffect(() => {
    if (debouncedUrl !== behavior.url) {
      onChange({
        ...behavior,
        url: debouncedUrl,
      });
    }
  }, [debouncedUrl, onChange, behavior.url]);

  const handleChangeOpenInNewTab = (value: boolean) => {
    onChange({
      ...behavior,
      openInNewTab: value,
    });
  };

  const handleChangeUrl = (value: string) => setUrl(cleanAndFormatUrl(value));

  return (
    <>
      <URLInput
        prefixInputIcon="linkOutline"
        padding="0"
        labelKey="ADMIN_LOGO_LINK_LABEL"
        placeholderKey="ADMIN_LABEL_ENTER_A_URL"
        type="url"
        onChange={handleChangeUrl}
        value={removeHttpsFromUrl(url)}
        valid={Boolean(url && isUrl(url))}
      />
      <ErrorLabel
        errorKey="LINK_ERROR_PLEASE_TRY_AGAIN"
        hidden={!url || isUrl(url)}
      />
      <Checkbox
        labelKey="OPEN_IN_NEW_TAB"
        onCheckedChange={handleChangeOpenInNewTab}
        checked={behavior.openInNewTab}
      />
    </>
  );
};

export default OpenLinkInputs;
