import EditorModal from 'components/admin-bridge/EditorModal';
import { TI18nKey } from 'components/i18n/TranslatedText/i18nKey';
import React, { PropsWithChildren } from 'react';
import { Content } from './styles';

interface IPageBlockSettingsProps extends PropsWithChildren {
  isOpen: boolean;
  onClose: () => void;
  titleKey: TI18nKey;
}

const PageBlockSettings: React.FC<IPageBlockSettingsProps> = ({ isOpen, onClose, titleKey, children }) => {
  return (
    <EditorModal
      draggableModalCss={`
        right: 20px;
        top: 20vh;
        position: fixed;
      `}
      isOpen={isOpen}
      itemSpacing="30px"
      modalTitleKey={titleKey}
      onClose={onClose}
    >
      <Content>
        {children}
      </Content>
    </EditorModal>
  );
};

export default PageBlockSettings;
