/* tslint:disable: jsx-boolean-value */
import React from 'react';
import PlayPauseButton from 'components/Video/video-controls/PlayPauseButton';
import FullscreenToggleButton from 'components/Video/video-controls/FullscreenToggleButton';
import TheaterModeToggleButton from 'components/Video/video-controls/TheaterModeToggleButton';
import VideoSeekBar from 'components/Video/video-controls/VideoSeekBar';
import VolumeSlider from 'components/Video/video-controls/VolumeSlider';
import AirplayToggleButton from 'components/Video/video-controls/AirplayToggleButton';
import ShareButton from 'components/Video/video-controls/ShareButton';
import ChromecastToggleButton from 'components/Video/video-controls/ChromecastToggleButton';
import ConcurrentsIcon from 'components/Video/video-controls/ConcurrentsIcon';
import { ControlsProps } from 'components/Video/video-controls/VideoOverlay/interfaces';
import CCButton from 'components/Video/video-controls/CCButton';
import {
  ControlsContainer,
  ControlsWrapper,
  Top,
  Bottom,
  Spacer,
  Wrapper,
  Header,
  StyledTimeProgress,
} from './styles';
import LiveBadge from 'components/ui/LiveBadge';

export default function Controls({ className, ...props }: ControlsProps) {
  const {
    isLandscape,
    isLive,
    isMobileLayout,
    videoDuration,
    setForceShowControls,
    showControls,
    socialsData: { facebook, twitter },
  } = props;

  const shouldRenderShareButton = facebook?.active || twitter?.active;

  const renderDesktop = () => (
    <>
      <Top>
        <VideoSeekBar {...props} />
      </Top>
      <Bottom>
        <PlayPauseButton {...props} />
        {isLive && <LiveBadge.Default />}
        {!isLive && (
          <Wrapper>
            <StyledTimeProgress videoDuration={videoDuration} />
            <VolumeSlider {...props} />
          </Wrapper>
        )}
        <ConcurrentsIcon {...props} color="white" />
        <Spacer />
        {isLive && <VolumeSlider {...props} />}
        <TheaterModeToggleButton {...props} />
        <FullscreenToggleButton {...props} />
        <ChromecastToggleButton {...props} />
        <AirplayToggleButton {...props} />
        {shouldRenderShareButton && <ShareButton {...props} />}
        <CCButton setShowControls={setForceShowControls} />
      </Bottom>
    </>
  );

  const renderMobile = () => (
    <>
      <Top>
        {!isLive && (
          <Wrapper>
            <StyledTimeProgress videoDuration={videoDuration} />
          </Wrapper>
        )}
      </Top>
      <Bottom isLandscape={isLandscape}>
        <VideoSeekBar {...props} />
        {isLive && <Spacer />}
      </Bottom>
    </>
  );

  return (
    <>
      {isMobileLayout && (
        <Header isLandscape={isLandscape} showControls={showControls}>
          {isLive && <LiveBadge.Default />}
          <ConcurrentsIcon className={className} color="white" />
          <Spacer />
          <FullscreenToggleButton {...props} />
          <ChromecastToggleButton {...props} />
          <AirplayToggleButton {...props} />
          <ShareButton {...props} />
          <CCButton setShowControls={setForceShowControls} />
        </Header>
      )}
      <ControlsWrapper className={className}>
        <ControlsContainer isLandscape={isLandscape}>
          {isMobileLayout ? renderMobile() : renderDesktop()}
        </ControlsContainer>
      </ControlsWrapper>
    </>
  );
}
