import React, { ComponentType, PropsWithChildren } from 'react';

/**
 * @description This HOC is used to disable drag and drop functionality on a component.
 * Used in page blocks to prevent elements inside the block from being dragged.
 * @param WrappedComponent - The component to wrap.
 * @returns A component with drag and drop disabled.
 */
export default function withDragDisabled<T>(WrappedComponent: ComponentType<T>) {
  return ({
    children,
    ...props
  }: T & PropsWithChildren) => {

    const handleStopPropagation = (e: any) => {
      e.stopPropagation();
      e.preventDefault();
    };

    return (
      <WrappedComponent
        draggable="false"
        onDragStart={handleStopPropagation}
        {...props}
      >
        {children}
      </WrappedComponent>
    );
  };
}
