import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { AirplayButton } from 'components/ui/Button';
import { ControlsProps } from 'components/Video/video-controls/VideoOverlay/interfaces';
import { hasAirplaySupport } from 'dom-utils';
import { queryVideoElement } from '../fullScreenUtils';

interface SafariHTMLVideoElement extends HTMLVideoElement {
  onwebkitcurrentplaybacktargetiswirelesschanged?: () => void;
  onwebkitplaybacktargetavailabilitychanged?: ((event: { availability: string }) => void);
  webkitCurrentPlaybackTargetIsWireless: boolean;
  webkitShowPlaybackTargetPicker(): void;
}

export default function AirplayToggleButton({
  isMobileLayout,
  airplayIsConnected: connected,
  onPlaybackUpdate,
  setAirplayIsConnected: setConnected,
  videoUrl,
}: ControlsProps) {
  const [isVisible, setVisible] = useState(hasAirplaySupport);
  const [videoElem, setVideoElem] = useState<SafariHTMLVideoElement | null>(null);
  const [firstChange, setFirstChange] = useState(true);

  const debouncedPlay = useCallback(debounce(() => {
    onPlaybackUpdate({ playing: true });
  }, 3e3), []);

  const showPlaybackTargetPicker = () => {
    videoElem?.webkitShowPlaybackTargetPicker?.();
  };

  useEffect(() => {
    if(!firstChange || connected) {
      debouncedPlay();
      return debouncedPlay.cancel;
    }
    setFirstChange(false);
  }, [connected]);

  useEffect(() => {
    const video = queryVideoElement() as null | SafariHTMLVideoElement;
    setVideoElem(video);

    if (video && hasAirplaySupport) {
      video.onwebkitplaybacktargetavailabilitychanged = ({ availability }) => {
        switch (availability) {
          case 'available':
            return setVisible(true);
          case 'not-available':
            return setVisible(false);
        }
      };
      video.onwebkitcurrentplaybacktargetiswirelesschanged = () => {
        setConnected(video.webkitCurrentPlaybackTargetIsWireless);
      };
      return () => {
        // type definitions
        video.onwebkitplaybacktargetavailabilitychanged = undefined;
        video.onwebkitcurrentplaybacktargetiswirelesschanged = undefined;
      };
    }
  }, [videoUrl, connected]);

  useEffect(() => {
    if (videoElem?.webkitCurrentPlaybackTargetIsWireless) {
      setConnected(videoElem.webkitCurrentPlaybackTargetIsWireless);
    }
  });

  useEffect(() => {
    if (connected) {
      setVisible(true);
    }
    if (!connected) {
      setVisible(false);
    }
  }, [connected]);

  useEffect(() => {
    // This is a hack to get the video to update every 2 seconds to force airplay to detect it
    // and start casting, the change to the video speed is unnoticeable for humans.
    // This fixes a bug where the video won't start casting until it detects an update, only happens
    // after a transition has occurred. It's happening in current prod too using the native browser player,
    // I tried every solution I could think of before using this, my best guess is that the issue is in the
    // react-player package and how it's handling source changes
    const interval = setInterval(() => {
      const video = queryVideoElement() as null | SafariHTMLVideoElement;
      if (video && !connected && isVisible) {
        const currentRate = video.playbackRate;
        video.playbackRate = currentRate + 0.0000000000000000000001;
        video.playbackRate = currentRate;
      }
    }, 2e3);
    return () => {
      clearInterval(interval);
    };
  }, [connected, isVisible]);

  return isVisible ? (
    <AirplayButton
      admin={true}
      hovering={connected}
      name={connected ? 'airplayDisconnect' : 'airplay'}
      onClick={showPlaybackTargetPicker}
      tooltipAlign="left"
      tooltipKey={connected ? 'LABEL_DISABLE_AIRPLAY' : 'LABEL_AIRPLAY'}
      tooltipPosition={isMobileLayout ? 'bottom' : 'top'}
    />
  ) : null;
}
