import get from 'lodash/get';
import groupBy from 'lodash/groupBy';

const getDayTimestamp = (val) => {
  const date = new Date(val);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
};

const DAY_MS = 24 * 60 * 60 * 1000;
const WEEK_MS = 7 * DAY_MS;
const intervals = {
  daily: DAY_MS,
  weekly: WEEK_MS,
};

// create list of events in given date range from schedule doc
export const getDays = ({ contentData, end, schedule, start }) => {
  let eventInstances = schedule.reduce((events, scheduleItem, scheduleIndex) => {
    const {
      arrayId,
      duration,
      kind,
      repeat,
      startTime,
    } = scheduleItem;
    const startTimestamp = new Date(startTime).getTime();
    const repeating = ['daily', 'weekly', 'monthly'].includes(repeat);
    const endTimestamp = repeating ?
      Infinity :
      startTimestamp + ((duration || 24 * 60 * 60) * 1000);
    const contentId = get(scheduleItem, `[${kind}]._id`);
    const { _id, type, ...videoData } = contentData[contentId] || {};
    const baseData = {
      duration,
      id: contentId,
      key: `${arrayId}-${startTimestamp}`,
      repeat: repeat || 'none',
      scheduleIndex,
      scheduleItemId: arrayId,
      type: kind,
      ...videoData,
    };

    // ignore items that are invalid or out of range
    if (!baseData.id || (endTimestamp < start || startTimestamp > end)) {
      return events;
    }
    // add single item (only render if its in the future or playing)
    const currentlyPlaying = startTimestamp + (duration * 1000) > Date.now();
    if (!repeating && (startTime >= Date.now() || currentlyPlaying)) {
      const eventData = {
        ...baseData,
        startTimestamp,
      };
      events.push(eventData);
    }
    // add instances of repeating events
    const interval = intervals[baseData.repeat];
    const firstRelevantPlayIndex = startTimestamp < start ?
      Math.ceil((start - startTimestamp) / interval) :
      0;
    const firstRelevantPlayTimestamp = startTimestamp + (firstRelevantPlayIndex * interval);
    for (
      let instanceStartTimestamp = firstRelevantPlayTimestamp;
      instanceStartTimestamp <= end;
      instanceStartTimestamp += interval
    ) {
      const eventData = {
        ...baseData,
        startTimestamp: instanceStartTimestamp,
      };
      if (instanceStartTimestamp === firstRelevantPlayTimestamp) {
        events.push(eventData);
      } else {
        events.push(eventData);
      }
    }
    return events;
  }, []);
  // sort
  eventInstances = eventInstances.sort((a, b) => a.startTimestamp - b.startTimestamp);
  // group by day
  const groupedDates = groupBy(
    eventInstances,
    ({ startTimestamp }) => getDayTimestamp(startTimestamp),
  );

  // sort days
  const days = Object.keys(groupedDates)
    .map(str => Number(str))
    .sort((a, b) => a - b)
    .map(dayTimestamp => ({
      dayTimestamp,
      events: groupedDates[dayTimestamp],
    }));

  return days;
};
