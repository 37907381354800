import React from 'react';
import { useSelector } from 'react-redux';
import {
  getCartItems,
  getCartItemsQuantity,
  getShopifyCheckoutAvailableShippingLines,
  getShopifyCheckoutError,
  getShopifyCheckoutLoading,
  getShopifyCheckoutReady,
  getShopifyCheckoutTotalTax,
  getShopifyPaymentProcessing,
  getShopifyTransaction,
  getSubtotal,
  getShopifyCheckout,
} from 'services/ecommerce/selectors';
import { getShopCurrencyCode } from 'services/shopify/selectors';

const useCheckout = () => {
  const [billingSameAsShipping, setBillingSameAsShipping] = React.useState(true);

  const shopifyCurrencyCode = useSelector(getShopCurrencyCode);
  const cartItems = useSelector(getCartItems);
  const cartItemsQuantity = useSelector(getCartItemsQuantity);
  const shopifyCheckoutLoading = useSelector(getShopifyCheckoutLoading);
  const shopifyCheckoutAvailableShippingLines = useSelector(getShopifyCheckoutAvailableShippingLines);
  const shopifyTotalTax = useSelector(getShopifyCheckoutTotalTax);
  const shopifyCheckoutError = useSelector(getShopifyCheckoutError);
  const total = useSelector(getSubtotal);
  const shopifyPaymentProcessing = useSelector(getShopifyPaymentProcessing);
  const hasCheckout = useSelector(getShopifyCheckout) ? true : false;

  return {
    cartItems,
    cartItemsQuantity,
    shopifyCheckoutAvailableShippingLines,
    shopifyTotalTax,
    shopifyCheckoutError,
    shopifyCurrencyCode,
    total,
    shopifyPaymentProcessing,
    billingSameAsShipping,
    setBillingSameAsShipping,
    shopifyCheckoutLoading,
    hasCheckout,
  };
};

export default useCheckout;
