import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { VideoTypes } from 'models';
import Dragger from 'components/admin2/LegacyDragger';
import VideoDataProvider from 'components/admin2/VideoDataProvider';
import VideoLibraryModal from 'components/admin2/videoPanels/VideoLibraryModal';
import videoLibraryModalTypes from 'components/modals/videoLibraryModalTypes';
import { VideoMetadataModalV2 } from 'components/modals/VideoMetadataModal';
import { useAdminTranslation } from 'hooks/use-translation';

import { WideButton } from './PageEditorScheduleSection';
import Section from './PageEditorSection';
import VideoItem from './PageEditorVideoItem';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default function PageEditorLiveStreamsSection({ onChange, value }) {
  const { t } = useAdminTranslation();
  const [isAddingStream, setAddingStream] = useState(false);
  const [videoTarget, setVideoTarget] = useState(null);

  const selectItem = ({ _id }) => {
    const newValue = [
      ...value,
      { _id },
    ];
    onChange(newValue);
    setAddingStream(false);
  };

  return (
    <Section
      infoKey="ADMIN_INFO_PAGE_LIVE_STREAMS"
      stretch
      titleKey="ADMIN_LABEL_PAGE_LIVE_STREAMS"
      vertical
    >
      <DraggerWrapper>
        <Dragger
          direction="vertical"
          droppableStyles={{
            alignItems: 'stretch',
            flexFlow: 'column nowrap',
          }}
          innerDivStyles={{
            alignItems: 'stretch',
            flexFlow: 'column nowrap',
          }}
          keyField="_id"
          list={value}
          noPadding
          onDragEnd={newValue => onChange(newValue)}
        >
          {(stream, index) => {
            const id = stream._id;

            if (!id) {
              return null;
            }

            return (
              <VideoDataProvider id={id}>
                {(data, error, loaded) => {
                  let itemProps = {
                    draggable: true,
                    onDelete: () => {
                      const newValue = value.filter((item, i) => i !== index);
                      onChange(newValue);
                    },
                    type: VideoTypes.livestream,
                  };
                  if (!loaded) {
                    itemProps = {
                      ...itemProps,
                      loading: true,
                    };
                  }
                  if (error || !data) {
                    itemProps = {
                      ...itemProps,
                      title: error?.message || 'Not found',
                    };
                  } else {
                    const { title, url } = data;
                    itemProps = {
                      ...itemProps,
                      onEdit: () => setVideoTarget(data),
                      title,
                      url,
                    };
                  }
                  return (
                    <VideoItem {...itemProps} />
                  );
                }}
              </VideoDataProvider>
            );
          }}
        </Dragger>
        <WideButton onClick={() => setAddingStream(true)}>
          {t('ADMIN_ACTION_ADD_LIVESTREAM')}
        </WideButton>
        {
          isAddingStream && (
            <VideoLibraryModal
              onClose={() => setAddingStream(false)}
              onSelectItem={selectItem}
              type={videoLibraryModalTypes.LIVESTREAM}
            />
          )
        }
        {
          videoTarget && (
            <VideoMetadataModalV2
              data={{
                videos: [videoTarget],
              }}
              onDismiss={() => setVideoTarget(null)}
            />
          )
        }
      </DraggerWrapper>
    </Section>
  );
}

const DraggerWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
`;

PageEditorLiveStreamsSection.propTypes = propTypes;
