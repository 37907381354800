import React from 'react';

import { Container } from './styles';
import EcommerceViewController from '../EcommerceViewController';

const LandingPageFloatCartWrapper = React.forwardRef((_, ref) => {
  return (
    <Container ref={ref as any}>
      <EcommerceViewController />
    </Container>
  );
});

export default LandingPageFloatCartWrapper;
