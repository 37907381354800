// TODO: Renderer-ize when we finish the new social API
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';
import CardImage from 'components/cards/CardImage';
import CardButton from 'components/cards/CardButton';
import Icon from 'components/ui/Icon';
import Image from 'components/ui/Image';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import TimeElapsed from 'components/ui/TimeElapsed';
import { bw } from 'colors';
import { openPopup } from 'utils';
import TranslatedText from 'components/i18n/TranslatedText';
import {
  TEXT_H5_ALT,
  TEXT_H5_TITLE,
  TEXT_H6,
} from 'style/mixins';
import { SURFACE_3 } from 'style/constants';
import TweetDataProvider from '../TweetDataProvider';

const BASE_TWEET_URL = 'https://twitter.com/intent/retweet?tweet_id=';

const DefaultUserPicture = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  margin: 0 10px 10px 10px;
  padding-left: 1px;
  padding-top: 3px;
  background-color: ${SURFACE_3};
  color: ${props => bw(SURFACE_3(props))};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const UserWrapper = styled.div`
  align-items: flex-start;
  display: flex;
`;

const UserPicture = styled(Image)`
  object-fit: cover;
  width: 37px;
  height: 37px;
  border-radius: 32px;
  margin: 0 10px 10px 0;
`;

const UserName = styled.div`
  display: flex;
  ${TEXT_H5_TITLE}
  line-height: 20px;
  align-items: center;
`;

const UserHandle = styled.div`
  ${TEXT_H6}
`;

const TweetText = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  ${TEXT_H5_ALT}
  max-height: 59px;
  overflow: hidden;
  margin-bottom: 22px;
`;

const CardLoading = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
`;

const TwitterLogo = styled(Icon).attrs(() => ({
  name: 'twitterFilled',
}))`
  line-height: 0;
  margin-left: 6px;
  & svg {
    width: 12px;
    height: 12px;
  }
`;

const StyledTimeElapsed = styled(TimeElapsed)`
  &:before {
    content: "•";
    font-weight: 700;
    margin: 0 4px;
  }
`;

const EmptyCard = styled.div`
  &, & ${TwitterLogo} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    & svg {
      width: 50%;
      height: 50%;
    }
  }
`;

const getImage = data => data?.mediaUrl;

const getText = data => data?.text;

const getProfileSrc = data => data?.user?.profileImageUrl;

const getUserName = data => data?.user?.name;

const getUserHandle = data => data?.user?.screenName;

export const getTweetIdFromUrl = (url = '') => {
  const statusIdRegEx = /[0-9]{10,20}/g;
  return get(url.match(statusIdRegEx), '[0]');
};

export default class TweetCard extends React.Component {
  static propTypes = {
    doc: PropTypes.shape({
      data: PropTypes.shape({
        entities: PropTypes.shape({
          media: PropTypes.arrayOf(PropTypes.object),
        }),
        status: PropTypes.shape({
          idStr: PropTypes.string,
          text: PropTypes.string,
          url: PropTypes.string,
        }),
      }),
      renderer: PropTypes.shape({
        tweetUrl: PropTypes.string,
      }),
    }).isRequired,
    iconName: PropTypes.string.isRequired,
    isCollapsed: PropTypes.bool,
    onDismiss: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    trackCard: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isCollapsed: false,
  };

  handleClick = (src) => {
    const { showModal } = this.props;
    showModal({ data: { src }, kind: 'image' });
  };

  handleTweetEngage = () => {
    const { doc, trackCard } = this.props;
    const tweetUrl = doc.renderer?.tweetUrl;
    const idStr = getTweetIdFromUrl(tweetUrl);
    const retweetUrl = `${BASE_TWEET_URL}${idStr}`;

    openPopup(650, 400, retweetUrl, 'Twitter');
    trackCard('engage', doc.renderer);
  };

  renderEmptyCard = () => {
    const {
      isCollapsed,
      onDismiss,
    } = this.props;

    return (
      <CardImage
        isCollapsed={isCollapsed}
        onClose={onDismiss}
      >
        <EmptyCard>
          <TwitterLogo />
        </EmptyCard>
      </CardImage>
    );
  };

  render() {
    const {
      doc,
      iconName,
      isCollapsed,
      onDismiss,
    } = this.props;
    const { renderEmptyCard } = this;

    // handle non-renderized card
    const tweetUrl = doc.renderer?.tweetUrl || doc.data?.status?.url;
    const id = getTweetIdFromUrl(tweetUrl);

    if (!(id && tweetUrl)) {
      return renderEmptyCard();
    }

    return (
      <TweetDataProvider id={id}>
        {(data, error, loaded) => {
          if (!loaded) {
            return (
              <CardImage
                isCollapsed={isCollapsed}
                onClose={onDismiss}
              >
                <CardLoading>
                  <LoadingSpinner />
                </CardLoading>
              </CardImage>
            );
          }
          if (error) {
            return renderEmptyCard();
          }

          const src = getImage(data);
          const text = getText(data);
          const profileSrc = getProfileSrc(data);
          const userName = getUserName(data);
          const userHandle = getUserHandle(data);
          const handleCardMediaClick = () => this.handleClick(src);

          const getDefaultUserPicture = () => {
            return (
              <DefaultUserPicture>
                <Icon name="twitter" />
              </DefaultUserPicture>
            )
          };

          return (
            <CardImage
              dim
              isCollapsed={isCollapsed}
              onClick={handleCardMediaClick}
              onClose={onDismiss}
              responsive
              src={src}
            >
              <Container>
                <UserWrapper>
                  <UserPicture
                    fallback={getDefaultUserPicture}
                    src={profileSrc}
                  />
                  <div>
                    {userName && (
                      <UserName>
                        {userName}
                        <StyledTimeElapsed date={data?.createdAt} />
                        <TwitterLogo />
                      </UserName>
                    )}
                    { userHandle && <UserHandle data-testid="tweetCardUsername">@{ userHandle }</UserHandle> }
                  </div>
                </UserWrapper>
                <TweetText data-testid="tweetCardMessage">{ text } </TweetText>
              </Container>
              <CardButton data-testid="liveCardCtaButton" iconName={iconName} onClick={this.handleTweetEngage}>
                <TranslatedText componentProps={{ 'data-testid': 'liveCardCtaButtonTitle' }} stringKey="RETWEET" />
              </CardButton>
            </CardImage>
          );
        }}
      </TweetDataProvider>
    );
  }
}
