/* tslint:disable:jsx-boolean-value */
import React, { useMemo } from 'react';
import { TransparentIconButton } from 'components/ui/Button';
import { ControlsProps } from 'components/Video/video-controls/VideoOverlay/interfaces';
import { THEATER_MODE_TOGGLE_ICON } from 'injection-classes';
import {
  requestFullscreen,
  canFullscreen,
  exitFullscreen,
} from '../fullScreenUtils';
import { PlayerTypes } from 'models';
import { MAESTRO_BLACK, MAESTRO_WHITE } from 'style/constants';

export default function TheaterModeToggleButton({
  className = '',
  isFull,
  isFullscreen,
  isMobileLayout,
  setViewMode,
  theaterModeActive,
  trackVideo,
  video,
  videoKind = PlayerTypes.file,
  videoTitle = '',
  spot,
}: ControlsProps) {
  const fullClassName = useMemo(() => (
    `${className} ${THEATER_MODE_TOGGLE_ICON}`
  ), [className]);

  const isTheater = useMemo(() => {
    return isFull && theaterModeActive;
  }, [isFull, theaterModeActive]);

  const toggleTheaterMode = () => {
    const id = video._id;
    const url = video?.url || '';

    trackVideo(
      'control',
      { id, kind: 'fullscreen', spot, title: videoTitle },
      { action: isTheater ? 'disable' : 'enable', kind: videoKind, url },
    );

    setViewMode(isTheater ? null : 'theater');

    if (!isTheater) {
      // TODO: Fix this, it needs to pass an elementId to indicate what is requesting to go fullscreen(the video element's ID goes here I think...)
      requestFullscreen('theater', id || '');
      return;
    }
    exitFullscreen('theater');
  };

  // not eligible
  if (!canFullscreen() || isMobileLayout || isFullscreen) {
    return null;
  }

  return (
    <TransparentIconButton
      admin
      className={fullClassName}
      textColor={MAESTRO_WHITE}
      hoverBackground={MAESTRO_WHITE}
      hoverTextColor={MAESTRO_BLACK}
      name="theater"
      onClick={toggleTheaterMode}
      tooltipAlign="left"
      tooltipKey={isTheater ? 'ACTION_DISABLE_THEATER_MODE' : 'ACTION_THEATER_MODE'}
    />
  );
}
