import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import {
  MAESTRO_WHITE,
} from 'style/constants';
import {
  desktop2KOnly,
  desktopFHDOnly,
  mobileLandscapeOnly,
  NO_HIGHLIGHT,
} from 'style/mixins';
import { HTMLAttributes } from 'react';

interface OverlayStyleProps {
  isFullscreen?: boolean;
  isMobile?: boolean;
  isPlaying?: boolean;
  showControls?: boolean;
}

export const Overlay = styled.div<OverlayStyleProps>`
  height: 100%;
  z-index: 0;
  position: relative;
  ${NO_HIGHLIGHT}
  & video::cue {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: ${({ isFullscreen }) => isFullscreen ? '30px' : '20px'};
    color: ${MAESTRO_WHITE};
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.64);
  }
  @media (max-width: 430px) {
    & video::cue {
      font-size: 15px;
    }
  }
  ${mobileLandscapeOnly`
    & video::cue {
      font-size: 20px;
    }
  `}
  ${desktopFHDOnly`
    & video::cue {
      font-size: 30px;
    }
  `}
  ${desktop2KOnly`
    & video::cue {
      font-size: 40px;
    }
  `}
`;

export const OverlayControls = styled.div<OverlayStyleProps>`
  ${NO_HIGHLIGHT}
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column;
  height: 100%;
  transition: opacity 0.75s;
  ${({ showControls }) => showControls ? `
  ` : `
    opacity: 0;
    cursor: none;
    pointer-events: none;
  `}
`;

export const PlayButton = styled.div<OverlayStyleProps & HTMLAttributes<HTMLSpanElement>>`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.75s;
  z-index: 1;
  position: relative;
  ${NO_HIGHLIGHT}
  ${({ isPlaying, isMobile }) => `
    opacity: ${!isMobile && isPlaying ? 0 : 1};
  `}
`;

export const PlayIcon = styled(Icon).attrs(props => ({
  color: MAESTRO_WHITE,
}))`
  z-index: 3;
  ${NO_HIGHLIGHT}
  & svg {
    cursor: pointer;
    width: 35px;
    height: 35px;
  }
`;

export const FullscreenClicker = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  ${NO_HIGHLIGHT}
`;

export const MobileWrapper = styled.button`
  ${NO_HIGHLIGHT}
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  ${({ disabled }) => disabled && 'pointer-events: none;'}
`;
