import styled from 'styled-components';
import { IconButton } from 'components/ui/Button';
import { MAESTRO_WHITE, MAESTRO_BLACK } from 'style/constants';
import { CIRCLE } from 'style/mixins';

export const PlayPauseButton = styled(IconButton).attrs(props => ({
  admin: true,
  background: MAESTRO_WHITE,
  hoverBackground: MAESTRO_BLACK,
  hoverTextColor: MAESTRO_WHITE,
  radius: '33px',
  textColor: MAESTRO_BLACK,
}))`
  cursor: pointer;
  ${CIRCLE}
  font-size: 16px;
  line-height: 0;
  padding: 9px;
  & svg {
    margin-left: 2px;
    width: 33px;
    height: 33px;
  }
`;
