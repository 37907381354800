import styled from 'styled-components';
import { MAESTRO_BLACK, MAESTRO_WHITE } from 'style/constants';
import {
  TransparentIconButton,
} from 'components/ui/Button';

export const FullscreenButton = styled(TransparentIconButton).attrs(props => ({
  admin: true,
  textColor: MAESTRO_WHITE,
  hoverBackground: MAESTRO_WHITE,
  hoverTextColor: MAESTRO_BLACK,
}))`
  padding: 5px;
  & svg {
    width: 19px !important;
    height: 13px !important;
  }
`;
