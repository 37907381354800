import React, { useMemo } from 'react';
import styled from 'styled-components';
import Dropdown from 'components/admin2/ui/Dropdown';
import { FONT_SIZES } from 'components/admin-bridge/CTAEditorModal/utils';

export interface FontSizeDropdownProps {
  className?: string;
  compactDropdown?: boolean;
  fontSize: number;
  labelKey?: string;
  onSizeChange?: (fontSize: number) => void;
}

export default function({
  className,
  compactDropdown,
  fontSize,
  labelKey,
  onSizeChange,
}: FontSizeDropdownProps) {
  const handleChange = (option: typeof FONT_SIZES[number]) => {
    onSizeChange?.(option.value);
  };

  const selectedFontSize = useMemo(() => (
    FONT_SIZES.find(option => option.value === fontSize)
  ), [fontSize]);

  return (
    <FontSizeDropdown
      className={className}
      compactDropdown={compactDropdown}
      hideSearchIcon={true}
      labelKey={labelKey}
      onChange={handleChange}
      options={FONT_SIZES}
      value={selectedFontSize}
    />
  );
}

const FontSizeDropdown = styled(Dropdown).attrs({
  padding: '0',
  labelPadding: '0 0 10px 0',
  width: 'auto',
})`
  width: max-content;
` as typeof Dropdown;
