import React, { ComponentProps, useState, useEffect, useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentPageDraftDoc,
  getIsCurrentPageGateActive,
  getObject,
  getLandingPageContent,
} from 'services/app/selectors';
import { isMissingSupportEmail as isMissingSupportEmailSelector } from 'services/customer-profile/selectors';
import { GateKind } from 'models/IGate';
import { publishGateState } from 'services/admin/actions';
import withRenderCondition from 'components/core/withRenderCondition';
import {
  StyledLabelToggle,
} from './styles';
import { showModal } from 'services/modals/actions';
import { ModalKinds } from 'services/modals/types';
import { isUserProducerRole } from 'services/auth/selectors';
import { PageType } from 'models';
import { getPlanFeatures } from 'services/billing';
import { COMPONENTS_WITH_VIDEO } from 'components/admin-bridge/AdminGridController/constants';

function GateToggle({
  checked,
  onChange,
  ...props
}: ComponentProps<typeof StyledLabelToggle>) {
  const dispatch = useDispatch();
  const currentPageDraft = useSelector(getCurrentPageDraftDoc);
  const initialActive = useSelector(getIsCurrentPageGateActive);
  const [isActive, setIsActive] = useState(initialActive);
  const currentPage = useSelector(getObject);
  const isMissingSupportEmail = useSelector(isMissingSupportEmailSelector);
  const isProducer = useSelector(isUserProducerRole);
  const landingContent = useSelector(getLandingPageContent);
  const planCanHoldFreeEvents = useSelector(getPlanFeatures)?.freeEvents;

  const shouldHaltDisablingLandingPageGate = useMemo(
    () =>
      landingContent.some((content) =>
        COMPONENTS_WITH_VIDEO.includes(content.kind),
      ),
    [landingContent],
  );

  const gateExists = !isNil(currentPage?.data?.gate);
  const updateGate = (active: boolean) => {
    const newPage = cloneDeep(currentPage!);
    newPage.data.gate.active = active;
    dispatch(publishGateState({ updatedPage: newPage }));
  };

  const tryToggle = (active: boolean) => {
    const newPage = currentPageDraft || currentPage;
    const isChannel = newPage?.type === PageType.CHANNEL;

    /**
     * If the user is disabling a gate on a landing page and there is any video spotlight, tagged videos, or playlist, ask to upgrade plan
     */
    if (!isChannel && !planCanHoldFreeEvents && shouldHaltDisablingLandingPageGate && active === false) {
      dispatch(
        showModal({
          kind: ModalKinds.upgradePlan,
          data: {
            planWarningMessage: 'ADMIN_UPGRADE_PLAN_LANDING_PAGE_WITHOUT_GATE_ERROR',
            preSelectedPlan: 'custom',
          },
        }),
      );
      return;
    }
    if (!gateExists || newPage?.data?.gate?.kind === GateKind.NONE) {
      dispatch(
        showModal({
          kind: ModalKinds.setUpAccess,
        }),
      );
      return;
    }
    if (isMissingSupportEmail) {
      dispatch(showModal({ kind: ModalKinds.supportContact }));
      return;
    }
    (onChange || updateGate)(active);
  };

  useEffect(() => {
    if (!onChange && gateExists && initialActive !== isActive) {
      setIsActive(initialActive);
    }
  }, [initialActive, gateExists, onChange]);

  if (isProducer) {
    return null;
  }

  return (
    <StyledLabelToggle
      data-testid="gateToggle"
      checked={checked !== undefined ? checked : isActive}
      onChange={tryToggle}
      {...props}
    />
  );
}

export default withRenderCondition(GateToggle);
