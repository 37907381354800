import { SURFACE_5, TEXT_100, TEXT_200 } from 'style/constants';
import { TEXT_BODY_S_REGULAR } from 'style/design-system/textStyles';
import styled from 'styled-components';
import { Line } from 'components/ui/Skeleton/styles';
import Link from 'components/ui/Link';

export const NavigationLinksContainer = styled.div`
  display: flex;
  padding: 20px 50px;
  align-items: flex-start;
  white-space: nowrap;
  width: 100%;
  gap: 40px;
  position: relative;

  @media (max-width: 1200px) {
    gap: 20px;
  }
`;

export const NavigationParentWrapper = styled.div`
  transform: translate(0);
`;

export const NavigationBarContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  min-width: 0;
  padding: 0px 15px;
`;

export const NavigationParentTag = styled(Link)<{ isCurrentPage?: boolean, isFolder: boolean }>`
  ${TEXT_BODY_S_REGULAR}
  color: ${({ isCurrentPage }) => (isCurrentPage ? TEXT_100 : TEXT_200)};
  text-decoration: none;
  position: relative;
  cursor: ${({ isFolder }) => (isFolder ? 'default' : 'pointer')};
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  :hover {
    color: ${TEXT_100};
    transition: color 0.2s;
  }
`;

export const StyledParentDivider = styled(Line).attrs((props) => ({
  color: SURFACE_5(props),
}))`
  height: 14px;
  width: 1px;
  margin-bottom: 0px;
`;
