import React from 'react';
import { StyledSpinner } from 'components/admin-bridge/Toolbar/styles';
import Button from './Button';
import { useSelector } from 'react-redux';
import { getAdminAlertWarning } from 'services/themes';
import { getAdminText500 } from 'services/themes';

interface IStreamStatusIndicatorProps {
  isDisabled: boolean;
  isEnding: boolean;
  isStarting: boolean;
  onEnd: () => void;
  onStart: () => void;
  streamStatus: 'online' | 'offline';
}

export default function GoLiveButton({
  isEnding,
  isDisabled,
  isStarting,
  onEnd,
  onStart,
  streamStatus,
}: IStreamStatusIndicatorProps) {
  const adminAlertWarning = useSelector(getAdminAlertWarning);
  const isLive = streamStatus === 'online';
  const adminText500 = useSelector(getAdminText500);

  if (isLive && isEnding || isStarting)
    return <StyledSpinner />;

  if (isLive)
    return (
      <Button
        disabled={isDisabled}
        onClick={onEnd}
        stringKey="ADMIN_LABEL_GO_OFFLINE"
        data-testid="admin-livestream-preview-go-offline-btn"
        minWidth="98px"
      />
    );

  return (
    <Button
      background={adminAlertWarning}
      textColor={adminText500}
      disabled={isDisabled}
      onClick={onStart}
      stringKey="ADMIN_LABEL_GO_LIVE"
      data-testid="admin-livestream-preview-go-online-btn"
      minWidth="98px"
    />
  );
}
