import React from 'react';
import { CTA_BUTTON_MAX_TEXT_LENGTH } from '../../../ui/CTAButton';
import TextInput from 'components/admin2/TextInput';
import { useCTAEditorModalContext } from '../Context';
import { useDebouncedState } from 'hooks';

const Text = () => {
  const { onChangeText, cta } = useCTAEditorModalContext();
  const [debouncedText, setText, text] = useDebouncedState(cta.text, 500);

  React.useEffect(() => {
    setText(cta.text);
  }, [cta.text]);

  React.useEffect(() => {
    if (debouncedText !== cta.text) {
      onChangeText(debouncedText);
    }
  }, [debouncedText, onChangeText, cta.text]);

  const handleChangeText = React.useCallback((value: string) => {
    setText(value.slice(0, CTA_BUTTON_MAX_TEXT_LENGTH));
  }, []);

  return (
    <TextInput
      labelKey="ADMIN_LABEL_BUTTON_LABEL"
      padding="0"
      placeholderText="e.g. WATCH NOW"
      onChange={handleChangeText}
      value={text}
    />
  );
};

export default Text;
