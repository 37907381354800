import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import { TEXT_100 } from 'style/constants';
import { SINGLE_LINE_ELLIPSIS } from 'style/mixins';
import { ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';

export const Concurrents = styled.span<{ color?: string }>`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  ${SINGLE_LINE_ELLIPSIS}
  margin-left: 11px;
  color: ${({ color, ...props }) => color || TEXT_100(props)};
  opacity: 0.7;
`;

export const Eye = styled(Icon).attrs(props => ({
  color: props.color || TEXT_100(props),
  name: 'eyeFilled',
}))`
  margin-left: 6px;
  & svg {
    width: 21px;
    height: 11px;
  }
`;

export const Container = styled.div`
  visibility: ${({ visible }: { visible: boolean }) => visible ? 'visible' : 'hidden'};
  display: flex;
  align-items: center;
  margin-left: 10px;
`;
