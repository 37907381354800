import React from 'react';
import Behaviors from '../Behaviors';
import Text from './Text';
import BehaviorDropdown from './BehaviorDropdown';
import VariantOption from './VariantOption';
import ButtonHeight from './ButtonHeight';
import { useCTAEditorModalContext } from '../Context';

const Content = () => {
  const { cta, onChangeBehavior } = useCTAEditorModalContext();
  return (
    <>
      <Text />
      <BehaviorDropdown onChange={onChangeBehavior} behavior={cta.behavior} />
      <Behaviors onChange={onChangeBehavior} behavior={cta.behavior} />
      <ButtonHeight />
      <VariantOption />
    </>
  );
};

export default Content;
