import PropTypes from 'prop-types';
import React from 'react';
import VideoDataProvider from 'components/admin2/VideoDataProvider';
import VideoItem from '../PageEditorVideoItem';

const propTypes = {
  arrayId: PropTypes.string.isRequired,
  draggable: PropTypes.bool.isRequired,
  duration: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  kind: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  renderThumbnail: PropTypes.bool.isRequired,
  repeat: PropTypes.oneOf(['daily', 'monthly', 'none', 'weekly']).isRequired,
  startTimestamp: PropTypes.number.isRequired,
};

const defaultProps = {
  kind: 'video',
};

export default function ScheduleItemWithProvider(props) {
  const { arrayId, id, kind, startTimestamp } = props;
  const key = `${arrayId}-${startTimestamp}`;

  return (
    <VideoDataProvider key={key} id={id} type={kind}>
      {(data, error, loaded) => {
        let itemProps = { ...props };
        if (!loaded) {
          itemProps = {
            ...itemProps,
            loading: true,
          };
        } else if (data) {
          const { durationSeconds, title, thumbnail, members } = data;

          itemProps = {
            ...itemProps,
            durationSeconds,
            members,
            thumbnail,
            title,
          };
        } else {
          itemProps = {
            ...itemProps,
            title: error?.message || 'Not found',
          };
        }

        return (
          <VideoItem {...itemProps} />
        );
      }}
    </VideoDataProvider>
  );
}

ScheduleItemWithProvider.propTypes = propTypes;
ScheduleItemWithProvider.defaultProps = defaultProps;
