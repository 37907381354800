import Button from 'components/ui/Button';
import styled from 'styled-components';
import { SURFACE_4, SURFACE_5, TEXT_100 } from 'style/constants';
import { TEXT_LABEL_L_MEDIUM, TEXT_LABEL_M_MEDIUM, TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';
import { desktopOnly, mobileOnly } from 'style/mixins';
import { darken } from 'colors';

export const DesktopContainer = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  gap: 20px;
  border-radius: 10px;
  background: ${props => darken(SURFACE_4(props), 0.1)};
  width: max-content;
  z-index: 2;
  cursor: default;
  border: 1px solid ${SURFACE_5};
`;

export const Title = styled.span`
  color: ${TEXT_100};

  ${desktopOnly`${TEXT_LABEL_S_MEDIUM}`}
  ${mobileOnly`${TEXT_LABEL_L_MEDIUM}`}
  margin-right: 3px;
`;

export const MobileDoneButton = styled(Button)`
  padding: 13px 24px;
  margin-top: 20px;
  width: 100%;
`;

export const VariantName = styled.div`
  ${TEXT_LABEL_M_MEDIUM}
  color: ${TEXT_100};
  margin-bottom: 20px;
`;
