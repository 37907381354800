import { ADMIN_TEXT_100 } from 'style/constants';
import { ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';
import styled from 'styled-components';

export const CreatingStreamLabel = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${ADMIN_TEXT_100};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;

  & > * {
    margin-left: 8px;
  }
`;
