import React, { memo, forwardRef, CSSProperties, IframeHTMLAttributes } from 'react';

interface IProps {
  allow?: string;
  allowFullScreen?: boolean;
  className?: string;
  height?: number;
  loading?: boolean;
  name?: string;
  onLoad: () => void;
  referrerPolicy?: IframeHTMLAttributes<HTMLIFrameElement>['referrerPolicy']
  sandbox?: string;
  src?: string;
  srcDoc?: string;
  style?: CSSProperties;
  title?: string;
  width?: number;
}

const IframeMemoized = ({
  allow,
  allowFullScreen,
  className,
  height,
  loading,
  name,
  onLoad,
  referrerPolicy,
  sandbox,
  src,
  srcDoc,
  style,
  title,
  width,
}: IProps,
ref?:  ((instance: HTMLIFrameElement | null) => void) | React.RefObject<HTMLIFrameElement> | null ) => {
  return (
    <iframe
      ref={ref}
      allowFullScreen={allowFullScreen}
      className={className}
      frameBorder="0"
      allow={allow}
      height={height}
      hidden={loading}
      name={name}
      onLoad={onLoad}
      referrerPolicy={referrerPolicy}
      sandbox={sandbox}
      scrolling="yes"
      src={src}
      srcDoc={srcDoc}
      style={{ ...style, flexGrow: 1 }}
      title={title}
      width={width}
    />
  );
};

export default memo(forwardRef(IframeMemoized));
