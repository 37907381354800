import React, { ComponentType, useMemo } from 'react';
import ShareModal from 'components/Video/video-controls/ShareModal';
// @ts-ignore
import ModalContainer from 'components/modals/ModalContainer';
import { ControlsProps } from 'components/Video/video-controls/VideoOverlay/interfaces';
import {
  VideoModalContainer,
  CloseButton,
} from './styles';

const MODAL_MAP: Record<string, ComponentType<ControlsProps>> = {
  shareModal: ShareModal,
};

export default function VideoModal(props: ControlsProps) {
  const { showVideoModal, videoModalId, isFullscreen } = props;
  const ModalContent = useMemo(() => videoModalId && MODAL_MAP[videoModalId], [videoModalId]);

  const close = () => showVideoModal();

  return ModalContent ? (
    <VideoModalContainer>
      <CloseButton onClick={close} />
      <ModalContent {...props} />
      {isFullscreen && <ModalContainer />}
    </VideoModalContainer>
  ) : null;
}
