import Button from 'components/ui/Button';
import styled from 'styled-components';
import { SURFACE_5, TEXT_100, TEXT_300 } from 'style/constants';
import { TEXT_BODY_S_BOLD, TEXT_LABEL_M_MEDIUM, TEXT_LABEL_S_MEDIUM, TEXT_LABEL_XS_MEDIUM, TEXT_TITLE_M } from 'style/design-system/textStyles';
import { mobileOnly } from 'style/mixins';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

export const GoBackWrapper = styled.div`
  margin-bottom: 10px;
`;

export const Name = styled.span`
  color: ${TEXT_100};

  ${TEXT_LABEL_M_MEDIUM}
  max-width: 100%;
  white-space: normal;
  overflow-wrap: break-word;

  ${mobileOnly`
    ${TEXT_TITLE_M}
  `}

  margin-bottom: 12px;
`;

export const Description = styled.span`
  color: ${TEXT_300};

  ${TEXT_LABEL_S_MEDIUM}
  max-width: 100%;
  white-space: normal;
  overflow-wrap: break-word;

  ${mobileOnly`
    ${TEXT_LABEL_M_MEDIUM}
  `}

  margin-bottom: 20px;
`;

export const Info = styled.span`
  margin-top: 5px;

  color: ${TEXT_300};
  ${TEXT_LABEL_XS_MEDIUM};

  margin-bottom: 20px;
`;

export const OptionsSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  margin-bottom: 40px;
`;

export const StyledButton = styled(Button).attrs(props => ({
  disabledBackground: SURFACE_5(props),
  disabledColor: TEXT_300(props),
}))`
  height: 44px;
  min-height: 44px;
  width: 100%;
  padding: 13px 16px 13px 24px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  align-self: stretch;
`;

export const ButtonText = styled.span`
  ${TEXT_BODY_S_BOLD};
`;
