import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import capitalize from 'lodash/capitalize';
import { useSelector } from 'hooks';
import { secondsToFormattedTime } from 'utils';
import { ADMIN_SERVICE_BASE_URL } from 'config';
import { getPrimaryToken } from 'services/auth';

import DragButt from 'components/admin2/ui/DragButt';
import AjaxProvider from 'components/core/AjaxProvider';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import ActionContainer from 'components/admin2/ActionContainer';
import { injectT, useAdminTranslation } from 'hooks/use-translation';
import { lazy } from 'utils/lazy';
import LiveBadge from 'components/ui/LiveBadge';
import {
  VideoItem,
  ItemStart,
  ItemName,
  ItemDetails,
  StyledIcon,
  Thumbnail,
  ThumbnailPlaceholder,
  Stack,
  Stack2,
  PlaylistStack,
  HalfWidth,
  Column,
  ActionColumn,
  TypeColumn,
  StyledTimestamp,
  Duration,
  PlaylistLength,
  SpinnerWrapper,
  Repeat,
} from './styles';

const twitchLiveRegEx = /(?:www\.|go\.)?twitch\.tv\/([a-z0-9_]+)($|\?)/;

const TwitchLiveIndicator = (props) => {
  const { channel, token } = props;
  return channel && (
    <AjaxProvider
      headers={{ Authorization: `Bearer ${token}` }}
      method="GET"
      url={`${ADMIN_SERVICE_BASE_URL}/video-metadata/twitch-live/${channel}`}
    >
      {(data) => {
        if (!data?.isLive) {
          return null;
        }
        return <LiveBadge.Default />;
      }}
    </AjaxProvider>
  );
};
TwitchLiveIndicator.propTypes = {
  channel: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

const propTypes = {
  className: PropTypes.string,
  draggable: PropTypes.bool,
  durationSeconds: PropTypes.number,
  loading: PropTypes.bool,
  members: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    schema: PropTypes.string,
  })),
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  renderThumbnail: PropTypes.bool,
  repeat: PropTypes.oneOf([
    'daily',
    'monthly',
    'none',
    'weekly',
  ]),
  startTimestamp: PropTypes.number,
  thumbnail: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(['video', 'playlist', 'livestream']).isRequired,
  url: PropTypes.string,
};

const defaultProps = {
  className: '',
  draggable: false,
  durationSeconds: null,
  loading: false,
  members: [],
  onDelete: null,
  onEdit: null,
  renderThumbnail: false,
  repeat: 'none',
  startTimestamp: null,
  thumbnail: null,
  title: 'Untitled video',
  url: null,
};

const assetTypeToI18n = injectT(
  (t) => lazy(
    () => ({
      playlist: t('ADMIN_LABEL_PLAYLIST'),
      video: t('ADMIN_LABEL_VIDEO'),
    }),
  ),
);

export default function PageEditorVideoItem({
  className,
  draggable,
  durationSeconds,
  loading,
  members,
  onDelete,
  onEdit,
  renderThumbnail,
  repeat,
  startTimestamp,
  thumbnail,
  title,
  type,
  url,
}) {
  const { t } = useAdminTranslation();
  const primaryToken = useSelector(getPrimaryToken);
  const isPlaylist = type === 'playlist';
  const isLiveStream = type === 'livestream';
  const twitchChannel = useMemo(() => (
    isLiveStream ? url?.match?.(twitchLiveRegEx)?.[1] : null
  ), [url, twitchLiveRegEx, isLiveStream]);
  const showDuration = typeof durationSeconds === 'number';

  const renderPlaylistThumbnail = !loading && isPlaylist && thumbnail;
  const renderVideoThumbnail =
    !loading && renderThumbnail && thumbnail && !renderPlaylistThumbnail;
  const renderPlaceholder = !loading && renderThumbnail && !thumbnail;

  const padLeft = renderThumbnail && !startTimestamp && !draggable;

  return (
    <VideoItem className={className} padLeft={padLeft}>
      {!loading && draggable && <DragButt />}
      {!loading && startTimestamp && (
        <ItemStart>
          <StyledTimestamp
            compact={false}
            noLocale
            show="time"
            timestamp={startTimestamp}
          />
          { repeat && repeat !== 'none' && (
            <Repeat>
              <span>{t('ADMIN_LABEL_REPEATING')}</span>
              <span>{capitalize(repeat)}</span>
            </Repeat>
          )}
        </ItemStart>
      )}
      <ItemDetails hasStart={!!startTimestamp}>
        {renderVideoThumbnail && (<Thumbnail image={thumbnail} />)}
        {renderPlaylistThumbnail && (
          <PlaylistStack>
            <Thumbnail image={thumbnail} />
            <Stack />
            <Stack2 />
          </PlaylistStack>
        )}
        {renderPlaceholder && (
          <ThumbnailPlaceholder>
            <StyledIcon name="play" />
          </ThumbnailPlaceholder>
        )}
        <HalfWidth>
          {!loading && (<ItemName>{ title }</ItemName>)}
          { twitchChannel && (
            <TwitchLiveIndicator channel={twitchChannel} token={primaryToken} />
          )}
        </HalfWidth>
        {!loading && !isLiveStream && (
          <Column>
            { showDuration && (<Duration>{secondsToFormattedTime(durationSeconds)}</Duration>) }
            { isPlaylist && (<PlaylistLength>{t('ADMIN_LABEL_AMOUNT_VIDEOS', { amount: String(members.length || 0) })}</PlaylistLength>) }
          </Column>
        )}
        {!loading && !renderThumbnail && (
          <Column>
            {assetTypeToI18n(t)[type] || capitalize(type)}
          </Column>
        )}
        {!loading && renderThumbnail && (
          <TypeColumn>
            {assetTypeToI18n(t)[type] || capitalize(type)}
          </TypeColumn>
        )}
        {!loading && (
          <ActionColumn>
            <ActionContainer
              onEdit={onEdit}
              onRemove={onDelete}
            />
          </ActionColumn>
        )}
        {loading && (
          <SpinnerWrapper>
            <LoadingSpinner />
          </SpinnerWrapper>
        )}
      </ItemDetails>
    </VideoItem>
  );
}

PageEditorVideoItem.propTypes = propTypes;
PageEditorVideoItem.defaultProps = defaultProps;
