import React from 'react';
import { GridContainer } from '../styles';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device';
import Countdown from 'components/ui/Countdown';
import { getCountdownStartTime, getCountdownTitle } from 'services/app/selectors';
import LandingContentItem from 'components/LandingContentItem';
import { getLandingPageContent } from 'services/admin';
import styled from 'styled-components';

const StyledCountdown = styled(Countdown)`
  position: relative;
`;


const GridView: React.FC = () => {
  const landingContent = useSelector(getLandingPageContent);
  const isMobile = useSelector(isMobileLayout);
  const countdownTitle = useSelector(getCountdownTitle);
  const countdownStartTime = useSelector(getCountdownStartTime);

  return (
    <GridContainer>
      {isMobile && <StyledCountdown message={countdownTitle} startTime={countdownStartTime} />}
      {
        landingContent.map((content, index) => (
          <LandingContentItem
            key={index}
            index={index}
            item={content}
          />
        ))
      }
    </GridContainer>
  );
};

export default GridView;
