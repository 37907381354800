/* tslint:disable: jsx-boolean-value */
import React, { useCallback, useMemo, useState } from 'react';
import Slider from 'components/ui/Slider';
import debounce from 'lodash/debounce';
import { PlayerTypes } from 'models';
import { ControlsProps } from 'components/Video/video-controls/VideoOverlay/interfaces';
import {
  VolumeSliderContainer,
  VolumeIcon,
  VolumeSliderStyleProps,
  SliderWrapper,
} from './styles';
import { MAESTRO_BLACK, MAESTRO_WHITE } from 'style/constants';

const noVolumeSupport = new Set(['iframe_video', 'livestream_new']);

export default function VolumeSlider({
  className,
  changeVolume,
  isLandscape,
  isMobileLayout,
  isPlaying,
  muted = false,
  toggleMute,
  videoKind = PlayerTypes.file,
  vertical,
  volume,
}: ControlsProps & VolumeSliderStyleProps) {
  const playerVolume = useMemo(() => muted ? 0 : volume, [muted, volume]);
  const [isHovering, setHovering] = useState(false);

  const disableHover = useCallback(debounce(() => {
    setHovering(false);
  }, 3e3), [setHovering]);

  const enableHover = () => {
    setHovering(true);
    disableHover();
  };

  const hideSlider = () => {
    setHovering(false);
  };

  const volumeIcon = useMemo(() => {
    if (!volume || muted) {
      return 'volumeMuted';
    }
    if (volume < 0.5) {
      return 'volumeLow';
    }
    return 'volumeHigh';
  }, [volume, muted]);

  const toggleVolume = () => {
    if (isMobileLayout) {
      return isHovering ? hideSlider() : enableHover();
    }
    toggleMute();
  };

  const updateVolume = (newVolume: number) => {
    enableHover();
    changeVolume(newVolume);
  };

  if (noVolumeSupport.has(videoKind)) {
    return null;
  }

  return (
    <VolumeSliderContainer
      onMouseEnter={enableHover}
      onMouseLeave={hideSlider}
      hovering={isHovering}
      isMobile={isMobileLayout}
      landscape={isMobileLayout && isLandscape}
      vertical={vertical}
    >
      <VolumeIcon
        admin
        name={volumeIcon}
        onClick={toggleVolume}
        tooltipKey={`ADMIN_LABEL_${muted ? 'UNMUTE' : 'MUTE'}`}
        tooltipAlign={isMobileLayout ? 'left' : 'right'}
        vertical={vertical}
      />
      <SliderWrapper>
        <Slider
          className={className}
          decreaseKey="ArrowDown"
          fixedLength
          increaseKey="ArrowUp"
          max={1}
          navigationStep={0.1}
          onChange={updateVolume}
          progressColor={MAESTRO_WHITE}
          showThumb={isMobileLayout || !isPlaying}
          sliderLength={isMobileLayout && isLandscape ? '95px' : isMobileLayout ? '90px' : '60px'}
          trackBackground={MAESTRO_BLACK}
          value={playerVolume}
          vertical={vertical}
        />
      </SliderWrapper>
    </VolumeSliderContainer>
  );
}
