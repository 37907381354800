import styled from 'styled-components';
import { ADMIN_HIGHLIGHT_SECONDARY, ADMIN_SURFACE_5 } from 'style/constants';
import DragButt from 'components/admin2/ui/DragButt';
import { ADMIN_SCROLL_BAR } from 'style/mixins';

export const SITE_NAVIGATION_ITEM_HEIGHT = 43;

export const Container = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Content = styled.div`
  ${ADMIN_SCROLL_BAR};
  scrollbar-color: ${ADMIN_SURFACE_5} transparent;
  scrollbar-width: thin;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;

  & > div {
    padding: 0;
    padding-right: 10px;
    padding-bottom: 60px;
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
`;

export const SiteNavigationItemContainer = styled.div<{ isChild?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: ${SITE_NAVIGATION_ITEM_HEIGHT}px;
`;

export const StyledDragButt = styled(DragButt)`
  & > div div:after, > div div:before {
    background: ${ADMIN_HIGHLIGHT_SECONDARY};
  }
  margin-right: 8px;
  transform: scale(0.8);
`;
