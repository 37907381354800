import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NAVIGATION_BLOCK_CUSTOM_NAVIGATION_ID, NAVIGATION_BLOCK_CUSTOM_NAVIGATION_MENU_LINK_ITEM, NAVIGATION_BLOCK_ID, NAVIGATION_BLOCK_SITE_NAVIGATION_ID, NAVIGATION_BLOCK_TOGGLE_ID } from 'global-ids';
import hash from 'json-stable-stringify';
import { useAdminTranslation } from 'hooks/use-translation';
import produce from 'immer';
import set from 'lodash/set';
import { hidePrivatePagesFromNavigation, onConfirmChangeForNavigationBlock } from 'services/navigationv2/utils';
import { getObject } from 'services/app';
import { getPageNavigationEnabled } from 'services/app/selectors';
import { setPendingPageDoc } from 'services/admin';
import { fetchAllNavigations, getAreAllNavigationsAlreadyFetched, getCreateLinkForCustomNavigation, getCustomNavigationCreationLoading, getEditableFolderIdInNavigationBlock, getNavigationSelectedInTheDropdown, setCreateLinkForCustomNavigation, updateNavigation } from 'services/navigationv2';
import { IExternalLink, INavigationItem, INavigationParent, NAVIGATION_TYPE } from 'models/INavigation';
import Dragger from 'components/admin2/Dragger';
import ToggleSwitch from 'components/admin2/ui/ToggleSwitch';
import Spinner from 'components/ui/Spinner';
import NavigationBlockItem from './NavigationBlockItem';
import SiteNavigationExternalLinks from './SiteNavigationExternalLinks';
import SiteNavigationExternalLinksForm from './SiteNavigationExternalLinksForm';
import NavigationBlockDropdown from './NavigationBlockDropdown';
import CustomNavigationBlockDropdown from './CustomNavigationBlockDropdown';
import MenuItem from './MenuItem';
import ExternalLinksInputs from './ExternalLinksInputs';
import useExternalLinksInputs from './ExternalLinksInputs/useExternalLinksInputs';
import MenuItemLink from './MenuItem/LinkItem';
import { ScrollableContainer, Info, SubtitleWrapper, Subtitle, Title, Body, MenuItemsText, ExternalLinksInputsWrapper, ExternalLinksDragAndDropWrapper, MenuItemWrapper, StyledDragButt, SpinnerContainer, Container, TitleAndToggle } from './styles';

const NavigationBarBlockSettings = () => {
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();
  const object = useSelector(getObject);
  const selectedNav = useSelector(getNavigationSelectedInTheDropdown);
  const selectedNavigation = useMemo(() => hidePrivatePagesFromNavigation(selectedNav), [hash(selectedNav)]);
  const selectedNavigationType = selectedNavigation.type;
  const { isAddingLinkToCustomNavigation: isCreatingNewLink, autoFilledLinkName } = useSelector(getCreateLinkForCustomNavigation);
  const isLoading = useSelector(getCustomNavigationCreationLoading);
  const areAllNavigationsLoaded = useSelector(getAreAllNavigationsAlreadyFetched);
  const isNavigationEnabledOnPage = useSelector(getPageNavigationEnabled);
  const editableFolderId = useSelector(getEditableFolderIdInNavigationBlock);
  const { linkName, linkUrl, linkUrlStatus, setLinkName, handleLinkUrlChange, isSaveButtonDisabled } = useExternalLinksInputs();

  useEffect(() => {
    if (areAllNavigationsLoaded) return;
    dispatch(fetchAllNavigations());
  }, []);

  const navigationItems = useMemo(() => {
    return selectedNavigation.parents.flatMap(parent => [
      parent,
      ...parent.children,
    ]);
  },[hash(selectedNavigation)]);

  const calculateIsParent = useCallback((item: INavigationItem) => {
    return selectedNavigation.parents.some(parent => parent.id === item.id);
  }, [hash(selectedNavigation)]);

  useEffect(() => {
    setLinkName(autoFilledLinkName);
  }, [autoFilledLinkName]);

  const renderSiteNavigation = useCallback(() => {
    return (
      <Body
        id={NAVIGATION_BLOCK_SITE_NAVIGATION_ID}
        data-testid={NAVIGATION_BLOCK_SITE_NAVIGATION_ID}
      >
        {navigationItems.map(item => {
          const isParent = calculateIsParent(item);
          return (
            <NavigationBlockItem
              key={item.id}
              item={item}
              isParent={isParent}
            />
          );
        })}
        <SiteNavigationExternalLinks />
      </Body>
    );
  }, [hash(navigationItems), calculateIsParent]);

  const onNavigationItemsChange = ({
    items,
  }: {
    items: INavigationParent[];
  }) => {
    const updatedNavigation = { ...structuredClone(selectedNavigation), parents: items };

    updatedNavigation.parents.forEach(parent => {
      parent.children.forEach(child => {
        // @ts-ignore
        delete child.children; // the library automatically adds a children property to the children. we don't want that.
      });
    });

    const isEqual = JSON.stringify(selectedNavigation.parents) === JSON.stringify(updatedNavigation.parents);
    if (isEqual) {
      return;
    }
    dispatch(updateNavigation(updatedNavigation));
  };

  const disableDrag = React.useCallback(({ item }) => item.id !== editableFolderId, [editableFolderId]);

  const renderNavigationItem = useCallback((data) => {
    const item = data.item as INavigationItem;
    return (
      <MenuItemWrapper>
        <StyledDragButt maxWidth="unset" data-testid={NAVIGATION_BLOCK_CUSTOM_NAVIGATION_MENU_LINK_ITEM} />
        <MenuItem navigationItem={item} />
      </MenuItemWrapper>
    );
  }, []);

  const renderNavigationItemsDragAndDrop = useCallback(() => {
    if (!selectedNavigation.parents.length) {
      return null;
    }

    return (
      <ExternalLinksDragAndDropWrapper>
        <Dragger
          maxDepth={2}
          onChange={onNavigationItemsChange}
          onConfirmChange={onConfirmChangeForNavigationBlock}
          renderItem={renderNavigationItem}
          disableDrag={disableDrag}
          source="navigation_block-parents"
          treeData={selectedNavigation.parents}
        />
      </ExternalLinksDragAndDropWrapper>
    );
  }, [hash(selectedNavigation.parents), onNavigationItemsChange, renderNavigationItem]);

  const onExternalLinksChange = useCallback((treeData) => {
    const items: IExternalLink[] = treeData.items;
    dispatch(updateNavigation({
      ...selectedNavigation,
      externalLinks: items,
    }));
  }, [hash(selectedNavigation)]);

  const renderExternalLinkItem = useCallback((data) => {
    const item = data.item as IExternalLink;
    return (
      <MenuItemWrapper>
        <StyledDragButt maxWidth="unset" data-testid={NAVIGATION_BLOCK_CUSTOM_NAVIGATION_MENU_LINK_ITEM}/>
        <MenuItemLink link={item} />
      </MenuItemWrapper>
    );
  }, []);

  const renderExternalLinksDragAndDrop = useCallback(() => {
    if (!selectedNavigation.externalLinks.length) {
      return null;
    }

    return (
      <ExternalLinksDragAndDropWrapper>
        <Dragger
          maxDepth={1}
          onChange={onExternalLinksChange}
          onConfirmChange={undefined}
          renderItem={renderExternalLinkItem}
          source="navigation_block-links"
          treeData={selectedNavigation.externalLinks}
        />
      </ExternalLinksDragAndDropWrapper>
    );
  }, [hash(selectedNavigation.externalLinks), onExternalLinksChange, renderExternalLinkItem]);

  const renderCustomNavigation = () => {
    const closeCustomNavigationExternalLinksInputs = () => {
      dispatch(setCreateLinkForCustomNavigation({
        isAddingLink: false,
        linkName: '',
      }));
    };
    return (
      <Body
        id={NAVIGATION_BLOCK_CUSTOM_NAVIGATION_ID}
        data-testid={NAVIGATION_BLOCK_CUSTOM_NAVIGATION_ID}
      >
        <MenuItemsText>{t('ADMIN_NAVIGATION_BLOCK_CUSTOM_SECTION_TITLE')}</MenuItemsText>
        {renderNavigationItemsDragAndDrop()}
        {renderExternalLinksDragAndDrop()}
        {isCreatingNewLink && (
          <ExternalLinksInputsWrapper>
            <ExternalLinksInputs
              linkName={linkName}
              linkUrl={linkUrl}
              linkUrlStatus={linkUrlStatus}
              setLinkName={setLinkName}
              handleLinkUrlChange={handleLinkUrlChange}
              closeItself={closeCustomNavigationExternalLinksInputs}
              isSaveButtonDisabled={isSaveButtonDisabled}
              navigationId={selectedNavigation._id!}
            />
          </ExternalLinksInputsWrapper>
        )}
      </Body>
    );
  };

  const renderNavigation = () => {
    if (isLoading) {
      return (
        <SpinnerContainer>
          <Spinner style={{ width: '30px' }} />
        </SpinnerContainer>
      );
    }
    if (selectedNavigationType === NAVIGATION_TYPE.default) {
      return renderSiteNavigation();
    }
    if (selectedNavigationType === NAVIGATION_TYPE.custom) {
      return renderCustomNavigation();
    }
    return null;
  };

  const renderFooter = () => {
    if (isLoading) return null;
    if (selectedNavigationType === NAVIGATION_TYPE.default) {
      return <SiteNavigationExternalLinksForm />;
    }
    if (selectedNavigationType === NAVIGATION_TYPE.custom) {
      return <CustomNavigationBlockDropdown />;
    }
    return null;
  };

  const handleToggle = useCallback((checked: boolean) => {
    dispatch(setPendingPageDoc(
      object._id,
      produce(object, (draft) => set(draft, 'data.regions.navigation.state', checked ? 'on' : 'off')),
    ));
  }, [hash(object)]);

  return (
    <Container
      id={NAVIGATION_BLOCK_ID}
      data-testid={NAVIGATION_BLOCK_ID}
    >
      <TitleAndToggle>
        <Title>{t('ADMIN_NAVIGATION_BLOCK_TITLE')}</Title>
        <ToggleSwitch
          id={NAVIGATION_BLOCK_TOGGLE_ID}
          data-testid={NAVIGATION_BLOCK_TOGGLE_ID}
          checked={isNavigationEnabledOnPage}
          onChange={handleToggle}
        />
      </TitleAndToggle>
      {isNavigationEnabledOnPage && (
        <>
          <SubtitleWrapper>
            <Subtitle>{t('ADMIN_NAVIGATION_BLOCK_SUBTITLE')}</Subtitle>
          </SubtitleWrapper>
          <Info>{t('ADMIN_NAVIGATION_BLOCK_INFO')}</Info>
          {!isLoading && <NavigationBlockDropdown />}
          <ScrollableContainer>
            {renderNavigation()}
          </ScrollableContainer>
          {renderFooter()}
        </>
      )}
    </Container>
  );
};

export default NavigationBarBlockSettings;
