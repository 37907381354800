import React from 'react';
import GateToggle from 'components/admin-bridge/Toolbar/actions/GateToggle';
import Button from 'components/admin-bridge/Toolbar/actions/Button';
import AutoSave from 'components/admin-bridge/Toolbar/actions/AutoSave';
import EditChannelPage from 'components/admin-bridge/Toolbar/actions/EditChannelPage';
import PublishButton from 'components/admin-bridge/Toolbar/actions/PublishButton';
import useHandlers from './hooks/use-handlers';
import useDefaultConditions, { Action } from './hooks/use-default-conditions';
import { useSelector } from 'react-redux';
import { isUnpublished } from 'services/app/selectors';
import { getAdminAccentSecondary } from 'services/themes';
import { useAdminTranslation } from 'hooks/use-translation';

function DefaultView() {
  const isPageUnpublished = useSelector(isUnpublished);
  const adminAccentSecondary = useSelector(getAdminAccentSecondary);
  const canRender = useDefaultConditions();

  const {
    clearPendingChanges,
    navToStreaming,
    navToGateEditor,
    exitEditor,
  } = useHandlers();
  const { t } = useAdminTranslation();

  return (
    <>
      <GateToggle
        shouldRender={canRender(Action.gateToggle)}
      />
      <AutoSave
        onClick={clearPendingChanges}
        shouldRender={canRender(Action.autoSave)}
      />
      <Button
        data-testid="streamButton"
        disabled={isPageUnpublished}
        onClick={navToStreaming}
        shouldRender={canRender(Action.stream)}
        stringKey="ADMIN_LABEL_STREAMING"
        tooltip={isPageUnpublished ? t('ADMIN_TOOLBAR_PUBLISH_TOOLTIP') : undefined}
        tooltipPosition="bottom"
        background={adminAccentSecondary}
      />
      <Button
        data-testid="editGateButton"
        onClick={navToGateEditor}
        shouldRender={canRender(Action.editGate)}
        stringKey="ADMIN_LABEL_EDIT_GATE"
      />
      <EditChannelPage
        shouldRender={canRender(Action.editChannel)}
      />
      <Button
        data-testid="exitEditorButton"
        onClick={exitEditor}
        shouldRender={canRender(Action.exitEditor)}
        stringKey="ADMIN_ACTION_EXIT_EDITOR"
      />
      <PublishButton
        shouldRender={canRender(Action.publish)}
      />
    </>
  );
}

export default {
  toolbarRight: DefaultView,
};
