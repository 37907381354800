import AdminModal from 'components/admin2/ui/AdminModal';
import styled from 'styled-components';
import {
  ADMIN_SURFACE_1,
  ADMIN_ALERT_WARNING,
} from 'style/constants';
import {
  ADMIN_SCROLL_BAR,
} from 'style/mixins';
import Icon from 'components/ui/Icon';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_LABEL_S_BOLD } from 'style/design-system/textStyles';

export const StyledAdminModal = styled(AdminModal)`
  padding: 0;
  width: 400px;
`;

export const Container = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  background: ${ADMIN_SURFACE_1};
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 25px;
`;

export const WarningText = styled.div`
`;

export const WarningIcon = styled(Icon).attrs(props => ({
  color: ADMIN_ALERT_WARNING(props),
  name: 'warning',
}))`
  padding-bottom: 12px;
  & svg {
    width: 27px;
    height: 22px;
  }
`;

export const Body = styled.div`
  margin: 0 20px 20px;
`;

export const Notice = styled.div`
  display: flex;
  ${ADMIN_TEXT_LABEL_S_BOLD}
  margin-bottom: 10px;
  justify-content: center;
  text-transform: uppercase;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

export const ReferencesContainer = styled.div`
  ${ADMIN_SCROLL_BAR};
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 300px;
  padding: 0 4px;
  margin: 0 -4px;
`;
