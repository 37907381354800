import React from 'react';
import FormattedTime from 'components/ui/FormattedTime';
import { Container, Backslash } from './styles';
import { useHighFrequencyPlaybackStateUpdates } from 'components/Video/video-player/Player/Context';

interface TimeProgressProps {
  className?: string;
  videoDuration: number;
}

export default function TimeProgress({
  className,
  videoDuration,
}: TimeProgressProps) {
  const { playedSeconds } = useHighFrequencyPlaybackStateUpdates();

  return (
    <Container className={className}>
      <FormattedTime seconds={playedSeconds} />
      <Backslash />
      <FormattedTime seconds={videoDuration} />
    </Container>
  );
}
