import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SITE_NAVIGATION_ITEM_ID, SITE_NAVIGATION_ITEM_ID_MATCHED } from 'global-ids';
import hash from 'json-stable-stringify';
import { INavigationItem, INavigationParent, NAVIGATION_PARENT_TYPE } from 'models/INavigation';
import { getEditableFolderIdInSiteNavigation, getSearchText, setSearchText } from 'services/navigationv2';
import { getDraftHomeId, getPendingPageDocs } from 'services/admin';
import ChannelLiveBadge from 'components/ui/ChannelLiveBadge';
import PageLink from 'components/ui/PageLink';
import HighlightedText from 'components/ui/HighlightedText';
import { Column, Container, HomeIcon, Left, ItemName, Right, Row, SiteNavigationItemTypeIcon, ItemNameCounter, ArrowIcon, PrivateIcon, UnpublishedChangesContainer, PrivateIconContainer } from './styles';
import { NAV_ITEM_NAME_MAX_LENGTH, mapNavigationItemTypeToIcon } from 'services/navigationv2/utils';
import UnpublishedChangesBadge from 'components/admin2/ui/UnpublishedChangesBadge';
import { usePageNavigationContext } from 'components/admin-bridge/PageNavigation/Context';
import { getPageId } from 'services/app';
import FolderInput from './FolderInput';
import MenuOptions from './MenuOptions';

interface ISiteNavigationItemProps {
  isParent: boolean;
  item: INavigationItem;
  parentItem?: INavigationParent;
  source: 'site_navigation' | 'toolbar';
  toolbarPointUp?: boolean;
}

const SiteNavigationItem = ({
  isParent, parentItem, item, source, toolbarPointUp,
}: ISiteNavigationItemProps) => {
  const homeId = useSelector(getDraftHomeId);
  const editableFolderId = useSelector(getEditableFolderIdInSiteNavigation);
  const searchText = useSelector(getSearchText);
  const pageDraftDocs = useSelector(getPendingPageDocs);
  const currentPageId = useSelector(getPageId);
  const { closeNavigation } = usePageNavigationContext();
  const dispatch = useDispatch();

  const isHome = homeId === item.id;
  const isCurrentPage = currentPageId === item.id;
  const hasCurrentDraft = Boolean(pageDraftDocs[item.id]);
  const isItemAChannel = item.type === NAVIGATION_PARENT_TYPE.channel;

  const itemNameMatchesSearch = source === 'site_navigation' && Boolean(item.name.toLowerCase().match(new RegExp(searchText.toLowerCase())));
  const highlight = source === 'site_navigation' ? searchText : '';
  const isEditingFolder = source === 'site_navigation' && editableFolderId === item.id;
  const shouldRenderPageLink = source === 'site_navigation' && item.type !== NAVIGATION_PARENT_TYPE.folder;

  const [itemName, setItemName] = useState(item.name);
  useEffect(() => { setItemName(item.name); }, [item.name]);
  const [isChannelLive, setIsChannelLive] = useState(false);

  const itemNameMaxLength = useMemo(() => {
    let offset = 2;

    if (!isParent) {
      offset += 3;
    }

    if (item.private) {
      offset += 2;
    }

    if (isHome) {
      offset += 2;
    }

    if (isChannelLive) {
      offset += 5;
    }

    if (source === 'toolbar') {
      offset += isHome ? -1 : -3;
    }

    return NAV_ITEM_NAME_MAX_LENGTH - offset;
  }, [isHome, item.private, isChannelLive, source, isParent]);

  const iconName = useMemo(() => {
    return mapNavigationItemTypeToIcon(item.type);
  }, [item.type]);

  const dataTestId = useMemo(() => {
    if (source === 'site_navigation') {
      return itemNameMatchesSearch ? SITE_NAVIGATION_ITEM_ID_MATCHED : SITE_NAVIGATION_ITEM_ID;
    }
    if (source === 'toolbar') {
      return 'channelDropdown';
    }
    return '';
  }, [source, itemNameMatchesSearch]);

  const handleLinkClick = useCallback(() => {
    dispatch(setSearchText(''));
    closeNavigation();
  }, [dispatch, closeNavigation]);

  const renderItemName = useCallback(() => {
    if (isEditingFolder) {
      return (
        <FolderInput
          onChange={setItemName}
          name={itemName}
          item={item}
        />
      );
    }

    return (
      <ItemName maxLength={itemNameMaxLength}>
        <HighlightedText text={itemName} highlight={highlight} admin={true} />
      </ItemName>
    );
  }, [isEditingFolder, itemName, itemNameMaxLength, hash(item), highlight]);

  const renderMenuOptionsOrArrowIcon = useCallback(() => {
    if (source === 'site_navigation') {
      return (
        <MenuOptions
          item={item}
          isParent={isParent}
        />
      );
    }

    if (source === 'toolbar') {
      return (
        <ArrowIcon pointUp={toolbarPointUp} />
      );
    }

    return null;
  }, [source, isParent, hash(item), toolbarPointUp]);

  const onIsLiveUpdate = useCallback((isLive: boolean) => {
    if (isItemAChannel && isChannelLive !== isLive) {
      setIsChannelLive(isLive);
    }
  }, [isItemAChannel, isChannelLive]);

  return (
    <Container
      id={SITE_NAVIGATION_ITEM_ID}
      data-testid={dataTestId}
      source={source}
      isCurrentPage={isCurrentPage}
      isEditingFolder={isEditingFolder}
    >
      {shouldRenderPageLink && <PageLink plain={true} slug={item.slug} onClick={handleLinkClick}/>}
      <Left>
        <SiteNavigationItemTypeIcon
          iconName={iconName}
          isParent={isParent}
        />
        <Column>
          <Row>
            {renderItemName()}
            {isItemAChannel && <ChannelLiveBadge id={item.id} onIsLiveUpdate={onIsLiveUpdate} />}
          </Row>
          {hasCurrentDraft && (
            <UnpublishedChangesContainer>
              <UnpublishedChangesBadge />
            </UnpublishedChangesContainer>
          )}
        </Column>
      </Left>
      <Right>
        {item.private && (
          <PrivateIconContainer>
            <PrivateIcon />
          </PrivateIconContainer>
        )}
        {isHome && <HomeIcon />}
        {isEditingFolder && <ItemNameCounter>{itemName.length}/{NAV_ITEM_NAME_MAX_LENGTH}</ItemNameCounter>}
        {renderMenuOptionsOrArrowIcon()}
      </Right>
    </Container>
  );
};

export default SiteNavigationItem;
