export const PLAYLIST = 'playlist';
export const SPOTLIGHT = 'spotlight';
export const VIDEO_SPOTLIGHT = 'videoSpotlight';
export const EVENT_CALENDAR = 'eventCalendar';
export const RICH_TEXT = 'richText';
export const HERO_BANNER = 'heroBanner';
export const IMAGE_AND_TEXT = 'imageAndText';
export const PAGES = 'pagesRow';
export const TAGGED_VIDEOS = 'taggedVideos';
export const TEXT_BANNER = 'textBanner';
export const IMAGE_GALLERY = 'imageGallery';
export const FAQ = 'faqV1';
export const VIDEO = 'video';

/**
 * Pricing V2: For starter plans, pages cannot be published if they contain any of these components
 */
export const COMPONENTS_WITH_VIDEO = [
  PLAYLIST,
  VIDEO_SPOTLIGHT,
  TAGGED_VIDEOS,
];
