import styled, { css } from 'styled-components';
import Icon from 'components/ui/Icon';
import {
  ADMIN_TEXT_200,
  ADMIN_TEXT_100,
  SPACING_MEDIUM,
  SPACING_SMALL,
  SPACING_X_SMALL,
} from 'style/constants';
import {
  ADMIN_BACKGROUND_TITLE,
  ADMIN_SCROLL_BAR,
} from 'style/mixins';

import ActionLink from '../ActionLink';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_BODY_XS_MEDIUM, ADMIN_TEXT_BODY_XS_REGULAR, ADMIN_TEXT_TITLE_S } from 'style/design-system/textStyles';

export const Container = styled.div`
  ${ADMIN_SCROLL_BAR};
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_TEXT_100};
  flex: 1 1 100%;
  overflow-x: hidden;
  overflow-y: auto;
  overflow-y: overlay;
  padding: ${SPACING_MEDIUM};
  ${ADMIN_BACKGROUND_TITLE};
  z-index: 1;
`;

export const ContentHeader = styled.div`
  margin-top: ${SPACING_MEDIUM};
  margin-bottom: ${SPACING_X_SMALL};
  ${ADMIN_TEXT_TITLE_S};
`;

export const ActionRow = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  margin-top: ${SPACING_SMALL};
  & > ${ActionLink}:not(:first-child) {
    margin-left: 16px;
  }
`;

export const FlexSpacer = styled.div`
  flex: 1 1 0;
`;

const ICON_MARGIN = css`
  margin-left: 8px;
`;

export const EditIcon = styled(Icon).attrs({
  name: 'circle-check',
})`
  ${ICON_MARGIN}
  font-size: 16px;
`;

export const PencilIcon = styled(Icon).attrs({
  name: 'pencil',
})`
  ${ICON_MARGIN};
  cursor: default;
  & > svg {
    width: 16px;
    height: 16px;
    fill: ${ADMIN_TEXT_200}
  }
  & > svg:hover {
    fill: ${ADMIN_TEXT_100};
  }
  &:hover {
    cursor: pointer;
  }
`;

export const Priorities = styled.span`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${ADMIN_TEXT_100};
  white-space: nowrap;
  letter-spacing: 1px;
  padding-top: 4px;
`;

export const PriorityHelper = styled.div`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  color: ${ADMIN_TEXT_200};
`;
