import React, { useState, useCallback, ReactNode, SyntheticEvent } from 'react';
import { Portal } from 'react-portal';
import { TOAST_PORTAL } from 'global-ids';
import { createObjectId } from 'utils';
import { Toast } from './styles';

export { ToastsContainer } from './styles';

export type TToast = {
  content: ReactNode;
};

export const useToast = () => {
  const [toasts, setToasts] = useState<(TToast & { id: string })[]>([]);

  const handleAnimationEnded = useCallback(
    (ev: SyntheticEvent<HTMLDivElement>) => {
      const toastId = ev.currentTarget.dataset.toastid;
      setToasts(
        (prevToasts) => prevToasts.filter(
          ({ id }) => id !== toastId,
        ),
      );
    },
    [],
  );

  const renderToasts = useCallback(
    () => {
      if (!toasts.length) return null;

      return (
        <Portal node={document.getElementById(TOAST_PORTAL)}>
          {toasts.map(({ content, id }) => (
            <Toast
              key={id}
              data-toastid={id}
              onAnimationEnd={handleAnimationEnded}
            >
              {content}
            </Toast>
          ))}
        </Portal>
      );
    },
    [toasts, handleAnimationEnded],
  );

  const showToast = useCallback(
    (t: TToast) => setToasts(
      (prevToasts) => [
        ...prevToasts,
        {
          ...t,
          id: createObjectId(),
        },
      ],
    ),
    [],
  );

  return {
    renderToasts,
    showToast,
  };
};
