import { ADMIN_TEXT_200, ADMIN_TEXT_100 } from 'style/constants';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_LABEL_M_BOLD } from 'style/design-system/textStyles';
import styled from 'styled-components';

export const SectionTitle = styled.div`
  ${ADMIN_TEXT_LABEL_M_BOLD}
  color: ${ADMIN_TEXT_100};
  text-transform: capitalize;
`;

export const SubSectionTitle = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM};
  font-weight: 600;
  color: ${ADMIN_TEXT_200};
  text-transform: capitalize;
`;

export const Section = styled.div<{ gap: number; }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap}px;
`;
