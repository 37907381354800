import { INavigationItem } from 'models/INavigation';
import React from 'react';
import { ItemNameInput } from './styles';
import { getDefaultNavigation, setEditableFolderIdInSiteNavigation, updateNavigation } from 'services/navigationv2';
import { slugify } from 'shared/string-utils';
import { getFolderName, treatFolderRenaming } from 'services/navigationv2/utils';
import { useDispatch, useSelector } from 'react-redux';

type FolderInputProps = {
  item: INavigationItem;
  name: string;
  onChange: (value: string) => void;
};

const FolderInput = ({ onChange, item, name }: FolderInputProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const navigation = useSelector(getDefaultNavigation);
  const dispatch = useDispatch();

  const renameFolder = React.useCallback((targetName: string) => {
    const updatedNavigation = structuredClone(navigation);
    const folder = updatedNavigation?.parents.find(parent => parent.id === item.id);
    if (!folder) return;

    const newFolderName = treatFolderRenaming(targetName);
    const newName = getFolderName(updatedNavigation, newFolderName, item.id);
    folder.name = newName;
    folder.slug = slugify(newName);
    onChange(newName);

    dispatch(setEditableFolderIdInSiteNavigation(''));
    dispatch(updateNavigation(updatedNavigation));
  }, [dispatch, item.id, navigation, onChange]);

  const handleKeyDown = React.useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      renameFolder(name);
    }
  }, [name, renameFolder]);

  React.useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <ItemNameInput
      setInputRef={inputRef}
      value={name}
      onChange={onChange}
      onBlur={renameFolder}
      onKeyDown={handleKeyDown}
      padding="0"
    />
  );
};

export default FolderInput;
