import React, { FC, useCallback, useState, useEffect, useMemo } from 'react';
import { ResolvedReturnType } from 'utils';
import TranslatedText from 'components/i18n/TranslatedText';
import KeyedListMapper from 'components/core/KeyedListMapper';
import { useDispatch, useSelector } from 'hooks';
import { writeLegacySuccess } from 'services/admin';
import { getPrimaryToken } from 'services/auth';
import { getSiteId } from 'services/app/selectors';
import { LargeButton } from 'components/admin2/ui/Button';
import Row from 'components/admin2/ui/Library/Row';
import {
  StyledAdminModal,
  Container,
  Header,
  WarningText,
  WarningIcon,
  Body,
  Notice,
  Footer,
  ReferencesContainer,
} from './styles';
import { validateReferences, deleteVideo } from './helpers';
import { useAdminTranslation } from 'hooks/use-translation';

/*
 * plural keys are for mapping filter value
 * singular keys are for mapping mongo collection on unarchive
 */
export const mapKinds = ({ collection }) => ({
  cards: { icon: 'cardLarge', name: 'Card' },
  groups: { icon: 'groupLarge', name: 'Group' },
  overlays: { icon: 'overlayLarge', name: 'Overlay' },
  pages: { icon: 'pageLarge', name: 'Page' },
  panels: { icon: 'panelLarge', name: 'Panel' },
  people: { icon: 'personLarge', name: 'Person' },
  playlist: { icon: 'list-bullet', name: 'Playlist' },
  playlists: { icon: 'list-bullet', name: 'Playlist' },
  polls: { icon: 'multipleChoiceLarge', name: 'Multiple Choice' },
  products: { icon: 'productLarge', name: 'Products' },
  quest: { icon: 'questLarge', name: 'Quest' },
  quests: { icon: 'questLarge', name: 'Quest' },
  video: { icon: 'videoLarge', name: 'Video' },
  videos: { icon: 'videoLarge', name: 'Video' },
}[collection]);

type ReferenceValidatedItem = {
  collection: string;
  objectId: string;
  onDelete?: never;
};

type NoReferenceValidationItem = {
  collection?: never;
  objectId?: never;
  onDelete: () => unknown;
};

export type DeleteConfirmationModalProps = {
  onClose: () => unknown;
} & (
  (
    {
      bulk?: never;
    } & (
      | ReferenceValidatedItem
      | NoReferenceValidationItem
    )
  ) | {
    bulk: (ReferenceValidatedItem | NoReferenceValidationItem)[];
    collection?: never;
    objectId?: never;
    onDelete?: never;
  }
);

const DeleteConfirmationModal: FC<DeleteConfirmationModalProps> = ({
  bulk,
  collection,
  objectId,
  onClose,
  onDelete,
}) => {
  const dispatch = useDispatch();
  const { t } = useAdminTranslation();
  const primaryToken = useSelector(getPrimaryToken);
  const siteId = useSelector(getSiteId);

  const needToValidateReferences = useMemo(
    () => (
      !!collection && !!objectId
    ) || (
      bulk?.some(
        (m) => !!m.collection && !!m.objectId,
      )
    ),
    [collection, objectId, bulk],
  );

  const [references, setReferences] = useState(
    needToValidateReferences
      ? undefined
      : [] as { collection: string; name: string; }[],
  );

  const referencesLoading = references === undefined;

  useEffect(
    () => {
      const abortHandlers: (() => unknown)[] = [];

      if (needToValidateReferences) {
        (
          async () => {
            if (!!collection && !!objectId) {
              setReferences(
                await validateReferences(
                  objectId,
                  collection,
                  primaryToken!,
                  siteId,
                  (canceler) => abortHandlers.push(canceler),
                ),
              );
            }

            if (bulk) {
              const newReferences: ResolvedReturnType<typeof validateReferences> = [];

              await Promise.all(
                bulk.map(
                  async (item) => {
                    if (!item.collection || !item.objectId)
                      return;

                    newReferences.push(
                      ...(
                        await validateReferences(
                          item.objectId,
                          item.collection,
                          primaryToken!,
                          siteId,
                          (canceler) => abortHandlers.push(canceler),
                        )
                      ),
                    );
                  },
                ),
              );

              setReferences(newReferences);
            }
          }
        )();
      }

      return () => abortHandlers.forEach(
        (cancel) => cancel(),
      );
    },
    [needToValidateReferences, primaryToken, siteId, collection, objectId, bulk],
  );

  const updateRefreshKey = useCallback(
    () => dispatch(
      writeLegacySuccess(),
    ),
    [dispatch],
  );

  const handleDelete = useCallback(
    async () => {
      if (onDelete) {
        onDelete();
        onClose();
        return;
      }

      try {
        if (!!collection && !!objectId) {
          await deleteVideo(
            objectId,
            collection,
            primaryToken!,
            siteId,
          );
          updateRefreshKey();
        }

        if (bulk) {
          await Promise.all(
            bulk.map(
              async (item) => {
                if (item.onDelete) {
                  await item.onDelete();
                  return;
                }

                await deleteVideo(
                  item.objectId,
                  item.collection,
                  primaryToken!,
                  siteId,
                );
              },
            ),
          );

          updateRefreshKey();
        }
      } catch (e) {
        // tslint:disable:next-line no-console
        console.error(e);
      }

      onClose();
    },
    [onClose, updateRefreshKey, primaryToken, siteId, onDelete],
  );

  const cantIgnoreReferences = useMemo(
    () => Boolean(
      references
      && references.length > 0
      && collection !== 'videos'
      && !bulk?.some(
        (b) => b.collection === 'videos',
      ),
    ),
    [references, collection, bulk],
  );

  return (
    <StyledAdminModal
      fixedDimensions={true}
      onClose={onClose}
    >
      <Container>
        <Header>
          <WarningIcon />
          <WarningText>
            <TranslatedText stringKey="ADMIN_PROMPT_CONFIRM_DELETION" />
          </WarningText>
        </Header>
        {referencesLoading && (
          <Notice>
            {t('ADMIN_MODAL_CHECKING_ITEMS_CURRENTLY_IN_USE')}
          </Notice>
        )}
        {!referencesLoading && (
          <>
            {references!.length > 0 && (
              <Body>
                <Notice>
                  {
                    (bulk && bulk.length > 1)
                      ? <>{t('ADMIN_ITEMS_IN_DELETE_CONFIRMATION_MODAL')}</>
                      : (
                        <TranslatedText
                          stringKey="ADMIN_LABEL_THIS_ITEM_APPEARS_IN"
                          renderAsFragment={true}
                        />
                      )
                  }
                  {` ${references!.length} `}
                  <TranslatedText
                    stringKey="ADMIN_LABEL_PLACE(S)"
                    renderAsFragment={true}
                  />
                </Notice>
                <ReferencesContainer>
                  <KeyedListMapper<{ collection: string; name: string; }>
                    keyField="_id"
                    list={references!}
                  >
                    {(key, item) => {
                      const kind = mapKinds(item);
                      return (
                        <Row
                          key={key}
                          icon={kind.icon}
                          name={item.name}
                          type={kind.name}
                        />
                      );
                    }}
                  </KeyedListMapper>
                </ReferencesContainer>
              </Body>
            )}
            <Footer>
              <LargeButton
                black={true}
                onClick={onClose}
                width="40%"
              >
                <TranslatedText stringKey="ADMIN_ACTION_CANCEL_DELETION" />
              </LargeButton>
              <LargeButton
                disabled={cantIgnoreReferences}
                onClick={handleDelete}
                width="40%"
              >
                <TranslatedText stringKey="ADMIN_ACTION_CONFIRM_DELETION" />
              </LargeButton>
            </Footer>
          </>
        )}
      </Container>
    </StyledAdminModal>
  );
};

export default DeleteConfirmationModal;
