import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRectPositionCheckingBounds } from 'dom-utils';
import hash from 'json-stable-stringify';
import { NAVIGATION_BLOCK_CUSTOM_NAVIGATION_MENU_ITEM_ID, NAVIGATION_BLOCK_CUSTOM_NAVIGATION_MENU_ITEM_CARDS_MENU_ID, NAVIGATION_BLOCK_CUSTOM_NAVIGATION_MENU_ITEM_THREE_DOTS_MENU_ID, NAVIGATION_BLOCK_CUSTOM_NAVIGATION_REMOVE_ITEM_ID, NAVIGATION_BLOCK_CUSTOM_NAVIGATION_RENAME_ITEM_ID } from 'global-ids';
import useFloatMenu from 'hooks/use-float-menu';
import { useAdminTranslation } from 'hooks/use-translation';
import { getEditableFolderIdInNavigationBlock, getNavigationSelectedInTheDropdown, removeItemFromCustomNavigation, setEditableFolderIdInNavigationBlock, updateNavigation } from 'services/navigationv2';
import { NAV_ITEM_NAME_MAX_LENGTH, getFolderName, mapNavigationItemTypeToIcon, treatFolderRenaming, updateNavigationItem } from 'services/navigationv2/utils';
import { dismissModal, showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';
import { INavigationItem, INavigationParent, NAVIGATION_PARENT_TYPE } from 'models/INavigation';
import { IMenuAction } from 'components/ui/CardsMenu';
import ThreeDotsMenu from 'components/ui/ThreeDotsMenu';
import { Container, IconAndName, Name, NameInput } from './styles';
import { slugify } from 'shared/string-utils';
import { SiteNavigationItemTypeIcon } from 'components/admin-bridge/SiteNavigation/SiteNavigationItem/styles';

const FOLDER_NAME_MAX_LENGTH = NAV_ITEM_NAME_MAX_LENGTH;

interface IMenuItem {
  navigationItem: INavigationItem;
}

const MenuItem = ({ navigationItem }: IMenuItem) => {
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();
  const selectedNavigation = useSelector(getNavigationSelectedInTheDropdown);
  const editableFolderId = useSelector(getEditableFolderIdInNavigationBlock);
  const isEditingFolder = editableFolderId === navigationItem.id;
  const [itemName, setItemName] = useState(navigationItem.name);
  const iconName = useMemo(() => {
    return mapNavigationItemTypeToIcon(navigationItem.type);
  }, [navigationItem.type]);

  const isParent = selectedNavigation.parents.some(parent => parent.id === navigationItem.id);
  const isParentWithChildren = useMemo(() => {
    const navigationParent = navigationItem as INavigationParent;
    const hasChildren = navigationParent.children?.length > 0;
    return isParent && hasChildren;
  }, [isParent, hash(navigationItem)]);

  const handleNameChange = useCallback((value: string) => {
    setItemName(value.slice(0, FOLDER_NAME_MAX_LENGTH));
  }, []);

  const triggerUpdate = useCallback(() => {
    const folderName = treatFolderRenaming(itemName);
    const newName = getFolderName(selectedNavigation, folderName, navigationItem.id);
    const newItem = {
      ...navigationItem,
      name: newName,
      slug: slugify(newName),
    };
    const updatedNavigation = updateNavigationItem(selectedNavigation, newItem);
    dispatch(updateNavigation(updatedNavigation));
    dispatch(setEditableFolderIdInNavigationBlock(''));
    setItemName(newName);
  }, [dispatch, itemName, navigationItem, selectedNavigation]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      triggerUpdate();
    }
  }, [triggerUpdate]);

  const handleBlur = useCallback(() => {
    if (isEditingFolder) {
      triggerUpdate();
    }
  }, [isEditingFolder, triggerUpdate]);

  const setMenuPosition = useCallback((menu: HTMLDivElement, menuContainer: HTMLDivElement) => {
    const menuContainerRect = menuContainer.getBoundingClientRect();
    const safePosition = getRectPositionCheckingBounds({
      elem: menu,
      position: {
        x: menuContainerRect.right,
        y: menuContainerRect.top,
      },
    });

    menu.style.left = `${safePosition.x + 5}px`;
    menu.style.top = `${safePosition.y}px`;
  }, []);
  const { isOpen: isFloatMenuOpen, containerRef, positionMenu, toggleMenu } = useFloatMenu(setMenuPosition);

  const renameFolder = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    toggleMenu();
    dispatch(setEditableFolderIdInNavigationBlock(navigationItem.id));
  }, [toggleMenu, navigationItem.id, dispatch]);

  const deleteItemFromNavigation = useCallback(() => {
    dispatch(
      showModal({
        kind: ModalKinds.adminConfirmation,
        data: {
          onConfirmClick: () => {
            dispatch(removeItemFromCustomNavigation(selectedNavigation._id!, navigationItem.id));
            dispatch(dismissModal('adminConfirmation'));
          },
          titleKey: 'ADMIN_NAVIGATION_BLOCK_CARDS_MENU_CUSTOM_NAVIGATION_ITEM_DELETE_TITLE',
          ...isParentWithChildren && {
            subtitleKey: 'ADMIN_NAVIGATION_BLOCK_CARDS_MENU_CUSTOM_NAVIGATION_ITEM_DELETE_SUBTITLE',
          },
        },
      }),
    );

    toggleMenu();
  }, [toggleMenu, dispatch, isParentWithChildren, navigationItem.id, selectedNavigation._id]);

  const menuActions = useMemo<IMenuAction[]>(() => {
    if (navigationItem.type === NAVIGATION_PARENT_TYPE.folder) {
      return [
        {
          id: 'folder-rename',
          name: t('ADMIN_NAVIGATION_BLOCK_CUSTOM_MENU_ITEM_ACTIONS_RENAME'),
          icon: 'pencil',
          dataTestId: NAVIGATION_BLOCK_CUSTOM_NAVIGATION_RENAME_ITEM_ID,
          handler: renameFolder,
        },
        {
          id: 'page-remove_from_custom_navigation',
          name: t('ADMIN_NAVIGATION_BLOCK_CUSTOM_MENU_ITEM_ACTIONS_REMOVE'),
          icon: 'trashV2',
          dataTestId: NAVIGATION_BLOCK_CUSTOM_NAVIGATION_REMOVE_ITEM_ID,
          handler: deleteItemFromNavigation,
        },
      ];
    }

    return [
      {
        id: 'page-remove_from_custom_navigation',
        name: t('ADMIN_NAVIGATION_BLOCK_CUSTOM_MENU_ITEM_ACTIONS_REMOVE'),
        icon: 'trashV2',
        dataTestId: NAVIGATION_BLOCK_CUSTOM_NAVIGATION_REMOVE_ITEM_ID,
        handler: deleteItemFromNavigation,
      },
    ];
  }, [navigationItem.type, renameFolder, deleteItemFromNavigation]);

  return (
    <Container
      ref={containerRef}
      isEditing={isEditingFolder}
      id={NAVIGATION_BLOCK_CUSTOM_NAVIGATION_MENU_ITEM_ID}
      data-testid={NAVIGATION_BLOCK_CUSTOM_NAVIGATION_MENU_ITEM_ID}
    >
      <IconAndName>
        <SiteNavigationItemTypeIcon iconName={iconName} isParent={isParent} />
        {isEditingFolder ? (
          <NameInput
            value={itemName}
            onChange={handleNameChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            autoFocus={true}
            padding="0"
          />
        ) : <Name maxLength={23}>{navigationItem.name}</Name>}
      </IconAndName>
      <ThreeDotsMenu
        threeDotsMenuId={NAVIGATION_BLOCK_CUSTOM_NAVIGATION_MENU_ITEM_THREE_DOTS_MENU_ID}
        cardsMenuId={NAVIGATION_BLOCK_CUSTOM_NAVIGATION_MENU_ITEM_CARDS_MENU_ID}
        menuActions={menuActions}
        toggleMenu={toggleMenu}
        positionMenu={positionMenu}
        isFloatMenuOpen={isFloatMenuOpen}
        isAdmin={true}
      />
    </Container>
  );
};

export default MenuItem;
