import styled from 'styled-components';

export const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  position: relative;
  background: rgba(0,0,0,.1);
  overflow: hidden;
`;
