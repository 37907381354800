import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SITE_NAVIGATION_CARDS_MENU_ID } from 'global-ids';
import hash from 'json-stable-stringify';
import { PageType } from 'models';
import { INavigationItem, INavigationParent, NAVIGATION_PARENT_TYPE, NavigationParentType, navigationParentTypeToPageType } from 'models/INavigation';
import { getDefaultNavigation, getEditableFolderIdInSiteNavigation, setEditableFolderIdInSiteNavigation, updateNavigation } from 'services/navigationv2';
import { setActiveAction, setHomePage } from 'services/admin/actions';
import { clonePage } from 'services/page';
import { dismissModal, showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';
import { useAdminTranslation } from 'hooks/use-translation';
import useDeletePage from 'hooks/use-delete-page';
import usePageCreator from 'hooks/use-page-creator';
import { CardsMenuContainer, MenuAction, MenuActionIcon, MenuActionName } from 'components/ui/CardsMenu/styles';
import { removeItemFromNavigation } from 'services/navigationv2/utils';
import { TI18nKey } from 'components/i18n/TranslatedText/i18nKey';
import { usePageNavigationContext } from 'components/admin-bridge/PageNavigation/Context';

interface ICardsMenuProps {
  closeItself: () => void;
  isHome: boolean;
  isParent: boolean;
  item: INavigationItem;
  type: NavigationParentType;
}

type MenuActions = {
  handler: () => void;
  icon: string;
  id: string;
  name: TI18nKey;
}[];

const CardsMenu = React.forwardRef(({
  closeItself,
  item,
  isHome,
  isParent,
  type,
}: ICardsMenuProps, ref) => {
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();
  const editableFolderId = useSelector(getEditableFolderIdInSiteNavigation);
  const defaultNavigation = useSelector(getDefaultNavigation);
  const [createNewPage] = usePageCreator();
  const { closeNavigation } = usePageNavigationContext();

  const { deleteDoc, canDelete } = useDeletePage(item.id);

  const duplicatePage = useCallback(() => {
    dispatch(clonePage(
      item.id,
      t('LABEL_CLONED_CHANNEL_DEFAULT_NAME', { originalName: item.name }),
      t('LABEL_CLONED_CHANNEL_DEFAULT_SLUG', { originalSlug: item.slug }),
      navigationParentTypeToPageType[type],
    ));
    closeItself();
  }, [hash(item), type, closeItself, dispatch, t]);

  const setAsHomepage = useCallback(() => {
    dispatch(setHomePage({ pageId: item.id }));
    closeItself();
  }, [item.id, closeItself, dispatch]);

  const deletePage = useCallback(() => {
    if (!canDelete) return;
    deleteDoc();
    dispatch(setActiveAction(null));
    closeItself();
  }, [canDelete, closeItself, deleteDoc, dispatch]);

  const deleteFolder = useCallback(() => {
    const updatedDefaultNavigation = removeItemFromNavigation(structuredClone(defaultNavigation), item.id);
    dispatch(updateNavigation(updatedDefaultNavigation));
    closeItself();
  }, [closeItself, defaultNavigation, dispatch, item.id]);

  const handleDeleteItem = useCallback((deleteItem: () => void) => {
    let titleKey = '';
    let subtitleKey = '';

    const isChild = !isParent;
    const navigationParent = item as INavigationParent;
    const isAParentWithNoChildren = isParent && navigationParent.children.length === 0;
    const isAParentWithChildren = isParent && navigationParent.children.length > 0;

    if (isChild || isAParentWithNoChildren) {
      if (item.type === NAVIGATION_PARENT_TYPE.landing) {
        titleKey = 'ADMIN_PROMPT_CONFIRM_PAGE_DELETION';
        subtitleKey = '';
      }
      if (item.type === NAVIGATION_PARENT_TYPE.channel) {
        titleKey = 'ADMIN_PROMPT_CONFIRM_CHANNEL_DELETION';
        subtitleKey = '';
      }
      if (item.type === NAVIGATION_PARENT_TYPE.folder) {
        deleteItem();
        return;
      }
    }

    if (isAParentWithChildren && item.type === NAVIGATION_PARENT_TYPE.folder) {
      titleKey = 'ADMIN_PROMPT_CONFIRM_FOLDER_DELETION';
      subtitleKey = 'ADMIN_PROMPT_CONFIRM_FOLDER_DELETION_DESCRIPTION';
    }

    if (isAParentWithChildren && item.type === NAVIGATION_PARENT_TYPE.landing) {
      titleKey = 'ADMIN_PROMPT_CONFIRM_PAGE_DELETION';
      subtitleKey = 'ADMIN_SITE_NAVIGATION_CARDS_MENU_PAGE_DELETE_CONFIRMATION_SUBTITLE';
    }

    dispatch(
      showModal({
        kind: ModalKinds.adminConfirmation,
        data: {
          onConfirmClick: () => {
            deleteItem();
            dispatch(dismissModal('adminConfirmation'));
          },
          titleKey: titleKey as any,
          subtitleKey: subtitleKey as any,
        },
      }),
    );
  }, [item, isParent, dispatch]);

  const createChild = useCallback((pageType: PageType) => {
    createNewPage(pageType, item.id);
    closeItself();
    closeNavigation();
  }, [closeItself, closeNavigation, createNewPage, item.id]);

  const renameFolder = useCallback(() => {
    dispatch(setEditableFolderIdInSiteNavigation(editableFolderId === item.id ? '' : item.id));
    closeItself();
  }, [closeItself, dispatch, editableFolderId, item.id]);

  const menuActions = useMemo(() => {
    if (type === NAVIGATION_PARENT_TYPE.landing) {
      const landingActions: MenuActions = [
        {
          id: 'landing-set_as_homepage',
          name: 'SET_AS_HOMEPAGE',
          icon: 'homeFilled',
          handler: setAsHomepage,
        },
        {
          id: 'landing-duplicate',
          name: 'DUPLICATE_PAGE',
          icon: 'copy',
          handler: duplicatePage,
        },
        ...(isParent ? [
          {
            id: 'landing-create_sub_page',
            name: 'CREATE_SUB_PAGE' as const,
            icon: 'plus',
            handler: () => createChild(PageType.LANDING),
          },
          {
            id: 'landing-create_sub_channel',
            name: 'CREATE_SUB_CHANNEL' as const,
            icon: 'plus',
            handler: () => createChild(PageType.CHANNEL),
          },
        ] : []),
        ...(!isHome ? [
          {
            id: 'landing-delete',
            name: 'ADMIN_LABEL_DELETE_PAGE' as const,
            icon: 'trashV2',
            handler: () => handleDeleteItem(deletePage),
          },
        ] : []),
      ];

      return landingActions;
    }

    if (type === NAVIGATION_PARENT_TYPE.channel) {
      const channelActions: MenuActions = [
        {
          id: 'channel-set_as_homepage',
          name: 'SET_AS_HOMEPAGE',
          icon: 'homeFilled',
          handler: setAsHomepage,
        },
        {
          id: 'channel-duplicate',
          name: 'DUPLICATE_CHANNEL',
          icon: 'copy',
          handler: duplicatePage,
        },
        ...(!isHome ? [
          {
            id: 'channel-delete',
            name: 'ADMIN_LABEL_DELETE_CHANNEL' as const,
            icon: 'trashV2',
            handler: () => handleDeleteItem(deletePage),
          },
        ] : []),
      ];

      return channelActions;
    }

    if (type === NAVIGATION_PARENT_TYPE.folder) {
      const folderActions: MenuActions = [
        {
          id: 'folder-rename',
          name: 'RENAME_FOLDER',
          icon: 'pencil',
          handler: renameFolder,
        },
        ...(isParent ? [
          {
            id: 'folder-create_sub_page',
            name: 'CREATE_SUB_PAGE' as const,
            icon: 'plus',
            handler: () => createChild(PageType.LANDING),
          },
          {
            id: 'folder-create_sub_channel',
            name: 'CREATE_SUB_CHANNEL' as const,
            icon: 'plus',
            handler: () => createChild(PageType.CHANNEL),
          },
        ] : []),
        {
          id: 'folder-duplicate',
          name: 'DELETE_FOLDER' as const,
          icon: 'trashV2',
          handler: () => handleDeleteItem(deleteFolder),
        },
      ];

      return folderActions;
    }

    return [];
  }, [type, isHome, isParent, setAsHomepage, duplicatePage, createChild, deletePage, deleteFolder, handleDeleteItem, renameFolder]);

  return (
    <CardsMenuContainer ref={ref as any} id={SITE_NAVIGATION_CARDS_MENU_ID} data-testid={SITE_NAVIGATION_CARDS_MENU_ID} isAdmin={true}>
      {menuActions.map((menuAction) => (
        <MenuAction onClick={menuAction.handler} key={menuAction.id} data-testid={`site_navigation-cards_menu-${menuAction.id}`} isAdmin={true}>
          <MenuActionIcon iconName={menuAction.icon} isAdmin={true} />
          <MenuActionName isAdmin={true}>{t(menuAction.name as any)}</MenuActionName>
        </MenuAction>
      ))}
    </CardsMenuContainer>
  );
});

export default CardsMenu;
