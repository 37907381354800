import React from 'react';
import Checkout from 'components/Ecommerce/Checkout';
import ProductDetail from 'components/Ecommerce/ProductDetail';
import { useDispatch, useSelector } from 'react-redux';
import { getEcommerceView, getProductDetail, getShopifyBlockData } from 'services/ecommerce/selectors';
import AddNewPaymentMethodForm from './AddNewPaymentMethodForm';
import SuccessView from './SuccessView';
import SelectPaymentMethods from './SelectPaymentMethods';
import usePaymentMethods from 'hooks/use-payment-methods';
import { PaymentMethod } from '@stripe/stripe-js';
import { replaceEcommerceViews } from 'services/ecommerce';
import Cart from './Cart';
import { getShopCurrencyCode } from 'services/shopify/selectors';
import StripeElements from 'components/application/StripeElements';
import { STRIPE_PUBLISHABLE_KEY } from 'config';
import { getSavedTheme } from 'services/themes';

const EcommerceViewController = () => {
  const view = useSelector(getEcommerceView);
  const { paymentMethod, defaultPaymentMethod, setPaymentMethod, paymentMethodsAvailable, setStripeTokenId, stripeTokenId } = usePaymentMethods();
  const pm = paymentMethod || defaultPaymentMethod || paymentMethodsAvailable[0];
  const dispatch = useDispatch();
  const shopifyCurrency = useSelector(getShopCurrencyCode);
  const productDetail = useSelector(getProductDetail);
  const { returnPolicy, termsOfUse } = useSelector(getShopifyBlockData) || {};
  const siteTheme = useSelector(getSavedTheme);

  const handleSubmitNewPaymentMethod = (newPaymentMethod: PaymentMethod, tokenId: string) => {
    setPaymentMethod(newPaymentMethod);
    setStripeTokenId(tokenId);
    dispatch(replaceEcommerceViews(['cart', 'checkout']));
  };

  const cartSettings = {
    currency: shopifyCurrency,
    returnPolicy,
    termsOfUse,
  };

  const getView = () => {

    switch (view) {
      case 'cart':
        return <Cart settings={cartSettings} />;
      case 'productDetail':
        if (!productDetail) {
          return null;
        }
        return <ProductDetail {...productDetail} />;
      case 'checkoutOverlay':
      case 'checkout':
        return <Checkout paymentMethod={pm} stripeTokenId={stripeTokenId} />;
      case 'success':
        return <SuccessView paymentMethod={pm}/>;
      case 'addPaymentMethod':
        return <AddNewPaymentMethodForm onSubmit={handleSubmitNewPaymentMethod} />;
      case 'choosePaymentMethod':
        return <SelectPaymentMethods onConfirmPaymentMethod={setPaymentMethod} paymentMethod={pm} />;
      default:
        return <Cart settings={cartSettings} />;
    }
  };

  return (
    <StripeElements
      apiKey={STRIPE_PUBLISHABLE_KEY as string}
      theme={siteTheme}
    >
      {getView()}
    </StripeElements>
  );
};

export default EcommerceViewController;
