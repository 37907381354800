import { StripeElementsOptions } from '@stripe/stripe-js';
import { ITheme } from 'models';
import { DARK_THEME } from 'services/themes/constants';

export const getStripeElementStyles = (theme: ITheme): StripeElementsOptions['appearance'] => ({
  theme: 'stripe',
  variables: {
    colorText: theme.colors.text100,
    colorTextSecondary: theme.colors.text100,
    colorSuccess: theme.colors.alertConfirmation,
    colorBackground: theme.colors.accentPrimary,
    colorDanger: theme.colors.alertError,
    fontFamily: theme.typography.body,
    fontWeightNormal: '400',
    colorIcon: theme.colors.highlightPrimary,
  },
  rules: {
    '.Label': {
      color: theme.colors.text200,
      fontWeight: '600',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
    '.Input': {
      backgroundColor: '#0000',
      color: theme.colors.text100,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: '400',
      border: `1px solid ${theme.colors.surface5}`,
      borderRadius: '4px',
      fontFamily: theme.typography.body,
    },
    '.Input:focus': {
      outline: 'none',
      border: `1px solid ${theme.colors.accentPrimary}`,
      boxShadow: 'none',
    },
    '.Input:focus-visible': {
      outline: 'none',
      border: `1px solid ${theme.colors.accentPrimary}`,
      boxShadow: 'none',
    },
    '.Input::placeholder': {
      color: theme.colors.text300,
    },
    '.Input--invalid': {
      color: theme.colors.text100,
      boxShadow: 'none',
    },
    '.PickerItem': {
      backgroundColor: theme.colors.surface4,
      color: theme.colors.text100,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: '400',
      borderRadius: '4px',
      border: 'none',
    },
    '.PickerItem--selected': {
      backgroundColor: theme.colors.surface4,
      color: theme.colors.text100,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: '400',
      borderRadius: '4px',
      border: 'none',
    },
    '.PickerItem--selected:hover': {
      backgroundColor: theme.colors.surface4,
      border: 'none',
    },
    '.PickerItem--highlight': {
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)',
    },
    '.PickerItem:hover': {
      backgroundColor: theme.colors.surface5,
      border: 'none',
    },
    '.PickerItem--new': {
      color: theme.colors.accentPrimary,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: '400',
    },
    '.Action': {
      color: theme.colors.accentPrimary,
    },
    '.PickerAction': {
      color: theme.colors.accentPrimary,
    },
    '.DropdownItem': {
      backgroundColor: theme.colors.surface5,
    },

  },
});
