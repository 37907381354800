import styled from 'styled-components';
import ColorOption from 'components/ui/ColorOption';
import { TEXT_100, TEXT_300 } from 'style/constants';
import { TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';
import { mobileOnly } from 'style/mixins';

export const SmallColorOption = styled(ColorOption)`
  width: 20px;
  height: 20px;
`;

export const ColorSelectorContainer = styled.div`
  width: fit-content;
  ${mobileOnly`width: 100%;`}
`;

export const ColorSelectorHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 10px;
`;

export const Title = styled.span`
  color: ${TEXT_100};

  ${TEXT_LABEL_S_MEDIUM};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;

  margin-right: 3px;
`;

export const SelectedColor = styled(Title)`
  color: ${TEXT_300};
  text-transform: capitalize;
`;

export const ColorOptionValues = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
`;
