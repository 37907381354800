import React from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import {
  CastingViewContainer,
  CastingViewWrapper,
  Title,
  ScreenIcon,
  Device,
} from './styles';
import { MAESTRO_WHITE } from 'style/constants';

interface CastingViewProps {
  deviceName?: string;
}

export default function CastingView({
  deviceName,
}: CastingViewProps) {
  return (
    <CastingViewContainer>
      <CastingViewWrapper>
        <ScreenIcon viewBox="0 0 204 149" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="3" y="3" width="197.652" height="128.254" rx="10" stroke={MAESTRO_WHITE} stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
          <line x1="50.3613" y1="145.442" x2="153.29" y2="145.442" stroke={MAESTRO_WHITE} stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        </ScreenIcon>
        <Title>
          <TranslatedText stringKey={deviceName ? 'LABEL_CASTING_TO' : 'LABEL_CASTING'} />
          {deviceName && (
            <Device>{deviceName}</Device>
          )}
        </Title>
      </CastingViewWrapper>
    </CastingViewContainer>
  );
}
