import React from 'react';
import { useCTAEditorModalContext } from '../Context';
import { CTASize, CTA_SIZES_FONT_SIZE_MAP, sizesDropdownOptions } from '../utils';
import Label from 'components/admin2/ui/Label';
import { ButtonHeightDropdown, ButtonHeightGrid } from '../styles';
import FontSizeDropdown from './FontSizeDropdown';

const MobileHeight = () => {
  const { cta, onChangeMobileHeight  } = useCTAEditorModalContext();
  const handleChangeMobileSize = React.useCallback((option: { label: string, value: CTASize; }) => {
    if (cta.height.mobile === option.value) {
      return;
    }

    onChangeMobileHeight(option.value);
  }, [onChangeMobileHeight, cta.height.mobile]);

  const value = sizesDropdownOptions.find((option) => option.value === cta.height.mobile) || sizesDropdownOptions[1];

  return (
    <ButtonHeightDropdown
      labelKey="ADMIN_LABEL_MOBILE"
      onChange={handleChangeMobileSize}
      options={sizesDropdownOptions}
      value={value}
    />
  );
};

const DesktopHeight = () => {
  const { cta, onChangeDesktopHeight, onChangeFontSize } = useCTAEditorModalContext();
  const handleChangeMobileSize = React.useCallback((option: { label: string, value: CTASize; }) => {
    if (cta.height.desktop === option.value) {
      return;
    }

    onChangeDesktopHeight(option.value);

    // CTAButton uses CTASize as a map for the design system tokens (title L bold/ label M regular).
    // The font size here just overrides the tokens font size.
    // Upon changing, we must use the token font size value.
    if (typeof cta.fontSize !== 'undefined') {
      onChangeFontSize?.(CTA_SIZES_FONT_SIZE_MAP[option.value]);
    }
  }, [onChangeDesktopHeight, onChangeFontSize, cta.height.desktop, cta.fontSize]);

  const value = sizesDropdownOptions.find((option) => option.value === cta.height.desktop) || sizesDropdownOptions[1];

  return (
    <ButtonHeightGrid>
      <ButtonHeightDropdown
        labelKey="ADMIN_LABEL_DESKTOP"
        onChange={handleChangeMobileSize}
        options={sizesDropdownOptions}
        value={value}
      />
      <FontSizeDropdown />
    </ButtonHeightGrid>
  );
};

const ButtonHeight = () => {
  return (
    <>
      <Label labelKey="BUTTON_SIZE" padding="0" />
      <DesktopHeight />
      <MobileHeight />
    </>
  );
};

export default ButtonHeight;
