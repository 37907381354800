import React, { PropsWithChildren } from 'react';
import { css } from 'styled-components';
import OptionalTranslated from 'components/admin2/ui/OptionalTranslated';

import LinkInput from './ModalComponents/LinkInput';
import OptionRow from './ModalComponents/OptionRow';
import OptionRowButton from './ModalComponents/OptionRowButton';
import FontSizeDropdown from './ModalComponents/FontSizeDropdown';
import PercentageDropdown from './ModalComponents/PercentageDropdown';
import { Modal, ModalTitle, StyledCloseButton, StyledDraggableModal } from './styles';
import { useFocusArea } from 'hooks';
import { getRectPositionCheckingBounds } from 'dom-utils';
import type { TranslationKey } from 'hooks/use-translation';

export { FontSizeDropdown, LinkInput, OptionRow, OptionRowButton, PercentageDropdown };

interface Position { x: number; y: number }

export interface ModalProps extends PropsWithChildren {
  className?: string;
  draggableModalCss?: ReturnType<typeof css> | string;
  hidden?: boolean;
  initialPosition?: Position;
  isOpen: boolean;
  itemSpacing?: string;
  modalTitle?: string;
  modalTitleKey?: TranslationKey;
  onClose: () => void;
}

interface IWrapperProps extends PropsWithChildren {
  draggableModalCss?: ReturnType<typeof css> | string;
  position?: Position;
  ready: boolean;
}

// We only make it draggable until we know the safe position to render it in
const Wrapper: React.FC<IWrapperProps> = ({ ready, children, draggableModalCss, position }) => {
  if (!ready) {
    return <div>{children}</div>;
  }

  return (
    <StyledDraggableModal draggableModalCss={draggableModalCss} initialPosition={position}>
      {children}
    </StyledDraggableModal>
  );
};

const EditorModal: React.FC<ModalProps> = ({
  children,
  className,
  draggableModalCss,
  hidden,
  initialPosition,
  isOpen,
  itemSpacing = '15px',
  modalTitle,
  modalTitleKey,
  onClose,
}) => {
  const [position, setPosition] = React.useState(initialPosition);
  const [ready, setReady] = React.useState(false);
  const ref = useFocusArea<HTMLDivElement>({
    onExit: onClose,
    active: ready,
    ignoreIDs: ['modal-portal', 'image-library-modal'],
  });

  React.useEffect(() => {
    if (!isOpen && ready) {
      setReady(false);
    }
  }, [isOpen, ready]);

  React.useEffect(() => {
    if (!isOpen || ready) {
      return;
    }

    const safePosition = getRectPositionCheckingBounds({
      elem: ref.current,
      margin: 20,
      position: initialPosition,
    });

    setPosition({ x: safePosition.x, y: safePosition.y });
    setReady(true);
  }, [ref.current?.getBoundingClientRect?.(), initialPosition, isOpen, ready]);

  if (!isOpen) {
    return null;
  }

  return (
    <Wrapper ready={ready} draggableModalCss={draggableModalCss} position={position}>
      <Modal ref={ref} className={className} hidden={hidden} itemSpacing={itemSpacing}>
        <OptionalTranslated component={ModalTitle} stringKey={modalTitleKey}>
          {modalTitle}
        </OptionalTranslated>
        <StyledCloseButton onClick={onClose} useAbsolutePosition={true} />
        {children}
      </Modal>
    </Wrapper>
  );
};

export default EditorModal;
