import Button from 'components/ui/Button';
import { ALERT_ERROR, TEXT_100 } from 'style/constants';
import { TEXT_LABEL_L_BOLD, TEXT_LABEL_M_BOLD, TEXT_LABEL_XS_BOLD } from 'style/design-system/textStyles';
import { mobileOnly } from 'style/mixins';
import styled, { css } from 'styled-components';

export const Badge = styled(Button).attrs(props => ({
    textColor: TEXT_100(props),
}))<{
    isRenderingOnPanel?: boolean;
    source: 'CartProduct' | 'ProductDetail' | 'ProductCard';
}>`
    position: absolute;
    padding: 12px 24px;
    left: 10px;
    top: 10px;
    pointer-events: none;
    background: ${ALERT_ERROR};

    ${({ source, isRenderingOnPanel }) => {
        switch(source) {
            case 'ProductDetail':
                return css`${TEXT_LABEL_M_BOLD}`;
            case 'CartProduct':
                return css`${TEXT_LABEL_XS_BOLD}`;
            case 'ProductCard':
                if (isRenderingOnPanel) {
                    return css`
                        ${TEXT_LABEL_XS_BOLD}
                        ${mobileOnly`${TEXT_LABEL_XS_BOLD}`}
                    `;
                }
                return css`
                    ${TEXT_LABEL_L_BOLD}
                    ${mobileOnly`${TEXT_LABEL_M_BOLD}`}
                `;
            default:
                return css`${TEXT_LABEL_L_BOLD}`;
        }
    }}
`;
