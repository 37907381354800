import React from 'react';
import useStreamModeState from 'hooks/use-stream-mode-state';
import Divider from 'components/admin2/ui/Divider';
import CopyButton from 'components/admin2/ui/CopyButton';
import { StreamSource } from 'services/livestream/constants';
import StreamProviderDisplay from 'components/admin-bridge/Toolbar/actions/StreamProviderDisplay';
import StreamStatusIndicator from 'components/admin-bridge/Toolbar/actions/StreamStatusIndicator';
import GoLiveButton from 'components/admin-bridge/Toolbar/actions/GoLiveButton';
import AddMetadataModal from 'components/admin2/AddMetadataModal';
import { useSelector } from 'react-redux';
import { getAdminText300 } from 'services/themes';
import EllipsisLoader from 'components/admin2/ui/EllipsisLoader';
import { CreatingStreamLabel } from './styles';
import { useAdminTranslation } from 'hooks/use-translation';
import ExitStreamMode from '../../actions/ExitStreamMode';
import CopyStreamKeysButton from './CopyStreamKeysButton';

function StreamModeView() {
  const adminText300 = useSelector(getAdminText300);
  const {
    activeSessionId,
    endStream,
    ingestUrl,
    isEnding,
    isGoLiveButtonDisabled,
    isGoOfflineButtonDisabled,
    isStarting,
    isStreamCreationInProgress,
    source,
    startStream,
    streamKey,
    streamStatus,
  } = useStreamModeState();
  const { t } = useAdminTranslation();

  const is3rdPartyStream = source === StreamSource.THIRD_PARTY;
  const isLive = streamStatus === 'online';

  return (
    <>
      <StreamProviderDisplay />
      {
        is3rdPartyStream && (
          isStreamCreationInProgress
            ? (
              <CreatingStreamLabel>
                {t('ADMIN_STREAM_MODE_SETTING_UP_STREAM')}
                <EllipsisLoader dotSize={6} loading={true} />
              </CreatingStreamLabel>
            )
            : (
              <>
                <CopyStreamKeysButton streamKey={streamKey} streamStatus={streamStatus} />
                <CopyButton
                  data-testid="copyStreamURLButton"
                  copyText={ingestUrl}
                  labelKey="STREAM_URL"
                />
              </>
            )
        )
      }
      <Divider
        color={adminText300}
        height="25px"
        margin={is3rdPartyStream ? '0 10px' : '0 10px 0 20px'}
        orientation="vertical"
        width="0"
      />
      <StreamStatusIndicator
        activeSessionId={activeSessionId}
        streamStatus={streamStatus}
      />
      <GoLiveButton
        isEnding={isEnding}
        isDisabled={isLive ? isGoOfflineButtonDisabled : isGoLiveButtonDisabled}
        isStarting={isStarting}
        onEnd={endStream}
        onStart={startStream}
        streamStatus={streamStatus}
      />
      {!isLive && <ExitStreamMode />}
      <AddMetadataModal />
    </>
  );
}

export default {
  toolbarRight: StreamModeView,
};
