import React, { MouseEventHandler, PropsWithChildren } from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { ButtonProps } from 'components/admin2/ui/Button';
import { StyledButton, StyledLink } from '../styles';
import withRenderCondition from 'components/core/withRenderCondition';
import withTooltip from 'components/core/withTooltip';
import OptionalTranslated from 'components/admin2/ui/OptionalTranslated';
import { TranslationKey } from 'hooks/use-translation';

interface ToolbarButtonProps extends PropsWithChildren<ButtonProps> {
  href?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  query?: Record<string, string | undefined>;
  stringKey?: TranslationKey;
}

function ToolbarButton({
  href,
  query,
  stringKey,
  ...props
}: ToolbarButtonProps) {
  const Link = query ? StyledLink : React.Fragment;
  const linkProps = query ? {
    disabled: props.disabled,
    href,
    query,
  } : {};

  return (
    <Link {...linkProps}>
      <OptionalTranslated
        component={StyledButton}
        stringKey={stringKey}
        {...props}
      >
        {props.children}
      </OptionalTranslated>
    </Link>
  );
}

export default withRenderCondition(withTooltip(ToolbarButton), { defaultCondition: true });
